import moment from "moment";
import { httpRequest } from "../network";

export const createPaymentIntent = (data: object) => {
  return httpRequest('POST', '/payment/transaction/create-payment-intent', data).then((response: any) => {
    return response
  })
    .catch((err) => {
      throw err
    })
}

export const updatePaymentIntent = (data: object) => {
  return httpRequest('PUT', '/payment/transaction', data).then((response: any) => {
    return response.data
  })
    .catch((err) => {
      throw err
    })
}

//Check Confirmed Trips from selected trips for booking
export const checkSelectedTripConfirmed = (data: object) => {
  return httpRequest('POST', '/aggregation/trip/user/get-trip-current-status', data).then((response: any) => {
    return response
  })
    .catch((err) => {
      throw err
    })
}

export const generatePaymentLink = (data: object) => {
  return httpRequest('POST', `/payment/true-layer/create-payment-link?utcOffset=${moment().utcOffset()}`, data).then((response: any) => {
    return response
  })
    .catch((err) => {
      throw err
    })
}

export const getPaymentStatus = (data: object) => {
  return httpRequest('POST', '/payment/true-layer/check-payment-status', data).then((response: any) => {
    return response
  }).catch((err) => {
    throw err
  })
}

export const getPurchaseHistory = (user: object) => {
  return httpRequest('GET', '/payment/transaction/get-all-transactions', {}, user).then((response: any) => {
    return response.data
  })
    .catch((err) => {
      throw err
    })
}

export const getGiftCardReceipt = (transactionId: string) => {
  return httpRequest('POST', '/payment/transaction/gift-card-receipt', {_id: transactionId})
    .then((response: any) => response.data)
    .catch((err) => { throw err; });
}