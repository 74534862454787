import "./style.scss";
import { Box, Button } from "@mui/material";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import EmptyCircle from "../../../assets/images/empty-circle.svg";
import LocationPin from "../../../assets/images/location-pin.svg";
import VerifiedPerson from "../../../assets/images/verified-person.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  calculateParkingFee,
  calculateServiceFee,
  capitalizeFirstLetter,
  clearLocalStorageBeforeVerification,
  getName,
  getOrganisationLogo,
  getOrganizationName,
} from "../../../utilities/helper";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  createMultipleInvites,
  removeAllSelectedUser,
  resetInviteResponse,
  setDiscount,
  setParking,
} from "../../../slice/invites";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import moment from "moment";
import LoadingScreen from "../../../components/loading-screen";
import { setRedirectURL } from "../../../slice/utility";
import userVerified from "../../../assets/images/user-verified.svg";
import userNotVerified from "../../../assets/images/user-not-verified.svg";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import increementSign from "../../../assets/images/increement.svg";
import decreementSign from "../../../assets/images/decreement.svg";
import CarStearingIcon from "../../../assets/images/stearing.svg";
import { DetailRow } from "../../market place/card detail";
import arrow from "../../../assets/images/arrow-icon-green.svg";
import { BasicDetails } from "../../../slice/login";
import UserProfile from "../../../components/user-profile";
import CustomFixedButton from "../../../components/custom-fixed-button";

interface props {
  discount: number | string;
  selectedDiscount: number;
  handleClick: () => void;
}

const discount = ["5", "10", "15", "20", "50"];
const DiscountTag = ({ discount, selectedDiscount, handleClick }: props) => {
  const getBorderColor = () => {
    if (selectedDiscount !== undefined && selectedDiscount !== 0) {
      return Number(discount) === selectedDiscount
        ? "2px solid #5ED497"
        : "2px solid #BDBDBD";
    }
    return "2px solid #5ED497";
  };

  const getBackgroundColor = () => {
    if (selectedDiscount !== undefined && selectedDiscount !== 0) {
      return Number(discount) === selectedDiscount ? "#5ED497" : "none";
    }
    return "none";
  };

  const getColor = () => {
    if (selectedDiscount !== undefined && selectedDiscount !== 0) {
      return Number(discount) === selectedDiscount ? "#171717" : "#BDBDBD";
    }
    return "#5ED497";
  };

  return (
    <Box
      className="mr-2 cursor-pointer fw-700"
      border={getBorderColor()}
      borderRadius="3px"
      padding="8px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color={getColor()}
      bgcolor={getBackgroundColor()}
      onClick={handleClick}
    >
      {discount}%
    </Box>
  );
};

const OfferCarpoolSummary = () => {
  const [userBasicDetail, setUserBasicDetail] = useState<{
    showUserDetail: boolean;
    userDetail: BasicDetails
  }>({
    showUserDetail: false,
    userDetail: {
      firstName: [],
      lastName: [],
      email: "",
      gender: "",
      phone: "",
      photo: "",
      userPreference: "",
      occupation: "",
      createdAt: "",
    },
  });

  const { decodedData } = useSelector(
    (store: RootState) => store.redirectDataReducer
  );
  const { trip } = useSelector((state: RootState) => state.inviteTripReducer);
  const { trips, newMatchTrip } = useSelector(
    (store: RootState) => store.tripReducer
  );
  const {
    filteredUsersAll,
    selectedUsers,
    selectedUsersId,
    tripDateUserMap,
    tripIdUserMap,
    inviteError,
    inviteLoading,
    inviteResponse,
    parking,
    discount: selectedDiscount,
  } = useSelector((state: RootState) => state.inviteReducer);
  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );
  const { location } = useSelector((store: RootState) => store.categoryReducer);

  let [parkingFee, setParkingFee] = useState(parking);
  const [updated, setUpdated] = useState(false);
  const [userDetail, setUserDetail] = useState({
    image: "",
    name: "",
    fuelPrice: "",
    parkingFee: "",
    discount: 0,
    serviceFee: "",
    totalPrice: "",
  });

  const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  let { data: base64Data } = useParams();
  const navigate = useNavigate();

  const [drawerState, setDrawerState] = useState({
    parkingFeeDrawer: false,
    userDetailDrawer: false,
  });

  const handleDrawerClose = (type?: string) => {
    // sync local state with redux state
    if (type === "parking") {
      !updated
        ? setParkingFee(Number(location.otherFee))
        : setParkingFee(parking);
    }

    if (type === "user") {
      setDrawerState((prev) => {
        return {
          ...prev,
          userDetailDrawer: false,
        };
      });
      return;
    }
    setDrawerState((prev) => {
      return {
        ...prev,
        parkingFeeDrawer: false,
      };
    });
  };

  // set the parking fee to location's other fee
  useEffect(() => {
    // if location has "other fee" more than zero and redux state is not matching with it, then set the redux state
    if (parking === 0 && location.otherFee) {
      // dispatch(setParking(Number(location.otherFee)));
      setParkingFee(Number(location.otherFee));
    }
    // eslint-disable-next-line
  }, []);

  // redirect to landing page if decoded data is not found
  useEffect(() => {
    if (Object.keys(decodedData).length === 0) {
      // if user comes directly to this page, then redirect to redirection landing page
      navigate(`/redirect/${base64Data}`);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // show the error message if request fails
    if (inviteError) {
      enqueueSnackbar(inviteError);
    }
    //eslint-disable-next-line
  }, [inviteError]);

  // as soon as invite is created successfully, then redirect to home page
  useEffect(() => {
    if (Object.keys(inviteResponse).length > 0) {
      dispatch(resetInviteResponse());
      dispatch(removeAllSelectedUser([]));
      // show message to user
      enqueueSnackbar("Invites have been sent", { variant: "success" });
      // remove redux persisted data
      localStorage.removeItem("persist:root");
      navigate("/dashboard/redirect-to-home");
    }
    //eslint-disable-next-line
  }, [inviteResponse]);

  // offer carpool onClick handler
  const handleOfferCarpoolButton = () => {
    if (trip === undefined) {
      return;
    }

    const inviteData: {
      _tripId: string;
      _user: string;
      perSeatPrice: number;
      distance: number;
      discount?: number;
      parking?: number;
      passengerDestinationTime?: number;
      fuelContribution?: number;
    }[] = [];

    filteredUsersAll.forEach((user) => {
      if (selectedUsersId.includes(user.user._id)) {
        const driverPrice = Number(getTotalPrice(user.fuelContribution));
        // loop through all the tripIds for this user to create invites
        tripIdUserMap[user.user._id].forEach((tripId) => {
          inviteData.push({
            _tripId: tripId,
            _user: String(user.user._id),
            perSeatPrice: Number(
              (driverPrice + calculateServiceFee(driverPrice)).toFixed(2)
            ),
            distance: user.passengerToDestinationDistance,
            discount: selectedDiscount ?? 0,
            parking: parking,
            passengerDestinationTime: Number(user.passengerToDestinationTime.toFixed(2)),
            fuelContribution: user.fuelContribution
          });
        });
      }
    });

    //if user is not verified redirect it for verification
    // check if location requires address to be verified as well
    if (
      !location.verificationType.includes("NONE") && (
        userDetailsReducer.basicDetails.isVerified !== true
      )
    ) {
      // remove already existing data from local storage
      clearLocalStorageBeforeVerification();
      
      localStorage.setItem("locationId", location._id);

      // store invite data in localstorage to send the invites after verification is done
      localStorage.setItem("inviteData", JSON.stringify(inviteData));

      dispatch(setRedirectURL(pathname));
      navigate("/get-verified");
      return;
    }

    // check if driver is verified
    if(userDetailsReducer.basicDetails.isDriverVerified === false) {
      // redirect to get-verified screen
      navigate("/get-verified", {state: { inviteRequests: inviteData, utcOffset: moment().utcOffset(), driverVerification: true }})

      // send the invite data in the user-verification screen to send the invites after verification
      // redirect to driver verification screen
      // navigate("/get-check-code", {state: { inviteRequests: inviteData, utcOffset: moment().utcOffset() }})

      return
    }

    // send the request to API to create invites
    dispatch(createMultipleInvites(inviteData));
  };

  const formatDate = () => {
    let formattedDate = "";

    let dates = new Set();

    Object.keys(tripDateUserMap).forEach((user) => {
      tripDateUserMap[user].forEach((date) => {
        dates.add(date);
      });
    });

    let datesArr = Array.from(dates)?.sort((a: string, b: string) => a.localeCompare(b));

    datesArr.forEach((date, index) => {
      if (index === datesArr.length - 1) {
        formattedDate += moment(new Date(date as string)).format("Do");
        formattedDate +=
          " " + moment(new Date(date as string)).format("MMM");
        return formattedDate;
      }
      formattedDate += moment(new Date(date as string)).format("Do");
      (datesArr.length > 1 && index === datesArr.length - 2) ? formattedDate +=
        " " + moment(new Date(date as string)).format("MMM") + " & " :
        formattedDate +=
        " " + moment(new Date(date as string)).format("MMM") + ", "
    })
    //   formattedDate += moment(new Date(date as string)).format("Do");
    //   formattedDate +=
    //   " " + moment(new Date(date as string)).format("MMM") + ", ";
    // });

    return formattedDate;
  };

  // calculate total seats in the car
  const calculateTotalSeats = () => {
    // return trips[0].totalSeats
    let dates = new Set();

    Object.keys(tripDateUserMap).forEach((user) => {
      tripDateUserMap[user].forEach((date) => {
        dates.add(date);
      });
    });

    let datesArr = Array.from(dates);

    return datesArr.length;
  };

  const handleDiscountClick = (discount: number) => {
    if (selectedDiscount === discount) {
      dispatch(setDiscount(0));
      return;
    }
    dispatch(setDiscount(discount));
  };

  const handleUserRowClick = (user: any) => {
    const { photo, firstName, lastName, fuelContribution } = user;
    setUserDetail((prev) => {
      return {
        ...prev,
        name:
          capitalizeFirstLetter(getName(firstName, "EN")) +
          " " +
          capitalizeFirstLetter(getName(lastName, "EN")),
        image: photo,
        fuelPrice: fuelContribution,
        discount:
          (selectedDiscount *
            (fuelContribution +
              calculateParkingFee(parking, trips[0].totalSeats))) /
          100,
        parkingFee: String(calculateParkingFee(parking, trips[0].totalSeats)),
        totalPrice: getTotalPrice(fuelContribution),
      };
    });
    setDrawerState((prev) => {
      return {
        ...prev,
        userDetailDrawer: true,
      };
    });
  };

  const getTotalPrice = (fuelPrice: number) => {
    let parkingFee = calculateParkingFee(parking, trips[0].totalSeats);
    let totalPrice = fuelPrice + parkingFee;
    return selectedDiscount !== undefined && selectedDiscount !== 0
      ? (totalPrice - (totalPrice * Number(selectedDiscount)) / 100).toFixed(2)
      : totalPrice.toFixed(2);
  };

  // // get organisation name from location details
  // const getOrganizationName = () => {
  //   if(location.domainData) {
  //     // get the email of the user and match its domain against the domain data of location, if domain found, set the organization name, else set the locationName
  //     const emailDomain = userBasicDetail.userDetail.email.split("@")[1];
      
  //     if(location.domainData[emailDomain]) {
  //       return location.domainData[emailDomain].organisationName
  //     }   
  //   }
    
  //   return getName(location.locationName, "EN")
  // }
  
  // // get organisation logo from location details
  // const getOrganisationLogo = () => {
  //   if(location.domainData) {
  //     // get the email of the user and match its domain against the domain data of location, if domain found, set the organization name, else set the locationName
  //     const emailDomain = userBasicDetail.userDetail.email.split("@")[1];

  //     if(location.domainData[emailDomain]) {
  //       return location.domainData[emailDomain].imageData
  //     }   
  //   }

  //   return ""
  // }

  return (
    <div id="trip-detail">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
        backLink={
          trip !== undefined && Object.keys(trip).length > 0
            ? `/invites/matches/all`
            : undefined
        }
      />

      {trips !== undefined && trips.length > 0 ? (
        <>
          <Box padding="16px" className="mb-3 bg-white border-bottom">
            <Box className="seat-info">
              <Box marginBottom="8px" className="sub-text text-faded">
                {formatDate()}
              </Box>

              <Box marginBottom="16px" className="sub-text text-faded">
                {/* {calculateTotalSeats()}{" "}
                {calculateTotalSeats() > 1 ? "seats" : "seat"} */}
                1 seat
              </Box>
            </Box>

            <Box className="" marginTop="16px">
              <Box marginBottom="8px" className="sub-text">
                <Box className="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <img alt="circle" src={EmptyCircle} />

                    <p className="sub-text-14 ml-2">
                      {trips[0].destinationLocationId === undefined
                        ?
                        getName(location.locationName, "EN")
                        : "Home"}
                    </p>
                  </Box>
                </Box>

                <Box
                  color="#E0E0E0"
                  className="center"
                  justifyContent="space-between"
                  marginLeft="10px"
                >
                  |
                </Box>

                <Box className="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <img alt="circle" src={LocationPin} />

                    <p className="sub-text-14 ml-2">
                      {trips[0].destinationLocationId === undefined
                        ? "Home"
                        : // : getName(trips[0]?.destination.line1, "EN")
                        getName(location.locationName, "EN")}
                    </p>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {Number(location.otherFee) > 0 && (
            <>
              {updated ? (
                <>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      padding: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <div className="carpool-offer-header mb-3">
                      <p className="fw-700">Extras</p>

                      <p
                        className="sub-text-14 primary-color cursor-pointer decoration-none"
                        onClick={() =>
                          setDrawerState((prev) => {
                            return {
                              ...prev,
                              parkingFeeDrawer: true,
                            };
                          })
                        }
                      >
                        Edit
                      </p>
                    </div>

                    <div className="carpool-offer-header">
                      <p>Parking</p>

                      <p className="fw-700">£{parking.toFixed(2)}</p>
                    </div>
                  </Box>
                </>
              ) : (
                <Box
                  color="#5ED497"
                  className="bg-white cursor-pointer mb-3 fw-700 d-flex justify-center items-center p-1 border-bottom"
                  onClick={() =>
                    setDrawerState((prev) => {
                      return {
                        ...prev,
                        parkingFeeDrawer: true,
                      };
                    })
                  }
                >
                  + Add parking
                </Box>
              )}
            </>
          )}

          <Box
            paddingY="24px"
            paddingX="16px"
            className="bg-white border-bottom select-offer-container flex-1"
          >
            <div className="carpool-offer-header mb-3">
              <p className="heading">Carpool offers</p>

              <Link
                to={`/redirect-page/matches/all/${base64Data}`}
                className="sub-text-14 primary-color cursor-pointer decoration-none"
              >
                Edit
              </Link>
            </div>

            <Box className="carpool-offer-apply-discount-main-wrapper">
              <Box
                color="#616161"
                className="apply-discount-text text-sm-2 fw-700 mb-2"
              >
                <p>Apply discount (optional)</p>
              </Box>

              <Box className="discount-tags-container d-flex">
                {discount.map((number, key) => (
                  <DiscountTag
                    key={key}
                    selectedDiscount={selectedDiscount}
                    discount={number}
                    handleClick={() => handleDiscountClick(Number(number))}
                  />
                ))}
              </Box>
            </Box>

            {selectedUsers.map((user, index) => (
              <Box
                className="border-bottom pb-1"
                key={index}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className="user-detail"
                  style={{ paddingBottom: "0" }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                  >
                      <img
                        alt="profile"
                        src={user.photo ? user.photo : DefaultProfile}
                        height="44px"
                        width="44px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = DefaultProfile;
                        }}
                        className="rounded-full bordered-2 shadow-light object-fit-cover cursor-pointer"
                        onClick={() => setUserBasicDetail({ showUserDetail: true, userDetail: user })}
                      />

                    <Box marginLeft="18px" className="info">
                      <p className="sub-text-14 driver-name d-flex items-center gap-05">
                        {capitalizeFirstLetter(getName(user.firstName, "EN")) + " " + capitalizeFirstLetter(getName(user.lastName, "EN"))}

                        {
                          !location.verificationType.includes("NONE") && (
                            <img
                              src={user.isVerified ? userVerified : userNotVerified}
                              alt="user-verify-status"
                            />
                          )
                        }

                        {
                          user.isDriverVerified && (
                            <img
                              src={CarStearingIcon}
                              alt="driver verified"
                            />
                          )
                        }
                      </p>

                      <Box display="flex" marginY="4px">
                        {user.loginType === "FACEBOOK" && (
                          <img
                            alt="verified"
                            src={VerifiedPerson}
                            onClick={() =>
                              window.open(
                                `https://www.facebook.com/profile.php?id=${user.socialId}`,
                                "_blank"
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <p className="sub-text text-faded primary2-color text-600">
                      +
                      {filteredUsersAll[0].tripDetails.destinationLocationId ===
                        undefined ? (
                        <>
                          {Math.abs(
                            Number(
                              (
                                Number(
                                  user?.passengerToDestinationTime || 0
                                ) *
                                60 +
                                Number(user?.driverToPassengerTime || 0) *
                                60 -
                                Number(
                                  user.tripDetails.totalTripTime.toFixed(2)
                                ) *
                                60
                              ).toFixed()
                            )
                          ).toFixed() + " mins"}
                        </>
                      ) : (
                        <>
                          {Math.abs(
                            Number(
                              (
                                Number(user?.driverToPassengerTime || 0) *
                                60 +
                                Number(
                                  user?.passengerToDestinationTime || 0
                                ) *
                                60 -
                                Number(
                                  user.tripDetails.totalTripTime.toFixed(2)
                                ) *
                                60
                              ).toFixed()
                            )
                          ).toFixed() + " mins"}
                        </>
                      )}
                    </p>
                  </Box>
                </Box>

                <Box
                  style={{
                    backgroundColor: "#F5F5F5",
                    height: "34px",
                    display: "flex",
                    alignItems: "center",
                    padding: "4px 8px",
                    justifyContent: "space-between",
                    borderRadius: "4px",
                    marginLeft: "3rem",
                  }}
                  className="cursor-pointer"
                  onClick={() => handleUserRowClick(user)}
                >
                  <Box>
                    <b>Price</b>
                  </Box>

                  <Box className="d-flex justify-center items-center">
                    <b className="mr-2">
                      £{getTotalPrice(user.fuelContribution)}
                    </b>

                    <img src={arrow} alt="arrow" />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          {/* <Box className="next-btn-box button-wrapper mt-auto">
            <Box className="margin-x d-flex justify-center items-center">
              <Button
                disabled={selectedUsers.length === 0 || inviteLoading}
                className="next-btn custom-btn btn-width btn-text"
                fullWidth
                onClick={handleOfferCarpoolButton}
              >
                Offer carpool
              </Button>
            </Box>
          </Box> */}
          <CustomFixedButton text={'Offer carpool'} disable={selectedUsers.length === 0 || inviteLoading} 
            handleSubmit={handleOfferCarpoolButton}
          />
        </>
      ) : (
        <>
          {Object.keys(newMatchTrip).length > 0 ? (
            <>
              <Box padding="16px" className="mb-3 bg-white border-bottom">
                <Box className="seat-info">
                  <Box marginBottom="8px" className="sub-text text-faded">
                    {formatDate()}
                  </Box>

                  <Box marginBottom="16px" className="sub-text text-faded">
                    {calculateTotalSeats()}{" "}
                    {calculateTotalSeats() > 1 ? "seats" : "seat"}
                  </Box>
                </Box>

                <Box className="" marginTop="16px">
                  <Box marginBottom="8px" className="sub-text">
                    <Box className="center" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <img alt="circle" src={EmptyCircle} />

                        <p className="sub-text-14 ml-2">
                          {/* {getName(trips[0]?.pickUp.line1, 'EN')} */}
                          {newMatchTrip.destinationLocationId === undefined
                            ? getName(newMatchTrip?.pickUp.line1, "EN")
                            : "Home"}
                        </p>
                      </Box>
                    </Box>

                    <Box
                      color="#E0E0E0"
                      className="center"
                      justifyContent="space-between"
                      marginLeft="10px"
                    >
                      |
                    </Box>
                    <Box className="center" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <img alt="circle" src={LocationPin} />

                        <p className="sub-text-14 ml-2">
                          {newMatchTrip.destinationLocationId === undefined
                            ? "Home"
                            : getName(newMatchTrip?.destination.line1, "EN")}
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                paddingY="24px"
                paddingX="16px"
                className="bg-white border-bottom"
              >
                <div className="carpool-offer-header">
                  <p className="heading">Carpool offers</p>
                  
                  <Link
                    to={`/redirect-page/matches/all/${base64Data}`}
                    className="sub-text-14 primary-color cursor-pointer decoration-none"
                  >
                    Edit
                  </Link>
                </div>

                {selectedUsers.map((user, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                    className="user-detail border-bottom"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                        <img
                          alt="profile"
                          src={user.photo ? user.photo : DefaultProfile}
                          height="44px"
                          width="44px"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = DefaultProfile;
                          }}
                          className="rounded-full bordered-2 shadow-light cursor-pointer"
                          onClick={() => setUserBasicDetail({ showUserDetail: true, userDetail: user })}
                        />
                      <Box marginLeft="18px" className="info">
                        <p className="sub-text-14 driver-name">
                          {getName(user.firstName, "EN")}
                        </p>

                        <Box display="flex" marginY="4px">
                          <img alt="verified" src={VerifiedPerson} />
                        </Box>
                      </Box>
                    </Box>

                    <Box>
                      <p className="sub-text text-faded primary2-color text-600">
                        +
                        {newMatchTrip.destinationLocationId === undefined ? (
                          <>
                            {(
                              Number(user.passengerToDestinationTime || 0) * 60
                            ).toFixed() + " mins"}
                          </>
                        ) : (
                          <>
                            {(
                              Number(user.driverToPassengerTime || 0) * 60
                            ).toFixed() + " mins"}
                          </>
                        )}
                      </p>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box className="next-btn-box button-wrapper mt-auto">
                <Box className="margin-x">
                  <Button
                    disabled={selectedUsers.length === 0 || inviteLoading}
                    className="next-btn custom-btn btn-text"
                    fullWidth
                    onClick={handleOfferCarpoolButton}
                  >
                    Offer carpool
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <LoadingScreen />
          )}
        </>
      )}

      <CustomDrawer
        anchor="bottom"
        isOpen={drawerState.parkingFeeDrawer}
        onClose={() => handleDrawerClose("parking")}
      >
        <div className="d-flex justify-center items-center heading mb-3">
          Add parking
        </div>

        <Box
          fontSize="32px"
          className="additional-price-container d-flex justify-center items-center gap-3 mb-3"
        >
          <img
            className="cursor-pointer"
            src={decreementSign}
            alt="decrease-price"
            onClick={() => {
              if (parkingFee > 0) {
                setParkingFee(Number(parkingFee) - 1);
              }
            }}
          />

          <span className="fw-700">£{parkingFee}</span>

          <img
            className="cursor-pointer"
            src={increementSign}
            alt="increase-price"
            onClick={() => setParkingFee(Number(parkingFee) + 1)}
          />
        </Box>

        <Box className="additional-message text-center mb-3 p-1">
          Add your parking costs for your trip and we'll calculated the share
          per passenger based on the number of seats in your vehicle.
        </Box>

        {/* <Box
          className="d-flex justify-center items-center p-1 pb-40"
          paddingTop="0px"
        >
          <Button
            className="next-btn custom-btn btn-width btn-text"
            fullWidth
            onClick={() => {
              dispatch(setParking(parkingFee));
              setUpdated(true);
              setDrawerState((prev) => {
                return {
                  ...prev,
                  parkingFeeDrawer: false,
                };
              });
            }}
          >
            {!updated ? "Add" : "Update"}
          </Button>
        </Box> */}

        <CustomFixedButton text={!updated ? "Add" : "Update"} disable={false} handleSubmit={() => {
              dispatch(setParking(parkingFee));
              setUpdated(true);
              setDrawerState((prev) => {
                return {
                  ...prev,
                  parkingFeeDrawer: false,
                };
              });
            }}
            isDrawerButton={true}
            />
      </CustomDrawer>

      <CustomDrawer
        anchor="bottom"
        isOpen={drawerState.userDetailDrawer}
        onClose={() => handleDrawerClose("user")}
      >
        <div className="selected-user-image-container d-flex justify-center items-center mb-3">
          <img
            className="selected-user-image"
            src={userDetail.image}
            alt="user"
          />
        </div>

        <div className="selected-user-name d-flex justify-center items-center heading">
          <p>{userDetail.name}</p>
        </div>

        <div className="other-details-container-wrapper p-1 mb-3">
          <DetailRow
            rowTitle="Fuel contribution"
            rowAmount={`£${Number(userDetail.fuelPrice).toFixed(2)}`}
          />

          {Number(userDetail.parkingFee) > 0 && (
            <DetailRow
              rowTitle="Parking contribution"
              rowAmount={`£${Number(userDetail.parkingFee).toFixed(2)}`}
            />
          )}

          {selectedDiscount !== undefined && selectedDiscount !== 0 && (
            <DetailRow
              rowTitle="Discount"
              rowAmount={`${selectedDiscount}% (£${userDetail.discount.toFixed(
                2
              )})`}
            />
          )}

          {/* <DetailRow
            rowTitle="Service fee"
            rowAmount={`£${userDetail.serviceFee}`}
          /> */}

          <DetailRow
            rowTitle={<b>Total Price</b>}
            rowAmount={`£${Number(userDetail.totalPrice).toFixed(2)}`}
          />
        </div>
      </CustomDrawer>

      <UserProfile 
        isOpen={userBasicDetail.showUserDetail}
        onClose={() => setUserBasicDetail(prev => ({...prev, showUserDetail: false}))}
        userDetail={{...userBasicDetail.userDetail, locationName: getOrganizationName(location, userBasicDetail), organisationLogo: getOrganisationLogo(location, userBasicDetail)}}
      />
    </div>
  );
};

export default OfferCarpoolSummary;
