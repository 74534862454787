import "./style.scss";
import { Box } from "@mui/material";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import EmptyCircle from "../../../assets/images/empty-circle.svg";
import LocationPin from "../../../assets/images/location-pin.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, Link, useParams, useSearchParams } from "react-router-dom";
import {
  checkSelectedTripConfirmed,
  generatePaymentLink,
} from "../../../services/payment";
import { enqueueSnackbar } from "notistack";
import LoadingScreen from "../../../components/loading-screen";
import {
  calculateParkingFee,
  capitalizeFirstLetter,
  clearLocalStorageBeforeVerification,
  getName,
  showMapDuration,
  paymentButtonHandler,
  getOrganizationName,
  getOrganisationLogo,
} from "../../../utilities/helper";
import { TripResponseInterfaceNew } from "../../../interface/book-seat-new";
import { useDispatch } from "react-redux";
import { setRedirectURL } from "../../../slice/utility";
import { useLocation } from "react-router-dom";
import userVerified from "../../../assets/images/user-verified.svg";
import userNotVerified from "../../../assets/images/user-not-verified.svg";
import arrow from "../../../assets/images/arrow-icon-green.svg";
import CarStearingIcon from "../../../assets/images/stearing.svg";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import { DetailRow } from "../../market place/card detail";
import { getSeatAvailabilityMultiple } from "../../../services/book-seat";
import { setSelectedTripResponseNew } from "../../../slice/book-seat";
import { BasicDetails } from "../../../slice/login";
import UserProfile from "../../../components/user-profile";
import CustomFixedButton from "../../../components/custom-fixed-button";


const ConfirmSeat = () => {
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [priceDetailDrawer, setPriceDetailDrawer] = useState(false);
  const [userDetail, setUserDetail] = useState({
    image: "",
    name: "",
    fuelPrice: 0,
    parkingFee: 0,
    discount: 0,
    serviceFee: 0,
    totalPrice: 0,
  });
  const [userBasicDetail, setUserBasicDetail] = useState<{
    showUserDetail: boolean;
    userDetail: BasicDetails;
    locationName: string;
    organisationLogo: string
  }>({
    showUserDetail: false,
    userDetail: {
      firstName: [],
      lastName: [],
      email: "",
      gender: "",
      phone: "",
      photo: "",
      userPreference: "",
      occupation: "",
      createdAt: "",
    },
    locationName: "",
    organisationLogo: "",
  });

  const {
    bookSeatReducer: { selectedTripResponseNew },
    userDetailsReducer,
  } = useSelector((state: RootState) => state);

  let [searchParams] = useSearchParams();
  let { data: base64Data } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if((selectedTripResponseNew.length === 0) && !searchParams.get("fromPayment")) {
      navigate(`/redirect/${base64Data}`);
    } else if(selectedTripResponseNew.length > 0) {
      setLoading(false);

      getAllCordinates(selectedTripResponseNew);
    }
    // eslint-disable-next-line
  }, [selectedTripResponseNew, loading]);

  // load the redux state if user is coming from payment failure/cancellation screen
  useEffect(() => {
    if(searchParams.get("fromPayment")) {
      dispatch(setSelectedTripResponseNew(JSON.parse(localStorage.getItem("selectedTripResponseNew"))))
    } else {
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  const formatDateWithDay = () => {
    let formattedDate = "";
    let datesArr = selectedTripResponseNew.map((it) => moment(it.estimatedDropTime).startOf("day").toISOString());
    let uniqueDateArray = new Set(datesArr)
    const sortedDates = Array.from(uniqueDateArray).sort((a, b) => a.localeCompare(b));
    sortedDates.forEach((date, index) => {
      const momentDate = moment(new Date(date as string));

      if (index === sortedDates.length - 1) {
        // formattedDate += momentDate.format("ddd") + " ";
        formattedDate += momentDate.format("Do") + " ";
        formattedDate += momentDate.format("MMM") + " ";
        // formattedDate += momentDate.format("HH:mm");

        return formattedDate;
      }

      formattedDate += momentDate.format("Do");
      (sortedDates.length > 1 && index === sortedDates.length - 2) ? formattedDate += momentDate.format("MMM") + " & " :
        formattedDate += momentDate.format("MMM") + ", ";
    });

    return formattedDate;
  };

  const forMattDate = (date: string, tripType: string) => {
    return moment(date).format(
      `ddd Do MMM, [est ${tripType === "pickUp" ? "drop-off" : "pick-up"
      } @]HH:mm`
    );
  };

  const navigate = useNavigate();

  const handlePayNow = async () => {
    // store cancel redirect and failure redirect links in the local storage
    localStorage.setItem("cancelRedirect", pathname + "?fromPayment=true");

    localStorage.setItem("failureRedirect", pathname + "?fromPayment=true");

    //checking if the user is verified or not
    if (
      !selectedTripResponseNew[0].locationDetail?.verificationType.includes("NONE") && (
        userDetailsReducer.basicDetails.isVerified !== true
      )
    ) {
      // remove already existing data from local storage
      clearLocalStorageBeforeVerification();

      localStorage.setItem(
        "locationId",
        selectedTripResponseNew[0].locationDetail._id
      );

      // store the data in local state to trigger the payment process later
      localStorage.setItem(
        "selectedTripResponseNew",
        JSON.stringify(selectedTripResponseNew)
      );

      dispatch(setRedirectURL(pathname));
      navigate("/get-verified");
      return;
    }

    try {
      setPaymentLoading(true);

      const tripResponse = await checkSelectedTripConfirmed({
        ids: selectedTripResponseNew.map((it) => it.tripDetail._id),
      });

      if (tripResponse.data && tripResponse.data.length) {
        setPaymentLoading(false);

        return enqueueSnackbar(
          `Seat booking window has been closed for following dates ${tripResponse.data.toString()}`,
          { variant: "error" }
        );
      }

      try {
        // check seat availability
        const value = await getSeatAvailabilityMultiple(selectedTripResponseNew.map((it) => it.tripDetail._id))

        // if seat is not available, then show the alert/notification
        if (value.tripSeatDetail[0].seatsAvailable === 0) {
          enqueueSnackbar("Seat is not available for this trip", {
            variant: "warning",
          });

          setPaymentLoading(false);

          return;
        }
      } catch (error) {
        setPaymentLoading(false);

        return enqueueSnackbar("Seat is not available for this trip", {
          variant: "warning",
        });
      }

      // make a request to API to get the payment link
      const paymentLinkResponse = await generatePaymentLink({
        amount: Number(
          selectedTripResponseNew
            .reduce((sum, it) => (sum += it.perSeatPrice), 0)
            .toFixed(2)
        ),
        tripIds: selectedTripResponseNew.map((it) => it.tripDetail._id),
        tripUniqueId: selectedTripResponseNew.map(
          (it) => it.tripDetail.uniqueId
        ),
      });

      if (paymentLinkResponse.success === true) {
        // store the data in local state to trigger the payment process later
        localStorage.setItem(
          "selectedTripResponseNew",
          JSON.stringify(selectedTripResponseNew)
        );

        paymentButtonHandler(paymentLinkResponse);
      } else {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar("Unable to create payment link", { variant: "error" });
      setPaymentLoading(false);
    }
  };

  const getAllCordinates = (
    trips: TripResponseInterfaceNew[]
  ) => {
    const pickUpCoordinate = {
      lat: trips[0].tripDetail.pickUp.geo[0],
      lng: trips[0].tripDetail.pickUp.geo[1],
    };
    const userCoordinate: { lat: number; lng: number }[] = [];
    const coordinateSet = new Set();

    trips.forEach((trip) => {
      const lat = trip.userDetail.address.geo[0];
      const lng = trip.userDetail.address.geo[1];
      const coordinateKey = lat + "-" + lng;

      if (!coordinateSet.has(coordinateKey)) {
        coordinateSet.add(coordinateKey);
        userCoordinate.push({ lat, lng });
      }
    });

    const destinationCoordinate = {
      lat: trips[0].tripDetail.destination.geo[0],
      lng: trips[0].tripDetail.destination.geo[1],
    };

    const allCoordinates = [
      pickUpCoordinate,
      ...userCoordinate,
      destinationCoordinate,
    ];

    // sending the index of the map
    initMap(allCoordinates);
  };

  const initMap = (tripCordinate: { lat: number; lng: number }[]) => {
    if(loading) return 

    var map = new google.maps.Map((document as any).getElementById(`map1`), {
      center: tripCordinate[0],
      zoom: 8,
    });

    // Create a new DirectionsService object
    var directionsService = new google.maps.DirectionsService();

    // Create a new DirectionsRenderer object
    var directionsRenderer = new google.maps.DirectionsRenderer({
      map: map,
    });

    // Define the start and end locations
    var start = new google.maps.LatLng(
      tripCordinate[0].lat,
      tripCordinate[0].lng
    );
    var end = new google.maps.LatLng(
      tripCordinate[tripCordinate.length - 1].lat,
      tripCordinate[tripCordinate.length - 1].lng
    );

    // Configure the DirectionsRequest object
    var request = {
      origin: start,
      destination: end,
      waypoints: tripCordinate.slice(1, -1).map((coordinate) => ({
        location: new google.maps.LatLng(coordinate.lat, coordinate.lng),
        stopover: true,
      })),
      travelMode: google.maps.TravelMode.DRIVING,
    };

    // Call the DirectionsService to get the route
    directionsService.route(request, function (result, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        // Display the route on the map
        directionsRenderer.setDirections(result);

        // get the directions response of the route
        let directionsResult = directionsRenderer.getDirections()!;

        // loop through each leg and show the duration for each leg in info window
        let prevDistance = 0;
        for (let i = 0; i < directionsResult.routes[0].legs.length; i++) {
          prevDistance = showMapDuration(
            directionsResult,
            map,
            i,
            prevDistance
          );
        }
      }
    });
  };

  // price details click handler
  const handlePriceDetailClick = (
    selectedTripResponse: TripResponseInterfaceNew
  ) => {
    setUserDetail({
      image: selectedTripResponse.driverDetail.photo,
      name: capitalizeFirstLetter(getName(selectedTripResponse.driverDetail.firstName, "EN")) + " " + capitalizeFirstLetter(getName(selectedTripResponse.driverDetail.lastName, "EN")),
      fuelPrice: selectedTripResponse.fuelCost,
      parkingFee: calculateParkingFee(
        selectedTripResponse.parking,
        selectedTripResponse.tripDetail.totalSeats
      ),
      discount: selectedTripResponse.discount,
      serviceFee: selectedTripResponse.serviceFee,
      totalPrice: selectedTripResponse.perSeatPrice,
    });

    setPriceDetailDrawer(true);
  };

  return (
    <div id="trip-detail">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
      />

      {
        loading ? <LoadingScreen /> : (
          <>
            {
              <>
                {selectedTripResponseNew.length > 0 && (
                  <>
                    <Box padding="16px" className="bg-white mb-3">
                      <Box className="map-container pb-1" style={{ height: "250px" }}>
                        <div
                          id={`map1`}
                          className="expand-transition rounded"
                          style={{ height: "100%", borderRadius: "6px" }}
                        ></div>
                      </Box>

                      <Box className="seat-info">
                        <Box marginBottom="8px" className="sub-text text-faded">
                          {formatDateWithDay()}
                        </Box>

                        <Box marginBottom="16px" className="sub-text text-faded">
                          1 seat
                        </Box>
                      </Box>

                      <Box className="" marginTop="16px">
                        <Box marginBottom="8px" className="sub-text">
                          <Box className="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <img alt="circle" src={EmptyCircle} />
                              <p className="sub-text-14 ml-2">
                                {selectedTripResponseNew[0].tripDetail
                                  .destinationLocationId === undefined
                                  ? // selectedTripResponseNew[0].tripDetail.pickUp.line1[0].value :
                                  selectedTripResponseNew[0]?.locationDetail
                                    ?.locationName[0]?.value ?? "N/A"
                                  : "Home"}
                              </p>
                            </Box>
                          </Box>
                          <Box
                            color="#E0E0E0"
                            className="center"
                            justifyContent="space-between"
                            marginLeft="10px"
                          >
                            |
                          </Box>
                          <Box
                            className="center"
                            justifyContent="space-between"
                            width="80%"
                          >
                            <Box display="flex" alignItems="center">
                              <img alt="circle" src={LocationPin} />
                              <p className="sub-text-14 ml-2">
                                {selectedTripResponseNew[0].tripDetail
                                  .destinationLocationId === undefined
                                  ? "Home"
                                  : // selectedTripResponseNew[0].tripDetail.destination.line1[0].value
                                  selectedTripResponseNew[0]?.locationDetail
                                    ?.locationName[0]?.value ?? "N/A"}
                              </p>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <div className="section-head rounded-6">
                        <Box className="between">
                          <Box className="sub-text-14 driver-name">Total price</Box>
                          <Box className="sub-text-14 driver-name">
                            £
                            {selectedTripResponseNew
                              .reduce((sum, it) => (sum += it.perSeatPrice), 0)
                              .toFixed(2)}
                          </Box>
                        </Box>
                      </div>
                    </Box>

                    <Box
                      paddingY="24px"
                      paddingX="16px"
                      className="bg-white carpool-offers"
                    >
                      <div className="carpool-offer-header">
                        <p className="heading">Seat Bookings</p>

                        <Link
                          to={`/redirect/${base64Data}`}
                          className="sub-text-14 primary-color cursor-pointer decoration-none"
                        >
                          Edit
                        </Link>
                      </div>

                      {selectedTripResponseNew.map((item, index) => (
                        <Box
                          key={index}
                          display="flex"
                          flexDirection="column"
                          className="user-detail"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            alignSelf="flex-start"
                          >
                            
                              <img
                                alt="profile"
                                src={
                                  item.driverDetail.photo
                                    ? item.driverDetail.photo
                                    : DefaultProfile
                                }
                                height="44px"
                                width="44px"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = DefaultProfile;
                                }}
                                className="rounded-full bordered object-fit-cover cursor-pointer"
                                onClick={() => setUserBasicDetail({ showUserDetail: true, userDetail: item.driverDetail, locationName: getOrganizationName(item?.locationDetail, {userDetail: item.driverDetail}), organisationLogo: getOrganisationLogo(item?.locationDetail, {userDetail: item.driverDetail}) })}
                              />

                            <Box marginLeft="18px" className="info">
                              <p className="sub-text-14 driver-name d-flex items-center gap-05">
                                {capitalizeFirstLetter(item.driverDetail.firstName[0].value)}{" "}
                                {capitalizeFirstLetter(item.driverDetail.lastName[0].value)}

                                {
                                  !item?.locationDetail?.verificationType.includes("NONE") && (
                                    <img
                                      src={
                                        item.driverDetail.isVerified
                                          ? userVerified
                                          : userNotVerified
                                      }
                                      alt="user-verify-status"
                                    />
                                  )
                                }

                                {
                                  item?.driverDetail?.isDriverVerified && (
                                    <img
                                      src={CarStearingIcon}
                                      alt="driver verified"
                                    />
                                  )
                                }
                              </p>

                              <Box display="flex" marginY="4px">
                                {/* <img alt="verified" src={VerifiedPerson}
                                  onClick={() => window.open(`https://www.facebook.com/profile.php?id=${item.driverDetail.socialId}`, '_blank')}
                                  style={{ cursor: 'pointer' }}
                                /> */}
                              </Box>

                              <Box
                                marginBottom="8px"
                                className="sub-text text-faded text-700"
                              >
                                {forMattDate(
                                  item.tripDetail.destinationLocationId === undefined
                                    ? item.estimatedDropTime
                                    : item.estimatedPickUpTime,
                                  item.tripDetail.destinationLocationId === undefined
                                    ? "pickUp"
                                    : "dropOff"
                                )}
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              backgroundColor: "#F5F5F5",
                              height: "34px",
                              display: "flex",
                              alignItems: "center",
                              padding: "4px 8px",
                              justifyContent: "space-between",
                              borderRadius: "4px",
                              marginLeft: "3rem",
                              boxSizing: "border-box",
                              width: "fill-available",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePriceDetailClick(item)}
                          >
                            <Box>
                              <b>Price</b>
                            </Box>

                            <Box className="d-flex justify-center items-center">
                              <b className="mr-2">£{item.perSeatPrice.toFixed(2)}</b>

                              <img src={arrow} alt="arrow" />
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    {paymentLoading && <LoadingScreen />}
{/* 
                    <Box className="next-btn-box button-wrapper d-flex justify-center items-center">
                      <Box className="margin-x btn-width">
                        <Button
                          className="next-btn custom-btn btn-text"
                          fullWidth
                          onClick={handlePayNow}
                        >
                          Pay Now
                        </Button>
                      </Box>
                    </Box> */}
                    <CustomFixedButton text={"Pay Now"} handleSubmit={handlePayNow} disable={false}/>
                  </>
                )}
              </>
            }
          </>
        )
      }


      {/* show price detail */}
      <CustomDrawer
        anchor="bottom"
        isOpen={priceDetailDrawer}
        onClose={() => setPriceDetailDrawer(false)}
      >
        <div className="selected-user-image-container d-flex justify-center items-center mb-3">
          <img
            className="selected-user-image"
            src={userDetail.image}
            alt="user"
          />
        </div>

        <div className="selected-user-name d-flex justify-center items-center heading">
          <p>{userDetail.name}</p>
        </div>

        <div className="other-details-container-wrapper p-1 mb-3">
          <DetailRow
            rowTitle="Fuel contribution"
            rowAmount={`£${userDetail.fuelPrice.toFixed(2)}`}
          />

          {(userDetail.parkingFee > 0) &&
            <DetailRow
              rowTitle="Parking contribution"
              rowAmount={`£${userDetail.parkingFee.toFixed(2)}`}
            />
          }

          {(userDetail.discount > 0) &&
            <DetailRow
              rowTitle="Discount"
              rowAmount={`${userDetail.discount}% (£${(
                (userDetail.discount *
                  (userDetail.fuelPrice + userDetail.parkingFee)) /
                100
              ).toFixed(2)}
            )`}
            />
          }

          <DetailRow
            rowTitle="Service fee"
            rowAmount={`£${userDetail.serviceFee.toFixed(2)}`}
          />
          
          <DetailRow
            rowTitle={<b>Total Price</b>}
            rowAmount={`£${userDetail.totalPrice.toFixed(2)}`}
          />
        </div>
      </CustomDrawer>

      <UserProfile 
        isOpen={userBasicDetail.showUserDetail}
        onClose={() => setUserBasicDetail(prev => ({...prev, showUserDetail: false}))}
        userDetail={{...userBasicDetail.userDetail, locationName: userBasicDetail.locationName, organisationLogo: userBasicDetail.organisationLogo}}
      />
    </div>
  );
};
export default ConfirmSeat;
