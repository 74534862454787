import { Box, Button } from "@mui/material";
import { arrowIconGreen, greenDiscount } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../../components/loading-screen";
import './style.scss';
import { getSingleTripDetail } from "../../../services/trips";
import { useDispatch } from "react-redux";
import { setTripDetail } from "../../../slice/trip";
import { ITripUserMap } from "../../../slice/invites";
import { enqueueSnackbar } from "notistack";
import moment from "moment";

const PromoCodeList = ({state, setState}) => {
  let numberOfLines = 0;

  return state.loader === true ? (<LoadingScreen />) : <Box id="promo-code">
    {/* {
      state.weeklyPromos.map(promosOfWeek => {
        return (
          <WeekCard promosOfWeek={promosOfWeek} key={promosOfWeek._id} setState={setState} />
        )
      })
    } */}
    {
      state.promos.map(promo => {
         numberOfLines++;
        return (
          <>
            <PromoCard key={promo._id} setState={setState} promo={promo} />
              {
              state.promos.length > numberOfLines ? 
                // Bottom line of each promo card
                (<hr className="horizontal-line-color" />) : 
                // Padding for last promo card to make it similar without horizontal line
                <div className="pb-1"></div>
                }
          </>
        )
      })
    }
    {
      (state.promos.length > 0 && state.hideLoadMore === false) && (<Box className="promo-week-card-load-more-container">
        <Button className="promo-week-card-load-more-button" onClick={() => {
          setState(prev => ({
            ...prev,
            page: prev.page + 1,
            loader: true
          }));
        }}
        >
          Load More
        </Button>
      </Box>)
    }
  </Box>
};

// eslint-disable-next-line
const WeekCard = ({ promosOfWeek, setState }) => {
  let numberOfLines = 0;
  return (
    <Box className="promo-week-card" key={promosOfWeek._id}>
      <Box className="promo-week-card-header">
        W/C {promosOfWeek.weekDate}
      </Box>
      <Box className="promo-week-card-body">
        {promosOfWeek.promos.map(promo => {
          numberOfLines++;
          return (
            <>
              <PromoCard key={promo._id} setState={setState} promo={promo} />
              {promosOfWeek.promos.length > numberOfLines && (<hr className="horizontal-line-color" />)}
            </>
          )
        })}
      </Box>
    </Box>
  )
}

const PromoCard = ({ promo, setState }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getPromoStatus = () => {
    // switch (promo.promoStatus.toLowerCase()) {
    //   case 'expired': return `expired on ${promo.validTill}`;
    //   case 'redeemed': return `redeemed on ${promo.redeemedDate}`;
    //   default: return `expires on ${promo.validTill}`
    // }
    if (Boolean(promo.redeemedDate) === true) {
      return <p className="text-green">
        redeemed on {moment(promo.redeemedDate).format('DD/MM/YYYY')}
      </p>
    } else if (new Date(promo.validTill).getTime() < new Date().getTime()) {
      return <p className="text-light-gray">
        expired on {moment(promo.validTill).format('DD/MM/YYYY')}
      </p>
    } else {
      return <p>
        expires {moment(promo.validTill).format('DD/MM/YYYY')}
      </p>
    }
  }

  return (
    <Box className="promo-card gap-1" key={promo._id}>
      <Box className="promo-discount-icon">
        <img src={greenDiscount} alt="gas-station" />
      </Box>
      <Box className="promo-content">
        <Box className="promo-left-content">
          <b>{promo.code}</b>
          <p>{moment(promo.createdAt).format('DD/MM/YYYY')}</p>
        </Box>
        <Box className="promo-right-content">
          <b>35p off £35 spend</b>
          <p>{getPromoStatus()}</p>
        </Box>
      </Box>
      <Box className="promo-chevron"
        onClick={async () => {
          setState((prev) => ({
            ...prev,
            loader: true,
          }));
          try {
            const modifiedTrip = (await getSingleTripDetail(promo._trip, undefined) as { data: ITripUserMap[] }).data[0];
            dispatch(setTripDetail({ ...modifiedTrip }));
            navigate("/dashboard/trip-detail");
          } catch (error) {
            enqueueSnackbar(error.message, { variant : "error"});
          } finally {
            setState(prev => ({
              ...prev,
              loader: false
            }))
          }
        }}>
        <img src={arrowIconGreen} alt='chevron-right' />
      </Box>
    </Box>
  )
}

export default PromoCodeList;