import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import DefaultUserIcon from "../../../../assets/images/defaultUser.svg";
import CustomFixedButton from "../../../../components/custom-fixed-button";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { createCarpoolPreference } from "../../../../services/signup";
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/header";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { car } from "../../../../assets/images";
import { updateBasicDetails } from "../../../../slice/sign-up";
import { updateBasicDetails as updateLoginBasicDetails } from "../../../../slice/login";

export const CarpoolingPreferences = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signUpDetails = useSelector((state: RootState) => state.signUpDetails);
  const userDetailsReducer = useSelector((state: RootState) => state.userDetailsReducer);

  const [state, updateState] = useState({
    carpoolPreference: 'BOTH',
    loading: false,
  })

  const _draft = localStorage.getItem("draftId");

  const handleSubmit = async () => {
    try {
      updateState(prev => ({
        ...prev,
        loading: true
      }));

      await createCarpoolPreference({
        _draft,
        preference: state.carpoolPreference,
      });

      dispatch(updateBasicDetails({...signUpDetails.basicDetails, carPoolPreference: state.carpoolPreference}));
      dispatch(updateLoginBasicDetails({...userDetailsReducer, userPreference: state.carpoolPreference}));
      if (state.carpoolPreference === 'FINDER') {
        navigate('/signup/invite-alerts');
      } else {
        navigate("/signup/schedule");
      }

    } catch (err) {
      enqueueSnackbar(err.message || 'Error occurred while updating preference', {
        variant: 'error'
      })
    } finally {
      updateState(prev => ({
        ...prev,
        loading: false
      }))
    }
  }

  return (
    <div>
      <Header
        canGoBack={true}
        rightIcon={<img alt='profile' className='header-img-icon' src={(signUpDetails.basicDetails.photoPath as string) ?? DefaultUserIcon} />}
        signUp={true}
      />
      <div className="d-flex flex-column pb-150" style={{minHeight: "72vh"}}>
        <Box className="padding-x rounded-top-green">
          <div className="sub-header">
            <div className="sub-header-icon-container">
              <img alt="person" src={car} />
            </div>

            <b className="title">
              Carpool Preferences
            </b>
          </div>
        </Box>
        <h3 className="font-14 px-1 pt-1">I plan to use EcoPooling to carpool as a: </h3>

        <Box className="px-1">
          <FormControl>
            <RadioGroup
              name={"carpoolPreference"}
              onChange={(e) => {
                updateState(prev => {
                  return { ...prev, carpoolPreference: e.target.value }
                });
              }}
              value={state.carpoolPreference}
            >
              <FormControlLabel
                className="sub-text radio-btn"
                value="BOTH"
                control={<Radio />}
                label="Driver or passenger"
              />

              <FormControlLabel
                className="sub-text radio-btn"
                value="OFFER"
                control={<Radio />}
                label="Driver"
              />

              <FormControlLabel
                className="sub-text radio-btn"
                value="FINDER"
                control={<Radio />}
                label="Passenger"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <CustomFixedButton disable={state.loading} handleSubmit={handleSubmit} text={'Next'}></CustomFixedButton>
      </div>
    </div>
  )
}