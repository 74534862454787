import * as yup from 'yup';

export function checkIfFilesAreTooBig(files: [File]): boolean {
  let valid = true
  files.forEach(file => {
    const size = file.size / 1024 / 1024
    console.log(file);
    if (size > 10) {
      valid = false
    }
  })

  return valid
}

export function checkIfFilesAreCorrectType(files: [File]): boolean {
  let valid = true

  files.forEach(file => {
    if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
      valid = false
    }
  })

  return valid
}

export const validationSchema = yup.object({
  email: yup.string().email('Invalid email').required("This is required field !"),
  firstName: yup.string().matches(/^[A-Za-z\s]+$/
    , "Only strings allowed").required("This is required field !"),
  lastName: yup.string().matches(/^[A-Za-z\s]+$/
    , "Only strings allowed").required("This is required field !"),
  occupation: yup.string().matches(/^[A-Za-z\s]+$/
    , "Only strings allowed").required("This is required field !"),
  phoneNumber: yup.string().matches(/^[0-9]+$/, "Only number allowed").min(11, "Must be 10 digit number").max(14, "Must be 10 digit number")
    .test(
      "countryCode",
      "Include country code also",
      value => {
        if (value && value.length === 10) {
          return false
        }
        return true;
      }
    ).required("A valid phone number is required"),
  // profilePicture: yup.mixed().test(
  //     'fileSize',
  //     'Maximum file size is 1 MB',
  //     (value:any) => value && value.size <= 1048576
  //   )
  //   .test(
  //     'fileFormat',
  //     'Only PNG and JPG formats are allowed',
  //     (value:any) => {
  //       if (!value) return false;
  //       const supportedFormats = ['image/png', 'image/jpeg'];
  //       return supportedFormats.includes(value.type);
  //     }
  //   ),

});
