import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import backButton from "../../../assets/images/back-arrow-icon.svg";
import otpVerify from "../../../assets/images/otp-verify.svg";
import { Box, Button } from "@mui/material";
import "./style.scss";
// import { numPadKeys } from "../Common components";
import { useLocation, useNavigate } from "react-router-dom";
import countDownWatch from "../../../assets/images/countdown-watch.svg";
import { verifyOTP, sendOTP } from "../../../services/OTP";
import { enqueueSnackbar } from "notistack";
// import { RootState } from "../../../store";
// import { useSelector } from "react-redux";
// import { activateCommunity } from "../../../services/location";

// interface IProps {
//   otp: string[];
//   setOTP: React.Dispatch<React.SetStateAction<string[]>>;
// }

interface CountdownTimerProps {
  remainingTime: number;
  setRemainingTime: React.Dispatch<React.SetStateAction<number>>
}

const CountdownTimer = ({ remainingTime, setRemainingTime }: CountdownTimerProps) => {
  //const [remainingTime, setRemainingTime] = useState(durationInSeconds);

  useEffect(() => {
    if (remainingTime > 0) {
      const intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, [remainingTime]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  return (
    <div style={{ color: "#616161" }}>
      <p>{`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`}</p>
    </div>
  );
};

// const KeyPad = ({ otp, setOTP }: IProps) => {
//   return (
//     <div className="otp-inputs">
//       <div className="numeric-keypad">
//         {numPadKeys.map(([key, letters], index) => (
//           <button
//             key={index}
//             style={{
//               background: key === "" || key === "⌫" ? "none" : undefined,
//               border: key === "" || key === "⌫" ? "none" : undefined,
//               borderRadius: key === "" || key === "⌫" ? "none" : undefined,
//               boxShadow: key === "" || key === "⌫" ? "none" : undefined,
//             }}
//             onClick={() => {
//               if (key === "⌫") {
//                 const newOTP = [...otp];
//                 const emptyIndex = newOTP.findIndex((digit) => digit === "");
//                 if (emptyIndex === -1) {
//                   newOTP[newOTP.length - 1] = "";
//                 } else {
//                   newOTP[emptyIndex - 1] = "";
//                 }
//                 setOTP(newOTP);
//               } else if (key !== "") {
//                 const newOTP = [...otp];
//                 const emptyIndex = newOTP.findIndex((digit) => digit === "");
//                 if (emptyIndex !== -1) {
//                   newOTP[emptyIndex] = String(key);
//                   setOTP(newOTP);
//                 }
//               }
//             }}
//           >
//             <div className="key-digit">{key}</div>
//             <div className="key-letters">{letters}</div>
//           </button>
//         ))}
//       </div>
//       {/* <div className="home-indicator d-flex justify-center items-center">
//         <div className="home-indicator-label"></div>
//       </div> */}
//     </div>
//   );
// };

const OTPScreen = () => {
  const [otp, setOTP] = useState<string[]>(["", "", "", ""]);
  // const [isMobile, setIsMobile] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(120)
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { state } = useLocation();
  // const { locationDetail } = useSelector((state: RootState) => state.utilityReducer)
  const navigate = useNavigate()


  const handleInputChange = (index: number, value: string) => {
    if (value.match(/^\d*$/)) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);

      if (value !== "" && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleInputKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOTP = [...otp];
      newOTP[index - 1] = "";
      setOTP(newOTP);
      inputRefs.current[index - 1]?.focus();
    }
  };

  // useEffect(() => {
  //   const isMobile = window.innerWidth <= 800;
  //   if (isMobile) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // }, []);

  const handleContinue = async () => {
    const payload = {
      email: state.email,
      otp: otp.join(""),
    };

    try {
      await verifyOTP(payload);
      // await activateCommunity(locationDetail._id);
      enqueueSnackbar("OTP verified successfully", { variant: "success" });
      navigate("/eligibility-success", { state });
    } catch {
      enqueueSnackbar("OTP verification failed", { variant: "error" });
    }
  };

  const handleResend = () => {
    sendOTP(state)
      .then((res: { data: { OTP: string } }) => {
        setTimer(120)
        enqueueSnackbar("OTP sent successfully", { variant: "success" })
      })
      .catch(() => enqueueSnackbar("Request failed, try again in sometime", { variant: "error" }))
  }

  return (
    <div id="otp-screen">
      <div className="back-button cursor-pointer p-1" onClick={() => navigate(-1)}>
        <img src={backButton} alt="back-button" />
      </div>
      <div className="otp-screen-verify mb-3">
        <div className="otp-verify-image mb-3 d-flex items-center justify-center">
          <img src={otpVerify} alt="" />
        </div>
        <div className="otp-verify-text mb-3 d-flex items-center justify-center heading">
          Verifying Code
        </div>
        <div className="otp-verify-sub-text d-flex items-center justify-center text-sm">
          Enter the 4 digit code sent to your email
        </div>
      </div>
      <div className="otp-input-box-container d-flex justify-center items-center p-1">
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="tel"
              maxLength={1}
              value={digit}
              // readOnly={isMobile ? true : false}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(index, e.target.value)
              }
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleInputKeyDown(index, e)
              }
              ref={(input) => {
                inputRefs.current[index] = input;
              }}
            />
          ))}
        </div>
      </div>
      <div className="verify-text d-flex justify-center items-center mb-5">
        Didn’t receive code?
        <span className="text-resend text-primary-color fw-700 cursor-pointer" onClick={handleResend}>Resend</span>
      </div>
      <div className="verify-countdown-container d-flex justify-center items-center gap-05">
        <img src={countDownWatch} alt="" />
        <CountdownTimer remainingTime={timer} setRemainingTime={setTimer} />
      </div>
      <Box className="d-flex justify-center items-center p-1">
        <Button
          className="next-btn custom-btn btn-width px-15rem"
          fullWidth
          type={"submit"}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Box>
      {/* {isMobile && <KeyPad otp={otp} setOTP={setOTP} />} */}
    </div>
  );
};

export default OTPScreen;
