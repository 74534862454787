import { useEffect, useState } from 'react'
import {
  useStripe,
} from "@stripe/react-stripe-js";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const [message, setMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      navigate("/dashboard/upcoming");
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      const options = paymentIntent?.status ? paymentIntent?.status : "default";
      switch (options) {
        case "succeeded": {
          setIsSuccess(true);
          setMessage("Payment succeeded!");
          break;
        }
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
    //eslint-disable-next-line
  }, [stripe]);



  return (
    <div>
      {message ? <div id="payment-message">
        {isSuccess
          ?
          <CheckCircleOutlineIcon color='primary' sx={{ width: "100px", height: "100px" }} />
          :
          <ErrorOutlineIcon color='error' sx={{ width: "100px", height: "100px" }} />
        }
        <Typography variant='h4'>{message}</Typography>
        <Link to="/dashboard/upcoming">Back to Dashboard</Link>
      </div>
        :
        <Typography variant='body2'>Loading ... </Typography>
      }
    </div>
  )
}

export default PaymentSuccess