import { httpFormRequest } from "../network";

export const basicDetailFileUpload = (file: File) => {
  return httpFormRequest('/upload', file).then((response: any) => {
    return response.data
  })
    .catch((err) => {
      return err
    })
}
