import { httpRequest } from "../network";

export const getInviteUsers = (tripId: string | undefined) => {
  return httpRequest(
    "GET",
    `aggregation/invite/filter-users-straight?tripId=${tripId}`
  )
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};
interface Params {
  tripStatus: string;
  page: number;
}

export const getAllTrips = (params: Params) => {
  return httpRequest("GET", "/aggregation/trip/user", {}, params)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAllTripsWithPopulatedDetails = (params: Params) => {
  return httpRequest("GET", "/aggregation/trip/user/trip-detail", {}, params)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

// get trip detail of a single trip
export const getSingleTripDetail = (tripId: string, tripStatus: string) => {
  return httpRequest(
    "GET",
    `/aggregation/trip/user/trip-detail/${tripId}?tripStatus=${tripStatus}`,
    {}
  )
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

// get all trips data for upcoming trips page
export const getAllTripsUserPopulated = (params: Params) => {
  return httpRequest("GET", "/aggregation/trip/user/all-trips", {}, params)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

// get trip data for specific tripIds
export const getTripData = (tripIds: string[]) => {
  return httpRequest("POST", "/aggregation/trip/user/multiple", { tripIds })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const createTrips = (data: object) => {
  return httpRequest("POST", "/aggregation/trip/user/multiple-trips", data)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getTripDuration = (tripId: string) => {
  return httpRequest("POST", `/aggregation/trip/user/trip-duration/${tripId}`)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

// cancel trip status
export const cancelTripStatus = (
  tripId: string,
  cancellationReason: string
) => {
  return httpRequest("PUT", `/aggregation/trip/user/cancel-trip/${tripId}`, {
    cancellationReason,
  })
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getTripsCount = (
  startDate: string | Date,
  endDate: string | Date
) => {
  return httpRequest("GET", `/aggregation/trip/user/user-trips-count?startDate=${startDate}&endDate=${endDate}`)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getTripReceipt = (_tripId: string) => {
  return httpRequest('POST', '/payment/transaction/trip-receipt', {_tripId})
    .then((response: any) => {
      return response.data;
    })
    .catch(error => {throw error;})
}