import { httpRequest, httpRequestDownload } from "../network";

export const uploadDeclaration =  async (data: any) => {
  try {
    const response: any = await httpRequest('POST', '/auth/signup/declaration', data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export const getDeclration = async ()=>{
  try {
    const response: any = await httpRequest("GET","/configuration/public/declaration");
    console.log(response);
    return response.data
  } catch (error) {
    console.log(error)
  }
}


export const downloadDeclration = async ()=>{
  try {
     const res: any = await httpRequestDownload("GET","/configuration/public/declaration/declaration-file",{},{});
     console.log(res)
     const url = window.URL.createObjectURL(res);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded_file.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export function mapFormToRequestForConfirmation(value: any){
    const payload = {
      isMOT: value.validMOT,
      isInsurance: value.activeInsurance,
      isDrivingLicence: value.validLicense,
      draftId: value._id
    }
    return payload;
}
