import "./style.scss";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import FilledLeap from "../../../assets/images/filled-leap.svg";
import LocationPin from "../../../assets/images/location-pin.svg";
import VerifiedPerson from "../../../assets/images/verified-person.svg";
import EmptyCircle from "../../../assets/images/empty-circle.svg";
import Checked from "../../../assets/images/checked.svg";
import { Box, Button } from "@mui/material";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  removeSelectedUser,
  setSelectedUsers,
  setFilteredUsers,
  IUSER,
  IUSERWITHTRIPINFO,
} from "../../../slice/invites";
import { getInviteUsers } from "../../../services/trips";
import { useNavigate, useParams } from "react-router-dom";
import { setInviteUserTrip } from "../../../slice/invite-trip-details";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import noMatchFound from "../../../assets/images/regret-message.svg";
import noMatchFound2 from "../../../assets/images/no-match-found-info.svg";
import moment from "moment";
import { handleGoToWhatsapp, showMapDuration } from "../../../utilities/helper";

const Matches = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  let [tripCordinate, setTripCoordinate] = useState(
    [] as Array<{ lat: number; lng: number }>
  );
  const [dataFetching, setDataFetching] = useState(true);
  const [drawer, setDrawer] = useState(false);

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  // const _driver: string | null = queryParams.get('_driver');
  // const tripId: string | null = queryParams.get('tripId');

  const tripId: string | undefined = id;

  const { filteredUsers, selectedUsers, selectedUsersId } = useSelector(
    (state: RootState) => state.inviteReducer
  );
  const tripDetails = useSelector(
    (state: RootState) => state.inviteTripReducer
  );
  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  const dispatch = useDispatch();

  // get the data of filtered users
  const getInvites = async (tripId: string | undefined) => {
    try {
      // make API request to get the data
      const resu = await getInviteUsers(tripId);

      //checking if data is available or not

      if (resu.data.invitees.length === 0) {
        setDrawer(true);
      }

      // set filtered users in global state
      dispatch(setFilteredUsers(resu.data.invitees));

      // set the trip details in global state
      dispatch(
        setInviteUserTrip({
          ...resu.data.tripDetails,
          totalTripTime: resu.data.totalTripTime,
          totalTripDistance: resu.data.totalTripDistance,
        })
      );
    } catch (error) {
      console.log("Error fetching data");
    }
    setDataFetching(false);
  };

  // extract the name of the filtered user
  const getName = (
    arrayVal: Array<{ value: string; lang: string }> | undefined,
    lang: string | undefined
  ) => {
    return (arrayVal || []).find(
      (obj) => (obj as { lang: string }).lang === "EN"
    )?.value;
  };

  // format the schedule date of the trip
  const returnFormattedDate = (xyz: any) => {
    return moment(xyz.scheduleDate).format("LLL");
  };

  // check if user is selected for invite or not
  const checkInvitedOrNot = (id: string) => {
    return selectedUsersId.includes(id);
  };

  // select button onclick handler
  const selectUserToInvite = (user: IUSER, metaInfo: object) => {
    // if user is already selected, then remove it from selected users
    if (selectedUsersId.includes(user._id)) {
      dispatch(removeSelectedUser([user]));
    } else {
      const updatedUser = { ...user, ...metaInfo };
      // if user is not selected, then add it to selected users
      dispatch(setSelectedUsers([updatedUser]));
    }
    // getInvites(tripId);
  };

  // select All User
  const SelectAllUser = () => {
    // get ids of all the filtered users
    let users: IUSERWITHTRIPINFO[] = [];
    filteredUsers?.forEach((user) => {
      // check if current id already exists in the selected users list, update the travel details to use in next screen
      if (!selectedUsersId.includes(user.user._id)) {
        const updatedUserData = {
          ...user.user,
          driverToPassengerTime: user.driverToPassengerTime,
          driverToPassengerDistance: user.driverToPassengerDistance,
          passengerToDestinationDistance: user.passengerToDestinationDistance,
          passengerToDestinationTime: user.passengerToDestinationTime,
        };

        users.push(updatedUserData);
      }
    });

    dispatch(setSelectedUsers(users));
    // getInvites(tripId);
  };

  //Open Route on Map
  const getAllCordinates = (
    e: React.MouseEvent<HTMLDivElement>
    // matechesArray: { filteredUsers: { array: { address: { geo: any[]; }; }[]; }; }, tripDetails: { trip: { pickUp: { geo: any[]; }; destination: { geo: any[]; }; }; }
  ) => {
    if (
      (e.currentTarget.firstElementChild as HTMLParagraphElement)
        .textContent === "View on map"
    ) {
      (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
        "Hide map";
    } else {
      (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
        "View on map";
    }
    // set the height of the map div if its height is not already 400px else set the height to 0px
    if (
      (e.currentTarget.nextElementSibling as HTMLDivElement).style.height ===
      "400px"
    ) {
      (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
        "0px";
    } else {
      (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
        "400px";
    }

    // toggle the chevron and cross icons
    // if down chevron is visible, then set it to hidden and set visibility of cross to visible and vice-versa
    if (
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .firstElementChild as SVGSVGElement
      ).style.visibility === "visible"
    ) {
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .firstElementChild as SVGSVGElement
      ).style.visibility = "hidden";
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .lastElementChild as SVGSVGElement
      ).style.visibility = "visible";
    } else {
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .firstElementChild as SVGSVGElement
      ).style.visibility = "visible";
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .lastElementChild as SVGSVGElement
      ).style.visibility = "hidden";
    }

    const arr: Array<{ lat: number; lng: number }> = [];
    arr.push({
      lat: parseFloat(String(tripDetails?.trip?.pickUp?.geo[0])),
      lng: parseFloat(String(tripDetails?.trip?.pickUp?.geo[1])),
    });
    filteredUsers?.forEach((element: any) => {
      arr.push({
        lat: parseFloat(element?.user?.address?.geo[0]),
        lng: parseFloat(element?.user?.address?.geo[1]),
      });
    });
    arr.push({
      lat: parseFloat(String(tripDetails?.trip?.destination?.geo[0])),
      lng: parseFloat(String(tripDetails?.trip?.destination?.geo[1])),
    });
    setTripCoordinate(arr);

    // sending the index of the map
    initMap(e.currentTarget.dataset.index as string);
  };

  // Initialize the map
  // const initMap = (index: string) => {
  //   var map = new google.maps.Map((document as any).getElementById(`map${index}`), {
  //     center: tripCordinate[0],
  //     zoom: 8,
  //   });

  //   // Create a new Polyline object
  //   var path = new google.maps.Polyline({
  //     path: tripCordinate,
  //     geodesic: true,
  //     strokeColor: '#FF0000',
  //     strokeOpacity: 1.0,
  //     strokeWeight: 2,
  //   });

  //   // Set the Polyline on the map
  //   path.setMap(map);
  // }

  const initMap = (
    index: string
    // tripCordinate: { lat: number; lng: number }[]
  ) => {
    var map = new google.maps.Map(
      (document as any).getElementById(`map${index}`),
      {
        center: tripCordinate[0],
        zoom: 8,
      }
    );

    // Create a new DirectionsService object
    var directionsService = new google.maps.DirectionsService();

    // Create a new DirectionsRenderer object
    var directionsRenderer = new google.maps.DirectionsRenderer({
      map: map,
    });

    // Define the start and end locations
    var start = new google.maps.LatLng(
      tripCordinate[0].lat,
      tripCordinate[0].lng
    );
    var end = new google.maps.LatLng(
      tripCordinate[tripCordinate.length - 1].lat,
      tripCordinate[tripCordinate.length - 1].lng
    );

    // Configure the DirectionsRequest object
    var request = {
      origin: start,
      destination: end,
      waypoints: tripCordinate.slice(1, -1).map((coordinate) => ({
        location: new google.maps.LatLng(coordinate.lat, coordinate.lng),
        stopover: true,
      })),
      travelMode: google.maps.TravelMode.DRIVING,
    };

    // Call the DirectionsService to get the route
    directionsService.route(request, function (result, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        // Display the route on the map
        directionsRenderer.setDirections(result);

        // get the directions response of the route
        let directionsResult = directionsRenderer.getDirections()!;

        // loop through each leg and show the duration for each leg in info window
        let prevDistance = 0;
        for (let i = 0; i < directionsResult.routes[0].legs.length; i++) {
          prevDistance = showMapDuration(
            directionsResult,
            map,
            i,
            prevDistance
          );
        }
      }
    });
  };

  useEffect(() => {
    getInvites(tripId);
    //eslint-disable-next-line
  }, []);

  return (
    <div id="matches">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
      />

      {/* if filter users are being fetched, then show the loading message */}
      {dataFetching ? (
        <div
          style={{
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#EFFBF5",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "2rem",
              borderRadius: "1rem",
              border: "1px solid #eee",
              margin: "0 1rem",
              fontSize: "0.8rem",
              textAlign: "center",
              lineHeight: "1.3rem",
              backgroundColor: "#FFF",
            }}
          >
            <p>
              ⏳ We're looking for other families on your route to Richard Pates
              School...
            </p>
          </div>
        </div>
      ) : (
        <>
          <Box className="margin-x matches-box">
            <div className="sub-header">
              <p className="heading">
                Select Match ({selectedUsers.length}/
                {(filteredUsers as []).length})
              </p>
              <p
                className="sub-text-14 primary-color click"
                onClick={() => SelectAllUser()}
              >
                Select all
              </p>
            </div>
            {(filteredUsers || []).map((item: any, i: number) => (
              <Box className="trip-person-info invited-person" key={i}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" className="margin-y items-center">
                    <a
                      href={item.user.photo ? item.user.photo : DefaultProfile}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="profile"
                        src={item.user.photo ? item.user.photo : DefaultProfile}
                        height="44px"
                        width="44px"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = DefaultProfile;
                        }}
                        className="rounded-full bordered"
                      />
                    </a>
                    <Box marginLeft="18px" className="info">
                      <p className="sub-text-14 driver-name">
                        {getName(item.user.firstName, "EN")}
                      </p>
                      <Box display="flex" marginY="4px">
                        {/* <Box className='verified-box' display="flex" alignItems='center' marginRight='4px'>
                            <img alt='verified' src={Dot} />
                            <p className='sub-text ml-1'>Verified</p>
                          </Box> */}
                        <img alt="verified" src={VerifiedPerson} />
                      </Box>
                      <p className="sub-text text-faded">
                        {returnFormattedDate(tripDetails.trip)}
                      </p>
                    </Box>
                  </Box>

                  {/* <Box className='invite-btn invite-btn-active center'>
                      <img alt='check' src={Checked} />
                      invited
                    </Box> */}

                  <Box
                    className="invite-btn invite-btn-active center"
                    onClick={(e) =>
                      selectUserToInvite(item.user, {
                        driverToPassengerTime: item.driverToPassengerTime,
                        driverToPassengerDistance:
                          item.driverToPassengerDistance,
                        passengerToDestinationDistance:
                          item.passengerToDestinationDistance,
                        passengerToDestinationTime:
                          item.passengerToDestinationTime,
                      })
                    }
                  >
                    <p className="select-button-text d-flex">
                      {checkInvitedOrNot(item.user._id) ? (
                        <img alt="check" src={Checked} />
                      ) : null}
                      {checkInvitedOrNot(item.user._id) ? "selected" : "select"}
                    </p>
                  </Box>
                </Box>

                <Box marginBottom="8px" className="sub-text">
                  <Box className="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <img alt="circle" src={EmptyCircle} />
                      <p className="sub-text-14 ml-2">
                        {getName(tripDetails?.trip?.pickUp.line1, "EN")}
                      </p>
                    </Box>
                    {/* <p className='sub-text'>25th Apr, 08:00</p> */}
                  </Box>

                  <Box
                    color="#E0E0E0"
                    className="center"
                    justifyContent="space-between"
                    marginLeft="10px"
                  >
                    |
                  </Box>

                  <Box className="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <img alt="circle" src={FilledLeap} />
                      <p className="sub-text-14 ml-2">
                        {getName(item.user?.address.line1, "EN")}
                      </p>
                    </Box>
                    <p className="sub-text text-faded text-600">
                      +
                      {(
                        Number(item?.driverToPassengerTime || 0) * 60 +
                        Number(item?.passengerToDestinationTime || 0) * 60 -
                        Number(tripDetails.trip?.totalTripTime || 0) * 60
                      ).toFixed(2)}{" "}
                      min
                    </p>
                  </Box>

                  <Box
                    color="#E0E0E0"
                    className="center"
                    justifyContent="space-between"
                    marginLeft="10px"
                  >
                    |
                  </Box>

                  <Box className="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <img alt="circle" src={LocationPin} />
                      <p className="sub-text-14 ml-2">
                        {getName(tripDetails?.trip?.destination.line1, "EN")}
                      </p>
                    </Box>
                    <p className="sub-text primary2-color text-600">
                      {(Number(tripDetails.trip?.totalTripTime) * 60).toFixed(
                        2
                      ) + " min"}
                    </p>
                  </Box>
                </Box>

                <Box
                  data-index={i}
                  className="map-view"
                  marginTop="16px"
                  marginBottom="8px"
                  onClick={(e) => getAllCordinates(e)}
                >
                  <p className="view sub-text-14 text-700 primary-color">
                    View on map
                  </p>
                  <div style={{ position: "relative" }}>
                    <ExpandMoreIcon
                      style={{ visibility: "visible" }}
                      className="primary-color"
                    />
                    <CloseIcon
                      style={{
                        visibility: "hidden",
                        position: "absolute",
                        right: "0",
                        top: "0",
                      }}
                      className="primary-color"
                    />
                  </div>
                </Box>
                <div id={`map${i}`} className="expand-transition rounded"></div>
              </Box>
            ))}
          </Box>

          <Box className="next-btn-box">
            <Box className="margin-x">
              <Button
                className="next-btn custom-btn"
                fullWidth
                onClick={() => navigate("/dashboard/trip-detail")}
              >
                Next
              </Button>
            </Box>
          </Box>
        </>
      )}
      <CustomDrawer
        anchor="bottom"
        isOpen={drawer}
        onClose={() => {
          navigate("dashboard/upcoming");
        }}
      >
        <Box className="no-match-found">
          <img className="no-match-message" src={noMatchFound} alt="" />
        </Box>
        <Box sx={{ marginTop: "10px" }} className="no-match-found">
          <img className="no-match-message" src={noMatchFound2} alt="" />
        </Box>
        <Box className="button-container">
          {/* <Button
            className='next-btn custom-btn'
            fullWidth onClick={() => { navigate("/dashboard/upcoming") }}>
            Back To Whatsapp
          </Button> */}
          <Button
            className="next-btn custom-btn"
            fullWidth
            onClick={handleGoToWhatsapp}
          >
            Back To Whatsapp
          </Button>
        </Box>
      </CustomDrawer>
    </div>
  );
};

export default Matches;
