import { FC } from 'react';
import './style.scss';
import Logo from '../../assets/images/logo-wth-name.svg'
import DefaultProfile from '../../assets/images/default-profile.svg';
import HamburgerMenu from '../../assets/images/hamburgerMenu.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { open } from '../../slice/drawer'


interface props {
  rightIcon?: string
  handleClickRight?: Function
}

//eslint-disable-next-line
const Header: FC<props> = ({ rightIcon, handleClickRight }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetailsReducer } = useSelector((state: RootState) => state);

  const handleClick = () => {
    if (userDetailsReducer.basicDetails.userPreference !== 'MARKET_PLACE') {
      if (userDetailsReducer.user.email) {
        navigate("/dashboard/home");
      } else {
        navigate("/");
      }
    } else {
      navigate("/dashboard/market-place");
    }
  }

  const handleClickRightIcon = () => {
    dispatch(open());
  }

  return (
    <>
      <div id='mobile-header-1' className={'header-box parent'}>
        <img
          alt='logo'
          className='cursor-pointer'
          onClick={handleClick}
          src={Logo}
        />
        <div className='d-flex items-center gap-05'>
          <img
            alt='logo'
            className='t-img'
            src={rightIcon ?? DefaultProfile}
          />
          <img className='cursor-pointer' src={HamburgerMenu} alt='hamburger-menu' onClick={handleClickRightIcon} />
        </div>
      </div>
    </>
  )
}

export default Header
