import React, { Fragment, useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import { Box, Button } from "@mui/material";
import Header from "../../../../components/header";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../../../../store";
import "./style.scss";
import { communityMemberRequest, getMembersOfLocation } from "../../../../services/location";
import { ILocationMemberPopulated } from "../../../../interface/location";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../../../components/loading-screen";
import { IUSER } from "../../../../slice/invites";
import { whatsAppWebhookApprovalSuccess } from "../../../../services/whatsApp";

interface IProps {
  fullName: string;
  userId: string;
  email: string;
  isSelected: (userId: string) => boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, userId: string) => void;
}

const UserInfo = ({ fullName, userId, email, isSelected, handleChange }: IProps) => {
  return (
    <div className="p-1 border-bottom d-flex justify-between">
      <div className="name-and-email-container">
        <div className="mb-1 fw-700 text-sm-2">
          <p>{fullName}</p>
        </div>

        <div className="mb-1 fw-700 text-new">
          <p>{email}</p>
        </div>
      </div>

      <div className="d-flex items-center">
        <Checkbox checked={isSelected(userId)} onChange={(event) => handleChange(event, userId)} />
      </div>
    </div>
  );
};

const Approval = () => {
  const [locationDetails, setLocationDetails] = useState<ILocationMemberPopulated>({})
  const [loading, setLoading] = useState(true)
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])

  const {
    basicDetails: { photo },
  } = useSelector((state: RootState) => state.userDetailsReducer);

  const { decodedData } = useSelector(
    (store: RootState) => store.redirectDataReducer
  );

  const navigate = useNavigate()

  // get location details
  useEffect(() => {
    if(!decodedData.locationId) {
      navigate('/')
      
      return
    }

    getLocationDetails()

    // eslint-disable-next-line
  }, [])

  // function to fetch location details and set in the local state
  const getLocationDetails = async () => {
    setLoading(true)

    try {
      const locationDetails = await getMembersOfLocation(decodedData.locationId) as ILocationMemberPopulated

      if (locationDetails === undefined || locationDetails === null) {
        enqueueSnackbar('Location details cannot be fetched', { variant: 'error' })
      }

      setLocationDetails(locationDetails)
    } catch (error) {
      enqueueSnackbar('Location details cannot be fetched', { variant: 'error' })
      navigate('/')
    } finally {
      setLoading(false)
    }
  }

  // toogle all users select/de-select
  const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    // check if checkbox is already checked, then unselect all users
    if (!event.target.checked) {
      setSelectedUsers([])

      setSelectAllCheckbox(false)
      return
    }

    //logic for selecting all users
    setSelectedUsers(() => {
      return locationDetails._members.map(user => user._user._id)
    })

    setSelectAllCheckbox(true)
  };

  //logic for selecting individual user
  const selectUser = (event: React.ChangeEvent<HTMLInputElement>, userId: string) => {
    // check if user is already selected
    if (selectedUsers.includes(userId)) {
      // if yes, then remove the user from the selectedUsers array
      setSelectedUsers(selectedUsers.filter(user => user !== userId))

      // uncheck select all checkbox
      setSelectAllCheckbox(false)
    } else {
      // if not, then push the user to the selectedUsers array
      setSelectedUsers([...selectedUsers, userId])
    }
  };

  // check if user is selected or not
  const checkSelectedUsers = (userId: string) => {
    //  check if user is selected or not
    if (selectedUsers.find(user => user === userId)) {
      return true
    }

    return false
  }

  // approve button click handler
  const handleApprove = async () => {
    setLoading(true)

    try {
      // make an API request to approve the selected users
      await communityMemberRequest(decodedData.locationId, selectedUsers)

      // loop through all selected users and send whatsApp notification
      for (let memberId of selectedUsers) {
        // get the member details
        const memberDetails = locationDetails._members.find(
          (member: { _user: IUSER }) => member._user._id === memberId
        )

        try {
          // send a whatsApp webhook to notify approved members
          await whatsAppWebhookApprovalSuccess(
            memberDetails._user.phone,
            memberDetails._user.jti,
            "MEMBERAPPROVED",
            locationDetails.locationName[0].value,
          )
        } catch (error) {
          enqueueSnackbar('Unable to notify approved members', { variant: 'error' })
        }
      }

      enqueueSnackbar('Users approved successfully', { variant: 'success' })

      navigate("/")
    } catch (error) {
      enqueueSnackbar('Unable to approved members', { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Header backLink={photo} />
      {
        loading ? (
          <LoadingScreen />
        ) : (
          <div id="approve-user-request">
            {
              locationDetails._members.length === 0 ? (
                <div className="d-flex items-center justify-center pt-5">No members to approve</div>
              ) : (
                <>
                  <div className="d-flex items-center justify-between mb-3 p-1">
                    <p className="fw-700 text-lg">Pending Approvals</p>

                    <p className="fw-700 text-sm-3 color-primary cursor-pointer">View history</p>
                  </div>

                  <div className="p-1 d-flex items-center flex-end">
                    <p className="fw-700">Select all</p>

                    <div className="d-flex ">
                      <Checkbox color="primary" checked={selectAllCheckbox} onChange={selectAll} />
                    </div>
                  </div>

                  {
                    locationDetails._members.map((member: { _user: IUSER }, index: number) => (
                      <UserInfo
                        key={index}
                        userId={member._user._id}
                        fullName={member._user.firstName[0].value}
                        email={member._user.email}
                        isSelected={checkSelectedUsers}
                        handleChange={selectUser}
                      />
                    ))
                  }
                </>
              )
            }

            <Box
              position="sticky"
              bottom="0"
              top={`${locationDetails._members.length === 0 ? "100%" : "auto"}`}
              bgcolor="#fff"
              borderTop="1px solid #EEEEEE"
              className="d-flex justify-center items-center pb-40"
            >
              <Box className="margin-x width-100 ">

                <Button
                  className="next-btn custom-btn btn-text"
                  fullWidth
                  onClick={() => {
                    if (locationDetails._members.length === 0) {
                      navigate("/")
                    } else {
                      handleApprove()
                    }
                  }}
                >
                  {
                    locationDetails._members.length === 0 ? "Go back" : "Approve"
                  }
                </Button>

              </Box>
            </Box>
          </div>
        )
      }
    </Fragment>
  );
};

export default Approval;
