import { Box } from "@mui/material";
import { backArrowIcon, circleFour, circleOne, circleThree, circleTwo } from "../../../assets/images";
import "./style.scss";
import CustomFixedButton from "../../../components/custom-fixed-button";
import { useNavigate } from "react-router-dom";

const MarketPlaceHowWorks = () => {

  const navigate = useNavigate();

  return (
    <Box id="marketplace-how-works">
      <Box className="marketplace-how-works-back-container" onClick={() => {
        navigate('/dashboard/market-place')
      }}>
        <img src={backArrowIcon} alt="back" />
        <b>Back</b>
      </Box>
      <Box className="marketplace-how-works-content">
        <Box className="marketplace-how-works-header-container">
          <h2>How it works</h2>
        </Box>
        <Box className="marketplace-how-works-line">
          <Box>
            <img src={circleOne} alt="1" />
          </Box>
          <Box>
            <h4 className="mt-2 mb-05">Unlock our Rewards Marketplace</h4>
            <p className="text-md">
              Simply complete at least 4 carpool trips each month
              to maintain access to over 150 discounted top brand
              gift cards to save on your everyday shopping
            </p>
          </Box>
        </Box>
        <Box className="marketplace-how-works-line">
          <Box>
            <img src={circleTwo} alt="2" />
          </Box>
          <Box>
            <h4 className="mt-2 mb-05">Choose a discounted gift card</h4>
            <p className="text-md">
              You can purchase a discounted gift card for the
              specific amount of the purchase you are making
            </p>
          </Box>
        </Box>
        <Box className="marketplace-how-works-line">
          <Box>
            <img src={circleThree} alt="3" />
          </Box>
          <Box>
            <h4 className="mt-2 mb-05">Make the payment via secure instant bank transfer</h4>
            <p className="text-md">
              During checkout, you'll also have the option to use any earned eco tokens
              from fuel contributions to offset your balance
            </p>
          </Box>
        </Box>
        <Box className="marketplace-how-works-line">
          <Box>
            <img src={circleFour} alt="4" />
          </Box>
          <Box>
            <h4 className="mt-2 mb-05">Your discounted gift card will be available immediately</h4>
            <p className="text-md">
              You can then spend it at your chosen retailer
            </p>
          </Box>
        </Box>
      </Box>
      <CustomFixedButton text={"Back"} disable={false} handleSubmit={() => {
        navigate('/dashboard/market-place')
      }} />
    </Box>
  )
};

export default MarketPlaceHowWorks;