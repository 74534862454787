import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getUser } from "../../services/user";
import { IUSER } from "../invites";
import { updateDailySchedule } from "../../services/favourite-location";

const initialState = {
  user: {} as IUSER,
  userLoading: true,
  userError: ""
}

// get user by JTI
export const getUserByJti = createAsyncThunk("user/getUser", async (jti: string, thunkAPI) => {
  try {
    const response = await getUser(jti)

    return response
  } catch (error) {
    return thunkAPI.rejectWithValue((error as AxiosError).message)
  }
})

// update daily schedule
export const updateUserDailySchedule = createAsyncThunk("user/updateDailySchedule", async (payload: object, thunkAPI) => {
  try {
    const data: any = await updateDailySchedule(payload)

    return data
  } catch (error) {
    return thunkAPI.rejectWithValue((error as AxiosError).message)
  }
})

const userSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getUserByJti.pending, (state) => {
      state.userLoading = true
    }).addCase(getUserByJti.fulfilled, (state, action) => {
      state.userLoading = false
      state.user = action.payload.data
      localStorage.setItem('token', action.payload.data.token)
    }).addCase(getUserByJti.rejected, (state, action) => {
      state.userLoading = false
      state.userError = action.payload as string
    })
  }
}
)

export const {
  setUser
} = userSlice.actions;


export default userSlice.reducer
