import "./style.scss";
import eligibilityCheck from "../../../assets/images/eligibility-check.svg";
import eligibilitityCheckFlowIcon from "../../../assets/images/ecopooling-whatsapp.svg";
import Description from "../Common components/Description";
import { CustomInputBox as SignUpWithWhatsApp } from "../Common components/CustomInputBox";
import { contents } from "../Common components";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { whatsAppWebHook } from "../../../services/whatsApp";
import { validate } from "../../../utilities/helper";
import { WHATSAPP_SIGN_UP_TEXT } from "../../../constants";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setLocationAdminSignUp } from "../../../slice/utility";
const EligibilitySuccess = () => {
  const [phone, setPhone] = useState<string>()
  const { locationAdminSignUp } = useSelector((state: RootState) => state.utilityReducer)
  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    setPhone(value);
  }

  const handleSubmit = () => {
    if (validate("phone", phone as string)) {
      whatsAppWebHook(phone as string, "SIGNUPINVITE", state.locationId as string);
      dispatch(setLocationAdminSignUp(false))
      window.open(
        `${process.env.REACT_APP_WHATSAPP_SIGN_ME_IN_URL}?text=${WHATSAPP_SIGN_UP_TEXT}`,
        "_blank"
      );
    }
  }

  return (
    <div id="eligibility-success">
      <div className="d-flex flex-column justify-center items-center p-1">
        <div className="eligibility-check-mark">
          <img src={eligibilityCheck} alt="eligibility-check" />
        </div>
        <div className="eligibility-check-success-message sub-text-new font-32 text-center">
          {/* checking if the user is admin of a private community and showing message accordingly */}
          {
            locationAdminSignUp ? <p>Administrator verified! </p> : <p> Eligibility check successful! </p>
          }
        </div>
        <div className="eligibility-check-ecopooling-whatsapp-icon my-3">
          <img src={eligibilitityCheckFlowIcon} alt="ecopooling-whatsapp" />
        </div>
        <div className="eligibility-check-proceed-to-flow-text sub-text-new">
          <p>Proceed to sign up</p>
        </div>
      </div>

      <div className="eligibility-check-description-container text-sm p-1 mb-1">
        {contents.map((content, key) => (
          <Description
            key={key}
            uniqueKey={key}
            icon={content.icon}
            description={content.text}
          />
        ))}
      </div>

      <div className="elgibility-check-action p-1 d-flex flex-column flex-1">
        <SignUpWithWhatsApp
          value={phone}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          btnText="Sign up with Whatsapp"
        />
      </div>
    </div>
  );
};

export default EligibilitySuccess;
