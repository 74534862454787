import { useNavigate } from "react-router-dom";
import userNotVerified from "../../../assets/images/exclamation-mark.svg"
import { Button, Box } from '@mui/material';
import './style.scss'
const PaymentFailed = () => {
  const navigate = useNavigate()
  return (
    <div className="payment-failed-wrapper-main">
      <div className="sub-payment-failed-wrapper">
        <div className="failed-mark-container">
          <img src={userNotVerified} alt="all-done" />
        </div>
        <div className="failed-text mt-3">Payment Failed</div>
        <div className="sub-failed-text mt-3">
          We are not able to process your payment at the moment.
        </div>
        <div className="sub-failed-text mt-3">
          Please try after sometime.
        </div>
      </div>
      <Box className="d-flex justify-center items-center">
        <Button
          className="next-btn custom-btn btn-width mt-3"
          fullWidth
          onClick={() => navigate("/dashboard/market-place")}
        >
          Done
        </Button>
      </Box>
    </div>
  );
}

export default PaymentFailed
