import React from 'react';
//import defaultProfile from "../../../../assets/images/default-profile.svg"
import leftArrowPointer from "../../../../assets/images/left-arrow-pointer.svg"
import "./style.scss";

interface IProps{
    image : string,
    name : string,
    token : number
}

const ReferredUsers = [
    {
    image : "https://common-internal.s3.ap-south-1.amazonaws.com/uploads/1693232055148_703A02D2-C34D-4394-BD60-0DEDD6F5DF17.jpeg",
    name : "John Doe",
    token : 1000
   },
    {
    image : "https://common-internal.s3.ap-south-1.amazonaws.com/uploads/1693232055148_703A02D2-C34D-4394-BD60-0DEDD6F5DF17.jpeg",
    name : "Jane Doe",
    token : 2000
   },
    {
    image : "https://common-internal.s3.ap-south-1.amazonaws.com/uploads/1693232055148_703A02D2-C34D-4394-BD60-0DEDD6F5DF17.jpeg",
    name : "Johnson Doe",
    token : 3000
   }
]
const ReferredUser = ({image,name,token}:IProps) => {
    return(
        <div className="referred-user-detail-box p-1">
        <div className="referred-user-details-container d-flex">
        <div className="referred-user-image  mr-2">
            <img src={image} alt="" />
        </div>
        <div className="referred-user-name-and-earned-token">
            <div className="referred-user-name mb-2 fw-700 text-sm-2">
               {name}
            </div>
            <div className="earned-token text-sm-2">
                {token} eco tokens
            </div>
        </div>
      </div>
      <div className="left-arrow-pointer-container">
        <img src={leftArrowPointer} alt="left-arrow" />
      </div>
      </div>
    )
}
const ReferralHistory = () => {
  return (
    <div id="referral-histoy">
      <div className="referral-history-heading p-1">
        <p className='referral-history'>Referral History</p> 
        <p className='text-sm'>Total referral eco tokens earned: 2000</p>
      </div>
 
      {
        ReferredUsers.map((user,key)=> 
            <ReferredUser image={user.image} name={user.name} token={user.token}/>
        )
      }
    
    </div>
  );
}

export default ReferralHistory
