import { Box } from "@mui/material"
import Header from "../../../components/header"
import CustomFixedButton from "../../../components/custom-fixed-button"
import { MarketPlaceLoginPic, whatsApp } from "../../../assets/images"
import './style.scss';
import { useCallback, useEffect, useState } from "react";
import { getUserByUniqueId } from "../../../services/user";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../../components/loading-screen";

export const MarketPlaceUserAuth = () => {
  const userUniqueId = new URLSearchParams(window.location.search).get('id');

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({
    marketPlaceUsersCount: 0,
    name: 'Ecopooling user',
  });

  const navigate = useNavigate();

  const getUser = useCallback(async () => {
    try {
      const userDetails = await getUserByUniqueId(userUniqueId) as {
        marketPlaceUsersCount: number,
        user: {
  
          firstName: { lang: string; value: string }[],
          lastName: { lang: string; value: string }[]
        }
      };
  
      const name = `${userDetails.user.firstName && userDetails.user.firstName.length ? userDetails.user.firstName[0].value : ''} ${
        userDetails.user.lastName && userDetails.user.lastName.length ? userDetails.user.lastName[0].value : ''
        }`;
  
      setUser(state => ({
        ...state,
        marketPlaceUsersCount: userDetails.marketPlaceUsersCount,
        name
      }));
    } catch (error) {
      // if unique id is invalid, then redirect to home
      navigate("/")
    } finally {
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [userUniqueId]);

  useEffect(() => {
    // if there is no unique id in the url, redirect to home
    if(!userUniqueId) {
      navigate("/")
    } else {
      getUser();
    }

    // eslint-disable-next-line
  }, [getUser]);

  return (
    <>
      {
        loading ? <LoadingScreen /> : (
          <Box id="marketplace-login">
            <Header signUp={true} publicSignUp={true} />
            <Box className="marketplace-body-container">
              <h2 className="text-center">{user.name} has invited you to join EcoPooling Rewards!</h2>

              <div className="d-flex justify-center w-100">
                <img src={MarketPlaceLoginPic} alt="brands" className="marketplace-login-pic" />
              </div>

              <p className="d-flex justify-center text-center">
                Join their Eco Rewards Pool and enjoy exclusive access to discounts at over 150 top brands,
                redeemable instantly online or in-store by preloading a retailer gift card with exact purchase
                price amount.
              </p>

              <CustomFixedButton
                text="Sign up via WhatsApp"
                handleSubmit={() => {
                  if (user.marketPlaceUsersCount < 10) {
                    window.open(`${process.env.REACT_APP_WHATSAPP_SIGN_ME_IN_URL}/?text=${encodeURIComponent(`Join EcoPooling Rewards with invite code: ${userUniqueId}`)
                      }`, "_blank");
                  } else {
                    enqueueSnackbar('Invalid link, Sign up exceeded', {
                      variant: 'error',
                    });
                  }
                }}
                disable={false}
                icon={whatsApp}
                bottomJSX={<Box className="pt-1">
                  <p className="marketplace-privacy-text">By signing up for Ecopooling, you agree to the&nbsp;
                    <a href="https://drive.google.com/file/d/1H_ktE1jW7c-ztjrFqOlOarF10mlSoDdV/view" className="marketplace-login-link" target="_blank" rel="noreferrer">Terms of Service</a>. View our&nbsp;
                    <a href="https://drive.google.com/file/d/1ZsP8MWMiqhvKyPQ-lhqmY_VFbZI5Q-tu/view" className="marketplace-login-link" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>
                </Box>}
              />
            </Box>
          </Box>
        )
      }
    </>
  )
}