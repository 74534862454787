import "./style.scss";
import { Box } from "@mui/material";
import Header from "../../../components/header-1";
import Person from "../../../assets/images/person.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import verifiedMark from "../../../assets/images/verify-tick-20X20.svg";
import CarStearingIcon from "../../../assets/images/stearing.svg";
import { capitalizeFirstLetter, getName } from "../../../utilities/helper";

const Profile = () => {
  const userReducer = useSelector(
    (state: RootState) => state.userDetailsReducer
  );
  const { basicDetails, address } = userReducer;
  const navigate = useNavigate();

  const getPrefText = (pref: string) => {
    switch (pref) {
      case "OFFER":
        return "I will be looking to offer carpools";
      case "FINDER":
        return "I’m looking to book passenger seats on carpools ";
      case "BOTH":
        return "Both driver and passenger";
      default:
        return "Text ";
    }
  };
  console.log('user details **************** ', basicDetails)

  return (
    <div id="profile">
      <Header rightIcon={basicDetails.photo} />

      <Box className="padding-x rounded-top-green">
        <div className="sub-header">
          <div className="sub-header-icon-container">
            <img alt="signature" src={Person} />
          </div>
          <p className="heading sub-text-new">My profile</p>
        </div>
      </Box>

      <div className="section-head section-head-bg-light-green">
        <Box className="center" justifyContent="space-between">
          <p>Your details</p>

          {
            basicDetails.userPreference !== 'MARKET_PLACE' && (
              <p
                className="link edit-new edit-link-color-new"
                onClick={() => navigate("/update/")}
              >
                Edit
              </p>
            )
          }
        </Box>
      </div>

      <Box
        marginX="16px"
        marginTop="16px"
        marginBottom="24px"
        className="your-detail"
      >
        <Box marginBottom="16px" className="center" flexDirection="column">
          {
            basicDetails.userPreference !== 'MARKET_PLACE' && (
              <img alt="profile" src={basicDetails.photo} className="bordered-2 object-fit-cover" />
            )
          }

          <div className="d-flex items-center">
            <p className="sub-text-16 name">
              {capitalizeFirstLetter(getName(basicDetails.firstName, "EN"))}{" "}
              {capitalizeFirstLetter(getName(basicDetails.lastName, "EN"))}
            </p>

            {basicDetails.isVerified && (
              <img
                className="verified-user"
                src={verifiedMark}
                alt="verified"
              />
              )} 
              
              {
                basicDetails.isDriverVerified && (
                <img
                  className="verified-user"
                  src={CarStearingIcon}
                  alt="driver verified"
                />
              )
            }
          </div>

          {
            basicDetails.userPreference === 'MARKET_PLACE' ? (
              <Box width="168px" className="text-center mt-3">
                  <p className="custom-label">Phone number</p>
                  <p className="sub-text-14">{basicDetails.phone}</p>
              </Box>
            ) : (
              <p className="sub-text-14">{basicDetails.email}</p>
            )

          }
        </Box>

        {
          basicDetails.userPreference !== 'MARKET_PLACE' && (
            <>
              <Box
                marginBottom="16px"
                className="center"
                justifyContent="space-between"
              >
                <Box width="168px">
                  <p className="custom-label">Gender</p>

                  <p className="sub-text-14">
                    {basicDetails.gender?.slice(0, 1) +
                      basicDetails.gender?.slice(1)?.toLowerCase() || ''}
                  </p>
                </Box>

                <Box width="168px">
                  <p className="custom-label">Phone number</p>
                  <p className="sub-text-14">{basicDetails.phone}</p>
                </Box>
              </Box>

              <Box>
                <p className="custom-label">Carpool preferences</p>

                <p className="sub-text-14">
                  {getPrefText(basicDetails.userPreference)}
                </p>
              </Box>
            </>
          )
        }
      </Box>

      {
        basicDetails.userPreference !== 'MARKET_PLACE' && (
          <>
            <Box
              className="section-head section-head-bg-light-green center"
              justifyContent="space-between"
            >
              <p>Home location</p>

              <p
                className="link  edit-new edit-link-color-new"
                onClick={() => navigate("/update/home-location")}
              >
                Edit
              </p>
            </Box>

            <Box className="address-box">
              <Box>
                <p className="custom-label">My address</p>
                
                <p className="sub-text-14">
                  {address?.line1[0]?.value + ', ' + address?.city[0]?.value + ', ' + address?.state[0]?.value + ', ' + address?.zip}
                </p>
              </Box>
            </Box>
          </>
        )
      }

    </div>
  );
};

export default Profile;
