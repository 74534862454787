import { Fragment } from "react";
import Header from "../../../../components/header";
import linkWhatsApp from "../../../../assets/images/eco-whatsapp.svg";
import { contents } from "../../Common components";
import Description from "../../Common components/Description";
import { CustomInputBox as SignUpWithWhatsApp } from "../../Common components/CustomInputBox";
import SubText from "../../Common components/SubText";
import { useEffect, useState } from "react"
import { getName } from "../../../../utilities/helper"
import { getLocationPublic as getLocation } from "../../../../services/location"
import { useLocation } from "react-router-dom";
import LoadingScreen from "../../../../components/loading-screen";
import { whatsAppWebHook } from "../../../../services/whatsApp";
import { validate } from "../../../../utilities/helper";
import { WHATSAPP_SIGN_UP_TEXT } from "../../../../constants";
import "./style.scss";

interface IStates {
  loading: boolean,
  locationName: string,
  phoneNo: number | string
}
const PublicSignUp = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [state, setStates] = useState<IStates>({
    loading: true,
    locationName: "",
    phoneNo: ""
  })

  // remove any redux persisted data
  localStorage.removeItem('persist:root')

  // remove zero from first digit
  useEffect(() => {
    if(state.phoneNo[2] === "0") {
      setStates((prev) => {
        return {
          ...prev,
          phoneNo: String(prev.phoneNo).slice(0,2)
        }
      })
    }
  }, [state]);

  useEffect(() => {
    getLocation(id as string)
      .then((res) =>
        setStates((prev) => {
          return {
            ...prev,
            locationName: getName(res.locationName, "EN") as string,
            loading: false,
          };
        })
      )
      .catch(() =>
        setStates((prev) => {
          return {
            ...prev,
            loading: false,
          };
        })
      );
    // eslint-disable-next-line
  }, [id]);

  const handleChange = (value:string) => {
    setStates((prev) => {
      return {
        ...prev,
        phoneNo: value
      }
    })
  };

  const handleSubmit = () => {
    if (validate('phone', state.phoneNo as string)) {
      whatsAppWebHook(state.phoneNo as string, "SIGNUPINVITE", id as string);
      window.open(
        `${process.env.REACT_APP_WHATSAPP_SIGN_ME_IN_URL}?text=${WHATSAPP_SIGN_UP_TEXT}`,
        "_blank"
      );
    }
  };

  return (
    <Fragment>
      {state.loading ? (
        <LoadingScreen />
      ) : (
        <div id="public-community">
          <Header type="signup" publicSignUp={true} signUp={true} />
          <div className="public-community-signup d-flex flex-column justify-center items-center p-1">
            <div className="public-community-signup-text heading text-center my-3">
              <p>Join {state.locationName} carpool community</p>
            </div>
            <div className="public-community-link-whatsapp-container my-3">
              <img src={linkWhatsApp} alt="" />
            </div>
          </div>
          <div className="public-community-signup-description-container text-sm p-1 mb-1">
            {contents.map((content, key) => (
              <Description
                uniqueKey={key}
                icon={content.icon}
                description={content.text}
                textColor="black"
              />
            ))}
          </div>
          <div className="public-community-signup-action pt-pl-pr-1">
            <SignUpWithWhatsApp
              value={state.phoneNo}
              onChange={handleChange}
              handleSubmit={handleSubmit}
              whatsAppSignUp={false}
            />
          </div>
          <SubText />
        </div>
      )}
    </Fragment>
  );
};

export default PublicSignUp;
