import { httpRequest } from "../network"

export const updateGroup = (payload: object) => {
  return httpRequest('PUT', '/auth/group', payload)
    .then((res: any) => res.data)
    .catch((err) => {
      throw err
    })
}

export const getGroup = (locationId: string) => {
  return httpRequest('GET', `/auth/group/${locationId}`)
    .then((res: any) => res.data)
    .catch((err) => {
      throw err;
    })
}

export const simulateMatching = (locationId:string) => {
  return httpRequest("GET",`/aggregation/invite/user/simulate/${locationId}`)
  .then((res: any)=> res.data)
  .catch((err)=>{
    throw err
  })
}
