import { httpRequest } from "../network";

// update daily schedule in offer carpool flow
export const updateDailySchedule = (data: object) => {
  return httpRequest('PUT', '/auth/favourite-location/user/daily-schedule', data).then((response: any) => {
    return response
  }).catch((err) => {
    throw err
  })
}

export const setFavouriteLocation = (payload:object) => {
   return httpRequest('POST','/auth/signup/favourite-location',payload)
   .then((res)=> res)
   .catch((err)=> {
     throw err
   })
}
