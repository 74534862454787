import { Outlet } from "react-router-dom";
import WebHeader from "../../components/web-header";
import { useLocation } from "react-router-dom";
import "./style.scss";


const Home = () => {
  const { pathname } = useLocation();

  const showWebHeader = () => {
    switch (pathname) {
      case '/dashboard/market-place/how-works': 
        return false;
      case '/maintenance':
        return false;
      default: return true;
    }
  }

  const getClassName = () => {
    return pathname === "/dashboard/home"
      ? "app-wrapper-with-background app-body-wrapper"
      : "app-wrapper app-body-wrapper";
  };

  return (
    <div className="main-outer-wrapper">
      { showWebHeader() && <WebHeader login={true} />}

      {pathname === "/dashboard/home" && (
        <></>
      )}

      <div
        id={pathname === "/dashboard/home" ? "" : "app"}
        className={getClassName()}
      >
        <Outlet />
      </div>

      {/* <footer className="footer">
        <div className="footer-logo cursor-pointer" onClick={() => navigate("/")}>
          <img src={logoWithName} alt="logo" />
        </div>

        <nav className="footer-nav-container">
          <ul className="footer-nav">
            <li className="cursor-pointer" onClick={() => window.open("https://drive.google.com/file/d/1i2RKSIMsLjC7ZP1R7V3KmOmoczUR66Ee/view?usp=sharing", "_blank")}>Privacy policy</li>

            <li className="cursor-pointer" onClick={() => window.open("https://drive.google.com/file/d/1UcLL7YYqtmnW1TixR0suLUag-BLs7wAF/view?usp=sharing", "_blank")}>Terms of use</li>

            <li className="cursor-pointer" onClick={() => window.open("https://www.ecopooling.co.uk/faq", "_blank")}>Contact support</li>
          </ul>
        </nav>
      </footer> */}
    </div>
  );
};

export default Home;
