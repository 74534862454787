import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface PropsI {
  pairs: Array<{ key: string, value: string }>
  handleChange: Function,
  label: string,
  val: string
}
export default function DropDown(props: PropsI) {
  const handleChange = (event: SelectChangeEvent) => {
    props.handleChange(event.target);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: "100%", margin: 0 }} size="small">
      <InputLabel id="demo-select-small-label">{props.label}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={props.val}
        label={props.label}
        onChange={handleChange}
      >
        {props.pairs.map((it, index) => <MenuItem key={index} style={{ color: "#424242", fontSize: "14px" }} value={it.value}>{it.key}</MenuItem>)}
      </Select>
    </FormControl>
  );
}
