import { httpRequest } from "../network";

interface IProps {
  email: string,
  password: string
}

export const signup = (data: IProps) => {
  return httpRequest('POST', '/auth/signup/user', data).then((response: any) => {
    return response.data
  })
    .catch((err) => {
      throw err
    })
}

export const signupWithPut = (data: IProps) => {
  return httpRequest('PUT', '/auth/signup/user', data).then((response: any) => {
    return response.data
  })
    .catch((err) => {
      throw err;
    })
}

export const getUserFromDraft = async (data: IProps) => {
  try {
    const res: any = await httpRequest("POST", "auth/signup/get-draft", { email: data.email });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const createUser = async (draftId: string) => {
  try {
    const res: any = await httpRequest("POST", "/auth/signup/create-user", { draftId });
    return res.data;
  } catch (error) {
    throw error
  }
}

// get home details for draft user
export const getDraftHomeDetails = (draftId: string) => {
  return httpRequest('GET', `/auth/signup/profile/${draftId}`).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const createSchedule = async (data) => {
  return httpRequest('POST', `/auth/signup/schedule`, { ...data })
    .then((response: any) => {
      return response.data
    }).catch((err) => {
      throw err
    })
}

export const updateSchedule = async (data) => {
  return httpRequest('PUT', `/auth/user/schedule`, { ...data })
    .then((response: any) => {
      return response.data
    }).catch((err) => {
      throw err
    })
}

export const createCarpoolPreference = async (data) => {
  return httpRequest('POST', '/auth/signup/carpool-preference', { ...data })
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    })
}