import './style.scss';
import Header from './../../../../../components/header';
import DefaultProfile from '../../../../../assets/images/default-profile.svg';
import Start from '../../../../../assets/images/star.svg';
import LocationPin from '../../../../../assets/images/location-pin.svg';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from './../../../../../components/mui/SwipeableDrawer';
import { useEffect, useState } from 'react';
import { getCategory } from '../../../../../services/location';
import { enqueueSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { FavLocationInterface, updateDraftUserFavouriteLocation, updateFavLocation } from '../../../../../slice/sign-up';
import { AppDispatch, RootState } from '../../../../../store';
import ArrowDown from '../../../../../assets/images/ArrowDown.svg'
import StarFilled from '../../../../../assets/images/starFilled.svg'
import StarEmpty from '../../../../../assets/images/StarEmpty.svg'
import LoadingScreen from '../../../../../components/loading-screen';

const FavoriteLocation = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState<any[]>([]);
  const [currentCategory, setCurrentCategory] = useState<any>();
  const [currCategoryCopy, setCurrCategoryCopy] = useState<any>()
  const [search, setSearch] = useState()
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { signUpDetails } = useSelector((state: RootState) => state);
  const draftId = localStorage.getItem("draftId");

  const [state, setState] = useState({
    addLocation: {
      isOpen: false
    }
  });

  // when loader is showing, then hide the scrollbar
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden'
    } else if (!loading) {
      document.body.style.overflow = 'auto'
    }
  }, [loading])

  const findCategoryList = async () => {
    setLoading(true)
    try {
      const res = await getCategory();
      setCategory([...res]);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Could not fetch category list !", { variant: 'error' });
    }
    setLoading(false);
  }
  const [isStarFilled, setIsStarFilled] = useState<boolean[] | []>([]);
  useEffect(() => {
    findCategoryList();
  }, [])

  const handleAddLocation = (locations: any) => {
    setCurrentCategory({ ...locations })
    setCurrCategoryCopy({ ...locations })
    setState(prev => {
      return {
        ...prev,
        addLocation: {
          ...prev.addLocation,
          isOpen: true
        }
      }
    });
  }

  const closeDrawer = () => {
    setState(prev => {
      return {
        ...prev,
        addLocation: {
          ...prev.addLocation,
          isOpen: false
        }
      }
    });
  }

  const addFavLocation = (value: any, remove: boolean) => {
    const { categoryName, categoryId, locationName, locationId } = value;
    let found = false;
    let newList = signUpDetails.favLocation.map((fav: any) => {
      if (fav.categoryId === categoryId) {
        found = true;

        if (remove === false) {
          return { categoryName, categoryId, locationName, locationId };
        } else {
          return undefined
        }
      } else {
        return fav;
      }
    })

    let newListClean = newList.filter(val => val !== undefined)
    if (!found) {
      newListClean = [...newListClean, { categoryName, categoryId, locationName, locationId }]
    }
    dispatch(updateFavLocation(newListClean));
  }

  // const removeFavLocation = (value: any)=>{
  //     const {_id} = value;
  //     let newList = signUpDetails.favLocation.filter((fav: FavLocationInterface)=>fav.categoryId !== _id);
  //     dispatch(updateFavLocation(newList));
  // }

  const isActiveLocation = (locationId: string) => {
    return signUpDetails.favLocation.some((val: FavLocationInterface) => val.locationId === locationId);
  }

  const getCurrLocationNameForCat = (category: any) => {
    let ans = "";
    let curr = signUpDetails.favLocation.find((loc: FavLocationInterface) => loc.categoryId === category._id);
    if (curr !== undefined) return curr.locationName;
    return ans;
  }

  const ToggleStar = (index: number) => {
    let updatedIsStarFilled = new Array(category.length).fill(false)
    let updatedArray = updatedIsStarFilled.map((val, i) => i === index ? val = !val : val = false)
    setIsStarFilled(updatedArray); // Set the updated state array
  }

  const handleSearch = (e: any) => {
    setSearch(e.target.value)
    let searchItem = e.target.value;
    let regex = new RegExp(searchItem, 'i');
    let newCategory = currentCategory?.locations?.filter((val: any) =>
      regex.test(val.locationName[0].value)
    );
    setCurrCategoryCopy((prev: any) => {
      return {
        ...prev,
        locations: newCategory
      }
    })
  }

  const handleClickNext = () => {
    if (signUpDetails.favLocation.length === 0) {
      enqueueSnackbar('Must select at least one location !', { variant: 'warning' })
    } else {
      // save the location and move to summary page
      dispatch(updateDraftUserFavouriteLocation({ favouriteLocations: signUpDetails.favLocation.map(loc => ({ _categoryId: loc.categoryId, _favoriteLocation: loc.locationId })), draftId }))

      navigate("/signup/summary")
    }

  }

  useEffect(() => {
    if (!draftId) navigate("/");
    //eslint-disable-next-line
  }, [draftId]);

  return (
    <div id="favorite-location" style={{ paddingBottom: '16px' }}>
      <Header
        rightIcon={<img alt='profile' className='header-img-icon' src={
          (signUpDetails.basicDetails.profilePicture as string) ?? DefaultProfile
        } />}
        signUp={true}
      />
      <Box className="padding-x rounded-top-green">
        <div className="sub-header">
        <div className='sub-header-icon-container'>
          <img alt="star" src={Start} />
          </div>
          <p className="sub-text-new sub-text">
            Please set your regular trip destinations that you would like to carpool including setting your primary trip destination.
          </p>
        
        </div>
      </Box>
      <p className="section-head">Set at least 1 trip destination</p>
      {loading === true ? (
        <LoadingScreen />
      ) : (
        <Box className="margin-x">
          {category?.map((cat: any, key: number) => (
            <Box className="margin-y" key={key}>
              <div
                className="star-and-location-container"
                style={{ display: "flex" }}
              >
                <img
                  key={key}
                  src={isStarFilled[key] ? StarFilled : StarEmpty}
                  alt="star-icon"
                  style={{ marginRight: "18px", marginLeft: "18px" }}
                  onClick={() => ToggleStar(key)}
                />

                <Box className="location-box cursor-pointer" onClick={() => handleAddLocation(cat)} sx={{ width: "100%" }}>
                  <div className="inline">
                    <p className="custom-label">
                      {cat.locationName[0].value}
                      <br />
                      <span className="link">
                        {getCurrLocationNameForCat(cat)}
                      </span>
                    </p>
                    <img
                      src={ArrowDown}
                      alt={"arrow down"}
                      className="custom-label link"

                    />
                  </div>
                </Box>
              </div>
            </Box>
          ))}
          <Box className="d-flex justify-center items-center">
          <Button
            className="next-btn custom-btn btn-width"
            fullWidth
            onClick={() => handleClickNext()}
          >
            Next
          </Button>
          </Box>
        </Box>
      )}

      {!!currCategoryCopy && (
        <CustomDrawer
          anchor="bottom"
          isOpen={state.addLocation.isOpen}
          onClose={closeDrawer}
        >
          <Box id="favorite-location-drawer" className="margin-x">
            <div className="search-box">
              <TextField
                className="custom-input"
                id="first_name"
                placeholder={`Find ${currCategoryCopy?.locationName[0].value}`}
                value={search}
                fullWidth={false}
                onChange={handleSearch}
                InputProps={{
                  style: {
                    height: "48px",
                  },
                }}
              />
              <p className="sub-text-16 cancel cursor-pointer" onClick={closeDrawer}>
                Cancel
              </p>
            </div>

            <div className="selected">
              <p className="sub-text-16 fw-700">Selected:</p>
              <p className="sub-text-16 sub-text">
                {
                  getCurrLocationNameForCat(currCategoryCopy) === "" ? (
                    <>
                      Select a {currCategoryCopy?.locationName[0].value}
                    </>
                  ) : (
                    getCurrLocationNameForCat(currCategoryCopy)
                  )
                }
              </p>
            </div>

            <div className="show-locations">
              <div className="between">
                <p className="title">
                  All {currCategoryCopy?.locationName[0].value}
                </p>
                <p className="align-middle">
                </p>
              </div>

              {currCategoryCopy?.locations.map((location: any, key: number) => (
                <MenuItem className="location-box cursor-pointer" key={key}
                  onClick={() => {
                    let remove = false
                    if (isActiveLocation(location?._id)) {
                      remove = true
                    }
                    addFavLocation({
                      categoryName: currCategoryCopy.locationName[0].value,
                      categoryId: currCategoryCopy._id,
                      locationName: location.locationName[0].value,
                      locationId: location._id,
                    }, remove)
                    closeDrawer()
                  }
                  }
                >
                  <div className="d-flex item-center margin-y-4"

                  >
                    <img alt="location-pin" src={LocationPin} />
                    <p
                      className={
                        isActiveLocation(location?._id)
                          ? "sub-text location-name active-location"
                          : "sub-text location-name"
                      }

                    >
                      {" "}
                      {location.locationName[0]?.value}
                    </p>
                  </div>
                </MenuItem>
              ))}
            </div>
          </Box>
        </CustomDrawer>
      )}
    </div>
  );
}

export default FavoriteLocation;
