import React, { useEffect } from "react";
import LoadingScreen from "../../../../components/loading-screen";
import { getAllTripsUserPopulated } from "../../../../services/trips";
import { updateAllTripsData } from "../../../../slice/trip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearLocalStorageBeforeVerification } from "../../../../utilities/helper";
import { resetBookSeatReducer } from "../../../../slice/book-seat";
import { resetFilteredUsersAll } from "../../../../slice/invites";
import { useSearchParams } from "react-router-dom";
import { getPaymentStatus } from "../../../../services/payment";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../../store";

const Loading = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const { userDetailsReducer } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (!searchParams.get("truelayer")) {
      getAllTripsUserPopulated({ tripStatus: "UPCOMING", page: 1 })
        .then((res) => {
          dispatch(updateAllTripsData(res));
          dispatch(resetBookSeatReducer());
          dispatch(resetFilteredUsersAll());
          clearLocalStorageBeforeVerification()
          userDetailsReducer.basicDetails.userPreference === 'MARKET_PLACE' ? navigate("/dashboard/market-place") :
            navigate("/dashboard/home");
        })
        .catch(() => {
          clearLocalStorageBeforeVerification()
          userDetailsReducer.basicDetails.userPreference === 'MARKET_PLACE' ? navigate("/dashboard/market-place") :
            navigate("/dashboard/home");
        });
    } else if(searchParams.get("truelayer")) {
      checkPaymentStatus()
    }
    // eslint-disable-next-line
  }, []);

  async function checkPaymentStatus() {
    let fromMarketplace = localStorage.getItem("fromMarketplace");

    let intervalId = setInterval(async () => {
      try {
        // make a request to server to get payment status
        const paymentStatusResponse = await getPaymentStatus({
          transactionId: localStorage.getItem("paymentId"),
        });
  
        if (paymentStatusResponse.data.paymentStatus === "SUCCESS") {
          // clear 30 seconds timeout function
          clearTimeoutFunc();
  
          // stop making the request
          clearInterval(intervalId);

          // remove user data from local host in case of unverified user for book seat flow
          localStorage.removeItem("selectedTripResponseNew");

          // remove data from local storage in case of unverified user for find carpool/review invites flow
          localStorage.removeItem("selectedTripResponse");
          localStorage.removeItem("selectedInvites");

          // navigate to payment completion page
          navigate("/payment-submitted", {state: {redirect: fromMarketplace ? 'marketplace' : 'bookSeat'}});
        } else if (paymentStatusResponse.data.paymentStatus === "FAILURE") {
          // clear 30 seconds timeout function
          clearTimeoutFunc();
  
          // stop making the request
          clearInterval(intervalId);
  
          if(
            (paymentStatusResponse.data.callBackPayload.failure_reason === "canceled") || 
            (paymentStatusResponse.data.callBackPayload.failure_reason === "user_canceled_at_provider") || 
            (paymentStatusResponse.data.callBackPayload.failure_reason === "not_authorized") || 
            (paymentStatusResponse.data.callBackPayload.failure_reason === "not_authorised")
          ) {
            // navigate to cancelled payment page
            navigate("/payment-cancelled", {state: {redirect: fromMarketplace ? 'marketplace' : 'bookSeat'}});
          } else {
            // navigate to failed payment page
            navigate("/payment-failed", { state: {paymentStatusResponse: paymentStatusResponse.data} });
          }
        }
      } catch (error) {
        // clear 30 seconds timeout function
        clearTimeoutFunc();

        // stop making the request
        clearInterval(intervalId);

        enqueueSnackbar("Unable to get payment status. Please contact support", { variant: "error" });

        userDetailsReducer.basicDetails.userPreference === 'MARKET_PLACE' ? navigate("/dashboard/market-place") :
          navigate("/")
      }
    }, 1000)

    // after 30 seconds, if the payment is not successful/not done, then cancel the payment window and retain the user at the same screen for another payment
    let timeoutId = setTimeout(() => {
      clearInterval(intervalId);

      enqueueSnackbar("Payment is unsuccessful. Please try again", {
        variant: "error",
      });

      navigate("/");
    }, 30000);

    let clearTimeoutFunc = () => {
      clearTimeout(timeoutId);
    };
  }

  return (
    <div>
      <LoadingScreen />
    </div>
  );
};

export default Loading;
