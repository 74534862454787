import './style.scss';
import Header from '../../../../components/header';
import DefaultProfile from '../../../../assets/images/default-profile.svg';
import Signature from '../../../../assets/images/signature.svg';
import { Box, Button, Checkbox } from '@mui/material';
import CustomDrawer from '../../../../components/mui/SwipeableDrawer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { updateConfirmation } from '../../../../slice/sign-up';
import { getDeclration, mapFormToRequestForConfirmation, uploadDeclaration } from '../../../../services/declaration';
import { enqueueSnackbar } from 'notistack';
import StringToHtml from '../../../../components/string-to-html';


const Declaration = () => {
    const [declaration, setDeclaration] = useState(false);
    const [loading, setLoading] = useState(false);
    const [declarationHtml, setDeclarationHtml] = useState("");
    const signUpDetails = useSelector((state: RootState) => state.signUpDetails);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const draftId = localStorage.getItem("draftId");

    const formik = useFormik({
        initialValues: signUpDetails.confirmation,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                dispatch(updateConfirmation(values));
                const payload = mapFormToRequestForConfirmation({
                    ...values,
                    ...signUpDetails.user,
                    ...{ _id: draftId }
                })
                if (!payload.isMOT || !payload.isInsurance || !payload.isDrivingLicence) {
                    return enqueueSnackbar("All Statements are Required !", { variant: 'error' });
                }
                await uploadDeclaration(payload)
                navigate('/signup/home-location-detail')
            } catch (error: any) {
                console.log(error);
                const msg = error?.response?.data?.message ?? "Something went wrong";
                enqueueSnackbar(msg, { variant: 'error' });
            } finally {
                setLoading(false);
            }
        }
    });

    const handleConfirm = () => {
        setDeclaration(prev => !prev)
    }

    useEffect(() => {
        if (!draftId) {
            navigate("/");
        }
        getDeclration().then((data: any) => {
            setDeclarationHtml(data.declaration)
        })
        //eslint-disable-next-line
    }, [draftId])

    return (
        <div id='declaration'>
            <Header
                canGoBack={true}
                rightIcon={<img alt='profile' className='header-img-icon' src={
                    (signUpDetails.basicDetails.profilePicture as string) ?? DefaultProfile
                } />}
                signUp={true}
            />
            <Box className='padding-x rounded-top-green'>
                <div className="sub-header">
                    <div className='sub-header-icon-container'>
                    <img alt='signature' src={Signature} />
                    </div>
                    <p className='sub-text-new sub-text'>
                        Please confirm that you have read and accept the following terms and conditions.
                    </p>
                </div>
            </Box>
            <p className="section-head">Declaration</p>
            <Box className='margin-x'>
                <Box className='read-instruction'>
                    <div className='sub-text max-height-300'>
                        <StringToHtml htmlString={declarationHtml} />
                    </div>
                </Box>
                 <Box className="d-flex justify-center items-center">
                <Button className='custom-btn margin-y w-auto' style={{padding:"1.5rem"}} fullWidth onClick={handleConfirm}>
                    I accept the terms and conditions
                </Button>
                </Box>
            </Box>

            <CustomDrawer
                anchor='bottom'
                isOpen={declaration}
                onClose={handleConfirm}
            >
                <Box className='declaration-drawer margin-x'>
                    <p className='confirm-text text-center'>Confirmation</p>

                    <Box className='options-box margin-y'>
                        <p className='custom-label dark-label'>Please confirm the following statements are correct</p>
                        <form onSubmit={formik.handleSubmit}>
                            <Box marginBottom="19px">
                                <div className="checkbox-container">
                                    <Checkbox
                                        name={"validLicense"}
                                        checked={formik.values.validLicense}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <p className='sub-text'>I have a valid driving license</p>
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox color='primary'
                                        name={"validMOT"}
                                        checked={formik.values.validMOT}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <p className='sub-text'>My vehicle has a valid MOT</p>
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox color='primary'
                                        name={"activeInsurance"}
                                        checked={formik.values.activeInsurance}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                    />
                                    <p className='sub-text'>I have an active car insurance policy</p>
                                </div>
                                <Box className="d-flex justify-center items-center">
                                <Button className='confirm-btn custom-btn btn-width' fullWidth disabled={loading || !formik.values.validLicense || !formik.values.validMOT || !formik.values.activeInsurance} type='submit'>Confirm</Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </CustomDrawer>
        </div>
    )
}

export default Declaration;
