import "./style.scss";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import FilledLeap from "../../../assets/images/filled-leap.svg";
import LocationPin from "../../../assets/images/location-pin.svg";
import VerifiedPerson from "../../../assets/images/verified-person.svg";
import CheckedBlack from "../../../assets/images/checkmark_black.svg";
import CarStearingIcon from "../../../assets/images/stearing.svg";
import { Box } from "@mui/material";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import CloseIcon from "@mui/icons-material/Close";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
//import seatUnavailable from "../../../assets/images/seat-unavailable.svg";
//import seatUnavailableMessage from "../../../assets/images/seat-unavailable-message.svg";
import moment from "moment";

import {
  // selectAllSeatNew,
  initBookSeatNew,
  // unSelectAllNew,
  addItemToSlectedNew,
  removeItemFromSlectedNew,
} from "../../../slice/book-seat";
import { enqueueSnackbar } from "notistack";
import {
  getInvites,
  getSeatAvailabilityMultiple,
  // getSeatDetails
} from "../../../services/book-seat";
import { TripResponseInterfaceNew } from "../../../interface/book-seat-new";
import { capitalizeFirstLetter, getOrganisationLogo, getOrganizationName, handleGoToWhatsapp, 
  // showMapDuration
 } from "../../../utilities/helper";
import LoadingScreen from "../../../components/loading-screen";
import { whatsAppWebHook } from "../../../services/whatsApp";
import userVerified from "../../../assets/images/user-verified.svg";
import userNotVerified from "../../../assets/images/user-not-verified.svg";
import { BasicDetails } from "../../../slice/login";
import UserProfile from "../../../components/user-profile";
import CustomFixedButton from "../../../components/custom-fixed-button";

const BookSeat = () => {
  //eslint-disable-next-line
  const [dataFetching, setDataFetching] = useState(true);
  const [drawer, setDrawer] = useState(false);
  const [drawer1, setDrawer1] = useState(false);
  const [userDetail, setUserDetail] = useState<{
    showUserDetail: boolean;
    userDetail: BasicDetails;
    locationName: string;
    organisationLogo: string
  }>({
    showUserDetail: false,
    userDetail: {
      firstName: [],
      lastName: [],
      email: "",
      gender: "",
      phone: "",
      photo: "",
      userPreference: "",
      occupation: "",
      createdAt: "",
    },
    locationName: "",
    organisationLogo: "",
  });

  const navigate = useNavigate();
  let { data: base64Data } = useParams();
  const dispatch = useDispatch();

  const {
    bookSeatReducer: { tripResponseNew, selectedTripResponseNew },
    userDetailsReducer,
    redirectDataReducer,
  } = useSelector((state: RootState) => state);

  // //Open Route on Map
  // const getAllCordinates = (
  //   e: React.MouseEvent<HTMLDivElement>,
  //   trip: TripResponseInterfaceNew
  //   // matechesArray: { filteredUsers: { array: { address: { geo: any[]; }; }[]; }; }, tripDetails: { trip: { pickUp: { geo: any[]; }; destination: { geo: any[]; }; }; }
  // ) => {
  //   if (
  //     (e.currentTarget.firstElementChild as HTMLParagraphElement)
  //       .textContent === "View on map"
  //   ) {
  //     (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
  //       "Hide map";
  //   } else {
  //     (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
  //       "View on map";
  //   }

  //   // set the height of the map div if its height is not already 400px else set the height to 0px
  //   if (
  //     (e.currentTarget.nextElementSibling as HTMLDivElement).style.height ===
  //     "400px"
  //   ) {
  //     (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
  //       "0px";
  //   } else {
  //     (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
  //       "400px";
  //   }

  //   // toggle the chevron and cross icons
  //   // if down chevron is visible, then set it to hidden and set visibility of cross to visible and vice-versa
  //   if (
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .firstElementChild as SVGSVGElement
  //     ).style.visibility === "visible"
  //   ) {
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .firstElementChild as SVGSVGElement
  //     ).style.visibility = "hidden";
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .lastElementChild as SVGSVGElement
  //     ).style.visibility = "visible";
  //   } else {
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .firstElementChild as SVGSVGElement
  //     ).style.visibility = "visible";
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .lastElementChild as SVGSVGElement
  //     ).style.visibility = "hidden";
  //   }

  //   //get the lat and long of user to diplay in map
  //   const {
  //     address: { geo },
  //   } = userDetailsReducer;

  //   const arr: Array<{ lat: number; lng: number }> = [];
  //   arr.push({
  //     lat: parseFloat(String(trip.tripDetail.pickUp.geo[0])),
  //     lng: parseFloat(String(trip.tripDetail.pickUp.geo[1])),
  //   });

  //   arr.push({
  //     lat: parseFloat(String(geo[0])),
  //     lng: parseFloat(String(geo[1])),
  //   });

  //   arr.push({
  //     lat: parseFloat(String(trip.tripDetail.destination.geo[0])),
  //     lng: parseFloat(String(trip.tripDetail.destination.geo[1])),
  //   });

  //   // sending the index of the map
  //   initMap(e.currentTarget.dataset.index as string, arr);
  // };

  // // Initialize the map
  // const initMap = (index: string, arr: Array<{ lat: number; lng: number }>) => {
  //   var map = new google.maps.Map(
  //     (document as any).getElementById(`map${index}`),
  //     {
  //       center: arr[0],/*[{lat:-33.8688,lang:151.2195}]*/
  //       zoom: 8,
  //     }
  //   );

  //   // Create a new Polyline object
  //   var path = new google.maps.Polyline({
  //     path: arr,
  //     geodesic: true,
  //     strokeColor: "#FF0000",
  //     strokeOpacity: 1.0,
  //     strokeWeight: 2,
  //   });

  //   // Set the Polyline on the map
  //   path.setMap(map);
  // };

  // const initMap = (
  //   index: string,
  //   tripCordinate: { lat: number; lng: number }[]
  // ) => {
  //   var map = new google.maps.Map(
  //     (document as any).getElementById(`map${index}`),
  //     {
  //       center: tripCordinate[0],
  //       zoom: 8,
  //     }
  //   );

  //   // Create a new DirectionsService object
  //   var directionsService = new google.maps.DirectionsService();

  //   // Create a new DirectionsRenderer object
  //   var directionsRenderer = new google.maps.DirectionsRenderer({
  //     map: map,
  //   });

  //   // Define the start and end locations
  //   var start = new google.maps.LatLng(
  //     tripCordinate[0].lat,
  //     tripCordinate[0].lng
  //   );
  //   var end = new google.maps.LatLng(
  //     tripCordinate[tripCordinate.length - 1].lat,
  //     tripCordinate[tripCordinate.length - 1].lng
  //   );

  //   // Configure the DirectionsRequest object
  //   var request = {
  //     origin: start,
  //     destination: end,
  //     waypoints: tripCordinate.slice(1, -1).map((coordinate) => ({
  //       location: new google.maps.LatLng(coordinate.lat, coordinate.lng),
  //       stopover: true,
  //     })),
  //     travelMode: google.maps.TravelMode.DRIVING,
  //   };

  //   // Call the DirectionsService to get the route
  //   directionsService.route(request, function (result, status) {
  //     if (status === google.maps.DirectionsStatus.OK) {
  //       // Display the route on the map
  //       directionsRenderer.setDirections(result);

  //       // get the directions response of the route
  //       let directionsResult = directionsRenderer.getDirections()!;

  //       // loop through each leg and show the duration for each leg in info window
  //       let prevDistance = 0;
  //       for (let i = 0; i < directionsResult.routes[0].legs.length; i++) {
  //         prevDistance = showMapDuration(
  //           directionsResult,
  //           map,
  //           i,
  //           prevDistance
  //         );
  //       }
  //     }
  //   });
  // };

  const select = (id: string) => {
    setDataFetching(true);

    getSeatAvailabilityMultiple([id])
      .then((value: any) => {
        dispatch(addItemToSlectedNew(id));
      })
      .catch((err: any) => {
        enqueueSnackbar("Seat is not available for this trip", {
          variant: "warning",
        });

        // setDrawer(true);
      })
      .finally(() => {
        setDataFetching(false);
      });
  };

  const formatdateFunc = (date: string) => {
    let formattedDate = "";
    formattedDate += moment(new Date(date)).format("Do");
    formattedDate += " " + moment(new Date(date)).format("MMM");
    // formattedDate +=
    //   moment(new Date(date)).format("HH") +
    //   ":" +
    //   moment(new Date(date)).format("mm");

    return formattedDate;
  };

  const unSelect = (id: string) => {
    dispatch(removeItemFromSlectedNew(id));
  };

  // const selectAll = () => {
  //   if (tripResponseNew.length === selectedTripResponseNew.length) {
  //     dispatch(unSelectAllNew(null));
  //   } else {
  //     setDataFetching(true);
  //     const allTripId = (tripResponseNew || []).map((it) => it.tripDetail._id);
  //     getSeatAvailabilityMultiple(allTripId)
  //       .then((value) => {
  //         console.log(value);
  //         dispatch(selectAllSeatNew(null));
  //       })
  //       .catch((error: any) => {
  //         console.log("error **************** ", error);

  //         // loop through all trips and check if any trip has zero seats available
  //         for (let trip of error.response.data.data.tripSeatDetail as [
  //           { _tripId: string; seatsAvailable: number }
  //         ]) {
  //           if (trip.seatsAvailable === 0) {
  //             const invite = tripResponseNew.find(
  //               (invite) =>
  //                 invite.tripDetail._id.toString() === trip._tripId.toString()
  //             );

  //             enqueueSnackbar(
  //               `Sorry, no seat is available for ${moment(
  //                 invite?.tripDetail.scheduleDate
  //               ).format("Do MMM")}`,
  //               { variant: "warning" }
  //             );

  //             return;
  //           }
  //         }

  //         // enqueueSnackbar(`Sorry, no seat is available for ${moment(invite?.tripDetail.scheduleDate).format('Do MMM')}`, { variant: 'warning' })

  //         // setDrawer(true);
  //       })
  //       .finally(() => {
  //         setDataFetching(false);
  //       });
  //   }
  // };

  const isSelected = (item: TripResponseInterfaceNew) => {
    let found = false;
    selectedTripResponseNew.forEach((it) => {
      if (item.tripDetail._id === it.tripDetail._id) found = true;
    });
    return found;
  };

  const handleNavigate = () => {
    if (selectedTripResponseNew.length === 0) {
      enqueueSnackbar("Must select at least one seat !", {
        variant: "warning",
      });
      return;
    } else {
      navigate(`/redirect-page/confirm-seat/${base64Data}`);
    }
    navigate(`/redirect-page/confirm-seat/${base64Data}`);
  };

  useEffect(() => {
    if (dataFetching === false && tripResponseNew.length === 0) {
      // hit the whatsApp webhook
      whatsAppWebHook(userDetailsReducer.basicDetails.phone, "SEATUNAVAILABLE");
    }
    // eslint-disable-next-line
  }, [dataFetching, tripResponseNew]);

  useEffect(() => {
    setDataFetching(true);

    // if coming back from next screen, then don't fetch the data again
    if (tripResponseNew.length === 0) {
      //init data coming from api to do
      getInvites(redirectDataReducer.decodedData.driverId as string)
        .then((val) => {
          // getSeatDetails().then((val) => {
          if (val.length === 0) {
            // enqueueSnackbar("No invites found",{variant:"error"})
            // navigate('/')
            setDrawer1(true);
          } else {
            dispatch(initBookSeatNew(val));
          }
        })
        .catch((err: any) => {
          enqueueSnackbar("Couldn't get invite details", { variant: "error" });
          navigate("/");
          console.log(err);
        })
        .finally(() => {
          setDataFetching(false);
        });
    } else {
      setDataFetching(false);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <div id="matches-book-seat" className="book-seat-wrapper">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
      />

      {dataFetching && <LoadingScreen />}

      <Box className="padding-x matches-box">
        <div className="sub-header">
          <p className="heading">
            Select carpools
            ({selectedTripResponseNew.length}/
                    {(tripResponseNew as []).length})
            </p>

          {/* <p
            className="sub-text-14 primary-color click"
            onClick={() => selectAll()}
          >
            {tripResponseNew.length !== 0 &&
              tripResponseNew.length === selectedTripResponseNew.length
              ? "De-select all"
              : "Select all"}
          </p> */}
        </div>

        {tripResponseNew.length === 0 ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              minHeight: "100vh",
              zIndex: 100,
            }}
          >
            <LoadingScreen />
          </div>
        ) : (
          tripResponseNew.map((item, i) => (
            <Box className={`trip-person-info ${isSelected(item) ? "invited-person" : "uninvited-user"}`} key={i}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" className="margin-y items-center">
                  
                    <img
                      alt="profile"
                      src={item.driverDetail.photo ?? DefaultProfile}
                      height="44px"
                      width="44px"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DefaultProfile;
                      }}
                      className="rounded-full bordered object-fit-cover cursor-pointer"
                      onClick={() => setUserDetail({ showUserDetail: true, userDetail: item?.driverDetail, locationName: getOrganizationName(item?.locationDetail, {userDetail: item?.driverDetail}), organisationLogo: getOrganisationLogo(item?.locationDetail, {userDetail: item?.driverDetail}) })}
                    />

                  <Box marginLeft="18px" className="info">
                    <p className="sub-text-14 driver-name d-flex items-center gap-4">
                      {capitalizeFirstLetter(item?.driverDetail?.firstName[0]?.value)}{" "}
                      {capitalizeFirstLetter(item?.driverDetail?.lastName[0]?.value)}

                      {
                        !item?.locationDetail?.verificationType.includes("NONE") && (
                          <img
                            src={
                              item?.driverDetail?.isVerified
                                ? userVerified
                                : userNotVerified
                            }
                            alt="user-verify-status"
                          />
                        )
                      }

                      {
                        item?.driverDetail?.isDriverVerified && (
                          <img
                            src={CarStearingIcon}
                            alt="driver verified"
                          />
                        )
                      }
                    </p>

                    {item?.driverDetail?.loginType === "FACEBOOK" && (
                      <Box display="flex" marginY="4px">
                        <img
                          alt="verified"
                          src={VerifiedPerson}
                          onClick={() =>
                            window.open(
                              `https://www.facebook.com/profile.php?id=${item?.driverDetail?.socialId}`,
                              "_blank"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                    )}

                    <p className="sub-text text-faded">
                      {formatdateFunc(item.estimatedDropTime)}
                    </p>
                  </Box>
                </Box>

                {isSelected(item) ? (
                  <Box
                    onClick={() => unSelect(item.tripDetail._id)}
                    className="invite-btn invite-btn-active center cursor-pointer"
                  >
                    <p className="select-button-text d-flex items-center">
                      <img alt="check" src={CheckedBlack} className="mr-0" /> Book
                    </p>
                  </Box>
                ) : (
                  <Box
                    onClick={() => select(item.tripDetail._id)}
                    className="invite-btn center cursor-pointer not-booked"
                  >
                    <p className="select-button-text">Book seat</p>
                  </Box>
                )}
              </Box>

              <Box marginBottom="8px" className="sub-text">
                <Box className="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center" className="w-80">
                    <img alt="circle" src={FilledLeap} />

                    <p className="sub-text-14 ml-2">
                      {item.tripDetail.destinationLocationId === undefined
                        ? item?.locationDetail?.locationName[0]?.value ?? "N/A"
                        : "Home"}
                    </p>
                  </Box>

                  <p className="text-600 text-faded align-right w-20">
                    {item.tripDetail.destinationLocationId !== undefined &&
                      "ETA "}

                    {moment(item.estimatedPickUpTime).format("HH:mm")}
                  </p>
                </Box>

                <Box
                  color="#E0E0E0"
                  className="center"
                  justifyContent="space-between"
                  marginLeft="10px"
                >
                  |
                </Box>

                <Box className="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center" className="w-80">
                    <img alt="circle" src={LocationPin} />

                    <p className="sub-text-14 ml-2">
                      {item.tripDetail.destinationLocationId === undefined
                        ? "Home"
                        : item?.locationDetail?.locationName[0]?.value ?? "N/A"}
                    </p>
                  </Box>

                  <p className="sub-text primary2-color text-600 align-right w-20">
                    {item.tripDetail.destinationLocationId === undefined &&
                      "ETA "}

                    {moment(item.estimatedDropTime).format("HH:mm")}
                  </p>
                </Box>
              </Box>

              <div className="section-head rounded-6 mb-05">
                <Box className="between">
                  <Box className="sub-text-14 driver-name">Price</Box>

                  {/*add price here */}
                  <Box className="sub-text-14 driver-name">
                    £{item.perSeatPrice.toFixed(2)}
                  </Box>
                </Box>
              </div>

              {/* <Box
                data-index={i}
                className="map-view cursor-pointer"
                marginTop="16px"
                marginBottom="8px"
                onClick={(e) => getAllCordinates(e, item)}
              >
                <p className="view sub-text-14 text-700 primary-color">
                  View on map
                </p>

                <div style={{ position: "relative" }}>
                  <ExpandMoreIcon
                    style={{ visibility: "visible" }}
                    className="primary-color"
                  />

                  <CloseIcon
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      right: "0",
                      top: "0",
                    }}
                    className="primary-color"
                  />
                </div>
              </Box>

              <div id={`map${i}`} className="expand-transition rounded"></div> */}
            </Box>
          ))
        )}
      </Box>

      {/* <Box className="next-btn-box">
        <Box className="margin-x d-flex justify-center items-center">
          <Button
            className="next-btn custom-btn btn-width btn-text"
            fullWidth
            onClick={() => handleNavigate()}
          >
            Next
          </Button>
        </Box>
      </Box> */}

      <CustomFixedButton disable={false} text={"Next"} handleSubmit={handleNavigate}/>

      <CustomDrawer
        anchor="bottom"
        isOpen={drawer}
        fullScreen={true}
        onClose={() => setDrawer(false)}
      >
        <div
          className="h-100 d-flex items-center flex-column justify-center gap-2"
          style={{ background: "#EFFBF5" }}
        >
          <Box
            className="seat-unavailable-message-box p-05 "
            style={{ background: "#fff", paddingBottom: "1rem" }}
          >
            <Box className="seat-unavailable">
              <h3 className="pl-15 pr-15 pt-0">🙁 Seat unavailable</h3>
            </Box>
            <Box sx={{ marginTop: "15px" }} className="seat-unavailable">
              <p className="px-15 pt-0">
                Unfortunately all seats on this trip have now been booked. We’ll
                keep you notified of other driver invites on this route and
                schedule.
              </p>
            </Box>
          </Box>
        </div>
        {/* <Box className="button-container">
          <Button
            className="next-btn custom-btn btn-text"
            fullWidth
            onClick={handleGoToWhatsapp}
          >
            Back To Whatsapp
          </Button>
        </Box> */}

        <CustomFixedButton text={"Back To Whatsapp"} handleSubmit={handleGoToWhatsapp} disable={false}/>
      </CustomDrawer>

      <CustomDrawer
        anchor="bottom"
        isOpen={drawer1}
        fullScreen={true}
        onClose={() => {
          setDrawer1(false);
          navigate("/");
        }}
      >
        <div
          className="h-100 d-flex items-center flex-column justify-center gap-2"
          style={{ background: "#EFFBF5" }}
        >
          <Box
            className="seat-unavailable-message-box p-05 "
            style={{ background: "#fff", paddingBottom: "1rem" }}
          >
            <Box className="seat-unavailable">
              <h3 className="pl-15 pr-15 pt-0">
                {" "}
                🙁 Currently no open invites for this route
              </h3>
            </Box>
            <Box sx={{ marginTop: "15px" }} className="seat-unavailable">
              <p className="px-15 pt-0">
                We'll notify you once drivers share invites for carpool seats on
                this route.
              </p>
            </Box>
          </Box>
        </div>
        {/* <Box className="button-container">
          <Button
            className="next-btn custom-btn btn-text"
            fullWidth
            onClick={handleGoToWhatsapp}
          >
            Back To Whatsapp
          </Button>
        </Box> */}

        <CustomFixedButton text={'Back To Whatsapp'} disable={false}
          handleSubmit={handleGoToWhatsapp}
        />
      </CustomDrawer>

      <UserProfile 
        isOpen={userDetail.showUserDetail}
        onClose={() => setUserDetail(prev => ({...prev, showUserDetail: false}))}
        userDetail={{...userDetail.userDetail, locationName: userDetail.locationName, organisationLogo: userDetail.organisationLogo}}
      />
    </div>
  );
};

export default BookSeat;
