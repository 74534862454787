import './style.scss'
import { navigateToPrivacyPolicyPage, navgiateToTermsAndConditionPage } from '../../../../utilities/helper';
interface IProps {
  margin: string
}
const SubText = ({ margin }: IProps) => {



  return (
    <div className={`signup-footer-text ${margin}`}>
      <p className="text-sm text-sm-center">
        By signing up for EcoPooling, you agree to the{" "}
        <span onClick={navgiateToTermsAndConditionPage} className="footer-text-link-color cursor-pointer">
          Terms of Service.
        </span>{" "}
        View our{" "}
        <span onClick={navigateToPrivacyPolicyPage} className="footer-text-link-color cursor-pointer">
          Privacy Policy.
        </span>
      </p>
    </div>
  );
};

SubText.defaultProps = {
  margin: "mt-auto"
}

export default SubText;
