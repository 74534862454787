import EcopoolingLogo from '../../assets/images/logo-wth-name.svg'
import ReloadIcon from '../../assets/images/reloadIcon.svg'
import React from 'react'
import CustomFixedButton from '../../components/custom-fixed-button'

const Maintenance = () => {

  return (
    <div className='p-15 d-flex justify-center min-h-100 border-box'>
        <div className='d-flex flex-column items-center' style={{marginTop: "20vh", marginBottom: '7rem'}}>
            <img src={EcopoolingLogo} alt="ecopooling-logo" className='mb-4' />

            <h2 className='text-center mt-0 mb-4'>
                We'll be back soon.
            </h2>

            <div className='p-1 bordered-light shadow-extra-light rounded-12'>
                <p className='text-md'>
                    We’re performing scheduled maintenance. Please check back shortly. Thank you for your patience.
                </p>
            </div>
        </div>

        <CustomFixedButton text="Try again" disable={false} icon={ReloadIcon} handleSubmit={() => window.location.replace("/")} />
    </div>
  )
}

export default Maintenance