import React, { useEffect, useState } from 'react'
import ExclamationMarkIcon from '../../assets/images/exclamation-mark.svg'
import ExclamationMarkSmallIcon from '../../assets/images/exclamation-mark-24X24.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingScreen from '../../components/loading-screen'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const GiftCardFailure = () => {
  const [loading, setLoading] = useState(true)

  const location = useLocation()
  const navigate = useNavigate()
  const { userDetailsReducer } = useSelector((state: RootState) => state);

  // if user is visiting this page directly, then redirect to home page
  useEffect(() => {
    if(location.state && location.state?.fromCardDetail && location.state?.fromCardDetail === true) {
      setLoading(false)
    } else {
      userDetailsReducer.basicDetails.userPreference === 'MARKET_PLACE' ?
        navigate("/dashboard/market-place")
        :navigate("/")
    }

    // eslint-disable-next-line
  }, [])


  return (
    <>
    {
      loading ? (
        <LoadingScreen />
      ) : (
        <div className='d-flex flex-column items-center p-2' style={{minHeight: "100vh"}}>
          <img src={ExclamationMarkIcon} alt="exclamation mark" className='mt-5 mb-3' />

          <h1 className='text-center text-xl mb-4'>
            Oops something went wrong
          </h1>

          <div className='d-flex items-center gap-1 p-1 bordered-light rounded-12 width-100 border-box shadow-extra-light mb-5 text-md'>
            <img src={ExclamationMarkSmallIcon} alt="exclamation mark" />

            <p className='color-error'>Unable to process gift card</p>
          </div>

          <div className='p-1 bordered-light rounded-12 width-100 border-box shadow-extra-light mb-6 text-md'>
            We’ve encountered a problem processing your gift card. Please contact support via the link below and share the transaction ID.
          </div>

          <p className='text-light-gray mb-5 text-md'>
            Transaction ID: {location?.state?.transactionUniqueId || "null"}
          </p>

          <div className='color-blue cursor-pointer flex-1 d-flex flex-column justify-center mb-4' onClick={() => window.open(process.env.REACT_APP_HELP_URL, "_blank")}>Contact support</div>
        </div>
      )
    }
    </>
  )
}

export default GiftCardFailure