import { Box } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../../components/header-1";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import schedule from "../../../assets/images/schedule.svg";
import { RootState } from "../../../store";
import "./style.scss";
import {
  FavLocInterface,
  updateFavLocationUtility,
} from "../../../slice/utility";
import UseSwitchesBasic from "../../../components/switch";
import { getName, getWeekdayName } from "../../../utilities/helper";
import moment, { Moment } from "moment";
import { updateWeeklySchedule } from "../../../services/schedule";
import LoadingScreen from "../../../components/loading-screen";
import CustomFixedButton from "../../../components/custom-fixed-button";

interface KeyActiveTime {
  label: string;
  key: string;
  active: boolean;
  pickUp: string;
  dropOff: string;
  pickUpActive?: boolean;
  momentObj?: Moment;
}


interface StateInteface {
  schedule: {
    meta: "pickUp" | "dropOff";
    isOpen: boolean;
  };
  locationName?: string;
  locationAddress?: string;
  locationId?: string | undefined;
  pickUpArray?: string[];
  dropOffArray?: string[];
  weeklySchedule?: any;
}


const PickupUpdate = () => {
  const { id } = useParams();

  const {
    utilityReducer,
    categoryReducer: { category, userFavLocations },
    userDetailsReducer: { basicDetails },
  } = useSelector((state: RootState) => state);

  const [currFavLocation, setCurrenFavLocation] = useState<FavLocInterface>();
  const [selectedFavLocation, setSelectedFavLocation] = useState<any>([]);
  const [currDaySchedule, setCurrDaySchedule] = useState<Array<KeyActiveTime>>(
    getKeyActiveTime()
  );
    const [scheduleData, setScheduleData] = useState<KeyActiveTime[]>([])
    const [datesExpanded, setDatesExpanded] = useState(false)

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [state, setState] = useState<StateInteface>({
    schedule: {
      isOpen: false,
      meta: "pickUp",
    },
    locationName: "",
    locationAddress: "",
    locationId: "",
    weeklySchedule: {} as any,
  });

  const createRequestData = (locationId: string) => {
    // extract dates from currentDaySchedule state
    const payload = {
      weeklySchedule: {
        pickUpDates: [] as string[],
        dropOffDates: [] as string[],
        pickUpFlexibility: "0 min",
        dropOffFlexibility: "0 min",
      },
      _favoriteLocation: locationId,
    };

    if (state.weeklySchedule.pickUpDates.length !== 0) {
      payload.weeklySchedule.pickUpDates = [
        ...state.weeklySchedule.pickUpDates,
      ];
    }

    if (state.weeklySchedule.dropOffDates.length !== 0) {
      payload.weeklySchedule.dropOffDates = [
        ...state.weeklySchedule.dropOffDates,
      ];
    }

    return payload;
  };

  const setWeeklySchedule = (type: string) => {
    if (type === "pickUp") {
      state.weeklySchedule?.pickUpDates?.forEach(
        (date: string, key: number) => {
          const day = getWeekdayNameE(date).trim();
          currDaySchedule.map((currDay) => {
            if (currDay.label === day) {
              currDay["active"] = true;
            }
            return currDay;
          });
        }
      );
    } else {
      state.weeklySchedule?.dropOffDates?.forEach((date: string) => {
        const day = getWeekdayNameE(date).trim();
        currDaySchedule.map((currDay) => {
          if (currDay.label === day) {
            currDay["active"] = true;
          }
          return currDay;
        });
      });
    }
  };

  const handleNext = () => {
    const data = createRequestData(state.locationId as string);

    setLoading(true);

    updateWeeklySchedule(data)
      .then(() => {
        setLoading(false);

        enqueueSnackbar("Updated !", { variant: "success" });
      })
      .catch((err) => {
        console.log(err)

        setLoading(false)

        if (basicDetails.userPreference === "OFFER") {
          enqueueSnackbar("Only passengers can update their invite alerts", { variant: "error" });
          return
        }

        enqueueSnackbar("Unable to update invite alerts. Please try again later", { variant: "error" });
      });
  };

  const handleSave = () => {
    let pickUpDatesArray: string[] = state.weeklySchedule.pickUpDates;
    let dropOffDatesArray: string[] = state.weeklySchedule.droOffDates;

    if (state.schedule.meta === "pickUp") {
      pickUpDatesArray = currDaySchedule
        .filter((currDay) => currDay.pickUpActive)
        .map((days) => days.pickUp);

      setState((prev: any) => {
        return {
          ...prev,
          weeklySchedule: {
            ...prev.weeklySchedule,
            pickUpDates: pickUpDatesArray,
          },
        };
      });

      // set the currDaySchedule state according to active days
      setCurrDaySchedule((prev: KeyActiveTime[]) => {
        const activeSchedule = prev.map((currentDayState: KeyActiveTime) => {
          if (currentDayState.pickUpActive) {
            return currentDayState
          }

          currentDayState.pickUp = ""
          return currentDayState
        })

        return activeSchedule
      })

      // setTabSelected('Next week')
      // setCurrDaySchedule(getKeyActiveTime());
    } else {
      dropOffDatesArray = currDaySchedule
        .filter((currDay) => currDay.active)
        .map((days) => days.dropOff);

      setState((prev: any) => {
        return {
          ...prev,
          weeklySchedule: {
            ...prev.weeklySchedule,
            dropOffDates: dropOffDatesArray,
          },
        };
      });

      // set the currDaySchedule state according to active days
      setCurrDaySchedule((prev: KeyActiveTime[]) => {
        const activeSchedule = prev.map((currentDayState: KeyActiveTime) => {
          if (currentDayState.active) {
            return currentDayState
          }

          currentDayState.dropOff = ""
          return currentDayState
        })

        return activeSchedule
      })
    }
    setState((prev) => {
      return {
        ...prev,
        schedule: { ...prev.schedule, isOpen: false },
      };
    });
  };

  // onclick handler for toggle switch
  const handleToggle = (e: any, currentItem: KeyActiveTime) => {
    if (e.target.checked) {
      const newList = currDaySchedule.map((schedule) => {   
        if (schedule.momentObj === currentItem.momentObj) {
          
          // set the time
          if (state.schedule.meta === 'dropOff') {
            schedule.active = true;
            schedule.dropOff = schedule.momentObj.toISOString();
          } else {
            schedule.pickUpActive = true;
            schedule.pickUp = schedule.momentObj.toISOString();
          }
        }

        return schedule;
      });

      setCurrDaySchedule(newList);
    } else {
      const newList = currDaySchedule.map((schedule) => {
        if (schedule.momentObj === currentItem.momentObj) {
          
          if (state.schedule.meta === 'dropOff') {
            schedule.active = false;
            schedule.dropOff = ""
          } else {
            schedule.pickUpActive = false;
            schedule.pickUp = ""
          }
        }

        return schedule;
      });

      setCurrDaySchedule(newList);
    }
  };

  // onClick handler for opening either schedule
  const openSchedule = (type: "pickUp" | "dropOff") => {
    setState((prev) => {
      return {
        ...prev,
        schedule: { meta: type, isOpen: true },
      };
    });

    setWeeklySchedule(type);

    const newList = currDaySchedule.map((schedule) => {
      if(schedule[type] !== "") {

        if(type === "dropOff") {
          schedule.active = true
        } else {
          schedule.pickUpActive = true
        }
      } else {
        if(type === "dropOff") {
          schedule.active = false
        } else {
          schedule.pickUpActive = false
        }
      }

      return schedule
    })

    setCurrDaySchedule(newList)




    // // check the dates selected for the selected schedule type(pickup or dropoff)
    // // select the week tab according to the dates in the state
    // if (moment().endOf('week') > moment(state.weeklySchedule[type === "dropOff" ? "dropOffDates" : "pickUpDates"][0])) {
    //   setTabSelected('This week')

    //   let selectedDays = [] as number[]
    //   for (let dropOffDate of state.weeklySchedule.dropOffDates) {
    //     selectedDays.push(moment(dropOffDate).get('day'))
    //   }

    //   // set the dates in currDaySchedule according to the "state" variable
    //   let currentDayScheduleClone = JSON.parse(JSON.stringify(currDaySchedule))

    //   console.log('currenday', currentDayScheduleClone)

    //   state.weeklySchedule.dropOffDates.forEach((date: string) => {
    //     currentDayScheduleClone[moment(date).day() - 1].dropOff = date
    //   })

    //   state.weeklySchedule.pickUpDates.forEach((date: string) => {
    //     currentDayScheduleClone[moment(date).day() - 1].pickUp = date
    //   })

    //   // set active to true if currDaySchedule is ahead of 2 days of the current date
    //   currentDayScheduleClone.forEach((date: any) => {
    //     if (moment(date[type === "dropOff" ? "dropOff" : "pickUp"]) > moment().add(1, 'day')) {
    //       date.active = true
    //     } else {
    //       date.active = false
    //     }
    //   })

    //   setCurrDaySchedule(currentDayScheduleClone)
    // } else {
    //   setTabSelected('Next week')

    //   // set the dates in currDaySchedule according to the "state" variable
    //   let currentDayScheduleClone = JSON.parse(JSON.stringify(currDaySchedule))
    //   state.weeklySchedule.dropOffDates.forEach((date: string) => {
    //     currentDayScheduleClone[moment(date).day() - 1].dropOff = date
    //   })

    //   state.weeklySchedule.pickUpDates.forEach((date: string) => {
    //     currentDayScheduleClone[moment(date).day() - 1].pickUp = date
    //   })

    //   currentDayScheduleClone.forEach((date: any) => {
    //     if (date[type === "dropOff" ? "dropOff" : "pickUp"].trim().length > 0) {
    //       date.active = true
    //     } else {
    //       date.active = false
    //     }
    //   })

    //   setCurrDaySchedule(currentDayScheduleClone)
    // }
  };

  const handleSchedule = (
    favLocation: FavLocInterface,
    key: "pickOff" | "dropOff"
  ) => {
    setCurrenFavLocation(favLocation);
    setState((prev) => {
      return {
        ...prev,
        schedule: { meta: "pickUp", isOpen: false },
      };
    });

    let selectedDates = [] as string[]

    for (let dropOffDate of state.weeklySchedule.dropOffDates) {
      selectedDates.push(dropOffDate)
    }

    let newCurrDaySchedule = currDaySchedule.map(schedule => {
      if(selectedDates.includes(schedule.dropOff)) {
        schedule.active = true
      } else {
        schedule.active = false
        schedule.dropOff = ""
      }

      return schedule
    })

    let selectedPickUpDates = [] as string[]
    for (let pickUpDate of state.weeklySchedule.pickUpDates) {
      selectedPickUpDates.push(pickUpDate)
    }

    newCurrDaySchedule = currDaySchedule.map(schedule => {
      if(selectedPickUpDates.includes(schedule.pickUp)) {
        schedule.pickUpActive = true
      } else {
        schedule.pickUpActive = false
        schedule.pickUp = ""
      }

      return schedule
    })

    // update the local state
    // setCurrDaySchedule(currentDayScheduleClone)
    setCurrDaySchedule(newCurrDaySchedule)
  };

  const getWeekdayNameE = (dateString: string) => {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
    ];
    const date = moment(dateString);
    const dayIndex = date.day();
    return weekdays[dayIndex] + " ";
  };

  const getDatesByCategoryId = (type: string) => {
    let dates: any[] = [];

    if (type === "pickUp") {
      dates = state.weeklySchedule?.pickUpDates ?? [];
    } else {
      dates = state.weeklySchedule?.dropOffDates ?? [];
    }
    return dates.map((date: any) => getWeekdayNameE(date));
  };

  const getCategoryNameById = (id: string) => {
    const categoryName = category.find(
      (currCategory) => currCategory._id === id
    );
    if (categoryName !== undefined) return categoryName.locationName[0].value;
    return "";
  };

  const getScheduleByLocationId = (id: string) => {
    const location = userFavLocations.find(
      (userFavLock) => userFavLock._favoriteLocation === id
    );
    if (location !== undefined) return location.dailySchedule;
    return { schedule: [], dropOffFlexibility: "", pickUpFlexibility: "" };
  };

  const getLocationNameAndAddress = (categoryId?: string) => {
    const locationId = utilityReducer.favLocation.find(
      (item) => item.categoryId === categoryId
    )?.locationId;
    const selectedCategory = category.find((item) => item._id === categoryId);

    if (!selectedCategory) {
      return {
        locationName: "",
        locationAddress: "",
        locationId: "",
      };
    }

    const location = selectedCategory.locations.find(
      (item) => item._id === locationId
    );
    if (!location) {
      return {
        locationName: "",
        locationAddress: "",
        locationId: "",
      };
    }

    const result = {
      locationName: getName(location.locationName, "EN"),
      locationAddress: getName(location.address.line1, "EN"),
      locationId: locationId,
    };

    return result;
  };

  // set current day schedule local state
  useEffect(() => {
    let location = userFavLocations.filter(
      (item: any) => String(item._categoryId) === id
    );

    
    if (location.length !== 0) {
      const [{ weeklySchedule }] = location;
      
      if (weeklySchedule !== null) {
        setState((prev) => {
          return {
            ...prev,
            weeklySchedule: weeklySchedule,
          };
        });

        // // set the dates in currDaySchedule state according to fetched data
        // // make a clone of the local state so as not to mutate it directly
        // let currentDayScheduleClone = JSON.parse(JSON.stringify(currDaySchedule))

        // weeklySchedule.dropOffDates.forEach((date: string) => {
        //   currentDayScheduleClone[moment(date).day() - 1].dropOff = date
        // })

        // weeklySchedule.pickUpDates.forEach((date: string) => {
        //   currentDayScheduleClone[moment(date).day() - 1].pickUp = date
        // })

        // update the local state
        // setCurrDaySchedule(currentDayScheduleClone)
        createScheduleData(weeklySchedule)
      }
    } else {
      navigate(-1);
    }

    if (id) {
      let res = getLocationNameAndAddress(id);

      setState((prev) => {
        return {
          ...prev,
          locationName: res.locationName,
          locationAddress: res.locationAddress,
          locationId: res.locationId,
        };
      });
    }

    setSelectedFavLocation(location);

    const favLocationsWithDailySchedule = utilityReducer.favLocation.map(
      (favLoc) => {
        const dailySchedule = getScheduleByLocationId(favLoc.locationId);

        return {
          ...favLoc,
          dailySchedule: { ...dailySchedule },
        };
      }
    );

    dispatch(
      updateFavLocationUtility(
        favLocationsWithDailySchedule as FavLocInterface[]
      )
    );

    //eslint-disable-next-line
  }, [id]);

  const createScheduleData = (weeklySchedule: any) => {
  //   // store the day of week in an array for later comparison for activating week-days
  //   let prevDropOffDaysSet  = new Set()
  //   let prevPickUpDaysSet  = new Set()

  //   if(weeklySchedule.dropOffDates) {
  //     weeklySchedule.dropOffDates.forEach((date: string) => {
  //       prevDropOffDaysSet.add(moment(date).day())
  //     })
  //   }

  //   if(weeklySchedule.pickUpDates) {
  //      weeklySchedule.pickUpDates.forEach((date: string) => {
  //       prevPickUpDaysSet.add(moment(date).day())
  //   })
  // }

  // let prevDropOffDays = Array.from(prevDropOffDaysSet)
  // let prevPickUpDays = Array.from(prevPickUpDaysSet)

    let scheduleDataArray: KeyActiveTime[] = []
    let counter = 0;

    // create ten rows of data, one for each weekday
    for(let i = 0; counter < 10; i++) {
      let momentObj = moment().add(2, "day").startOf("day").add(i, 'day')

      if(momentObj.day() === 6 || momentObj.day() === 0) {
        continue
      }

      let dropOffDate = ""
      let pickUpDate = ""
      let activeFlag = false
      let pickUpActiveFlag = false

      if((weeklySchedule.dropOffDates.find(date => date === momentObj.toISOString()))) {
        dropOffDate = momentObj.toISOString()

        // prevDropOffDays.splice(prevDropOffDays.indexOf(momentObj.day()), 1)
        
        activeFlag = true
      }
      
      if((weeklySchedule.pickUpDates.find(date => date === momentObj.toISOString()))) {
        pickUpDate = momentObj.toISOString()
        
        // prevPickUpDays.splice(prevPickUpDays.indexOf(momentObj.day()), 1)

        pickUpActiveFlag = true
      }

      scheduleDataArray[counter] = {
        pickUp: pickUpDate,
        dropOff: dropOffDate,
        active: activeFlag,
        pickUpActive: pickUpActiveFlag,
        label: getWeekdayName(momentObj.day()),
        key: getWeekdayName(momentObj.day()).toUpperCase(),
        momentObj
      }

      counter++
    }

    setScheduleData(scheduleDataArray)
    setCurrDaySchedule(scheduleDataArray.slice(0,5))

    // update "state" data for later comparison
    let dropOffDatesArray = scheduleDataArray
        .filter((currDay) => ((currDay.dropOff !== "") && currDay.active))
        .map((days) => days.dropOff);

    let pickUpDatesArray = scheduleDataArray
        .filter((currDay) => ((currDay.pickUp !== "") && currDay.pickUpActive))
        .map((days) => days.pickUp);

      setState((prev: any) => {
        return {
          ...prev,
          weeklySchedule: {
            ...prev.weeklySchedule,
            pickUpDates: pickUpDatesArray,
            dropOffDates: dropOffDatesArray,
          },
        };
      });
  }

  return (
    <div id="invite-alerts-pickup-drop">
      <Header rightIcon={basicDetails.photo} />

      {selectedFavLocation.length > 0 ? (
        <React.Fragment>
          <Box className="padding-x rounded-top-green">
            <div className="sub-header">
              <div className='sub-header-icon-container'>
                <img alt="person" className="signature" src={schedule} />
              </div>

              <p className="sub-text-new sub-text">
                Set which days you want to receive carpool invites for in the
                upcoming week.
              </p>
            </div>
          </Box>

          <Box className="fav-location-detail-container mb-2">
            <p className="section-head section-head-bg-light-green">
              {getCategoryNameById(selectedFavLocation[0]?._categoryId)} details
            </p>

            <Box className="fav-location-details pl-1">
              <p className="fav-location-name-heading text-new pb-05 pt-1">
                {getCategoryNameById(selectedFavLocation[0]?._categoryId)} name
              </p>

              <p className="fav-location-name text-sm">{state.locationName}</p>

              <p className="fav-location-address-heading text-new pb-05 pt-1">
                Address
              </p>

              <p className="fav-location-address text-sm pb-05">
                {state.locationAddress}
              </p>
            </Box>
          </Box>

          <p className="section-head section-head-bg-light-green">{getCategoryNameById(selectedFavLocation[0]?._categoryId)} route invite alerts for each week</p>

          {selectedFavLocation.map((val: any, key: number) => (
            <Box className="margin-x" key={key}>
              <Box className="margin-y" key={key}>
                <Box
                  className="location-box d-flex items-center justify-between cursor-pointer"
                  onClick={() => {
                    openSchedule(/*val,*/ "dropOff");
                  }}
                >
                  <div>
                    <p className="custom-label">
                      {getCategoryNameById(val._categoryId)} Drop-off
                    </p>

                    <p className="custom-label blue-text">
                      {getDatesByCategoryId("dropOff")
                        ?.map((day: string) => day.slice(0, 3))
                        .join(", ")}
                    </p>
                  </div>

                  <p className="custom-label link d-flex items-center justify-center edit-new">
                    Edit
                  </p>

                </Box>
              </Box>

              <Box className="margin-y">
                <Box
                  className="location-box d-flex items-center justify-between cursor-pointer"
                  onClick={() => openSchedule(/*val,*/ "pickUp")}
                >
                  <div>
                    <p className="custom-label">
                      {getCategoryNameById(val._categoryId)} Pick-up
                    </p>

                    <p className="custom-label blue-text">
                      {getDatesByCategoryId("pickUp")
                        ?.map((day: string) => day.slice(0, 3))
                        .join(", ")}
                    </p>
                  </div>

                  <p className="custom-label link d-flex items-center justify-center edit-new">
                    Edit
                  </p>

                </Box>
              </Box>
            </Box>
          ))}

          {/* <Box className="d-flex justify-center items-end flex-1 px-1">
            <Button
              className="next-btn custom-btn btn-width btn-text btn-margin-responsive"
              fullWidth
              disabled={loading}
              onClick={handleNext}
            >
              {loading ? "UPDATING..." : "UPDATE"}
            </Button>
          </Box> */}
          <CustomFixedButton text={loading ? "UPDATING..." : "UPDATE"} disable={loading} handleSubmit={handleNext} />

          <CustomDrawer
            anchor="bottom"
            isOpen={state.schedule.isOpen}
            overflowDesktop={false}
            fullScreen={true}
            onClose={() =>
              handleSchedule(currFavLocation as FavLocInterface, "pickOff")
            }
          >
            <Box id="pickup-drop--drawer" className="overflow-auto" 
              style={{ maxHeight: "calc(100vh - 150px)" }}
            >
              <p className="drawer-title text-center schedule-title mb-1">
                {currFavLocation?.categoryName[0].value}{" "}
                Set{" "}
                {state.schedule.meta === "dropOff" ? "Drop-off" : "Pick-up"}{" "}
                alerts
              </p>

              <Box className="options-pickup-box">
                {currDaySchedule.map((item: KeyActiveTime, key: number) => (
                  <Box className="between margin-y" key={key}>
                    <Box className="d-flex" alignItems={"center"}>
                      <UseSwitchesBasic
                        handleChange={(e: any) => handleToggle(e, item)}
                        checked={
                          state.schedule.meta === "dropOff" ? item.active : item.pickUpActive
                        }
                      />{" "}
                      <div>
                        <p>{item.label}</p>
                        <p className="text-sm">
                          {
                            item.momentObj.format('Do MMM')
                          }
                        </p>
                      </div>
                    </Box>
                  </Box>
                ))}

                {/* {
                  !datesExpanded && (
                    <Button fullWidth onClick={() => {
                      setCurrDaySchedule([...currDaySchedule, ...scheduleData.slice(5)]);

                      setDatesExpanded(true);
                      }}
                      style={{borderWidth: "2px", padding: "1rem"}}
                      variant="outlined"
                      className="text-700 mt-4 btn-text"
                      >
                      Later
                    </Button>
                  )
                }
                
                <div
                  style={{bottom: '0px', position: `${!datesExpanded ? 'static' : 'sticky'}`, paddingBottom: '40px', background: "#fff"}}
                >
                  <Button
                    className="save-btn custom-btn btn-text mb-0"
                    fullWidth
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                </div> */}

                <CustomFixedButton text={'Save'} disable={false} handleSubmit={handleSave} 
                  upperButtonDisable={false} upperButtonHandler={() => {
                    setCurrDaySchedule([...currDaySchedule, ...scheduleData.slice(5)]);
                    setDatesExpanded(true);
                    }} 
                    showUpperButton={!datesExpanded}
                  isDrawerButton={true}
                />

                {/* <Button
                  className="save-btn custom-btn calc-top btn-text"
                  fullWidth
                  onClick={() => handleSave()}
                  style={{bottom: '2rem', position: `${!datesExpanded ? 'static' : 'sticky'}`, marginBottom: '40px'}}
                >
                  Save
                </Button> */}
              </Box>
            </Box>
          </CustomDrawer>
        </React.Fragment>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};

export default PickupUpdate;


function getKeyActiveTime() {
  let res: Array<KeyActiveTime> = [
    {
      key: "MONDAY",
      label: "Monday",
      active: false,
      pickUpActive: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "TUESDAY",
      label: "Tuesday",
      active: false,
      pickUpActive: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "WEDNESDAY",
      label: "Wednesday",
      active: false,
      pickUpActive: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "THURSDAY",
      label: "Thursday",
      active: false,
      pickUpActive: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "FRIDAY",
      label: "Friday",
      active: false,
      pickUpActive: false,
      pickUp: "",
      dropOff: "",
    },
  ];
  return res;
}
