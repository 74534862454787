import './style.scss';
import { Box, TextField } from '@mui/material';
import Header from '../../../components/header-1';
import BellIcon from '../../../assets/images/bell-icon.svg';
import LocationPin from '../../../assets/images/location-pin.svg';
//import ArrowDown from '../../../assets/images/ArrowDown.svg';
import CustomDrawer from '../../../components/mui/SwipeableDrawer';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory, getFavLocationOfUser } from '../../../services/location';
import { FavLocationInterface, updateCatList, updateUserFavLocations } from '../../../slice/category';
import { RootState } from '../../../store';
import { updateFavLocationUtility } from '../../../slice/utility';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import myDestination from '../../../assets/images/my-destination.svg';


const ScheduleDetail = () => {


    const dispatch = useDispatch();
    const { categoryReducer: { category,userFavLocations }, utilityReducer, userDetailsReducer: { basicDetails } } = useSelector((state: RootState) => state);
    //eslint-disable-next-line
    const [currCategory, setCurrentCategory] = useState<any>(null);
    const [currCategoryCopy, setCurrentCategoryCopy] = useState<any>(null)
    const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const {pathname} = useLocation()
 
  const addFavLocation = (value: any, remove: boolean) => {
    const { categoryName, categoryId, locationName, locationId } = value;
    let found = false;
    let newList = utilityReducer.favLocation.map((fav: any) => {
      if (fav.categoryId === categoryId) {
        found = true;


        if (remove === false) {
          return { categoryName, categoryId, locationName, locationId };
        } else {
          return undefined
        }
        // return { categoryName, categoryId, locationName, locationId };
      } else {
        return fav;
      }
    })


    let newListClean = newList.filter(val => val !== undefined)


    if (!found) {
      newListClean = [...newListClean, { categoryName, categoryId, locationName, locationId }]
    }
    dispatch(updateFavLocationUtility(newListClean));
  }




  const getData = async () => {
    try {
      const res1 = await getCategory();
      const res2 = await getFavLocationOfUser();
      console.log({ res1, res2 });
      const utilityFavLocList = res2.map((favLocation: any) => {
        const { _categoryId, _favoriteLocation } = favLocation;
        return { categoryId: _categoryId, locationId: _favoriteLocation, locationName: [{ lang: "EN", value: "" }], categoryName: [{ lang: "EN", value: "" }] }
      })
      dispatch(updateFavLocationUtility(utilityFavLocList))
      dispatch(updateCatList(res1));
      dispatch(updateUserFavLocations(res2));
    } catch (error: any) {
      console.log(error)
    }
  }
  const isActiveLocation = (locationId: string) => {
    return utilityReducer.favLocation.some((val) => val.locationId === locationId);
  }

    const handleClickNext = (id:string) => {
      let location = userFavLocations.filter(
        (item: any) => String(item._categoryId) === id
      );
      if(location.length === 0){
        enqueueSnackbar('No location found',{variant:'error'})
      }
      else if(pathname !== "/dashboard/my-destination" ) {
        navigate(`/dashboard/pick-detail/${id}`);
      }   
    }

  const getLocationName = (cat: FavLocationInterface) => {
    const favLock = utilityReducer.favLocation.find((favLock) => favLock.categoryId === cat._id);
    if (favLock !== undefined) {
      const res = cat.locations.find((loc) => loc._id === favLock.locationId);
      if (res !== undefined) {
        return res?.locationName[0].value
      };
    }
    return "";
  }


  const closeDrawer = () => {
    setOpen(false);
  }


  const handleSearch = (e: any) => {
    setSearch(e.target.value)
    let searchItem = e.target.value;
    let regex = new RegExp(searchItem, 'i');
    let newCategory = currCategory?.locations?.filter((val: any) =>
      regex.test(val.locationName[0].value)
    );
    setCurrentCategoryCopy((prev: any) => {
      return {
        ...prev,
        locations: newCategory
      }
    })
  }


  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, [])

  const getLogo = () => {
     return pathname === "/dashboard/my-destination" ? myDestination : BellIcon
  }

  const getHeaderText = () => {
    return pathname === "/dashboard/my-destination" ? "Below is a list of all the trip destination which you have been on-boarded to.": 
    "My invite alerts"
  }

  const getTextColor = () => {
    return pathname === "/dashboard/my-destination"  ? "red-text" : "blue-text";
  }

  const getCursor = () => {
    return pathname === "/dashboard/my-destination"  ? "none" : "cursor-pointer";
  }


  return (
    <div id="schedule-detail">
      <Header rightIcon={basicDetails.photo} />
      <Box className="padding-x rounded-top-green">
        <div className="sub-header mb-4">
          <div className="sub-header-icon-container">
            <img alt="signature" src={getLogo()} />
          </div>
          <p className="heading sub-text-new">
           {getHeaderText()}
          </p>
        </div>
      </Box>

      <Box
        className="section-head section-head-bg-light-green center"
        justifyContent="space-between"
      >
        <p>Trip destinations</p>
        <p className="link"> </p>
      </Box>

      <Box className="margin-x pb-1px locationsContainer">
        {category.map((cat, index) => (
          <Fragment key={index}>
            <Box
              className={`margin-y ${getCursor()}`}
              onClick={() => {
                handleClickNext(cat._id);
              }}
            >
                {getLocationName(cat) && (
              <Box className="location-box">
                <div className="inline d-flex">
                  <div>
                  
                      <>
                        <p
                          className="custom-label"
                          style={{ marginBottom: "0" }}
                        >
                          {cat.locationName[0].value}
                        </p>
                        <span className={`custom-label ${getTextColor()} text-sm`}>
                          {getLocationName(cat)}
                        </span>
                      </>
                  </div>
                </div>
              </Box>
                 )}
            </Box>
          </Fragment>
        ))}
        <Box className="d-flex justify-center items-center">
          {/* <Button className='next-btn custom-btn mb-4 btn-width' fullWidth onClick={handleClickNext}>Next</Button> */}
        </Box>
      </Box>

      <CustomDrawer anchor="bottom" isOpen={open} onClose={closeDrawer}>
        <Box id="favorite-location-drawer" className="margin-x">
          <div className="search-box">
            <TextField
              className="custom-input"
              id="first_name"
              placeholder={`Find ${currCategoryCopy?.locationName[0].value}`}
              value={search}
              fullWidth={false}
              onChange={handleSearch}
              InputProps={{
                style: {
                  height: "48px",
                  maxWidth: "293px",
                },
              }}
            />
            <p
              className="sub-text-16 cancel cursor-pointer"
              onClick={closeDrawer}
            >
              Cancel
            </p>
          </div>

          {currCategoryCopy && (
            <div className="selected">
              <p className="sub-text-16 fw-700">Selected:</p>
              <p className="sub-text-16 sub-text">
                Select a {currCategoryCopy?.locationName[0].value}
              </p>
            </div>
          )}
          {currCategoryCopy?.locations?.map((loc: any, i: number) => (
            <div key={i}>
              <div
                className="d-flex item-center margin-y-4 cursor-pointer"
                onClick={() => {
                  let remove = false;
                  if (isActiveLocation(loc?._id)) {
                    remove = true;
                  }
                  addFavLocation(
                    {
                      categoryName: currCategoryCopy.locationName,
                      categoryId: loc.categoryId,
                      locationName: loc.locationName,
                      locationId: loc._id,
                    },
                    remove
                  );
                  closeDrawer();
                }}
              >
                <img alt="location-pin" src={LocationPin} />
                <p
                  className={
                    isActiveLocation(loc?._id)
                      ? "sub-text location-name active-location"
                      : "sub-text location-name"
                  }
                >
                  {loc?.locationName[0]?.value}
                </p>
              </div>
            </div>
          ))}
        </Box>
      </CustomDrawer>
    </div>
  );
}


export default ScheduleDetail;
