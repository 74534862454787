import React, { ReactNode } from 'react'



interface infoProps {
    children : ReactNode;
    border : "blue" | "green";
}const InfoBox = ({border,children}:infoProps) => {
  const infoBoxClass = () => {
      return border === "blue" ? "d-flex flex-column blue-bordered rounded-12" : "d-flex flex-column fw-700 green-bordered rounded-12"
  }
  return(
      <div className={infoBoxClass()}>
       {children}
      </div>
  )
}

export default InfoBox;
