import { httpRequest } from "./../../network";



export const updateVehicle = async (data: any)=>{
  try {
    const res: any = await httpRequest("PUT","/auth/vehicle/user",data);
    return res.data;
  } catch (error) {
    throw error
  }
}