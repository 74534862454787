import React from 'react'
import CustomDrawer from '../mui/SwipeableDrawer'
import DefaultProfile from "../../assets/images/default-profile.svg";
import verifiedPersonIcon from "../../assets/images/verified-person.svg";
import identityVerifiedIcon from "../../assets/images/user-verified.svg";
import identityNotVerifiedIcon from "../../assets/images/user-not-verified.svg";
import stearingWheelIcon from "../../assets/images/stearing_wheel_blue.svg";
import stearingWheelGrayIcon from "../../assets/images/stearing_wheel_gray.svg";
import checkedIcon from "../../assets/images/checked.svg";
import "./style.scss"
import moment from 'moment';


const UserProfile = ({ isOpen, onClose, userDetail }) => {
  return (
    <CustomDrawer
        anchor="bottom"
        isOpen={isOpen}
        onClose={onClose}
    >
        <div className='d-flex flex-column items-center p-1'>
            <div className="profile-pic d-flex items-center justify-center">
                <img
                alt="profile"
                src={userDetail.photo || DefaultProfile}
                height="100%"
                width="100%"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DefaultProfile;
                }}
                className="rounded-full bordered-2 shadow-light object-fit-cover min-height-100 min-w-100"
                />
            </div>

            <h4 className='my-1 mt-3 d-flex items-center'>
                {
                    userDetail?.firstName[0]?.value + " " + userDetail?.lastName[0]?.value
                }

                {
                    userDetail.loginType === "FACEBOOK" && (
                        <img src={verifiedPersonIcon}
                            onClick={() =>
                                window.open(
                                `https://www.facebook.com/profile.php?id=${userDetail.socialId}`,
                                "_blank"
                                )
                            }
                            alt="facebookuser" 
                        />
                    )
                }
            </h4>

            <h5 className='my-1 text-gray'>
                {
                    userDetail.occupation || ""
                }
            </h5>

            <h5 className='my-1 mb-2 text-light-gray'>
                Member since {" "}
                {
                    moment(userDetail.createdAt).format("MMMM YYYY") || ""
                }
            </h5>

            {
                (userDetail.organisationLogo && userDetail.organisationLogo !== "") && (
                    <img className='my-2' style={{width: '3rem', height: '3rem'}} src={(userDetail.organisationLogo && userDetail.organisationLogo !== "") ? userDetail.organisationLogo : verifiedPersonIcon} alt="organisation-logo"/>
                )
            }

            <p className='my-0 mb-3 text-light-gray text-md'>
                {
                    userDetail.locationName
                }
            </p>

                {
                    userDetail.isVerified && (
                        <>
                            <div className={`rounded-6 ${userDetail.isVerified ? 'bg-light-blue' : 'bg-f5'} width-100 p-05 px-1 border-box d-flex items-center justify-between mb-3`}>
                                <span className='text-500 text-md' style={{color: "#616161"}}>
                                    Identity verified
                                </span>

                                <img src={userDetail.isVerified ? identityVerifiedIcon : identityNotVerifiedIcon} alt="identity-verification-icon"/>
                            </div>

                            <div className={`d-flex items-center width-100 ${userDetail.isVerified ? 'mb-4' : 'mb-5'} px-1 border-box`}>
                                <div className='w-50 d-flex flex-column'>
                                    <p className='text-md text-500 mb-2' style={{color: "#616161"}}>
                                        First name
                                    </p>

                                    {
                                            userDetail.isVerified && (
                                                <p className='text-md'>
                                                    {
                                                        userDetail?.firstName[0]?.value
                                                    }
                                                </p>
                                            )
                                    }
                                </div>

                                <div className='w-50'>
                                    <p className='text-md text-500 mb-2' style={{color: "#616161"}}>
                                        Last name
                                    </p>

                                    {
                                            userDetail.isVerified && (
                                                <p className='text-md'>
                                                    {
                                                        userDetail?.lastName[0]?.value
                                                    }
                                                </p>
                                            )
                                    }
                                </div>
                            </div>
                        </>
                    )
                }

            {
                (userDetail.userPreference === "OFFER" || userDetail.userPreference === "BOTH") && (
                    <>
                    {
                        userDetail.isDriverVerified && (
                            <div className={`rounded-6 ${userDetail.isDriverVerified ? 'bg-light-blue' : 'bg-f5'} width-100 p-05 px-1 border-box d-flex items-center justify-between mb-3`}>
                                <span className='text-500 text-md' style={{color: "#616161"}}>
                                    Driver verified
                                </span>

                                <img src={userDetail.isDriverVerified ? stearingWheelIcon : stearingWheelGrayIcon} alt="identity-verification-icon"/>
                            </div>
                        )
                    }

                    {
                        userDetail.isDriverVerified && (
                            <div className='d-flex items-center width-100 mb-3 px-1 border-box'>
                                <div className='d-flex flex-column gap-1 width-100'>
                                    <div className='d-flex gap-1 items-center justify-between'>
                                        <span className='text-md text-500' style={{color: "#616161"}}>
                                            Driving license
                                        </span>

                                        {
                                            userDetail.isDriverVerified && (
                                                <img src={checkedIcon} alt="driving license checked" />
                                            )
                                        }
                                    </div>

                                    <div className='d-flex gap-1 items-center justify-between'>
                                        <span className='text-md text-500' style={{color: "#616161"}}>
                                            MOT
                                        </span>

                                        {
                                            userDetail.isDriverVerified && (
                                                <img src={checkedIcon} alt="MOT checked" />
                                            )
                                        }
                                    </div>

                                    <div className='d-flex gap-1 items-center justify-between'>
                                        <span className='text-md text-500' style={{color: "#616161"}}>
                                            Tax
                                        </span>

                                        {
                                            userDetail.isDriverVerified && (
                                                <img src={checkedIcon} alt="tax checked" />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    </>
                )
            }
        </div>
    </CustomDrawer>
  )
}

UserProfile.defaultProps = {
    isOpen: false,
    onClose: () => {},
}

export default UserProfile