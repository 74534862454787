import './style.scss';
import { useEffect, useState } from "react";
import LoadingScreen from "../../../components/loading-screen";
import Header from "../../../components/header";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Box, Button } from '@mui/material';
import { emptyCircle, locationPin, greenTick, cross, groupIcon } from '../../../assets/images';
import FilledLeap from "../../../assets/images/filled-leap.svg";
import { showMapDuration } from '../../../utilities/helper';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import CustomDrawer from '../../../components/mui/SwipeableDrawer';
import { getFavLocationOfUser } from '../../../services/location';
import { updateGroup, getGroup } from '../../../services/group';
import UserProfile from '../../../components/user-profile';
import { enqueueSnackbar } from 'notistack';
import CustomFixedButton from '../../../components/custom-fixed-button';
import whatsAppBlackIcon from "../../../assets/images/whatsapp.svg";

interface IAddress {
  line1: { lang: string, value: string };
  line2: { lang: string, value: string };
  state: { lang: string, value: string };
  city: { lang: string, value: string };
  country: { lang: string, value: string };
  zip: string;
  geo: [number, number];
}

interface IMember {
  userName: string;
  locationName: string;
  driverPassengerTime: string,
  passengerDestinationTime: string,
  totalPrice: string,
  adminServiceFee: string,
  price: string,
  userPicture: string,
  domainImageUrl: string,
  _id: string,
  passengerDestination: string,
  address: IAddress,
  locationAddress: IAddress
}

const Group = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    tab: 'pending',
    groupUsers: [],
    locationId: "",
    pendingUsers: [],
    declinedUsers: [],
  });

  const userDetailsReducer = useSelector((state: RootState) => state.userDetailsReducer);

  useEffect(() => {
    getFavoriteLocationId();

    // eslint-disable-next-line
  }, []);


  const getGroupUsers = async (locationId: string) => {
    try {
      const groups = await getGroup(locationId);
      if (groups.length > 0) {
        setState(prev => {
          return {
            ...prev,
            groupUsers: [ ...groups[0]._members],
            declinedUsers: [ ...groups[0]._declined],
            pendingUsers: [...(groups[0]._pendings || [])],
            tab: groups[0]._pendings?.length > 0 ? 'pending' : 'group'
          }
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  // const getPendingUsers = async (locationId: string) => {
  //   try {
  //     const users = await simulateMatching(locationId) as any;
      
  //     setState(prev => {
  //       return {
  //         ...prev,
  //         pendingUsers: [ ...users],
  //         tab: users.length > 0 ? 'pending' : 'group'
  //       }
  //     })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const getFavoriteLocationId = async () => {
    try {
      setLoading(true);
      const location = await getFavLocationOfUser();
      setState(prev => {
        return {
          ...prev,
          locationId: location[0]._favoriteLocation
        }
      })
      
      // await getPendingUsers(location[0]._favoriteLocation);
      await getGroupUsers(location[0]._favoriteLocation);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleTab = (tab: string) => {
    switch (tab) {
      case 'pending': setState(prev => { return { ...prev, tab: 'pending' } }); break;
      case 'group': setState(prev => { return { ...prev, tab: 'group' } }); break;
      default: setState(prev => { return { ...prev, tab: 'pending' } });
    }
  }

  const handleAcceptedUser = async (user) => {
    try {
      const pendingUsers = state.pendingUsers.filter(item => item._id !== user._id);

      const pendingUsersId = pendingUsers.map(user => user._id);
      const groupUsersIds = state.groupUsers.map(user => user._id);
      const payload = {
        _location: state.locationId,
        _declined: [...state.declinedUsers],
        _members: [...groupUsersIds, user._id],
        _pendings: [...pendingUsersId]
      }

      await updateGroup({
        ...payload
      });

      setState(prev => {
        return {
          ...prev,
          groupUsers: [...prev.groupUsers, user],
          pendingUsers: [...pendingUsers],
          tab: pendingUsers.length > 0 ? 'pending' : 'group'
        }
      });

      // show notification that user is added
      enqueueSnackbar(`New passenger successfully added to your carpool group`, {
        variant: 'success',
      })
    } catch (error) {
      console.log(error);
    }
  }

  const handleDeclinedUser = async (user) => {
    try {
      const pendingUsers = state.pendingUsers.filter(item => item._id !== user._id);
    const groupUsers = state.groupUsers.filter(item => item._id !== user._id);
    
    const groupUsersIds = groupUsers.map(user => user._id);
    const pendingUsersIds = pendingUsers.map(user=> user._id);
    const payload = {
      _location: state.locationId,
      _declined: [...state.declinedUsers, user._id],
      _members: [...groupUsersIds],
      _pendings: [...pendingUsersIds]
    }

    await updateGroup({
      ...payload
    });
    
    setState(prev => {
      return {
        ...prev,
        declinedUsers: [...prev.declinedUsers, user._id],
        pendingUsers: [...pendingUsers],
        groupUsers: [...groupUsers],
        tab: pendingUsers.length > 0 ? 'pending' : 'group'
      }
    });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    loading ? <LoadingScreen /> :
      <>
        <Header
          canGoBack={true}
          rightIcon={<img alt='profile' className='header-img-icon' src={
            userDetailsReducer.basicDetails.photo
          } />}
          signUp={false}
        />
        
        <TitleBar />

        <Box className="p-1">

          <div className="group-tab-container w-100 pt-1">

            {state.pendingUsers.length > 0 && <Tab count={state.pendingUsers.length} title={window.innerWidth < 600 ? <>Pending <br/> matches</> : "Pending matches" } id="pending"
              status={state.tab === 'pending' ? true : false} onClick={handleTab} flexDirection={'flex-column'}/>}
            <Tab count={state.groupUsers.length} title={window.innerWidth < 600 && state.pendingUsers.length > 0 ? <>Approved <br/> passengers</> : "Approved passengers"} id="group"
              status={state.tab === 'group' ? true : false} onClick={handleTab} flexDirection={ state.pendingUsers.length > 0 ? 'flex-column' : 'flex-row'} />
          </div>
          <ListCards data={state.tab === 'pending' ? state.pendingUsers : state.groupUsers} state={state}
            handleAcceptedUser={handleAcceptedUser} handleDeclinedUser={handleDeclinedUser} />
        </Box>
      </>
  );
}

const TitleBar = () => {
  return (
    <Box className="padding-x rounded-top-green">
      <div className="sub-header">
        <div className="sub-header-icon-container">
          <img alt="group-icon" src={groupIcon} />
        </div>

        <b className="title">
          My carpool group
        </b>
      </div>
    </Box>
  )
}

const Tab = ({ count, title, status, onClick, id, flexDirection }) => {
  return (
    <div className={`${(status === true ? "group-tab-active" : "group-tab-inactive")} ${id === 'pending' && 'mr-5'} ${flexDirection} d-flex items-center ${flexDirection === 'flex-row' ? 'justify-start' : 'justify-center'} gap-1`} onClick={(e) => {
      onClick(id);
      e.stopPropagation();
    }
    }>
      <h3 className='text-center m-0 text-lrg'>{count}</h3>
      <p className='text-center'>{title}</p>
    </div>
  );
}

const ListCards = ({ data, handleDeclinedUser, handleAcceptedUser, state }) => {
  return (
    <Box marginBottom="110px">
      {
        data.length > 0 ?
          data.map((item, index) => {
            return (
              <Card item={item} key={index} state={state}
                handleDeclinedUser={handleDeclinedUser} handleAcceptedUser={handleAcceptedUser} />
            )
          }) :
          <div className='group-no-data-found'>
            <b>No member found</b>
          </div>
      }

      {
        state.tab === 'group' && state.groupUsers.length > 0 && (
          <CustomFixedButton disable={false} text="Offer carpool via WhatsApp" icon={whatsAppBlackIcon} handleSubmit={() => { window.open(`${process.env.REACT_APP_WHATSAPP_SIGN_ME_IN_URL}/?text=${encodeURIComponent("Offer carpool")}`, "_blank") }} />
        )
      }
    </Box>
  )
}

const Card = ({ item, handleDeclinedUser, handleAcceptedUser, state }) => {

  const [openDrawer, setOpenDrawer] = useState(false);
  const [ showUserDetail, setShowUserDetail ] = useState( false);
  const [userDetail, setUserDetail] = useState({
    photo: item.photo,
    firstName: [{
      value: "",
      lang: "",
    }],
    lastName: [{
      value: "",
      lang: "",
    }],
    occupation: item.occupation,
    loginType: item.loginType,
    socialId: item.socialId,
    createdAt: item.createdAt,
    organisationLogo: item.domainImageUrl,
    locationName: item.locationName,
    isVerified: item.isVerified,
    userPreference: item.userPreference,
    isDriverVerified: item.isDriverVerified
  })
  const userDetailsReducer = useSelector((state: RootState) => state.userDetailsReducer);

  const getAllCordinates = (
    e: React.MouseEvent<HTMLDivElement>,
    user: IMember,
  ) => {
    // variable to check if it is a map closing request
    let mapClosingRequest = false;

    if (
      (e.currentTarget.firstElementChild as HTMLParagraphElement)
        .textContent === "View on map"
    ) {
      (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
        "Hide map";
    } else {
      (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
        "View on map";

      mapClosingRequest = true;
    }

    // set the height of the map div if its height is not already 400px else set the height to 0px
    if (
      (e.currentTarget.nextElementSibling as HTMLDivElement).style.height ===
      "400px"
    ) {
      (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
        "0px";
    } else {
      (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
        "400px";
    }

    // toggle the chevron and cross icons
    // if down chevron is visible, then set it to hidden and set visibility of cross to visible and vice-versa
    if (
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .firstElementChild as SVGSVGElement
      ).style.visibility === "visible"
    ) {
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .firstElementChild as SVGSVGElement
      ).style.visibility = "hidden";
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .lastElementChild as SVGSVGElement
      ).style.visibility = "visible";
    } else {
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .firstElementChild as SVGSVGElement
      ).style.visibility = "visible";
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .lastElementChild as SVGSVGElement
      ).style.visibility = "hidden";
    }

    // do not make a call to the google maps API if it is a map closing request
    if (mapClosingRequest) {
      return
    }

    const arr: Array<{ lat: number; lng: number }> = [];
    // push latitude and longitude of trip pickup location
    arr.push({
      lat: parseFloat(String(user.address?.geo[0])),
      lng: parseFloat(String(user.address?.geo[1])),
    });

    // push latitude and longitude of user home location
    arr.push({
      lat: parseFloat(String(userDetailsReducer.address?.geo[0])),
      lng: parseFloat(String(userDetailsReducer.address?.geo[1])),
    });

    // push latitude and longitude of trip destination location
    arr.push({
      lat: parseFloat(String(user?.locationAddress?.geo[0])),
      lng: parseFloat(String(user?.locationAddress?.geo[1])),
    });

    console.log('user is', user)
    console.log('Geo array is', arr);

    // sending the index of the map
    initMap(e.currentTarget.dataset.index as string, arr);
  };

  const initMap = (
    index: string,
    tripCordinate: { lat: number; lng: number }[]
  ) => {
    var map = new google.maps.Map(
      (document as any).getElementById(`map${index}`),
      {
        center: tripCordinate[0],
        zoom: 8,
      }
    );

    // Create a new DirectionsService object
    var directionsService = new google.maps.DirectionsService();

    // Create a new DirectionsRenderer object
    var directionsRenderer = new google.maps.DirectionsRenderer({
      map: map,
    });

    // Define the start and end locations
    var start = new google.maps.LatLng(
      tripCordinate[0].lat,
      tripCordinate[0].lng
    );
    var end = new google.maps.LatLng(
      tripCordinate[tripCordinate.length - 1].lat,
      tripCordinate[tripCordinate.length - 1].lng
    );

    // Configure the DirectionsRequest object
    var request = {
      origin: start,
      destination: end,
      waypoints: tripCordinate.slice(1, -1).map((coordinate) => ({
        location: new google.maps.LatLng(coordinate.lat, coordinate.lng),
        stopover: true,
      })),
      travelMode: google.maps.TravelMode.DRIVING,
    };

    // Call the DirectionsService to get the route
    directionsService.route(request, function (result, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        // Display the route on the map
        directionsRenderer.setDirections(result);

        // get the directions response of the route
        let directionsResult = directionsRenderer.getDirections()!;

        // loop through each leg and show the duration for each leg in info window
        let prevDistance = 0;
        for (let i = 0; i < directionsResult.routes[0].legs.length; i++) {
          prevDistance = showMapDuration(
            directionsResult,
            map,
            i,
            prevDistance
          );
        }
      }
    });
  };

  return (
    <Box className="group-card">
      <Box className="group-card-header">
        <div className="group-card-header-left">
          <div className='user-img-container'>
            <img className='shadow-light bordered-2' src={item.userPicture} height={45} width={45} alt="profile" onClick = {(event) => {
              event.stopPropagation();
              setShowUserDetail(true);
              setUserDetail({
                ...item,
                firstName: [{
                  value: item.userName.split(' ')[0],
                  lang: "",
                }],
                lastName: [{
                  value: item.userName.slice(item.userName.indexOf(' ') + 1),
                  lang: "",
                }],
                photo: item.userPicture
              });
            }} />
          </div>
          <div className='d-flex flex-column p-1 text-700'>
            {item.userName}
            <div className='d-flex flex-row mt-2'>
              {/* <img alt="group" src={verifiedPersonIcon} className='mr-1' /> */}
              {/* <img height={20} width={20} src={item.domainImageUrl} alt="domain_img" /> */}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-end align-items-center">
          <div className='d-flex flex-row align-items-center pt-1 cursor-pointer'
            onClick={() => {
              setOpenDrawer(true);
              return;
            }}>
            <img src={cross} alt="no" />
          </div>
          {state.tab === 'pending' && (<div className='d-flex flex-row align-items-center pt-1 pl-1 cursor-pointer'
            onClick={() => handleAcceptedUser(item)}>
            <img src={greenTick} alt="yes" />
          </div>)}
        </div>
      </Box>
      <Box className="group-card-body">
        <div className="line">
          {/* */}
          <div className='d-flex flex-row'>
            <img alt="circle" src={emptyCircle} />
            <p>Home</p>
          </div>
        </div>
        <p className="p-75">|</p>
        <div className="line">
          <div className='d-flex flex-row'>
            <img alt="circle" src={FilledLeap} />
            <p>{item.passengerDestination} </p>
          </div>
          <small>
            <b>+{(Number(item.driverPassengerTime) + Number(item.passengerDestinationTime) - Number(item.driverTime)) > 0 ? 
              (Number(item.driverPassengerTime) + Number(item.passengerDestinationTime) - Number(item.driverTime)).toFixed() : 0} mins</b>
          </small>
        </div>
        <p className='p-75'>|</p>
        <div className="line">
          <div className='d-flex flex-row'>
            <img alt="circle" src={locationPin} />
            <p>{item.locationName}</p>
          </div>
          <small className='color-primary'>
            <b>{(Number(item.driverPassengerTime) + Number(item.passengerDestinationTime)).toFixed()} mins</b>
          </small>
        </div>
      </Box>

      <Box className="group-card-price">
        <b>Price</b>
        <b>£{item.price.toFixed(2)}</b>
      </Box>

      <Box
        data-index={item._id}
        className="group-map-view cursor-pointer"
        marginTop="16px"
        marginBottom="8px"
        onClick={(e) =>
          getAllCordinates(e, item)
        }
      >
        <p className="view text-700 primary-color">
          View on map
        </p>
        <div style={{ position: "relative" }}>
          <ExpandMoreIcon
            style={{ visibility: "visible" }}
            className="primary-color"
          />
          <CloseIcon
            style={{
              visibility: "hidden",
              position: "absolute",
              right: "0",
              top: "0",
            }}
            className="primary-color"
          />
        </div>
      </Box>

      <div
        id={`map${item._id}`}
        className="expand-transition rounded"
      ></div>
       <UserProfile
        isOpen={showUserDetail}
        onClose={() => setShowUserDetail(false)}
        userDetail={{ ...userDetail, locationName: item.locationName, organisationLogo: item.domainImageUrl}}
      />
      <CustomDrawer
        anchor='bottom'
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <Box>
          <h2 className='text-center'>Remove member</h2>
          <p className='text-center'>Are you sure that you would like to remove<br /> this member from your group?</p>

          <div className='text-center'>
            <div className='p-1'>
              <b className="color-blue p-2" onClick={() => setOpenDrawer(false)}>
                Cancel
              </b>
            </div>
            <div style={{
              margin: "0 2rem 1rem 2rem"
            }}>
              <Button className='mb-4 btn-width' style={{
                backgroundColor: "#DF1642",
                padding: "1.25rem"
              }} size='large' onClick={() => { handleDeclinedUser(item); setOpenDrawer(false) }}>
                <b>Confirm</b>
              </Button>
            </div>
          </div>
        </Box>
      </CustomDrawer>
    </Box>
  );
}

export default Group;