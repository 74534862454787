import { Box, Button } from "@mui/material"
import "./style.scss";
import { arrowIconGreen, greenGasStation } from "../../../assets/images";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTripDetail } from "../../../slice/trip";
import { getSingleTripDetail } from "../../../services/trips";
import { ITripUserMap } from "../../../slice/invites";
import LoadingScreen from "../../../components/loading-screen";
import moment from "moment";

export const Earnings = ({state, setState}) => {

  // const [state, setState] = useState({
  //   weeklyTrips: [],
  //   loader: true,
  //   page: 1,
  //   hideLoadMore: false
  // });

  // const getWeeklyEarningsData = async () => {
  //   try {
  //     const weeklyTrips = await getWeeklyTripsEarnings(state.page);
  //   setState(prev => ({
  //     ...prev,
  //     weeklyTrips: [
  //       ...prev.weeklyTrips, ...weeklyTrips.filter(week => {
  //         let include = true;
  //         prev.weeklyTrips.forEach(prevWeek => {
  //           if (prevWeek.weekDate === week.weekDate) {
  //             include = false;
  //           }
  //         });
  //         return include;
  //       })
  //     ],
  //     hideLoadMore: !Boolean(weeklyTrips.length),
  //     loader: false
  //   }));
  //   } catch (error) {
  //     setState(prev => ({
  //       ...prev,
  //       hideLoadMore: true,
  //       loader: false
  //     }));
  //   }
  // }

  // useEffect(() => {
  //   getWeeklyEarningsData();
  //   //eslint-disable-next-line
  // }, [])

  // useEffect(() => {
  //   getWeeklyEarningsData();
  //   //eslint-disable-next-line
  // }, [state.page])

  return state.loader === true ? (<LoadingScreen />) : <Box id="earnings">
    {
      state.weeklyTrips.map(tripsOfWeek => {
        return (
          <WeekCard tripsOfWeek={tripsOfWeek} key={tripsOfWeek._id} setState={setState} />
        )
      })
    }
    {
      (state.weeklyTrips.length > 0 && state.hideLoadMore === false) && (<Box className="week-card-load-more-container">
        <Button className="week-card-load-more-button" onClick={() => {
          setState(prev => ({
            ...prev,
            page: prev.page + 1,
            loader: true
          }));
        }}
        >
          Load More
        </Button>
      </Box>)
    }
    {/* {
      state.weeklyTrips.length <= 0 && (
        <Box className="earnings-no-data-found">
        </Box>
      )
    } */}
  </Box>
}

const WeekCard = ({ tripsOfWeek, setState }) => {
  let numberOfLines = 0;
  return (
    <Box className="week-card">
      <Box className="week-card-header">
        Fuel Payout - {tripsOfWeek.payoutDate}
      </Box>
      <Box className="week-card-body">
        {tripsOfWeek.trips.map(trip => {
          numberOfLines++;
          return (
          <>
            <TripSavingsCard trip={trip} key={trip._id} setState={setState} />
            {tripsOfWeek.trips.length > numberOfLines && (<hr className="horizontal-line-color"/>)}
          </>
          )
        })}
      </Box>
    </Box>
  )
}

const TripSavingsCard = ({ trip, setState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box className="trip-rewards-card gap-1">
      <Box className="trip-rewards-fuel-icon">
        <img src={greenGasStation} alt="gas-station" />
      </Box>
      <Box className="trip-rewards-content">
        <Box className="trip-rewards-left-content">
          {
            trip.type === 'pick-up' && (
              <b>{trip.locationCategory} - Home</b>
            )
          }
          {
            (trip.type === 'drop-off' || trip.type === 'N/A') && (
              <b>Home - {trip.locationCategory}</b>
            )
          }
          <p>{moment(trip.scheduleDate).format("Do MMM")}</p>
        </Box>
        <Box className="trip-rewards-right-content">
          <b>{Math.round(trip.earnedEcoTokens)} tokens</b>
          <p>£{Number(trip.tripAmount).toFixed(2)}</p>
        </Box>
      </Box>
      <Box className="trip-rewards-chevron"
        onClick={async () => {
          setState((prev) => ({
            ...prev,
            loader: true,
          }))
          const modifiedTrip = (await getSingleTripDetail(trip._id, trip.tripStatus) as { data: ITripUserMap[] }).data[0];
          dispatch(setTripDetail({ ...modifiedTrip }));
          navigate("/dashboard/trip-detail");
        }}>
        <img src={arrowIconGreen} alt='chevron-right' />
      </Box>
    </Box>
  )
}