import React, { useState, useEffect, Fragment } from "react";
import "./style.scss";
import {
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  updateTripStatus,
  updateCurrentPage,
  updateAllTripsData,
  updateTripsHistory,
} from "../../../../slice/trip";
import { GroupedTrips } from "../../../dashboard/upcoming-trip";
import Car from "../../../../assets/images/car_green.svg";
import Steering from "../../../../assets/images/stearing_wheel_green.svg";
import moment from "moment";
import { TripInterface } from "../../../../slice/trip";
import Trip from "../../Common components/Trip";
import DefaultProfile from "../../../../assets/images/default-profile.svg";
import PendingIcon from "../../../../assets/images/pending.svg";
import CheckBlackIcon from "../../../../assets/images/checkmark_black.svg";
import CancelledIcon from "../../../../assets/images/cancel.svg";
import { getAllTripsUserPopulated } from "../../../../services/trips";
// import { checkInvites } from "../../../../services/book-seat";
import {
  getCategory,
  getFavLocationOfUser,
  getPopulatedFavLocationsOfUser,
} from "../../../../services/location";
import {
  updateFavLocationUtility,
  setUsersAllFavLocation,
} from "../../../../slice/utility";
import {
  updateCatList,
  updateUserFavLocations,
} from "../../../../slice/category";
import { enqueueSnackbar } from "notistack";
import LoadingScreen from "../../../../components/loading-screen";
import { setTripDetail } from "../../../../slice/trip";
import { useNavigate, useSearchParams } from "react-router-dom";
import Card from "../../Common components/Card";
import MarketPlaceCard from "../../Common components/market-place-card.tsx";
import backgroundImage from "../../../../assets/images/background.png";
import {
  capitalizeFirstLetter,
  getName as getLocationName,
} from "../../../../utilities/helper";
import Header from "../../../../components/header-1";
// import InviteAlert from "../../Common components/Invite-alert";
import { resetFilteredUsersAll } from "../../../../slice/invites";
import { resetBookSeatReducer } from "../../../../slice/book-seat";
import CircularProgress from "@mui/material/CircularProgress";
// import UserProfile from "../../../../components/user-profile";
import NoTripsCard from "../../../../components/no-trips-card";
import { BackToWhatsAppButton } from "../../../../components/back-to-whatsapp";

const Dashboard = () => {
  const {
    userDetailsReducer,
    tripReducer: { currentPage, status, allTrips, tripsHistory },
    categoryReducer: { category },
    utilityReducer: { allFavLocationsOfUser },
  } = useSelector((state: RootState) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();

  const { basicDetails, user } = userDetailsReducer;

  const [currTab, setCurrTab] = useState(searchParams.get("passenger") ? "PASSENGER" : "DRIVER");
  const [selectedTrips1, setSelectedTrips1] = useState<GroupedTrips>({});
  const [pastTrips, setPastTrips] = useState<GroupedTrips>({});
  const [loading, setLoading] = useState(true);
  const [tripsLoading, setTripsLoading] = useState(true);
  // const [totalInvites, setTotalInvites] = useState<number>();
  // const [offerCarpool, setOfferCarpool] = useState({
  //   from: "",
  //   to: "",
  //   type: "",
  //   _location: "",
  // });

  const getHeading = () => {
    return status === "UPCOMING" ? "Upcoming trips" : "Past trips";
  };

  const getTripColorClass = (status: string) => {
    switch (status) {
      case "COMPLETED": {
        return "green";
      }
      case "CONFIRMED": {
        return "gray-trip-status";
      }
      case "PENDING": {
        return "gray-trip-status";
      }
      case "EXPIRED": {
        return "red-trip-status";
      }
      default: {
        return "red-trip-status";
      }
    }
  };

  const getTripStatusIcon = (status: string) => {
    switch (status) {
      case "COMPLETED": {
        return CheckBlackIcon;
      }
      case "CONFIRMED": {
        return CheckBlackIcon;
      }
      case "EXPIRED": {
        return CancelledIcon;
      }
      case "PENDING": {
        return PendingIcon;
      }
      default: {
        return CancelledIcon;
      }
    }
  };

  const getCategoryName = (locationId: string, tripType: string) => {
    //console.log(locationId, "jldjfsld")
    for (let cat of category) {
      for (let loc of cat.locations) {
        if (loc._id.toString() === locationId) {
          return `${cat.locationName[0].value}-${tripType}`;
        }
      }
    }

    return `N/A-${tripType}`;
  };

  let getUsersLocationAndInvites = async () => {
    if (allFavLocationsOfUser.length === 0) {
      try {
        const res: any = await getPopulatedFavLocationsOfUser();

        const usersLocationList = res?.data?.map((data: any) => ({
          locationId: data.locationCategory?._id,
          locationName: getLocationName(
            data.locationCategory?.locationName,
            "EN"
          ),
        }));

        // setOfferCarpool((prev) => {
        //   return {
        //     ...prev,
        //     from: "Home",
        //     to: usersLocationList[0].locationId,
        //     _location: usersLocationList[0].locationId,
        //     type: "Drop Off",
        //   };
        // });
        setLoading(false);
        dispatch(setUsersAllFavLocation(usersLocationList));
      } catch {
        // setTotalInvites(0);
      }
    } else {
      // setOfferCarpool((prev) => {
      //   return {
      //     ...prev,
      //     from: "Home",
      //     to: allFavLocationsOfUser[0].locationId,
      //     _location: allFavLocationsOfUser[0].locationId,
      //     type: "Drop Off",
      //   };
      // });
      setLoading(false);
    }
  };

  // const fetchInvites = async () => {
  //   try {
  //     const res4: any = await checkInvites();

  //     setTotalInvites(res4?.length || 0);
  //   } catch (error) {
  //     setTotalInvites(0)
  //   }
  // }

  const getCondition = () => {
    return status === "UPCOMING"
      ? allTrips.length === 0
      : tripsHistory.length === 0;
  };

  const getData = async () => {
    try {
      const res = await getFavLocationOfUser();
      // const usersLocationList = res3.data.map((data:any)=>getLocationName(data.locationCategory.locationName,"EN"))
      const utilityFavLocList = res.map((favLocation: any) => {
        const { _categoryId, _favoriteLocation } = favLocation;
        return {
          categoryId: _categoryId,
          locationId: _favoriteLocation,
          locationName: [{ lang: "EN", value: "" }],
          categoryName: [{ lang: "EN", value: "" }],
        };
      });

      dispatch(updateFavLocationUtility(utilityFavLocList));
      dispatch(updateUserFavLocations(res));
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsersLocationAndInvites();
    getCategoryNames();
    // fetchInvites();
    dispatch(resetFilteredUsersAll());
    dispatch(resetBookSeatReducer());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let temp = [] as TripInterface[];
    let tripsArray = status === "UPCOMING" ? allTrips : tripsHistory;

    if (Array.isArray(tripsArray)) {
      temp = [
        ...[...tripsArray]
          .filter((trip) => {
            if (currTab === "DRIVER") {
              return trip._driver._id === user._id;
            } else {
              return trip._driver._id !== user._id;
            }
          })
          .sort((a: object, b: object) => {
            if (
              moment((a as { scheduleDate: string }).scheduleDate) >
              moment((b as { scheduleDate: string }).scheduleDate)
            ) {
              return 1;
            } else if (
              moment((a as { scheduleDate: string }).scheduleDate) <
              moment((b as { scheduleDate: string }).scheduleDate)
            ) {
              return -1;
            } else {
              return 0;
            }
          }),
      ];

      status === "UPCOMING"
        ? setSelectedTrips1(groupTripsByScheduleDate(temp))
        : setPastTrips(groupTripsByScheduleDate(temp));
    }

    //eslint-disable-next-line
  }, [allTrips, currentPage, status, currTab, tripsHistory]);

  useEffect(() => {
    if (getCondition()) {
      //setLoading(true);
      setTripsLoading(true);
      getData().then(() => {
        if (basicDetails.userPreference === "BOTH" && !searchParams.get("passenger")) {
          setCurrTab("DRIVER");
        } else if (basicDetails.userPreference === "OFFER" && !searchParams.get("passenger")) {
          setCurrTab("DRIVER");
        } else {
          setCurrTab("PASSENGER");
        }
      });

      getAllTripsUserPopulated({ tripStatus: status, page: currentPage })
        .then((res) => {
          // const historyTripData = res.data.filter(tripData => {
          //   return moment(tripData.scheduleDate) < moment()
          // });

          // const upcomingTripData = res.data.filter(tripData => {
          //   return moment(tripData.scheduleDate) >= moment()
          // });

          status === "UPCOMING"
            // ? dispatch(updateAllTripsData({ ...res, data: upcomingTripData }))
            // : dispatch(updateTripsHistory({ ...res, data: historyTripData }));
            ? dispatch(updateAllTripsData(res))
            : dispatch(updateTripsHistory(res));

          // setLoading(false);
          setTripsLoading(false);
        })
        .catch(() => {
          enqueueSnackbar(
            "Unable to get trip data. Please try refreshing the page",
            { variant: "error" }
          );
          // setLoading(false);
          setTripsLoading(false);
          if (basicDetails.userPreference === "BOTH") {
            setCurrTab("DRIVER");
          } else if (basicDetails.userPreference === "OFFER") {
            setCurrTab("DRIVER");
          } else {
            setCurrTab("PASSENGER");
          }
        });
    } else {
      //  setLoading(false);
      setTripsLoading(false);
      if (basicDetails.userPreference === "BOTH" && !searchParams.get("passenger")) {
        setCurrTab("DRIVER");
      } else if (basicDetails.userPreference === "OFFER" && !searchParams.get("passenger")) {
        setCurrTab("DRIVER");
      } else {
        setCurrTab("PASSENGER");
      }
    }

    //eslint-disable-next-line
  }, [currentPage, status, basicDetails.userPreference]);

  let getCategoryNames = async () => {
    try {
      const res = await getCategory();
      dispatch(updateCatList(res));
    } catch {
      dispatch(updateCatList([]));
    }
  };

  const getName = (trip: TripInterface, name: "firstName" | "lastName") => {
    if (trip._driver) {
      const res = trip?._driver[name];

      if (res !== undefined && res.length > 0) return res[0].value;
    }

    return "NA";
  };

  function groupTripsByScheduleDate(trips: TripInterface[]) {
    const groupedTrips = {} as GroupedTrips;

    trips.forEach((trip) => {
      const scheduleDate = moment(trip.scheduleDate).format("ddd Do MMM");

      if (scheduleDate) {
        if (!groupedTrips[scheduleDate]) {
          groupedTrips[scheduleDate] = [];
        }

        groupedTrips[scheduleDate].push(trip);
      }
    });

    return groupedTrips;
  }

  const handleClickStatus = () => {
    const targetStatus = status === "UPCOMING" ? "COMPLETED" : "UPCOMING";

    dispatch(updateTripStatus(targetStatus));
    dispatch(updateCurrentPage(1));
  };

  const countTrip = (tab: "DRIVER" | "PASSENGER") => {
    if (tab === "DRIVER") {
      return (status === "UPCOMING" ? allTrips : tripsHistory).reduce(
        (count, tripCurrent) => {
          if (tripCurrent._driver._id === user._id) {
            return count + 1;
          } else {
            return count;
          }
        },
        0
      );
    } else {
      return (status === "UPCOMING" ? allTrips : tripsHistory).reduce(
        (count, tripCurrent) => {
          if (tripCurrent._driver._id !== user._id) {
            return count + 1;
          } else {
            return count;
          }
        },
        0
      );
    }
  };

  // const handleSubmit = () => {
  //   let obj = {
  //     type: offerCarpool.type,
  //     locationId: offerCarpool._location,
  //     redirect: "Offer",
  //     jti: user.jti,
  //   };

  //   console.log(obj);
  //   let redirectUri = jsonToBase64(obj);

  //   dispatch(resetDecodedData({}));

  //   navigate(`/redirect/${redirectUri}`);
  // };

  // const handleChange = (e: any) => {
  //   const { name, value } = e.target;

  //   if (name === "from") {
  //     setOfferCarpool((prev) => {
  //       return {
  //         ...prev,
  //         from: value,
  //         to: "Home",
  //         _location: value,
  //         type: "Pick Up",
  //       };
  //     });
  //   } else if (name === "to") {
  //     setOfferCarpool((prev) => {
  //       return {
  //         ...prev,
  //         from: "Home",
  //         to: value,
  //         _location: value,
  //         type: "Drop Off",
  //       };
  //     });
  //   }
  // };

  // const handleSwap = () => {
  //   let temp = offerCarpool.from;
  //   setOfferCarpool((prev) => {
  //     return {
  //       ...prev,
  //       from: offerCarpool.to,
  //       to: temp,
  //       type: offerCarpool.type === "Pick Up" ? "Drop Off" : "Pick Up",
  //     };
  //   });
  // };

  return (
    <div id="dashboard-new">
      {loading ? (
        <LoadingScreen />
      ) : (
        <Fragment>
          <Header rightIcon={basicDetails.photo} />
          {/* <UserProfile isOpen={true} /> */}

          <BackToWhatsAppButton enableHorizontalMargin={true} />
          <div className="main-contents-wrapper">
            <div className="ecopooling-background">
              <img
                width="100%"
                src={backgroundImage}
                alt="background-ecopooling"
                className="home-background-image"
                // style={{position: "absolute", top: "-2rem"}}
              />

              {/* {totalInvites > 0 && (
                <Box className="invite-alert-container">
                  <InviteAlert invites={totalInvites} />
                </Box>
              )} */}

              <div className="dashboard-cards">
                {/* {basicDetails.userPreference !== "FINDER" && (
                  <Card>
                    <Box paddingY="8px">
                      <p className="heading">Offer Carpool</p>
                    </Box>

                    <Box marginTop="16px" position="relative">
                      <Box position="relative">
                        <TextField
                          className="custom-input"
                          name="from"
                          select
                          value={offerCarpool.from}
                          onChange={handleChange}
                          InputProps={{
                            style: {
                              height: "48px",
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={locationPin} alt="location-pin" />
                              </InputAdornment>
                            ),
                          }}
                        >
                          <MenuItem value="Home" sx={{ display: "none" }}>
                            Home
                          </MenuItem>

                          {(allFavLocationsOfUser as string[]).map(
                            (location: any, key) => (
                              <MenuItem key={key} value={location.locationId}>
                                {location.locationName}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      </Box>

                      <Box
                        position="absolute"
                        top="37px"
                        right="-13px"
                        className="cursor-pointer"
                        zIndex="1"
                        onClick={handleSwap}
                      >
                        <img src={SwapIcon} alt="swap" />
                      </Box>

                      <Box className="div" marginY="24px" position="relative">
                        <TextField
                          className="custom-input"
                          name="to"
                          select
                          placeholder="To"
                          onChange={handleChange}
                          value={offerCarpool.to}
                          InputProps={{
                            style: {
                              height: "48px",
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={locationPin} alt="location-pin" />
                              </InputAdornment>
                            ),
                          }}
                        >
                          <MenuItem value="Home" sx={{ display: "none" }}>
                            Home
                          </MenuItem>

                          {(allFavLocationsOfUser as string[]).map(
                            (location: any, key) => (
                              <MenuItem key={key} value={location.locationId}>
                                {location.locationName}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      </Box>

                      <Box className="next-btn">
                        <Button
                          className="custom-btn px-15rem"
                          fullWidth
                          onClick={handleSubmit}
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                )} */}

                <Card>
                  <Box className="mb-4 upcoming-trips-container">
                    <Box
                      className="center"
                      paddingY="8px"
                      justifyContent="space-between"
                    >
                      <p className="heading">{getHeading()}</p>

                      <p
                        className="primary-color click"
                        onClick={handleClickStatus}
                      >
                        {status === "UPCOMING"
                          ? " View history "
                          : "View upcoming"}
                      </p>
                    </Box>

                    <Box
                      className="d-flex gap-12"
                      marginBottom={"24px"}
                      marginTop="16px"
                    >
                      {(basicDetails.userPreference === "BOTH" ||
                        basicDetails.userPreference === "OFFER") && (
                          <p
                            className={`${currTab === "DRIVER" ? "pill pill-active" : "pill"
                              }`}
                            onClick={() => setCurrTab("DRIVER")}
                          >
                            I'm driving ({countTrip("DRIVER")})
                          </p>
                        )}

                      {(basicDetails.userPreference === "BOTH" ||
                        basicDetails.userPreference === "FINDER") && (
                          <p
                            className={`${currTab === "PASSENGER"
                              ? "pill pill-active"
                              : "pill"
                              }`}
                            onClick={() => setCurrTab("PASSENGER")}
                          >
                            I'm a passenger ({countTrip("PASSENGER")})
                          </p>
                        )}
                    </Box>

                    {Object.keys(
                      status === "UPCOMING" ? selectedTrips1 : pastTrips
                    ).map((it: string, key) => (
                      <div key={key}>
                        <p className="section-head">{it}</p>

                        {(status === "UPCOMING" ? selectedTrips1 : pastTrips)[
                          it
                        ].map((trip, tripKey) => (
                          <Trip
                            key={tripKey}
                            currTab={currTab}
                            image={trip?._driver?.photo ?? DefaultProfile}
                            multipleImage={[
                              ...(trip?._user?.map((it) => it.photo) || []),
                              ...(trip?.invitedUsers?.map((it) => it.photo) ||
                                []),
                            ]}
                            bookedSeat={String(trip._user.length)}
                            totalSeats={String(trip.totalSeats)}
                            text={
                              trip._user.length > 1
                                ? "seat booked"
                                : "seats booked"
                            }
                            firstName={capitalizeFirstLetter(
                              getName(trip, "firstName")
                            )}
                            lastName={capitalizeFirstLetter(
                              getName(trip, "lastName")
                            )}
                            tripColorClass={(currTab === "PASSENGER" && trip.tripStatus !== "CANCELLED") ? "bg-green" : ((trip._user.length > 0 && trip.tripStatus !== "CANCELLED") ? 'bg-green' : getTripColorClass(trip.tripStatus))}
                            tripStatusIcon={(currTab === "PASSENGER" && trip.tripStatus !== "CANCELLED") ? getTripStatusIcon("CONFIRMED") : ((trip._user.length > 0 && trip.tripStatus !== "CANCELLED") ? getTripStatusIcon("CONFIRMED") : '')}
                            tripStatus={
                              (currTab === "PASSENGER" && trip.tripStatus !== "CANCELLED") ? "Confirmed" :
                              trip.tripStatus === 'CANCELLED' ? 'Cancelled' : 
                              trip.tripStatus === 'EXPIRED' ? 'Expired' : (trip._user.length + '/' + trip.totalSeats + ' seats booked')
                            }
                            categoryName={
                              trip.destinationLocationId === undefined
                                ? getCategoryName(
                                  trip.pickUpLocationId,
                                  "pickup, "
                                )
                                : getCategoryName(
                                  trip.destinationLocationId,
                                  "dropoff, "
                                )
                            }
                            time={moment(trip.scheduleDate).format("HH:mm")}
                            handleClick={() => {
                              dispatch(setTripDetail(trip));
                              navigate("/dashboard/trip-detail");
                            }}
                          />
                        ))}
                      </div>
                    ))}

                    {Object.keys(
                      status === "UPCOMING" ? selectedTrips1 : pastTrips
                    ).length === 0 && !tripsLoading ? (
                      <Box>
                        <NoTripsCard
                          logo={currTab.toLowerCase() === 'driver' ? Steering : Car}
                          heading={currTab.toLowerCase() === 'driver' ? "You're riding solo" : "No seat bookings"}
                          description={ currTab.toLowerCase() === 'driver' ? "It looks like you are riding solo. Offer a carpool today." :
                           "You don't currently have any seat bookings in carpools."}
                        />
                      </Box>
                    ) : (
                      Object.keys(
                        status === "UPCOMING" ? selectedTrips1 : pastTrips
                      ).length === 0 && (
                        <Box margin="80px 0px">
                          <div className="d-flex justify-center items-center mt-5">
                            <CircularProgress />
                          </div>
                          
                          <p style={{ textAlign: "center" }}>
                            Fetching trips...
                          </p>
                        </Box>
                      )
                    )}
                  </Box>
                </Card>

                <Card>
                  <Box paddingY="8px" className="text-center">
                    <p className="heading">Rewards marketplace</p>
                  </Box>

                  <MarketPlaceCard fromHome={true} isBordered={false} />
                </Card>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Dashboard;
