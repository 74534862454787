import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
const initialState = {
   tokens : 0,
   tokenValue : 0,
   cards : [],
   purchasedCards : []
}
export const marketPlaceSlice = createSlice({
  name: 'marketPlaceReducer',
  initialState,
  reducers: {
  setTokenNumberAndValue:(state,action:PayloadAction<any>) => {
    const {ecoTokens} = action.payload
    state.tokens = ecoTokens
    state.tokenValue = 0.01 * ecoTokens
  },
  setAllCards:(state,action:PayloadAction<any>) =>{
      state.cards = action.payload
  },

  setAllPurchasedCard : (state,action:PayloadAction<any>) => {
      state.purchasedCards = action.payload
  }
  }
})

export const {
    setTokenNumberAndValue,
    setAllCards,
    setAllPurchasedCard
} = marketPlaceSlice.actions
export default marketPlaceSlice.reducer