import "./style.scss";
import { Box, Button } from "@mui/material";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import EmptyCircle from "../../../assets/images/empty-circle.svg";
import LocationPin from "../../../assets/images/location-pin.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import moment from "moment";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  checkSelectedTripConfirmed,
  generatePaymentLink,
} from "../../../services/payment";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import LoadingScreen from "../../../components/loading-screen";
import {
  calculateParkingFee,
  capitalizeFirstLetter,
  clearLocalStorageBeforeVerification,
  getName,
  getOrganisationLogo,
  getOrganizationName,
  paymentButtonHandler,
} from "../../../utilities/helper";
import userVerified from "../../../assets/images/user-verified.svg";
import userNotVerified from "../../../assets/images/user-not-verified.svg";
import { setRedirectURL } from "../../../slice/utility";
import arrow from "../../../assets/images/arrow-icon-green.svg";
import CarStearingIcon from "../../../assets/images/stearing.svg";
import { InviteResponse } from "../../../interface/book-seat";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import { DetailRow } from "../../market place/card detail";
import { getSeatAvailabilityMultiple } from "../../../services/book-seat";
import { setSelectedInvites, setSelectedTripResponse } from "../../../slice/book-seat";
import { BasicDetails } from "../../../slice/login";
import UserProfile from "../../../components/user-profile";
import CustomFixedButton from "../../../components/custom-fixed-button";

const FindCarPoolConfirmSeat = () => {
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [priceDetailDrawer, setPriceDetailDrawer] = useState(false);
  const [userDetail, setUserDetail] = useState({
    image: "",
    name: "",
    fuelPrice: 0,
    parkingFee: 0,
    discount: 0,
    serviceFee: 0,
    totalPrice: 0,
  });
  const [userBasicDetail, setUserBasicDetail] = useState<{
    showUserDetail: boolean;
    userDetail: BasicDetails,
    locationName: string,
    organisationLogo: string
  }>({
    showUserDetail: false,
    userDetail: {
      firstName: [],
      lastName: [],
      email: "",
      gender: "",
      phone: "",
      photo: "",
      userPreference: "",
      occupation: "",
      createdAt: "",
    },
    locationName: "",
    organisationLogo: "",
  });

  const {
    bookSeatReducer: { selectedTripResponse, selectedInvites },
    userDetailsReducer,
  } = useSelector((state: RootState) => state);

  let [searchParams] = useSearchParams();
  let { data: base64Data } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // redirect to landing page if no user is selected
  useEffect(() => {
    if ((selectedTripResponse.length === 0) && !searchParams.get("fromPayment")) {
      // if user comes directly to this page, then redirect to redirection landing page
      navigate(`/redirect/${base64Data}`);
    } else if(selectedTripResponse.length > 0) {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [selectedTripResponse]);

  // load the redux state if user is coming from payment failure/cancellation screen
  useEffect(() => {
    if(searchParams.get("fromPayment")) {
      dispatch(setSelectedTripResponse(JSON.parse(localStorage.getItem("selectedTripResponse"))))

      dispatch(setSelectedInvites(JSON.parse(localStorage.getItem("selectedInvites"))))
    } else {
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  const formatDate = () => {
    let formattedDate = "";
    let datesArr = selectedInvites.map((it) => it.estimatedDropTime);

    const sortedDates = [...datesArr].map(date => moment(date).startOf('day').toISOString()).sort((a, b) => {
      if (moment(a) > moment(b)) {
        return 1
      } else {
        return -1
      }
    });

    let datesParsed = [] as string[];
    sortedDates.forEach((date, index) => {
      // if current date is already parsed/included, then ignore it
      if (datesParsed.includes(date)) {
        return;
      } else {
        if (index === sortedDates.length - 1) {
          formattedDate += moment(new Date(date as string)).format("Do");
          formattedDate += " " + moment(new Date(date as string)).format("MMM");
          formattedDate += " ";
          return formattedDate;
        }
        formattedDate += moment(new Date(date as string)).format("Do");

        if (sortedDates.length > 1 && index === sortedDates.length - 2) {
          formattedDate +=
            " " + moment(new Date(date as string)).format("MMM") + " & ";
        } else {
          formattedDate +=
            " " + moment(new Date(date as string)).format("MMM") + ", ";
        }

        datesParsed.push(date);
      }
    });

    return formattedDate;
  };

  const forMattDate = (estimatedPickUpTime: string, tripType: string) => {
    return moment(estimatedPickUpTime).format(
      `ddd Do MMM, [est ${tripType === "pickUp" ? "drop-off" : "pick-up"
      } @]HH:mm`
    );
  };

  const getStartLocation = () => {
    let res = "";

    res += selectedInvites[0]?.locationDetail?.locationName[0]?.value ?? "N/A";

    return res;
  };

  const getDestLocation = () => {
    let res = "";

    res += selectedInvites[0]?.locationDetail?.locationName[0]?.value ?? "N/A";

    return res;
  };

  const navigate = useNavigate();

  const handlePayNow = async () => {
    // store cancel redirect and failure redirect links in the local storage
    localStorage.setItem("cancelRedirect", pathname + "?fromPayment=true");

    localStorage.setItem("failureRedirect", pathname + "?fromPayment=true");

    //checking if the user is verified or not
    if (
      !selectedInvites[0].locationDetail?.verificationType.includes("NONE") && (
        userDetailsReducer.basicDetails?.isVerified !== true
      )
    ) {
      // remove already existing data from local storage
      clearLocalStorageBeforeVerification();

      localStorage.setItem(
        "locationId",
        selectedTripResponse[0].destinationLocationId
      );

      // store the data in local state to trigger the payment process later
      localStorage.setItem(
        "selectedTripResponse",
        JSON.stringify(selectedTripResponse)
      );

      localStorage.setItem("selectedInvites", JSON.stringify(selectedInvites));

      dispatch(setRedirectURL(pathname));

      navigate("/get-verified");

      return;
    }

    try {
      setPaymentLoading(true);

      const tripResponse = await checkSelectedTripConfirmed({
        ids: selectedTripResponse.map((it) => it._id),
      });

      if (tripResponse.data && tripResponse.data.length) {
        setPaymentLoading(false);
        return enqueueSnackbar(
          `Seat booking window has been closed for following date ${tripResponse.data.toString()}, please update your selection`,
          { variant: "error" }
        );
      }

      try {
        // check seat availability
        const value = await getSeatAvailabilityMultiple(selectedTripResponse.map((it) => it._id))

        // if seat is not available, then show the alert/notification
        if (value.tripSeatDetail[0].seatsAvailable === 0) {
          enqueueSnackbar("Seat is not available for this trip", {
            variant: "warning",
          });

          setPaymentLoading(false);

          return;
        }
      } catch (error) {
        setPaymentLoading(false);

        return enqueueSnackbar("Seat is not available for this trip", {
          variant: "warning",
        });
      }

      // make a request to API to get the payment link
      const paymentLinkResponse = await generatePaymentLink({
        amount: Number(calculateTotalPrice().toFixed(2)),
        tripIds: selectedTripResponse.map((it) => it._id),
        tripUniqueId: selectedTripResponse.map((it) => it.uniqueId),
      });

      if (paymentLinkResponse.success === true) {
        // store the data in local state to trigger the payment process later
      localStorage.setItem(
        "selectedTripResponse",
        JSON.stringify(selectedTripResponse)
      );

      localStorage.setItem("selectedInvites", JSON.stringify(selectedInvites));

      paymentButtonHandler(paymentLinkResponse);
      } else {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar("Unable to create payment link", { variant: "error" });
      setPaymentLoading(false);
    }
  };

  // calculate total price
  const calculateTotalPrice = () => {
    // sum price of all the invites which are selected
    return selectedInvites.reduce(
      (accumulator, currentValue) => accumulator + currentValue.perSeatPrice,
      0
    );
  };

  // price details click handler
  const handlePriceDetailClick = (selectedTripResponse: InviteResponse) => {
    setUserDetail({
      image: selectedTripResponse.driverDetail.photo,
      name: capitalizeFirstLetter(getName(selectedTripResponse.driverDetail.firstName, "EN")) + " " + capitalizeFirstLetter(getName(selectedTripResponse.driverDetail.lastName, "EN")),
      fuelPrice: selectedTripResponse.fuelCost,
      parkingFee: calculateParkingFee(
        selectedTripResponse.parking,
        selectedTripResponse.tripDetail.totalSeats
      ),
      discount: selectedTripResponse.discount,
      serviceFee: selectedTripResponse.serviceFee,
      totalPrice: selectedTripResponse.perSeatPrice,
    });

    setPriceDetailDrawer(true);
  };

  return (
    <div id="trip-detail">
      <Header
        rightIcon={
          <img
            alt="profile"
            className="header-img-icon header-profile-image bordered-2 shadow-light"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
          />
        }
        showBackIcon={true}
        backLink={`/redirect-page/find-carpool-list/${base64Data}`}
      />

      {
        loading ? <LoadingScreen /> : (
          <>
            {
              <>
                {selectedTripResponse.length > 0 ? (
                  <>
                    <Box className="p-1 bg-white mb-3">
                      <Box className="seat-info">
                        <Box marginBottom="8px" className="sub-text text-faded">
                          {formatDate()}

                          <div>1 seat</div>
                        </Box>
                      </Box>

                      <Box className="" marginTop="16px">
                        <Box marginBottom="8px" className="sub-text">
                          <Box className="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <img alt="circle" src={EmptyCircle} />

                              <p className="sub-text-14 ml-2">
                                {selectedTripResponse[0].destinationLocationId ===
                                  undefined
                                  ? getStartLocation()
                                  :
                                  "Home"}
                              </p>
                            </Box>
                          </Box>

                          <Box
                            color="#E0E0E0"
                            className="center"
                            justifyContent="space-between"
                            marginLeft="10px"
                          >
                            |
                          </Box>

                          <Box className="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <img alt="circle" src={LocationPin} />

                              <p className="sub-text-14 ml-2">
                                {selectedTripResponse[0].destinationLocationId ===
                                  undefined
                                  ?
                                  "Home"
                                  : getDestLocation()}
                              </p>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        className="between p-05"
                        style={{ background: "rgba(245, 245, 245, 1)" }}
                      >
                        <Box className="sub-text-14 driver-name">Total price</Box>

                        <Box className="sub-text-14 driver-name">
                          £{calculateTotalPrice().toFixed(2)}
                        </Box>
                      </Box>
                    </Box>

                    <Box className="bg-white pb-1 flex-1">
                      <div className="carpool-offer-header mb-3 p-1">
                        <p className="heading">Seat bookings</p>

                        <Link
                          to={`/redirect-page/find-carpool-list/${base64Data}`}
                          className="sub-text-14 primary-color cursor-pointer decoration-none"
                        >
                          Edit
                        </Link>
                      </div>

                      {selectedInvites.map((invite, index) => (
                        <Box
                          key={index}
                          display="flex"
                          flexDirection="column"
                          className="user-detail mx-3"
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignSelf="flex-start"
                          >
                            
                              <img
                                alt="profile"
                                src={
                                  invite.tripDetail._driver.photo
                                    ? invite.tripDetail._driver.photo
                                    : DefaultProfile
                                }
                                height="44px"
                                width="44px"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = DefaultProfile;
                                }}
                                className="rounded-full bordered-2 shadow-light object-fit-cover cursor-pointer"
                                onClick={() => setUserBasicDetail({ showUserDetail: true, userDetail: invite.tripDetail._driver, locationName: getOrganizationName(invite?.locationDetail, {userDetail: invite.tripDetail._driver}), organisationLogo: getOrganisationLogo(invite?.locationDetail, {userDetail: invite.tripDetail._driver}) })}
                              />
                          
                            <Box marginLeft="18px" className="info">
                              <p className="sub-text-14 driver-name d-flex items-center gap-05">
                                {capitalizeFirstLetter(
                                  getName(invite.tripDetail._driver.firstName, "EN")
                                )}{" "}
                                {capitalizeFirstLetter(
                                  getName(invite.tripDetail._driver.lastName, "EN")
                                )}

                                {
                                  !invite?.locationDetail?.verificationType.includes("NONE") && (
                                    <img
                                      src={
                                        invite.driverDetail.isVerified
                                          ? userVerified
                                          : userNotVerified
                                      }
                                      alt="user-verify-status"
                                    />
                                  )
                                }

                                {
                                  invite.tripDetail._driver.isDriverVerified && (
                                    <img
                                      src={CarStearingIcon}
                                      alt="driver verified"
                                    />
                                  )
                                }
                              </p>

                              <Box display="flex" marginY="4px">
                              </Box>

                              <Box
                                marginBottom="8px"
                                className="sub-text text-faded font-700"
                              >
                                {invite.tripDetail.destinationLocationId ===
                                  undefined ? (
                                  <>
                                    {forMattDate(
                                      invite.estimatedDropTime as string,
                                      "pickUp"
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {forMattDate(
                                      invite.estimatedPickUpTime as string,
                                      "dropOff"
                                    )}
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              backgroundColor: "#F5F5F5",
                              height: "34px",
                              display: "flex",
                              alignItems: "center",
                              padding: "4px 8px",
                              justifyContent: "space-between",
                              borderRadius: "4px",
                              marginLeft: "3rem",
                              boxSizing: "border-box",
                              width: "fill-available",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePriceDetailClick(invite)}
                          >
                            <Box>
                              <b>Price</b>
                            </Box>
                            <Box className="d-flex justify-center items-center">
                              <b className="mr-2">
                                £{invite.perSeatPrice.toFixed(2)}
                              </b>
                              <img src={arrow} alt="arrow" />
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    {paymentLoading && <LoadingScreen />}

                    {/* <Box className="next-btn-box button-wrapper mt-auto">
                      <Box className="margin-x d-flex justify-center items-center">
                        <Button
                          className="next-btn custom-btn btn-width btn-text"
                          fullWidth
                          onClick={handlePayNow}
                        >
                          Pay Now
                        </Button>
                      </Box>
                    </Box> */}
                    <CustomFixedButton text={'Pay Now'} handleSubmit={handlePayNow} disable={false}/>
                  </>
                ) : (
                  <>
                    <div className="text-center margin-y">
                      <LoadingScreen />
                    </div>

                    <Box className="next-btn-box button-wrapper mt-auto">
                      <Box className="margin-x">
                        <Button
                          className="next-btn custom-btn btn-text"
                          fullWidth
                          onClick={handlePayNow}
                        >
                          Pay Now
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            }
          </>
        )
      }


      {/* show price detail */}
      <CustomDrawer
        anchor="bottom"
        isOpen={priceDetailDrawer}
        onClose={() => setPriceDetailDrawer(false)}
      >
        <div className="selected-user-image-container d-flex justify-center items-center mb-3">
          <img
            className="selected-user-image"
            src={userDetail.image}
            alt="user"
          />
        </div>
        <div className="selected-user-name d-flex justify-center items-center heading">
          <p>{userDetail.name}</p>
        </div>
        <div className="other-details-container-wrapper p-1 mb-3">
          <DetailRow
            rowTitle="Fuel contribution"
            rowAmount={`£${userDetail.fuelPrice.toFixed(2)}`}
          />
          {(userDetail.parkingFee > 0) &&
            <DetailRow
              rowTitle="Parking contribution"
              rowAmount={`£${Number(userDetail.parkingFee).toFixed(2)}`}
            />
          }
          {(userDetail.discount > 0) &&
            <DetailRow
              rowTitle="Discount"
              rowAmount={`${userDetail.discount}% (£${(
                (userDetail.discount *
                  (userDetail.fuelPrice + userDetail.parkingFee)) /
                100
              ).toFixed(2)}
            )`}
            />
          }
          <DetailRow
            rowTitle="Service fee"
            rowAmount={`£${userDetail.serviceFee.toFixed(2)}`}
          />
          <DetailRow
            rowTitle={<b>Total Price</b>}
            rowAmount={`£${userDetail.totalPrice.toFixed(2)}`}
          />
        </div>
      </CustomDrawer>

      <UserProfile 
        isOpen={userBasicDetail.showUserDetail}
        onClose={() => setUserBasicDetail(prev => ({...prev, showUserDetail: false}))}
        userDetail={{...userBasicDetail.userDetail, locationName: userBasicDetail.locationName, organisationLogo: userBasicDetail.organisationLogo}}
      />
    </div>
  );
};
export default FindCarPoolConfirmSeat;
