import Header from "../../../../components/header"
import { Button } from "@mui/material"
import SubText from "../../Common components/SubText"
import './style.scss'
import { useLocation } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"
import { getName } from "../../../../utilities/helper"
import { getLocationPublic } from "../../../../services/location"
import LoadingScreen from "../../../../components/loading-screen"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setLocationAdminSignUp, setLocationDetail } from "../../../../slice/utility"
interface IStates {
  loading: boolean,
  locationName: string
}
const PrivateSignUp = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [state, setStates] = useState<IStates>({
    loading: true,
    locationName: ""
  })

  // remove any redux persisted data
  localStorage.removeItem('persist:root')

  useEffect(() => {
    getLocationPublic(id as string)
      .then((res) => {
        setStates((prev) => {
          return {
            ...prev,
            locationName: getName(res.locationName, "EN") as string,
            loading: false,
          }
        })
        dispatch(setLocationDetail(res))
      }  
      )
      .catch(() =>
        setStates((prev) => {
          return {
            ...prev,
            loading: false,
          };
        })
      );

      if(queryParams.get("admin")){
        let data = {
          locationId: id,
          locationName: state.locationName,
          admin : true
        }
        dispatch(setLocationAdminSignUp(true))
        navigate('/eligibility-check', { state: data })
      }
    // eslint-disable-next-line
  }, [id]);

  const handleClick = () => {
    let data = {
      locationId: id,
      locationName: state.locationName
    }
    navigate('/eligibility-check', { state: data })
  }


  return (
    <Fragment>
      {state.loading ? (
        <LoadingScreen />
      ) : (
        <div id="private-community">
          <Header type="signup" privateSignUp={true} signUp={true} />
          <div className="content-lower">
              <div className="private-community-main-wrapper p-1">
                <div className="private-community-signup heading text-center">
                  <p>Join {state.locationName} carpool community</p>
                </div>
                <div className="private-community-sub-text text-center text-sm-2">
                  <p>
                    Powered by EcoPooling Ltd, the next-generation eco-friendly
                    carpooling solution for schools, universities, commuters and
                    communities.
                  </p>
                </div>
              </div>
              <div className="sign-up-button d-flex flex-column justify-center items-center my-3 padding-x">
                <Button
                  className="next-btn custom-btn btn-width px-15-rem"
                  fullWidth
                  onClick={handleClick}
                >
                  Sign up
                </Button>
              </div>
              <div className="footer-text ml-1">
              <SubText margin="mt-0"/>
              </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default PrivateSignUp
