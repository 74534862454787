import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { updatePaymentIntent } from "../../../services/payment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import LoadingScreen from "../../../components/loading-screen";

const FinderBookSeatPaymentHandler = () => {
  const [initialRender, setInitialRender] = useState(true);
  const navigate = useNavigate();

  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  const clearLocalStorage = () => {
    localStorage.removeItem("transactionId");
    localStorage.removeItem("transactionIdByStripe");
    localStorage.removeItem("_trip");
  };
  const transactionId = localStorage.getItem("transactionId");
  const transactionIdByStripe = localStorage.getItem("transactionIdByStripe");
  let _trip = localStorage.getItem("_trip");
  if (_trip !== undefined) {
    _trip = JSON.parse(_trip as string);
  }
  const handleAfterEffect = async () => {
    if (initialRender) {
      console.log({
        _id: transactionId,
        transactionId: transactionIdByStripe,
        paymentStatus: "SUCCESS",
        _trip,
        type: "finder",
      });
      try {
        await updatePaymentIntent({
          _id: transactionId,
          transactionId: transactionIdByStripe,
          paymentStatus: "SUCCESS",
          _trip,
          type: "finder",
        });
        enqueueSnackbar("success", { variant: "success" });
        clearLocalStorage();
        navigate("/dashboard/upcoming");
      } catch (error: any) {
        const msg = error.response.data?.message ?? "Something went wrong !";
        enqueueSnackbar(msg, { variant: "error" });
        clearLocalStorage();
        navigate("/dashboard/upcoming");
      }
    }
  };
  useEffect(() => {
    if (userDetailsReducer.user.email !== "") {
      console.log("2222222222222222222222222222222222222");
      handleAfterEffect();
      setInitialRender(false);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div id="matches">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
      />
      <Box className="text-center margin-y">
        <LoadingScreen />
        Seat Booking In Progress Please Wait...
      </Box>
    </div>
  );
};
export default FinderBookSeatPaymentHandler;
