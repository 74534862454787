import { httpRequest } from "../network";

export const sendOTP = (payload:object) => {
    return httpRequest("POST","/configuration/public/location/check-email",payload)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}

export const verifyOTP = (payload:object) => {
    return httpRequest("POST","/configuration/public/location/verify-otp",payload)
    .then((res)=> res)
    .catch((err)=> {
        throw err
    })
}