import React from 'react';
import { cancelTransactionLogo } from '../../../../../assets/images';
import MatchResultListItem from '../../../Common components/matched-result-list';
import { bellIcon as BellIcon } from '../../../../../assets/images';
import "./style.scss"
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import CustomFixedButton from '../../../../../components/custom-fixed-button';

const PaymentCancelled = () => {

  const location = useLocation()
  const navigate = useNavigate()

  return (
    <>
      {
        !location.state || !location.state.redirect ? (
          <Navigate to="/" />
        ) : (
          <div
            id="payment-cancelled"
            className="d-flex flex-column p-1 pb-2"
          >
            <div className="flex-1 d-flex justify-center items-center flex-column">
              <div className="mb-3">
                <img src={cancelTransactionLogo} alt="cancel-transaction" />
              </div>
              <p className="fw-700 text-lg mb-3">Cancelled Transaction</p>
              <div className="flex justify-center items-center cancel_message">
                <MatchResultListItem
                  icon={BellIcon}
                  text="We have successfully cancelled your payment request, no money has been taken from your account."
                />
              </div>
            </div>

            {/* <Box className="d-flex justify-center items-center btn-margin-responsive">
              <Button
                className="next-btn custom-btn btn-width px-15rem"
                fullWidth
                onClick={() => navigate(localStorage.getItem("cancelRedirect"))}
              >
                Done
              </Button>
            </Box> */}

            <CustomFixedButton text={'Done'} handleSubmit={() => navigate(localStorage.getItem("cancelRedirect"))}
              disable={false}
            />
          </div>
        )
      }
    </>
  );
}

export default PaymentCancelled
