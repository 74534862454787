import React, { ReactNode } from "react";

interface props {
  icon: string;
  heading?: string;
  text: string | ReactNode;
  bottomContent?: ReactNode;
}
const MatchResultListItem = ({ icon, heading, text, bottomContent="" }: props) => {
  return (
    <>
      <div className="d-flex flex-column gap-1 p-1 bordered shadow-extra-light rounded-12 mx-3">
        <div className="d-flex gap-1">
          <div>
            <img src={icon} alt="path-icon" />
          </div>

          <div className="text">
            {
              heading && (
                <div className="fw-700 mb-2 text-lrg">{heading}</div>
              )
            }
            {text}
          </div>
        </div>

        {bottomContent}
      </div>

    </>
  );
};

export default MatchResultListItem;
