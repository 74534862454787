import React from 'react'
import "./style.scss"
import ReferAndEarnImage from "../../../assets/images/refer-and-earn.svg"
import { Box,Button } from '@mui/material';
import CheckedCircle from '../../../assets/images/checked-circle.svg';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import CopyIcon from "../../../assets/images/copy-icon.svg"
import shareIcon from "../../../assets/images/shareIcon.svg";
import Header from '../../../components/header';
import ReferralHistory from './referral-history';
//import { useSelector } from 'react-redux';
//import { RootState } from '../../../store';

const CustomTextField = styled(TextField)(() => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#D8DDFD',
        padding: "8px",
        borderRadius: '12px',
        color: '#3142C4',
        fontWeight: "700",
        paddingRight: "14px",
      '& fieldset': {
        borderColor: '#3142C4',
        borderWidth: 1,
        borderStyle: 'dashed',
      },
      '&:hover fieldset': {
        borderColor: '#3142C4',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3142C4',
      },
      '& .MuiInputAdornment-root': {
        '& img': {
            cursor: "pointer"
          },
      },
    }
  }));
  
const referAndEarnDescription = [
    "Invite friends with the referral link",
    "They sign up and book carpool seats",
    "You get 100 eco tokens per booking"
]
const ReferAndEarn = () => {

  //  const {basicDetails} = useSelector((state:RootState)=> state.userDetailsReducer)
    const handleCopy = () => {
        let inputValue = "http://www.ecopooling.com/unique"
          navigator.clipboard.writeText(inputValue)
            .then(() => {
              console.log('Copied to clipboard:', inputValue);
            })
            .catch(err => {
              console.error('Error copying to clipboard:', err);
            });
        };

  return (
    <div id="refer-and-earn">
      <Header/>
      <div className="refer-and-earn-logo-container d-flex justify-center p-1">
        <img src={ReferAndEarnImage} alt="" />
      </div>
      <div className="refer-and-earn-description p-1">
        {referAndEarnDescription.map((texts, key) => (
          <Box
            key={key}
            className="guideline-container center py-05"
            justifyContent="start"
          >
            <div className="checked-circle-image d-flex items-center mr-2">
              <img alt="checked-circle" src={CheckedCircle} />
            </div>

            <p className="text-sm-2">{texts}</p>
          </Box>
        ))}
      </div>
      <div className="referral-link-container p-1">
        <p className="fw-700 mb-1">Referral link</p>
        <CustomTextField
          value="http://www.ecopooling.com/unique"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <img src={CopyIcon} alt="copy-icon" onClick={handleCopy}></img>
            ),
          }}
        />
      </div>
      <div className="d-flex justify-center items-center p-1">
        <Button
          className="next-btn custom-btn btn-width px-15rem"
          type={"submit"}
          fullWidth
        >
          <img src={shareIcon} alt="share" />
          &nbsp; Share
        </Button>
      </div>
      <div className="share-text p-1 text-center text-sm-2">
        Share with your network via social media, chat, SMS or email.
      </div>
      <div className="d-flex justify-center items-center p-1">
        <Button
          className="next-btn custom-btn btn-width px-15rem"
          type={"submit"}
          fullWidth
        >
         Next
        </Button>
      </div>
      <ReferralHistory/>
    </div>
  );
};

export default ReferAndEarn
