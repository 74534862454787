import { Box, Button } from "@mui/material";
import whatsAppBlackIcon from "../../assets/images/whatsapp.svg";
import "./style.scss";

const BackToWhatsAppButton = ({enableHorizontalMargin=false}) => {
  return (<>
    <Box className={`back-to-whatsapp-button-container ${enableHorizontalMargin ? 'horizontal-margin' : ''}`}>
      <Button className="custom-back-to-whatsapp-button" onClick={() => {
          window.open(
            `${process.env.REACT_APP_WHATSAPP_SIGN_ME_IN_URL}/?text=${encodeURIComponent("Hi")}`,
            "_blank"
          );
        }}> 
        <img src={whatsAppBlackIcon} alt="wa-icon" /> &nbsp;
        Back to WhatsApp
      </Button>
   </Box>
  </>)
}

export {BackToWhatsAppButton}