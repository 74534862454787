import { createSlice } from "@reduxjs/toolkit";

interface IDecodedData {
  jti: string,
  redirect: string,
  type: string,
  locationId: string,
  tripIds?: string[],
  userId?: string,
  driverId?: string
}

const initialState = {
  decodedData: {} as IDecodedData,
  decodedDataLoading: true,
}

const decodedDataSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setDecodedData: (state, action) => {
      state.decodedData = action.payload;
    },
    setDecodedLoading: (state, action) => {
      state.decodedDataLoading = action.payload
    },
    resetDecodedData: (state,action) => {
     state.decodedData = {} as IDecodedData
    }
  }
}
)

export const {
  setDecodedData,
  setDecodedLoading,
  resetDecodedData
} = decodedDataSlice.actions;

export default decodedDataSlice.reducer
