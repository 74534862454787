import React, { useEffect, useState } from "react";
import {
  getApplicablePromos,
  makeGiftCardPayment,
  //  checkPaymentStatus,
  purchaseUsingToken,
} from "../../../services/eco-saving";
import { setTokenNumberAndValue } from "../../../slice/marketplace";
import { Box, Button } from "@mui/material";
// import OfferCards from "../../../components/offer-cards";
import { useLocation } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import LoadingScreen from "../../../components/loading-screen";
import { paymentButtonHandler } from "../../../utilities/helper";
import "./style.scss";
import CustomFixedButton from "../../../components/custom-fixed-button";
import { greenDiscount, leafFilled } from "../../../assets/images";

interface props {
  text: string;
}

interface IDetailProps {
  rowTitle: string | React.ReactNode;
  rowAmount: string | number;
  borderBottom?: boolean;
}

const promoCodeValue = 0.35;

export const Tag = ({ text }: props) => {
  return (
    <div
      style={{
        height: "12px",
        width: "84px",
        borderRadius: "100px",
        backgroundColor: "#E0E0E0",
        padding: "8px 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        lineHeight: "18px",
      }}
    >
      {text}
    </div>
  );
};

export const DetailRow = ({ rowTitle, rowAmount, borderBottom=true }: IDetailProps) => {
  const hasBorderBottom = (typeof rowTitle === "string") && borderBottom;
  return (
    <div className="padding-50 padding-x"
      style={{
        height: "53px",
        display: "flex",
        alignItems: "center",
        borderBottom: hasBorderBottom ? "2px solid #EEEEEE" : "none",
      }}
    >
      <div style={{ width: "50%" }}>{rowTitle}</div>
      <div style={{ width: "50%", textAlign: "right", fontWeight: "700" }}>
        {rowAmount}
      </div>
    </div>
  );
};

export const getAvailabilityTags = (availability: string) => {
  if (availability === 'ONLINE') {
    return <Tag text="Online" />;
  } else if (availability === 'STORE') {
    return <Tag text="In Store" />;
  } else if (availability === 'BOTH') {
    return (
      <>
        <Tag text="Online" />
        <Tag text="In Store" />
      </>
    );
  } else {
    // Return a default tag or null if needed
    return null;
  }
};
const CardDetail = ({ showRedemptionDetails, setShowRedemptionDetails, setShowPaymentDetails, showLoader }) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [tokensUsed, setTokensUsed] = useState(false)
  const [ecoTokensValue, setEcoTokensValue] = useState(0)
  // const [showRedemptionDetails, setShowRedemptionDetails] = useState(false)

  const boldTitle = <b>Amount left to pay</b>;

  const location = useLocation();

  const giftData = location.state || JSON.parse(localStorage.getItem("giftData"));

  const [promos, setPromos] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { marketPlaceReducer, userDetailsReducer } = useSelector((state: RootState) => state);

  const { tokens } = marketPlaceReducer;

  // set the eco token value which can be redeemed in the state to use later
  useEffect(() => {
    if(giftData.ecoTokens) {
      setEcoTokensValue(() => {
        if(giftData.ecoTokens === 0) return 0

        if(giftData.ecoTokens * 0.01 >= giftData.card_value) return giftData.card_value

        return giftData.ecoTokens * 0.01
      })
    }
    if (userDetailsReducer.basicDetails.marketPlaceAccess !== 'full-access') {
      setTokensUsed(true);
    }
    // eslint-disable-next-line
  }, [])

  const purchaseGiftCard = () => {
    // setLoading(true)
    showLoader(true)

    const payload: { [key: string]: string | number | object } = {
      discount: giftData.discount,
      giftUniqueId: giftData.giftUniqueId,
      actualAmount: giftData.card_value,
      promos: promos.map(promo => promo._id)
    };

    if (giftData.ecoTokens > 0 && tokensUsed) {
      payload.ecoTokens = ecoTokensValue * 100;
    }

    const amountToPay = calculateFinalPrice();

    // const tilloDiscountValue = ((giftData.tilloDiscount/100) || 0) * giftData.card_value
    // const ecopoolingDiscountValue = ((giftData.discount/100) || 0) * giftData.card_value

    // calculate tillo service fee
    // const cardValueAfterTilloDiscount = (giftData.card_value - tilloDiscountValue)
    // const tilloServiceFee = cardValueAfterTilloDiscount * (0.75/100)
    const tilloServiceFee = giftData.card_value * (0.75/100)

    if (amountToPay === 0) {
      payload._user = giftData._user;
      payload.transactionType = "GIFT";
      payload.transactionThrough = "MANUAL";
      payload.amount = { totalAmount: 0 };
      payload._gift = giftData.giftId;
      payload.paymentStatus = "SUCCESS";

      payload["tilloServiceFee"] = Number(tilloServiceFee.toFixed(3))

      // calculate ecopooling commission by subtracting the amount to give to tillo from the amount we get
      // payload["ecopoolingCommission"] = Number(((giftData.card_value - ecopoolingDiscountValue) - (cardValueAfterTilloDiscount + tilloServiceFee)).toFixed(3))
      payload["ecopoolingCommission"] = Number((giftData.card_value * (((giftData.tilloDiscount/100) || 0) - ((giftData.discount/100) || 0) - (0.75/100))).toFixed(3))

      purchaseUsingToken(payload)
        .then((res: any) => {
          dispatch(
            setTokenNumberAndValue({ ecoTokens: tokens - giftData.ecoTokens })
          );

          if(giftData.deliveryType && giftData.deliveryType === "AUTO") {
            localStorage.setItem("giftData", JSON.stringify(location.state));

            // store transaction uniqueId to use later
            localStorage.setItem("transactionUniqueId", res.data.uniqueId)

            navigate("/payment-submitted", {state: {redirect: "marketplace"}})
          } else {
            navigate("/dashboard/market-place/payment-done");
          }

          return;
        })
        .catch(() => {
          enqueueSnackbar("Something went wrong, try again in sometime", {
            variant: "error",
          });

          return;
        }).finally(() => {
          // setLoading(false)
          showLoader(false)
        });
    } else {
      payload["amount"] = amountToPay;
      payload["giftId"] = giftData.giftId;

      payload["tilloServiceFee"] = Number(tilloServiceFee.toFixed(3))

      // calculate ecopooling commission by subtracting the amount to give to tillo from the amount we get
      // payload["ecopoolingCommission"] = Number(((giftData.card_value - ecopoolingDiscountValue) - (cardValueAfterTilloDiscount + tilloServiceFee)).toFixed(3))
      payload["ecopoolingCommission"] = Number((giftData.card_value * (((giftData.tilloDiscount/100) || 0) - ((giftData.discount/100) || 0) - (0.75/100))).toFixed(3))

      makeGiftCardPayment(payload)
        .then((res: any) => {
          // store cancel redirect and failure redirect links in the local storage
          localStorage.setItem("cancelRedirect", "/dashboard/market-place");

          localStorage.setItem("failureRedirect", location.pathname);

          // store gift data in the local storage to show this page after failure redirect
          // localStorage.setItem("giftData", JSON.stringify(location.state));

          if(giftData.deliveryType && giftData.deliveryType === "AUTO") {
            // store transaction uniqueId to use later
            localStorage.setItem("transactionUniqueId", res.data.uniqueId)
          } 

          paymentButtonHandler(res, true);

          dispatch(
            setTokenNumberAndValue({ ecoTokens: tokens - giftData.ecoTokens })
          );
        })
        .catch(() => {
          enqueueSnackbar("Something went wrong, try again in sometime", {
            variant: "error",
          });

          // setLoading(false)
          showLoader(false)
        })
    }
  };

  const promosCount = async () => {
    try {
      setLoading(true)
      const promos = await getApplicablePromos(calculateFinalPrice());
      setPromos(promos);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    promosCount();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    promosCount();
    // eslint-disable-next-line
  }, [tokensUsed]);
  // set the state to identify if use tokens button is clicked or not
  const toggleTokens = () => {
    setTokensUsed(!tokensUsed)
  }

  // function to calculate discount with and without tokens
  const calculateDiscount = () => {
    if(tokensUsed) {
      return ((giftData.card_value - ecoTokensValue) * giftData.discount) / 100
    } else {
      return (giftData.card_value * giftData.discount) / 100
    }
  }

  // calculate final price after accomodating tokens and discount
  const calculateFinalPrice = () => {
    let afterTokenValue = giftData.card_value

    if(tokensUsed) {
      afterTokenValue = giftData.card_value - ecoTokensValue
    }

    // round off to two decimal places such that third decimal place greater than or equal to 5 will be ceiled for second decimal place
    // eg: 0.975 will become 0.98, and 0.974 will become 0.97
    return Number(
      Number(
        afterTokenValue - 
        calculateDiscount() - 
        (promos.length * promoCodeValue)
        ).toLocaleString("en", {
          useGrouping: false,
          maximumFractionDigits: 2
        })
      );
  }

  const countOf35OffCoupons = () => {
    return promos.length;
  }

  return (
    <div className="card-details-wrapper">

      {
        loading && <LoadingScreen />
      }

      {/* <div className="card-header-contents" style={{ display: "flex" }}>
        <div className="back-button-icon" style={{ padding: "4px", cursor: 'pointer' }} onClick={() => showRedemptionDetails ? setShowRedemptionDetails(false) : 
          // navigate("/dashboard/market-place", { state: { selectedGiftCard: JSON.parse(localStorage.getItem("selectedGiftCard"))}})
          setShowCardDetails(false)
          }>
          <img src={backArrow} alt="back-button" />
        </div>

        <div
          className="back-icon-text"
          style={{ padding: "4px", fontWeight: "700" }}
        >
          Back
        </div>
      </div> */}

      <div className="card-details-new-wrapper">
        <Box className="purchase-history-drawer-main">
          {/* <OfferCards
            logo={giftData.card_logo}
            text={`${giftData.discount}% OFF`}
          />
          <Box className="amount-container" sx={{ marginTop: "16px" }}>
            <b>{giftData.name}</b>
          </Box>

          <Box className="mb-3" style={{ display: "flex", gap: "10px" }}>
            {getAvailabilityTags(giftData.availability)}
          </Box> */}
          <h2>Purchase summary</h2>

          {
            !showRedemptionDetails && (
              <>
                {
                  giftData.ecoTokens >= 2000 ? (
                    <Button variant="outlined" className="token-button" onClick={toggleTokens} disabled={
                      userDetailsReducer.basicDetails.marketPlaceAccess !== 'full-access'
                    }>
                      {
                        tokensUsed ? "Remove tokens" : (
                          <>
                            Use {" "}£
                            {
                              (ecoTokensValue) >= giftData.card_value ? (
                                <>
                                  {giftData.card_value}
                                </>
                              ) : (
                                <>
                                  {Number(ecoTokensValue).toFixed(2)}
                                </>
                              )
                            }
                            {" "} tokens
                          </>
                        )
                      }
                    </Button>
                  ) : (
                    <></>
                    // <div className="p-1 width-100 bordered-light rounded-12 shadow-extra-light mt-3 d-flex items-center gap-05" style={{marginLeft: ".5rem", marginRight: ".5rem", boxSizing: "border-box"}}>
                    //   <img src={leafIcon} alt="leaf" />
                    //     Minimum token spend £20
                    // </div>
                  )
                }
              </>
            )
          }
        </Box>

          {
            showRedemptionDetails ? (
             <>
              <p className="font-700 mb-4 text-center">
                Before you proceed
              </p>

              <div className="d-flex gap-1 shadow-extra-light rounded-12 p-1 mb-2 mx-3 overflow-hidden">
                {/* <div>
                  <img src={exclamationIcon} alt="redemption guidelines" />
                </div> */}

                <div className="text-md" dangerouslySetInnerHTML={{__html: giftData?.notes || "No instructions available"}}>
                </div>
              </div>

              <Box
                className="text-center mb-3 pt-1"
                style={{
                  fontWeight: "400",
                  lineHeight: "15px",
                  fontSize: "14px",
                }}
              >
                <a
                  style={{ textDecoration: "none", color: "#3142C4" }}
                  href={giftData.termsURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and conditions
                </a>
              </Box>

              <Box
                className="text-center mb-4"
                style={{
                  fontWeight: "400",
                  lineHeight: "15px",
                  fontSize: "14px",
                }}
              >
                <a
                  style={{ textDecoration: "none", color: "#3142C4" }}
                  href={giftData.redemptionInstructionsUrl || giftData.termsURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Redemption details
                </a>
              </Box>
             </> 
            ) :(
              <Box className="mt-2">
                <DetailRow
                  rowTitle="Gift card price"
                  rowAmount={`£${giftData.card_value.toFixed(2)}`}
                  borderBottom={(tokensUsed || (countOf35OffCoupons() > 0)) ? false : true}
                />

                {
                  (tokensUsed || (countOf35OffCoupons() > 0)) && (
                    <p className="text-gray text-700 text-md mt-1 mb-1 padding-x">
                    Discounts & promo codes
                    </p>
                  )
                }

                {
                  // tokensUsed &&
                  // <DetailRow
                  //   rowTitle="Tokens redeemed"
                  //   rowAmount={`${(ecoTokensValue * 100)} (£${(
                  //     ecoTokensValue
                  //   ).toFixed(2)})`}
                  // />
                  tokensUsed && 
                    <DiscountRow 
                      icon={leafFilled}
                      description={'Tokens Redeemed'}
                      price={ecoTokensValue}
                    />
                }

                {
                  countOf35OffCoupons() > 0 && 
                  <DiscountRow
                    icon={greenDiscount}
                    description={countOf35OffCoupons() > 1 ? `${countOf35OffCoupons()} x 35p Off £35 promo ` :
                    '35p Off £35 promo'}
                    price={Number(countOf35OffCoupons() * promoCodeValue).toFixed(2)}
                   />
                }

                {
                  calculateFinalPrice() > 0 && (
                    <DetailRow
                      rowTitle="Gift card discount"
                      // if third decimal digit of discount value is '5', then truncate the last digit so that second decimal digit is not rounded off to ceil
                      // eg: 0.975 will become 0.97
                      // other values will be taken care by toFixed(2) appropriately
                      rowAmount={`${giftData.discount}% (£${ calculateDiscount().toFixed(3).slice(-1) === '5' ? calculateDiscount().toString().slice(0, -1) : calculateDiscount().toFixed(2) })`}
                    />

                  )
                }

                <DetailRow
                  rowTitle={boldTitle}
                  rowAmount={`£${(Math.round(Number(Number(calculateFinalPrice()).toLocaleString("en", {
                    useGrouping: false,
                    maximumFractionDigits: 2,
                  })) * 100) /100).toFixed(2)}`}
                />
              </Box>
            )
          }
      </div>

      {
        !showRedemptionDetails && 
        (userDetailsReducer.basicDetails.marketPlaceAccess === 'full-access') && 
        (giftData.notes !== undefined) && 
        (giftData.notes !== null) && 
        (giftData.notes?.trim() !== "") ? (
          // <Box 
          // className="marketplace-card-next-button-container"
          // style={{
          //   position: "fixed",
          //   bottom: "0",
          //   left: "0",
          //   right: "0",
          //   width: "100%",
          //   background: "#fff"
          // }}
          // >
          //   <Box className="d-flex justify-center items-end flex-1 " style={{ padding: "0 1.5rem"}}>
          //   <Button
          //     className="next-btn custom-btn btn-width btn-text"
          //     fullWidth
          //     onClick={() => {
          //       if((giftData.notes !== undefined) && (giftData.notes !== null) && (giftData.notes?.trim() !== "")) {
          //         setShowRedemptionDetails(false)
          //       }
          //     }}
          //   >
          //     Next
          //   </Button>
          // </Box>
          // </Box>
            <CustomFixedButton
              handleSubmit={() => {
                if ((giftData.notes !== undefined) && (giftData.notes !== null) && (giftData.notes?.trim() !== "")) {
                  setShowRedemptionDetails(true)
                }
              }}
              disable={loading}
              text={'Next'}
              isDrawerButton={true}
            />
        ) : (
          // <Box 
          // className="marketplace-card-next-button-container"
          // style={{
          //   position: "fixed",
          //   bottom: "0",
          //   left: "0",
          //   right: "0",
          //   width: "100%",
          //   background: "#fff"
          // }}
          // >
          //   <Box className="d-flex justify-center items-end flex-1 " style={{ padding: "0 1.5rem"}}>
          //   <Button
          //     className="next-btn custom-btn btn-width btn-text"
          //     fullWidth
          //     onClick={() => {purchaseGiftCard()}}
          //     disabled={userDetailsReducer.basicDetails.marketPlaceAccess !== 'full-access' && giftData.ecoTokens < 2000}
          //   >
          //     {
          //       calculateFinalPrice() === 0 || userDetailsReducer.basicDetails.marketPlaceAccess !== "full-access" ? 
          //       "Redeem tokens now" :
          //       "Pay now"
          //     }
          //   </Button>
          // </Box>
          // </Box>
            <CustomFixedButton
              text={
                calculateFinalPrice() === 0 || userDetailsReducer.basicDetails.marketPlaceAccess !== "full-access" ?
                  "Redeem tokens now" :
                  "Pay now"
              }
              disable={userDetailsReducer.basicDetails.marketPlaceAccess !== 'full-access' && giftData.ecoTokens < 2000}
              handleSubmit={purchaseGiftCard}
              isDrawerButton={true}
            />
        )
      }

    </div>
  );
};

const DiscountRow = ({icon, description, price}) => {
  return (
    <Box className="gift-card-discount-row">
      <div className="discount-row-left">
        <img src={icon} alt={"icon"}/>
        <p>&nbsp;{description}</p>
      </div>
      <b>(£ {Number(price).toFixed(2)})</b>
    </Box>
  )
}

export default CardDetail;
