import { httpRequest } from "../network";

export interface FavLocationPayLoadInterface {
  _favoriteLocation: string,
  schedule: {
    day: Array<string>
    dropOff: string | null | undefined
    pickOff: string | null | undefined
  }

}

interface ScheduleInterface {
  day: string
  dropOff: string
  pickUp: string,
}

export interface FavLocationPayLoadInterface1 {
  _favoriteLocation: string,
  _categoryId: string
  dailySchedule: {
    dropOffFlexibility: string
    pickUpFlexibility: string
    schedule: Array<ScheduleInterface>
  }
}

export interface pickUpAndDropInterface {
  draftId: string
  favouriteLocations: FavLocationPayLoadInterface[]
}

export interface pickUpAndDropInterface1 {
  draftId: string
  favouriteLocations: FavLocationPayLoadInterface1[]
}

export interface pickUpAndDropUpdateInterface {
  favouriteLocations: FavLocationPayLoadInterface1[]
}

export const getCategory = () => {
  return httpRequest('GET', '/configuration/public/category').then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const getSingleCategory = (categoryId: string) => {
  return httpRequest('GET', `/configuration/location/${categoryId}`).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const getCategoryByLocationId = (locationIds: string[]) => {
  return httpRequest('POST', `/configuration/location/by-loc`, { locationIds }).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const getFavLocationOfUser = () => {
  return httpRequest('GET', '/auth/favourite-location/user').then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}
export const updateFavLocationOfUser = (payload: object) => {
  return httpRequest('POST', '/auth/favourite-location/user', payload).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}


export const updatePickAndDrop = (data: pickUpAndDropInterface1) => {
  return httpRequest('POST', '/auth/signup/favourite-location-daily', data).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

// update favourite location of draft user
export const updateDraftFavouriteLocation = (data: object) => {
  return httpRequest('POST', '/auth/signup/favourite-location', data).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const updatePickAndDropUser = (data: pickUpAndDropUpdateInterface) => {
  return httpRequest('POST', '/auth/favourite-location/user/daily', data).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const getLocation = (id: string) => {
  return httpRequest('GET', `/configuration/loc/${id}`).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

// update location admin
export const updateLocationAdmin = (locationId: string, adminId: string) => {
  return httpRequest('PUT', `/configuration/loc/admin/${locationId}`, {adminId}).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const getLocationPublic = (id: string) => {
  return httpRequest('GET', `/configuration/public/location/${id}`).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const getPopulatedFavLocationsOfUser = () => {
  return httpRequest("GET","/auth/favourite-location/user/populated")
  .then((res)=> res)
  .catch((er)=> {
    throw er
  })
}

export const activateCommunity = (id:string) => {
  return httpRequest("PUT",`/configuration/public/activate-community/${id}`,{isActivated: true})
  .then((res) => res)
  .catch((er)=> {
    throw er
  })
}

// approve or add community member
export const communityMemberRequest = (locationId: string, userIds: string[]) => {
  return httpRequest("PUT",`/configuration/loc/community-member-requests/${locationId}`,{ userIds })
  .then((res) => res)
  .catch((er)=> {
    throw er
  })
}

export const getMembersOfLocation = (id: string) => {
  return httpRequest('GET', `/configuration/loc/members-populated/${id}`).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const createInviteAlertsSchedule = (payload: {_draft: string, inviteAlertsSchedule: object[]}) => {
  return httpRequest('POST', '/auth/signup/invite-alerts-schedule', payload).then((response: any) => {
    return response.data;
  }).catch((err) => {
    throw err;
  })
}

export const updateInviteAlertsSchedule = (payload: {inviteAlertsSchedule: object[]}) => {
  return httpRequest('PUT', '/auth/favourite-location/user/invite-alerts-schedule', payload).then((response: any) => {
    return response.data;
  }).catch((err) => {
    throw err;
  })
}