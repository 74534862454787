import { Box, Button } from '@mui/material'
import Header from '../../../../components/header'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import DefaultUserIcon from "../../../../assets/images/defaultUser.svg";
import BellIcon from "../../../../assets/images/bell-icon.svg";
import ToggleSwitch from "../../../../components/switch";
import CustomDatePicker from '../../../../components/time-picker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CustomFixedButton from '../../../../components/custom-fixed-button';
import './style.scss';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { createInviteAlertsSchedule, getFavLocationOfUser, updateInviteAlertsSchedule } from '../../../../services/location';
import LoadingScreen from '../../../../components/loading-screen';

const InviteAlertsPage = () => {
  const page = window.location.pathname.includes('signup') && "signup";
  const signUpDetails = useSelector((state: RootState) => state.signUpDetails);
  const userDetailsReducer = useSelector((state: RootState) => state.userDetailsReducer);
  const navigate = useNavigate();
  const [entriesCount, setEntriesCount] = useState(5);
  const [alerts, setAlerts] = useState([]);
  const [showLater, setShowLater] = useState(true);
  const [loading, setLoading] = useState(false);

  if (page !== 'signup') {
    localStorage.getItem('token') === undefined && navigate('/');
  }

  useEffect(() => {
    let daysArray = [];
    let n = 2
    while (daysArray.length < entriesCount) {
      // if the day is saturday or sunday, then skip that day
      if (moment().add(n, 'days').day() === 0 || moment().add(n, 'days').day() === 6) {
        n++

        continue
      }

      daysArray.push({
        day: moment().add(n, 'days').format('ddd'),
        date: moment().add(n, 'days').format('Do MMM'),
        arriveBy: moment().set({ hour: 9, minute: 0, second: 0 }).format('HH:mm'),
        departAfter: moment().set({ hour: 17, minute: 30, second: 0 }).format('HH:mm'),
        active: false
      })

      n++
    }

    setAlerts(prev => {
      daysArray.forEach(newAlert => {
        prev.forEach(alert => {
          if (newAlert.date === alert.date) {
            newAlert.date = alert.date;
            newAlert.day = alert.day;
            newAlert.arriveBy = alert.arriveBy;
            newAlert.departAfter = alert.departAfter;
            newAlert.active = alert.active;
          }
        })
      })
      return [...daysArray]
    });
    page !== 'signup' && getInviteAlerts();
  }, [entriesCount, page]);


  const getInviteAlerts = async () => {
    try {
      //TODO: Fetch array of items in a variable using backend API.
      setLoading(true)
      const location = (await getFavLocationOfUser())[0];
      const { inviteAlertsSchedule } = location;
      if (inviteAlertsSchedule !== undefined) {
        setAlerts(prev => {
          inviteAlertsSchedule.forEach(storedAlert => {
            prev.forEach(alert => {
              if (alert.date === storedAlert.date) {
                alert.date = storedAlert.date;
                alert.day = storedAlert.day;
                alert.arriveBy = storedAlert.arriveBy;
                alert.departAfter = storedAlert.departAfter;
                alert.active = true;
              }
            });
          });
          return [...prev];
        });
      }

    } catch (error) {
      enqueueSnackbar(error.message || 'Error occurred while fetching invite alerts schedule', {
        variant: 'error'
      })
    }
    finally {
      setLoading(false);
    }
  }

  const saveInviteAlerts = async () => {
    try {
      setLoading(true)
      const inviteAlertsSchedule = alerts.filter(alert => alert.active === true).map(item => ({ ...item, active: undefined }));
      const payload = {
        inviteAlertsSchedule
      }
      console.log('payload', payload)
      const _draft = localStorage.getItem("draftId");
      page !== 'signup' ? await updateInviteAlertsSchedule({ ...payload }) : await createInviteAlertsSchedule({ ...payload, _draft });
      page !== 'signup' ? navigate("/dashboard/home") : navigate("/signup/summary");

      if(page !== 'signup') {
        enqueueSnackbar('Invite alerts updated successfully', {
          variant: "success"
        });
      }

    } catch (error) {
      enqueueSnackbar(error.message || 'Error occurred while updating invite alerts', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    loading === true ? (
      <LoadingScreen />
    ) : (
      <Box className="pb-150" id="invite-alerts-schedule-container">
        <Header
          canGoBack={true}
          rightIcon={<img alt='profile' className='header-img-icon' src={
            page === 'signup' ? (signUpDetails.basicDetails.photoPath as string) ?? DefaultUserIcon : userDetailsReducer.basicDetails.photo
          } />}
          signUp={page === 'signup' ? true : false}
        />

        {/* *************** main-content header *************** */}
        <Box className="padding-x rounded-top-green">
          <div className="sub-header">
            <div className="sub-header-icon-container">
              <img alt="person" src={BellIcon} />
            </div>

            <b className="title">
              Carpool invite alerts
            </b>
          </div>
        </Box>

        {/* *************** main-content wrapper *************** */}
        <Box className="pt-1 px-1">
          {/* *************** main-content top heading *************** */}
          <h3 className='font-14 invite-alerts-header-text'>
            Select days of week you need a lift
          </h3>

          {/* *************** clock header section *************** */}
          <Box className="d-flex w-66 ml-auto gap-1">
            <b className='w-50 text-md invite-alerts-header-text d-flex'>
              Arrive by
            </b>

            <b className='w-50 text-md invite-alerts-header-text d-flex'>
              Depart after
            </b>
          </Box>


        </Box>
        <Box marginBottom={"1rem"}>
          {
            alerts.map(alert => {
              return (
                <InviteItem item={alert} alerts={alerts} key={alert.date} setState={setAlerts} />
              )
            })
          }
        </Box>
        {showLater && <Box className="invite-alerts-later-container">
          <Button className="invite-alerts-later-button" onClick={() => {
            setEntriesCount(10);
            setShowLater(false);
          }}>Later</Button>
        </Box>}
        <CustomFixedButton text={page === 'signup' ? 'Next' : 'Update'} disable={false} handleSubmit={saveInviteAlerts} />
      </Box>
    )
  )
}

const InviteItem = ({ item, alerts, setState }) => {
  return (
    <Box className="d-flex items-center justify-between gap-1 p-1 pt-0">
      <Box className="d-flex items-center justify-start gap-05 w-50 flex-3">
        <ToggleSwitch
          checked={item.active}
          handleChange={() => {
            alerts.forEach(alert => {
              if (alert.date === item.date) {
                if(alert.active !== true) {

                  if(alert.arriveBy === '') {
                    alert.arriveBy = moment().set({ hour: 9, minute: 0, second: 0 }).format("HH:mm");
                  }

                  if(alert.departAfter === '') {
                    alert.departAfter = moment().set({ hour: 17, minute: 30, second: 0 }).format("HH:mm");
                  }
                }

                alert.active = !alert.active
              }
            });
            setState([...alerts]);
          }}
        />

        <Box className="d-flex flex-column">
          <p>
            {item.day}
          </p>
          <p className='text-sm'>
            {item.date}
          </p>
        </Box>
      </Box>

      <Box className="d-flex items-center justify-end gap-1 w-50 flex-7">
        <CustomDatePicker timeDefault={item.arriveBy} handleChange={val => {
          alerts.forEach(alert => {
            if (alert.date === item.date) {
              alert.arriveBy = moment(val).format("HH:mm");
            }
          });
          setState([...alerts]);
        }} disbled={!item.active}
        showLayover={true}
        showLayoverWithoutDisable={item.arriveBy === ''}
        />

        <CustomDatePicker timeDefault={item.departAfter} handleChange={val => {
          alerts.forEach(alert => {
            if (alert.date === item.date) {
              alert.departAfter = moment(val).format("HH:mm");
            }
          });
          setState([...alerts]);
        }} disbled={!item.active} 
        showLayover={true}
        showLayoverWithoutDisable={item.departAfter === ''}
        />
      </Box>
    </Box>
  )
}

export default InviteAlertsPage;