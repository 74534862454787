import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import "./style.scss";
import Header from "../../../../components/header";
import DefaultUserIcon from "../../../../assets/images/defaultUser.svg";
import VerifiedTickIcon from "../../../../assets/images/verified-tick.svg";
import Person from "../../../../assets/images/person.svg";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { validationSchema } from "./validation";
import { useDispatch } from "react-redux";
import { updateBasicDetails } from "../../../../slice/sign-up";
import {
  mapFormToRequestForBasicDetails,
  uploadUserBasicDetails,
} from "../../../../services/basic-detail";
import { setFacebookCredentials } from "../../../../services/social-login";
import { enqueueSnackbar } from "notistack";
import { getLocationPublic } from "../../../../services/location";
import { basicDetailFileUpload } from "../../../../services/basic-detail-file-upload";
import LoadingScreen from "../../../../components/loading-screen";
import { setFavouriteLocation } from "../../../../services/favourite-location";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomFixedButton from "../../../../components/custom-fixed-button";

const BasicDetail = () => {
  const signUpDetails = useSelector((state: RootState) => state.signUpDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [imageUploading, setImageUploading] = useState(false);
  const [socialId, setSocialId] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  // eslint-disable-next-line
  const [searchParam, setSearchParam] = useSearchParams();
  const [locationDetail, setLocationDetail] = useState<any>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const prevURL = searchParams.get("prev_url");
  const id = searchParams.get("id");
  const draftId = localStorage.getItem("draftId");
  const userName = searchParams.get("userName") ?? "";
  const whatsAppNumber = searchParams.get("whatsAppNumber") ?? "";

  const formik = useFormik({
    initialValues: signUpDetails.basicDetails,

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      setLoading(true);

      try {
        let image_url = imageUrl;

        if(values.profilePicture instanceof File && (values.profilePicture as File).type.split("/")[0] !== "image") {
          enqueueSnackbar("please upload a valid profile photo", { variant: "error" });
          return;
        }

        // if profile picture is uploaded, then do not try to update again
        if(imageUrl === "") {
          if (
            values.profilePicture != null &&
            values.profilePicture instanceof File
          ) {
            image_url = await basicDetailFileUpload(
              values.profilePicture as File
            );
  
            // if profile picture is a url from facebook
          } else if (values.profilePicture != null && values.profilePicture !== "") {
            image_url = values.profilePicture as string;
          } else {
            if (signUpDetails.basicDetails.photoPath === "") {
              enqueueSnackbar("please upload a profile photo", { variant: "error" });
  
              return;
            } else {
              image_url = signUpDetails.basicDetails.photoPath;
            }
          }
        }


        const payload = mapFormToRequestForBasicDetails({
          ...values,
          ...{ photo: image_url },
        });

        dispatch(updateBasicDetails({ ...values, photoPath: payload.photo }));

        // if data fetched from facebook, then set the social id in database
        if(socialId !== "") {
          payload.socialId = socialId
          payload.loginType = "FACEBOOK"
        }

        let userDetails = await uploadUserBasicDetails(payload);

        localStorage.setItem("draftId", userDetails._id);

        const favLocationPayload = {
          draftId: userDetails._id,
          favouriteLocations: [
            {
              _favoriteLocation: locationDetail._id,
              _categoryId: locationDetail.categoryId,
            },
          ],
        };

        await setFavouriteLocation(favLocationPayload);

        if (prevURL) {
          navigate(prevURL);
        } else {

          // check if location is beta, then redirect to carpool-preference page
          if(locationDetail.isBeta) {
            navigate("/signup/carpool-preference")
          } else {
            navigate("/signup/home-location-detail");
          }
        }
      } catch (error: any) {
        const msg = error?.response?.data?.message ?? "Something went wrong";

        enqueueSnackbar(msg, { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
  });

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const [profileString, setProfileString] = useState<string>("");

  useEffect(() => {
    const name = userName.split(' ');
    const firstName = name.shift();
    const lastName = name.join(' ');

    formik.setFieldValue("firstName", firstName);
    formik.setFieldValue("lastName", lastName);
    formik.setFieldValue("phoneNumber", whatsAppNumber);
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = () => {
      setProfileString(reader.result as string);
    };

    if (formik.values.profilePicture instanceof File)
      reader.readAsDataURL(formik.values.profilePicture as File);

    //eslint-disable-next-line
  }, [formik.values.profilePicture]);

  useEffect(() => {
    if (id) {
      localStorage.setItem("favLocation", id);
    }

    const locationId = localStorage.getItem("favLocation");

    getLocationPublic(locationId as string)
      .then((res) => {
        if (!searchParam.get("code")) {
          setLoading(false);
        }

        setLocationDetail(res);

        // store the location name in local storage for later retrieval at what-next screen
        localStorage.setItem("locationName", res.locationName[0].value);
      })
      .catch((err) => console.log(err));

    setLoading(false);

    //eslint-disable-next-line
  }, [draftId]);

  // const setDetailsWithFacebook = async () => {
  //   const url = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FACEBOOK_REDIRECT_URI}&config_id=${process.env.REACT_APP_FACEBOOK_CONFIG_ID}`;
  //   window.open(url, "_self");
  // };

  useEffect(() => {
    //if the url param has fb code then fetch the data from api
    // and populate the form
    if (searchParam.get("code")) {
      setLoading(true);

      setFacebookCredentials({
        code: searchParam.get("code"),
      })
        .then((res) => {
          const {
            data: { name, email, picture },
          } = res;

          setSocialId(res.data.id);

          let [firstName, lastName] = name.split(" ");

          formik.setFieldValue("email", email ?? "");
          formik.setFieldValue("firstName", firstName ?? "");
          formik.setFieldValue("lastName", lastName ?? "");
          formik.setFieldValue("profilePicture", picture.data.url ?? "");

          setProfileString(picture.data.url);

          // disable the loading screen
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          // disable the loading screen
          setLoading(false);

          enqueueSnackbar("Unable to fetch facebook data", {
            variant: "error",
          });
        });
    }

    //eslint-disable-next-line
  }, [draftId]);

  useEffect(() => {
    if (formik.values.phoneNumber[2] === "0") {
      formik.setFieldValue("phoneNumber", formik.values.phoneNumber.slice(0,2))
    }
  }, [formik]);

  return (
    <div id="basic-detail">
      {loading ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <Header
            rightIcon={
              <img
                alt="profile"
                style={{ height: "32px", width: "32px" }}
                className="header-img-icon circle-img shadow-light"
                src={
                  profileString === ""
                    ? (signUpDetails.basicDetails.photoPath as string) !== ""
                      ? (signUpDetails.basicDetails.photoPath as string)
                      : (DefaultUserIcon as string)
                    : profileString
                }
              />
            }
            signUp={true}
          />

          <Box className="padding-x rounded-top-green">
            <div className="sub-header">
              <div className="sub-header-icon-container">
                <img alt="person" src={Person} />
              </div>

              <p className="sub-text-new sub-text font-1-5rem">
                Your details
              </p>
            </div>
          </Box>

          {
            imageUploading && <LoadingScreen />
          }

          {/* <p className="section-head">Your details</p> */}

          {/* facebook button to populate fields */}
          {/* <Box sx={{ width: "100%", padding: "1rem", boxSizing: "border-box" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                padding: "1rem",
                backgroundColor: "rgba(24, 119, 242, 1)",
                fontWeight: "700",
                justifyContent: "flex-start",
                gap: "1rem",
                textTransform: "none",
                fontFamily: "inherit",
              }}
              className="facebook-btn-basic-detail"
              onClick={setDetailsWithFacebook}
            >
              <img src={FacebookIcon} alt="facebook-logo" />
              Complete profile from Facebook
            </Button>
          </Box> */}

          <form onSubmit={formik.handleSubmit}>
            <Box className="margin-x basic-pb-150">
              <Box className="margin-y">
                <label className="custom-label" htmlFor="first_name">
                  First Name
                </label>

                <TextField
                  className="custom-input margin-y-label"
                  id="first_name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  placeholder="Enter your first name"
                  InputProps={{
                    style: {
                      height: "48px",
                    },

                    endAdornment: (
                      <InputAdornment position="start">
                        {signUpDetails.basicDetails.isVerified !==
                          undefined && (
                            <img src={VerifiedTickIcon} alt="verified-tick" />
                          )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box className="margin-y">
                <label className="custom-label" htmlFor="last_name">
                  Last Name
                </label>

                <TextField
                  className="custom-input margin-y-label"
                  id="last_name"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  placeholder="Enter your last name"
                  InputProps={{
                    style: {
                      height: "48px",
                    },

                    endAdornment: (
                      <InputAdornment position="start">
                        {signUpDetails.basicDetails.isVerified !==
                          undefined && (
                            <img src={VerifiedTickIcon} alt="verified-tick" />
                          )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box className="margin-y">
                <label className="custom-label" htmlFor="occupation">
                  Occupation (i.e. Student, Accountant, Retail assistant)
                </label>

                <TextField
                  className="custom-input margin-y-label"
                  id="occupation"
                  name="occupation"
                  value={formik.values.occupation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.occupation && Boolean(formik.errors.occupation)
                  }
                  helperText={formik.touched.occupation && formik.errors.occupation}
                  placeholder="Enter your occupation"
                  InputProps={{
                    style: {
                      height: "48px",
                    },

                    endAdornment: (
                      <InputAdornment position="start">
                        {signUpDetails.basicDetails.isVerified !==
                          undefined && (
                            <img src={VerifiedTickIcon} alt="verified-tick" />
                          )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box className="margin-y">
                <label className="custom-label" htmlFor="email">
                  Email
                </label>

                <TextField
                  className="custom-input margin-y-label"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  placeholder="Enter your email address"
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                  }}
                />
              </Box>

              <Box className="margin-y">
                <label className="custom-label" htmlFor="phone">
                  Phone Number
                </label>

                <div>
                  <PhoneInput
                    country={"gb"}
                    specialLabel=""
                    inputClass="custom-phone-input margin-y-label"
                    value={formik.values.phoneNumber}
                    onChange={(val: any) => {
                      formik.setFieldValue("phoneNumber", val);
                    }}
                    onBlur={formik.handleBlur}
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                    }}
                    inputStyle={{
                      borderColor:
                        formik.touched.phoneNumber &&
                        formik.errors.phoneNumber &&
                        "red",
                    }}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <p
                      style={{ color: "red" }}
                      className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense"
                    >
                      {"Enter a valid phone number."}
                    </p>
                  )}
                </div>
              </Box>

              <Box className="margin-y">
                <FormControl>
                  <label className="custom-label dark-label" id="gender">
                    Gender
                  </label>

                  <RadioGroup
                    aria-labelledby="gender"
                    name="gender"
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                  >
                    <FormControlLabel
                      className="sub-text radio-btn"
                      value="MALE"
                      control={<Radio />}
                      label="Male"
                    />

                    <FormControlLabel
                      className="sub-text radio-btn"
                      value="FEMALE"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box className="margin-y">
                <div className="custom-label dark-label">Profile Picture</div>

                <Box className="center" justifyContent="start">
                  <div style={{ width: "50px", height: "50px" }}>
                    <img
                      className="circle-img shadow-light"
                      alt="profile"
                      src={
                        profileString === ""
                          ? (signUpDetails.basicDetails.photoPath as string) !==
                            ""
                            ? (signUpDetails.basicDetails.photoPath as string)
                            : (DefaultUserIcon as string)
                          : profileString
                      }
                    />
                  </div>

                  <Button
                    onClick={() => fileUploadRef?.current?.click()}
                    variant="outlined"
                    className="section-head upload-btn"
                    size="small"
                    style={{ textTransform: "none" }}
                  >
                    <span style={{ fontWeight: "700", fontSize: "12px" }}>
                      Upload photo
                    </span>
                  </Button>

                  <input
                    ref={fileUploadRef}
                    name={"profilePicture"}
                    type="file"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if(event.currentTarget.files?.[0] === undefined) return

                      if (
                        event.currentTarget.files !== null &&
                        // 1000000 bytes is 1MB
                        event.currentTarget.files[0].size > 2500000
                      ) {
                        enqueueSnackbar("Max file size allowed is 2.5MB", {
                          variant: "error",
                        });

                        return;
                      }

                      setImageUploading(true)
                      
                      basicDetailFileUpload(
                        event.currentTarget.files?.[0]
                      ).then(res => {
                        setImageUrl(res)
                      })
                      .catch(err => {
                        setImageUrl("")
                      })
                      .finally(() => setImageUploading(false))

                      formik.setFieldValue(
                        "profilePicture",
                        event.currentTarget.files?.[0]
                      );
                    }}
                    style={{ display: "none" }}
                  />
                </Box>
              </Box>
            </Box>

            {/* <p className="section-head">Carpool preferences</p> */}

            {/* <Box className="basic-detail-next-button-container">
              <p className="custom-label dark-label margin-y">
                Select how you plan to use EcoPooling (Can be updated from
                preferences later)
              </p>

              <Box marginBottom="40px">
                <FormControl>
                  <RadioGroup
                    name={"carPoolPreference"}
                    onChange={formik.handleChange}
                    value={formik.values.carPoolPreference}
                  >
                    <FormControlLabel
                      className="sub-text radio-btn"
                      value="OFFER"
                      control={<Radio />}
                      label="I will be looking to offer carpools"
                    />

                    <FormControlLabel
                      className="sub-text radio-btn"
                      value="FINDER"
                      control={<Radio />}
                      label="I’m looking to book passenger seats on carpools "
                    />

                    <FormControlLabel
                      className="sub-text radio-btn"
                      value="BOTH"
                      control={<Radio />}
                      label="Both driver and passenger"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box className="d-flex justify-center items-center button-width mx-auto">
                <Button
                  className="next-btn custom-btn btn-width px-15rem"
                  disabled={loading}
                  type={"submit"}
                  fullWidth
                >
                  Next
                </Button>
              </Box>
            </Box> */}
            <CustomFixedButton disable={loading} text={'Next'}></CustomFixedButton>
          </form>
        </React.Fragment>
      )}
    </div>
  );
};

export default BasicDetail;
