import { Box } from "@mui/material";
import CustomFixedButton from "../../../components/custom-fixed-button";
import { blackShareIcon, blueCopyIcon, groupIcon, promoCodeImage } from "../../../assets/images";
import Header from "../../../components/header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import './style.scss';
import { enqueueSnackbar } from "notistack";
import { getUserById } from "../../../services/user";
import { updateBasicDetails } from "../../../slice/login";
import { IUSER } from "../../../slice/invites";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { detectDeviceType } from "../../../utilities/helper";
import CheckedCircleImg from "../../../assets/images/checked-circle.svg"

const InviteMarketPlaceFriends = () => {
  const { userDetailsReducer } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUniqueCode = useCallback(async () => {
    try {
      if (Boolean(userDetailsReducer.basicDetails.uniqueId) === false) {
        const user = await getUserById(userDetailsReducer.user._id) as IUSER;

        dispatch(updateBasicDetails({uniqueId: user.uniqueId}));
      }
    } catch (error) {
      enqueueSnackbar('Error occurred while fetching share link', {
        variant: 'error',
      })
    }
  }, [userDetailsReducer.user._id, userDetailsReducer.basicDetails.uniqueId, dispatch])


  useEffect(() => {
    getUniqueCode();
  }, [getUniqueCode]);

  return (
    <Box id="invite-marketplace-container">

      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
      />

      <div className="invite-friends-content">
        <div className="invite-friends-image-container">
          <img src={promoCodeImage} alt="promo" />
        </div>
        <div className="invite-friends-list-container">
          <div className="list-line">
            <div>
              <img src={CheckedCircleImg} alt='green-tick' style={{ marginTop: '2px' }} />
            </div>
            <p>
              Invite up to 10 of your family & friends to your 'Eco Rewards Pool'
              via the link below
            </p>
          </div>
          <div className="list-line">
            <div>
              <img src={CheckedCircleImg} alt="green-tick" style={{ marginTop: '2px' }} />
            </div>
            <p>
              They start enjoying instant savings on their everyday shopping
            </p>
          </div>
        </div>
        <div className="invite-friends-link-container">
          <h4>Invite link</h4>
          <div className="invite-friends-link">
            <p className="overflow-x">{`${process.env.REACT_APP_WEBSITE_URL}/marketplace-login?id=${
              userDetailsReducer.basicDetails.uniqueId}`}</p>
            <p className="cursor-pointer"
            onClick={async () => {
              await navigator.clipboard.writeText(`${process.env.REACT_APP_WEBSITE_URL}/marketplace-login?id=${
                userDetailsReducer.basicDetails.uniqueId
              }`);
              enqueueSnackbar('Link copied', {
                variant: "success"
              } )
            }}><img src={blueCopyIcon} alt="copy-icon" /></p>
          </div>
        </div>
      </div>

      <CustomFixedButton
        borderTop={false}
        showButton={detectDeviceType() === 'Mobile'}
        handleSubmit={async () => {
          try {
            const shareData = {
              title: 'EcoPooling',
              text: 'Join EcoPooling MarketPlace\n',
              url: `${process.env.REACT_APP_WEBSITE_URL}/marketplace-login?id=${userDetailsReducer.basicDetails.uniqueId}`
            }
            if (navigator.canShare(shareData)) {
              await navigator.share(shareData);
            }
          } catch (error) {
            console.log(error);
          }
        }}
        icon={blackShareIcon}
        bottomJSX={<Box className="invite-friends-fixed-button-container">
          <div className="invite-friends-rewards-button" onClick={() => {
            navigate('/dashboard/market-place/invites/list')
          }}>
            <img src={groupIcon} alt="group-icon" />
            <b style={{
              textAlign: 'center',
              padding: '0.5rem'
            }}>
              &nbsp;My eco rewards group
            </b>
          </div>
        </Box>}

        text={"Share"} disable={false}></CustomFixedButton>
    </Box>
  )
}

export default InviteMarketPlaceFriends;