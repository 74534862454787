import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  InviteResponse,
  Trip,
  TripData,
  TripFindCarpool,
} from "../../interface/book-seat";
import { TripResponseInterfaceNew } from "../../interface/book-seat-new";
import { AxiosError } from "axios";
import { getSeatDetails } from "../../services/book-seat";

interface TripDataInterfaceState {
  trip: TripData;
  tripResponseNew: TripResponseInterfaceNew[];
  selectedTripResponse: Trip[];
  selectedInvites: InviteResponse[];
  selectedTripResponseNew: TripResponseInterfaceNew[];
  inviteLoading: boolean;
  inviteError: boolean;
  invites: InviteResponse[];
}
const initialState: TripDataInterfaceState = {
  trip: {
    pricePerSeat: 0,
    allTrips: [] as TripFindCarpool[],
  },
  tripResponseNew: [],
  selectedTripResponse: [],
  selectedInvites: [],
  selectedTripResponseNew: [],
  invites: [],
  inviteLoading: true,
  inviteError: false,
};

export const getInvites = createAsyncThunk(
  "bookSeat/getInvites",
  async (_, thunkAPI) => {
    try {
      const data = await getSeatDetails();

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as AxiosError).message);
    }
  }
);

export const bookSeatSlice = createSlice({
  name: "bookSeatReducer",
  initialState,
  reducers: {
    initBookSeat: (state, action: PayloadAction<TripData>) => {
      state.trip.allTrips = action.payload.allTrips;
      state.trip.pricePerSeat = action.payload.pricePerSeat;
    },
    initBookSeatNew: (
      state,
      action: PayloadAction<Array<TripResponseInterfaceNew>>
    ) => {
      if (Array.isArray(action.payload)) {
        state.tripResponseNew = action.payload;
      }
    },
    addItemToSlected: (state, action: PayloadAction<string>) => {
      const newTrip = state.selectedTripResponse.find(
        (item) => item._id === action.payload
      );
      if (newTrip === undefined) {
        const targetTrip = state.trip.allTrips.find(
          (item) => item._id === action.payload
        );
        if (targetTrip !== undefined) {
          state.selectedTripResponse = [
            ...state.selectedTripResponse,
            targetTrip,
          ];
        } else {
          const selectedInvite = state.invites.find(
            (invite) =>
              invite.tripDetail._id.toString() === action.payload.toString()
          ) as InviteResponse;
          state.selectedTripResponse = [
            ...state.selectedTripResponse,
            selectedInvite?.tripDetail,
          ];
          // store the selected invite for find carpool flow UI
          state.selectedInvites = [...state.selectedInvites, selectedInvite];
        }
      }
    },
    addItemToSlectedNew: (state, action: PayloadAction<string>) => {
      const newTrip = state.selectedTripResponseNew.find(
        (item) => item.tripDetail._id === action.payload
      );
      if (newTrip === undefined) {
        const targetTrip = state.tripResponseNew.find(
          (item) => item.tripDetail._id === action.payload
        );
        if (targetTrip !== undefined) {
          state.selectedTripResponseNew = [
            ...state.selectedTripResponseNew,
            targetTrip,
          ];
        }
      }
    },
    removeItemFromSlected: (state, action: PayloadAction<string>) => {
      state.selectedTripResponse = state.selectedTripResponse.filter(
        (item) => item._id !== action.payload
      );
      state.selectedInvites = state.selectedInvites.filter(
        (invite) => invite.tripDetail._id !== action.payload
      );
    },
    removeItemFromSlectedNew: (state, action: PayloadAction<string>) => {
      state.selectedTripResponseNew = state.selectedTripResponseNew.filter(
        (item) => item.tripDetail._id !== action.payload
      );
    },
    selectAllSeat: (state, action: PayloadAction<null>) => {
      if (state.trip.allTrips.length > 0) {
        state.selectedTripResponse = [...state.trip.allTrips];
      } else {
        // for find carpool flow
        const allTrips = state.invites.map((invite) => invite.tripDetail);
        state.selectedTripResponse = [...allTrips];

        // store all invites for find carpool flow
        state.selectedInvites = state.invites;
      }
    },
    unSelectAllSeat: (state, action: PayloadAction<null>) => {
      state.selectedTripResponse = [];
      state.selectedInvites = [];
    },
    selectAllSeatNew: (state, action: PayloadAction<null>) => {
      state.selectedTripResponseNew = [...state.tripResponseNew];
    },
    unSelectAllNew: (state, action: PayloadAction<null>) => {
      state.selectedTripResponseNew = [];
    },
    resetInvite: (state) => {
      state.inviteError = false;
      state.inviteLoading = false;
    },
    setSelectedTripResponse: (state, action: PayloadAction<null>) => {
      state.selectedTripResponse = action.payload;
    },
    setSelectedInvites: (state, action: PayloadAction<null>) => {
      state.selectedInvites = action.payload;
    },
    setSelectedTripResponseNew: (state, action: PayloadAction<null>) => {
      state.selectedTripResponseNew = action.payload;
    },
    resetBookSeatReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvites.pending, (state) => {
        state.inviteLoading = true;
      })
      .addCase(getInvites.fulfilled, (state, action) => {
        state.inviteLoading = false;
        state.invites = action.payload;
      })
      .addCase(getInvites.rejected, (state) => {
        state.inviteLoading = false;
        state.inviteError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  initBookSeat,
  addItemToSlected,
  removeItemFromSlected,
  selectAllSeat,
  unSelectAllSeat,
  initBookSeatNew,
  addItemToSlectedNew,
  removeItemFromSlectedNew,
  selectAllSeatNew,
  unSelectAllNew,
  setSelectedTripResponse,
  setSelectedInvites,
  setSelectedTripResponseNew,
  resetBookSeatReducer,
} = bookSeatSlice.actions;

export default bookSeatSlice.reducer;
