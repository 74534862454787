import React from 'react';

interface StringToHtmlProps {
  htmlString: string;
}

 const StringToHtml: React.FC<StringToHtmlProps> = ({ htmlString }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};
export default StringToHtml
