import "./style.scss";
import { LocalizationProvider, DesktopTimePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";

type Props = {
  handleChange: ((value: moment.Moment | null) => void) | undefined;
  timeDefault: string | undefined;
  disbled: boolean | undefined;
  showLayover?: boolean
  showLayoverWithoutDisable?: boolean
};

function CustomDatePicker(props: Props) {
  return (
    <Box className={"fullWidth relative"}>
      {
        props.showLayover && props.disbled && (
          <div className="absolute set-time-overlay">
            Set time
          </div>
        )
      }
      {
        props.showLayoverWithoutDisable && (
          <div className="absolute set-time-overlay">
            Set time
          </div>
        )
      }
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer components={["TimePicker"]}>
          <DemoItem>
            {/* <MobileTimePicker
              onAccept={props.handleChange}
              ampm={false}
              disabled={!!props.disbled}
              value={
                props.timeDefault
                  ? moment(props.timeDefault, "hh:mm")
                  : moment("HH:mm")
              }
            /> */}
            <DesktopTimePicker
              value={props.timeDefault ? moment(props.timeDefault, "HH:mm") : moment("HH:mm")}
              onChange={props.handleChange}
              disabled={!!props.disbled}
              ampm={false}
            />
            {/* <TimePicker
              value={props.timeDefault ? moment(props.timeDefault, "hh:mm") : moment("HH:mm")}
              onChange={props.handleChange}
              disabled={!!props.disbled}
              ampm={false}
            /> */}
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
}

export default CustomDatePicker;
