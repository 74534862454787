import React, { Fragment, useEffect } from "react";
import checkMark from "../../../../assets/images/check_mark.svg";
import MatchResultListItem from "../../Common components/matched-result-list";
import BellIcon from "../../../../assets/images/bell-icon.svg";
import shoppingBag from "../../../../assets/images/shopping-bag.svg";
import drivingWheelIcon from "../../../../assets/images/driving-wheel.svg";
import CustomList from "../../Common components/CustomList";
import InfoBox from "../../Common components/InfoBox";
import car from "../../../../assets/images/car.svg";
// import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { getMyInvitesUrl } from "../../../../utilities/helper";
import { whatsAppWebHookCancelTrip } from "../../../../services/whatsApp";
import { enqueueSnackbar } from "notistack";
import CustomFixedButton from "../../../../components/custom-fixed-button";

const CancelTrip = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = useSelector((state: RootState) => state.userDetailsReducer);
  const redirectUrl = getMyInvitesUrl(user.jti);

  // hit webhook to notify passenger/driver in all cases except when there are no seats booked
  useEffect(() => {
    if (state.cancellationReason !== "Driver cancels - no seats book") {
      const cancellationReasonType = getCancellationReasonType(
        state.cancellationReason
      );

      // send a request to whatsApp webhook
      whatsAppWebHookCancelTrip(
        cancellationReasonType,
        state.locationName,
        state.driverName,
        state.from,
        state.to,
        state.date,
        state.price,
        state.whatsAppNumber,
        state.jti,
        state.pickUpTime,
        state.dropOffTime,
        state.tripType,
        state.passengerName
      ).catch(() => {
        enqueueSnackbar("Unable to send whatsApp notification", {
          variant: "error",
        });
      });
    }

    // eslint-disable-next-line
  }, []);

  // get cancellation reason type for whatsApp webhook
  const getCancellationReasonType = (cancellationReason: string) => {
    switch (cancellationReason) {
      case "Driver cancels - with seats book":
        return "DRIVERCANCEL";

      case "Passenger cancels - before 24hrs of trip start":
        return "PASSENGERCANCELBEFORE";

      case "Passenger cancels - after 24hrs before trip start":
        return "PASSENGERCANCELAFTER";
    }
  };

  const descriptionForDriver = [
    {
      icon: drivingWheelIcon,
      text: "Offer another carpool",
      bordered: true,
      hasArrow: true,
      path: "/dashboard/home",
    },
    {
      icon: shoppingBag,
      text: "Rewards marketplace",
      bordered: true,
      hasArrow: true,
      path: "/dashboard/market-place",
    },
  ];

  const descriptionForPassenger = [
    {
      icon: car,
      text: "Review my carpool invites",
      hasArrow: true,
      bordered: true,
      path: `/redirect/${redirectUrl}`,
    },
    {
      icon: shoppingBag,
      text: "Rewards marketplace",
      hasArrow: true,
      path: "/dashboard/market-place",
    },
  ];

  const handleClick = () => {
    navigate("/dashboard/redirect-to-home");
  };

  const getTextForPassengerTripCancellation = (
    cancelled: "before" | "after"
  ) => {
    return cancelled === "before" ? (
      <p>
        Your seat booking in this carpool has now been cancelled. We’ve also
        notified the driver, you don’t need to do anything further.
        <br />
        <br />
        You will
        receive a full refund excluding service fees to the bank account you
        used for booking. Refund payment processing can take up to 5 working
        days.
      </p>
    ) : (
      <Fragment>
        <p className="mb-3">
          Your seat booking in this carpool has now been cancelled. We’ve also
          notified the driver, you don’t need to do anything further.
        </p>

        <p className="mb-3">
          As this cancellation is less that 24 hours before the trip, you will
          only receive a 50% refund excluding service fees to the bank account
          you used for booking. Refund payment processing can take up to 5
          working days.
          
        </p>

        <p>
        The driver will receive the other 50% as compensation for
        the late change in pickup plans.
        </p>
      </Fragment>
    );
  };
  //eslint-disable-next-line
  const getLayout = () => {
    switch (state.cancellationReason) {
      case "Driver cancels - no seats book":
        return driverCancel("no-seat");
      case "Driver cancels - with seats book":
        return driverCancel("seat");
      case "Passenger cancels - before 24hrs of trip start":
        return passengerCancel("before");
      default:
        return passengerCancel("after");
    }
  };

  const driverCancel = (type: "seat" | "no-seat") => {
    return type === "seat" ? (
      <Fragment>
        <div className="mb-3 p-1">
          <MatchResultListItem
            icon={BellIcon}
            text="All passenger seat bookings for this carpool are now cancelled. We’ve notified each of the passengers, you don’t need to do anything further."
          />
        </div>
        <div className="mb-50">
          <InfoBox border="green">
            {descriptionForDriver.map((item, index) => (
              <CustomList key={index} {...item} />
            ))}
          </InfoBox>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className="mb-50">
          <InfoBox border="green">
            {descriptionForDriver.map((item, index) => (
              <CustomList key={index} {...item} />
            ))}
          </InfoBox>
        </div>
      </Fragment>
    );
  };
  const passengerCancel = (type: "before" | "after") => {
    return (
      <Fragment>
        <div className="mb-3 p-1">
          <MatchResultListItem
            icon={BellIcon}
            text={getTextForPassengerTripCancellation(type)}
          />
        </div>
        <div className="mb-50">
          <InfoBox border="green">
            {descriptionForPassenger.map((item, index) => (
              <CustomList key={index} {...item} />
            ))}
          </InfoBox>
        </div>
      </Fragment>
    );
  };

  // const getButtonClass = () => {
  //   return state === null ||
  //     state === "Passenger cancels - after 24hrs before trip start"
  //     ? "done-btn-2"
  //     : "done-btn";
  // };

  return (
    <div
      id="cancel-trip"
      className="d-flex flex-row items-start justify-center pt-10vh"
    >
      <div className="main-wrapper d-flex flex-column items-start">
        <div className="confirmed-check-mark mb-3">
          <img src={checkMark} alt="confirmed" />
        </div>
        <div className="fw-700 mb-5">
          <p>Trip Cancelled</p>
        </div>
      {/* {getLayout()} */}
      </div>
      {/* <Box
        className={`${getButtonClass()} d-flex justify-center items-center p-1 btn-padding-responsive`}
      >
        <Button
          className="next-btn custom-btn mt-3"
          fullWidth
          onClick={handleClick}
        >
          Done
        </Button>
      </Box> */}
      <CustomFixedButton text={'Done'} handleSubmit={handleClick} disable={false} />
    </div>
  );
};

export default CancelTrip;
