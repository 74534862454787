import React from "react";
import RightIconLight from "../../../../assets/images/righ-arrow-light.svg";
import { useNavigate } from "react-router";
import { resetAllTripsData, resetTripsHistory } from "../../../../slice/trip";
import { useDispatch } from "react-redux";

interface listProps {
  icon: string;
  text: string;
  bordered?: boolean;
  hasArrow?: boolean;
  path?: string;
}

const CustomList = ({ icon, text, bordered, hasArrow, path }: listProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getListClass = () => {
    return bordered && hasArrow
      ? "d-flex justify-between border-bottom p-1 cursor-pointer"
      : bordered && !hasArrow
      ? "d-flex border-bottom p-1 cursor-pointer"
      : hasArrow
      ? "d-flex justify-between p-1 cursor-pointer"
      : "d-flex width-100 p-1 cursor-pointer";
  };

  const handleClick = () => {
     dispatch(resetAllTripsData())
     dispatch(resetTripsHistory())
     navigate(path)
  }

  return (
    <div className={getListClass()}>
      <div className="d-flex items-center" onClick={handleClick}>
        <div className="icon-container d-flex items-center">
          <img src={icon} alt="verified-mark" />
        </div>
        <div className="ml-3 d-flex items-center">
          <p>{text}</p>
        </div>
      </div>
      {hasArrow && (
        <div className="pr-2 d-flex items-center justify-cente">
          <img src={RightIconLight} alt="" />
        </div>
      )}
    </div>
  );
};

export default CustomList;
