import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createInvites } from "../../services/invites";
import { AxiosError } from "axios";

export interface IUSER {
  _id: string;
  role: object;
  email: string;
  address: ADDRESS;
  firstName: [
    {
      value: string;
      lang: string;
    }
  ];
  lastName: [
    {
      value: string;
      lang: string;
    }
  ];
  gender: string;
  isDrivingLicence: boolean;
  isInsurance: boolean;
  isMOT: boolean;
  phone: string;
  photo: string;
  status: string;
  userPreference: string;
  loginType?: string;
  socialId?: string;
  isVerified?: boolean;
  isAddressVerified?: boolean;
  isDriverVerified?: boolean;
  jti: string,
  uniqueId?: string;
}

export interface IUSERWITHTRIPINFO extends IUSER {
  driverToPassengerDistance: number;
  driverToPassengerTime: number;
  passengerToDestinationDistance: number;
  passengerToDestinationTime: number;
  finalCost?: number;
  adminServiceFee?: number;
  fuelContribution?: number;
  tripDetails?: {
    totalTripTime: number;
  }
}

export interface USERDATA {
  user: IUSER;
  driverToPassengerDistance: number;
  driverToPassengerTime: number;
  passengerToDestinationDistance: number;
  passengerToDestinationTime: number;
}

export interface IUserDataAll {
  user: IUSER;
  driverToPassengerDistance: number;
  driverToPassengerTime: number;
  passengerToDestinationDistance: number;
  passengerToDestinationTime: number;
  finalCost: number;
  totalPrice?: number;
  adminServiceFee: number;
  fuelContribution?: number;
  tripDetails: INVITETRIP;
}

export interface INVITEDUSER {
  filteredUsers?: Array<USERDATA>;
  filteredUsersAll: Array<IUserDataAll>;
  selectedUsersId: Array<string>;
  selectedUsers: Array<IUSERWITHTRIPINFO>;
  inviteLoading: boolean;
  inviteResponse: object;
  inviteError: string;
  filteredUsersId: string[];
  tripDateUserMap: ITripUserMap;
  tripIdUserMap: ITripUserMap;
  parking?: number;
  discount?: number;
}

export interface MultiLanguage {
  lang: string;
  value: string;
}

export interface ADDRESS {
  state: Array<MultiLanguage>;
  city: Array<MultiLanguage>;
  country: Array<MultiLanguage>;
  line1: Array<MultiLanguage>;
  line2: Array<MultiLanguage>;
  geo: number[];
  zip: string;
}

export interface INVITETRIP {
  _id?: string;
  childSeatsBooked: number;
  address: ADDRESS;
  destination: ADDRESS;
  dropDate: Date;
  pickUp: ADDRESS;
  scheduleDate: Date;
  seatsBooked: number;
  totalChildSeats: number;
  tripStatus: string;
  totalSeats: number;
  status: string;
  _driver: object;
  totalTripTime: number;
  totalTripDistance: number;
  isSelected?: boolean;
  destinationLocationId?: string;
}

export interface ITripUserMap {
  [id: string]: string[];
}

const initialState: INVITEDUSER = {
  filteredUsers: [] as USERDATA[],
  filteredUsersAll: [],
  selectedUsers: [],
  selectedUsersId: [],
  inviteLoading: false,
  inviteResponse: {},
  inviteError: "",
  filteredUsersId: [],
  tripDateUserMap: {},
  tripIdUserMap: {},
  parking: 0,
  discount: 0,
};

export const createMultipleInvites = createAsyncThunk(
  "invites/createInvites",
  async (tripIds: { _tripId: string; _user: string }[], thunkAPI) => {
    try {
      const response = await createInvites(tripIds);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as AxiosError).message);
    }
  }
);

export const inviteSlice = createSlice({
  name: "inviteReducer",
  initialState,
  reducers: {
    setFilteredUsers: (state, action: PayloadAction<USERDATA[]>) => {
      state.filteredUsers = action.payload;
    },
    setFilteredUsersAll: (state, action) => {
      state.filteredUsersAll = action.payload.filteredUsersArr;
      state.filteredUsersId = action.payload.filteredUsersIdArr;
      state.tripDateUserMap = action.payload.userTripDateMap;
      state.tripIdUserMap = action.payload.userTripIdMap;
    },
    updateInviteUserTrip: (state, action: PayloadAction<INVITETRIP>) => {
      const newInviteChange = action.payload;
      const existingUsers = state.filteredUsers?.map((obj) => {
        if ((obj as unknown as { _id: string })._id === newInviteChange._id) {
          return { ...obj, isSelected: newInviteChange.isSelected };
        } else {
          return obj;
        }
      });
      state.filteredUsers = existingUsers;
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = [...state.selectedUsers, ...action.payload];

      // push each users id
      action.payload.forEach((user: IUSER) => {
        state.selectedUsersId.push(user._id);
      });
    },
    updateSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;

      // push each users id
      state.selectedUsersId = action.payload.map((user: IUSER) => user._id);
    },
    removeSelectedUser: (state, action) => {
      state.selectedUsers = state.selectedUsers.filter(
        (user) => user._id !== action.payload[0]._id
      );
      state.selectedUsersId = state.selectedUsersId.filter(
        (id) => id !== action.payload[0]._id
      );
    },
    removeAllSelectedUser: (state, action) => {
      state.selectedUsers = []; //state.selectedUsers.filter(user => user._id !== action.payload[0]._id)
      state.selectedUsersId = []; //state.selectedUsersId.filter(id => id !== action.payload[0]._id)
    },
    resetInviteResponse: (state) => {
      state.inviteError = "";
      state.inviteResponse = {};
    },
    setParking: (state, action) => {
      state.parking = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    resetFilteredUsersAll: (state) => {
      state.filteredUsersAll = initialState.filteredUsersAll;
      state.filteredUsersId = initialState.filteredUsersId;
      state.tripDateUserMap = initialState.tripDateUserMap;
      state.tripIdUserMap = initialState.tripIdUserMap;
      state.selectedUsers = initialState.selectedUsers;
      state.selectedUsersId = initialState.selectedUsersId
    },
    resetParkingDiscount: (state) => {
      state.parking = 0;
      state.discount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMultipleInvites.pending, (state) => {
        state.inviteLoading = true;
      })
      .addCase(createMultipleInvites.fulfilled, (state, action) => {
        state.inviteLoading = false;
        state.inviteResponse = action.payload;
      })
      .addCase(createMultipleInvites.rejected, (state, action) => {
        state.inviteLoading = false;
        state.inviteError = action.payload as string;
      });
  },
});

export const {
  setFilteredUsers,
  setFilteredUsersAll,
  updateInviteUserTrip,
  setSelectedUsers,
  updateSelectedUsers,
  removeSelectedUser,
  resetInviteResponse,
  removeAllSelectedUser,
  setParking,
  setDiscount,
  resetFilteredUsersAll,
  resetParkingDiscount,
} = inviteSlice.actions;

export default inviteSlice.reducer;
