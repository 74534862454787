import React, { useEffect, useState } from 'react'
import "./style.scss"
import crownIcon from '../../../assets/images/crown.jpg'
import checkIcon from '../../../assets/images/checked-circle.svg'
import fileIconSmall from '../../../assets/images/file-icon-sm.svg'
import { chevronRight } from '../../../assets/images'
import { TextField } from '@mui/material'
import { updateUser } from '../../../services/update/user-profile'
import { useFormik } from 'formik'
import { driverVerificationValidationSchema } from './validation'
import { enqueueSnackbar } from 'notistack'
import LoadingScreen from '../../../components/loading-screen'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomFixedButton from '../../../components/custom-fixed-button'

const DriverVerification = () => {
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)

    const navigate = useNavigate()
    const location  = useLocation()

    // if user tries to visit this page directly, then redirect to home
    useEffect(() => {
        if(location.state === null) {
            navigate("/")
        } else {
            setInitialLoading(false)
        }

        // eslint-disable-next-line
    }, [])

    const formik = useFormik({
        initialValues: {
            licenceNumber: "",
            licenceCheckCode: ""
        },
        validationSchema: driverVerificationValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true)

                const userResponse = await updateUser({ licenceNumber: values.licenceNumber, licenceCheckCode: values.licenceCheckCode, pendingInviteRequestData: location.state })

                if(userResponse !== undefined) {
                    navigate("/redirect-page/what-next", { state: { fromDriverVerification: true } })
                }

                setLoading(false)
            } catch (error) {
                enqueueSnackbar("Something went wrong !", { variant: 'error' });

                setLoading(false)
            }
        }
    })

    const handleCheckCodeButton = () => {
        window.open(process.env.REACT_APP_DRIVER_VERIFICATION_URL, "_blank")
    }

  return (
    <>
        {
            initialLoading ? <LoadingScreen /> : (
                <div className=' border-box min-h-100 d-flex flex-column driver-verification-wrapper'>
                    <div className="text-center mt-5">
                        <img src={crownIcon} alt="driver-verification" />
                    </div>

                    <p className='text-center text-2xl text-700 my-4 mb-5'>
                        Get your check code
                    </p>

                    <section className='mb-5'>
                        <div className='d-flex items-start gap-1 mb-3'>
                            <img src={checkIcon} alt="visit gov.uk portal" />

                            <p className='text-md line-y-md'>
                                Visit the Gov UK ‘review driver record’ portal and enter your driver’s license number, NI number and postcode
                            </p>
                        </div>

                        <div className='d-flex items-start gap-1 mb-3'>
                            <img src={checkIcon} alt="visit gov.uk portal" />

                            <p className='text-md line-y-md'>
                                You’ll receive your ‘check code’ on the screen within seconds
                            </p>
                        </div>

                        <div className='d-flex items-start gap-1 mb-3'>
                            <img src={checkIcon} alt="visit_gov_portal" />

                            <p className='text-md line-y-md'>
                                Enter your ‘check code’ and we’ll use this to verify your license with DVLA
                            </p>
                        </div>
                    </section>

                    <div className='d-flex justify-between items-center p-1 mx-3 shadow-extra-light rounded-12 cursor-pointer mb-5 select-none access-check-code-button' onClick={handleCheckCodeButton}>
                        <img src={fileIconSmall} alt="access check code" className='pr-1' />

                        <p className='flex-1 text-500'>
                            Access my check code
                        </p>

                        <img src={chevronRight} alt="access check code" />
                    </div>

                    {
                        loading && <LoadingScreen />
                    }

                    <form className='d-flex flex-1 flex-column' onSubmit={formik.handleSubmit}>
                        <div className='d-flex flex-column mb-5'>
                            <label className='text-500 text-md mb-2'>
                                Check code
                            </label>

                            <TextField 
                            name="licenceNumber"
                            placeholder="e.g. gn WX df TR"
                            onChange={formik.handleChange}
                            error={formik.errors.licenceNumber && formik.touched.licenceNumber}
                            />
                            </div>
                            
                            <div className='d-flex flex-column mb-6'>
                            <label className='text-500 text-md mb-2'>
                            Driving licence
                            </label>
                            
                            <TextField 
                            name="licenceCheckCode"
                            placeholder="e.g. SMITHD806432CJ9JN 32"
                            onChange={formik.handleChange}
                            error={formik.errors.licenceCheckCode && formik.touched.licenceCheckCode}
                            />
                        </div>

                        {/* <div className='d-flex items-end flex-1 btn-margin-responsive'>
                            <Button variant="contained" className='py-1 text-dark text-700 capitalize btn-text' fullWidth type="submit">Submit</Button>
                        </div> */}
                        <CustomFixedButton text={"Submit"} disable={false} />
                    </form>
                </div>
            )
        }
    </>
  )
}

export default DriverVerification