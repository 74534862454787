import './style.scss';
import Header from '../../../../components/header';
import DefaultUserIcon from '../../../../assets/images/defaultUser.svg';
import VerifiedTickIcon from '../../../../assets/images/verified-tick.svg'
import Home from '../../../../assets/images/home.svg';
import { Box, InputAdornment, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
// import GoogleMap from '../../../../components/google-map';
import { homeLocationDetailUpload, mapDataForHomeLocation } from '../../../../services/home-location-detail';
import { updateHomeLocation } from '../../../../slice/sign-up';
import { enqueueSnackbar } from 'notistack';
import { validationSchema } from './validation';
import CustomFixedButton from '../../../../components/custom-fixed-button';

const HomeLocation = () => {
    const [location, setLocation] = useState<any>({});

    const signUpDetails = useSelector((state: RootState) => state.signUpDetails);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locationInstance = useLocation();

    const searchParams = new URLSearchParams(locationInstance.search);

    const prevURL = searchParams.get('prev_url')

    const draftId = localStorage.getItem("draftId");

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: signUpDetails.homeLocation,
        onSubmit: async (values) => {
            try {
                // const { state, city, country, longitude, latitude, zip } = location;

                dispatch(updateHomeLocation({
                    line1: values.line1,
                    line2: values.line2,
                    city: values.city,
                    state: values.state,
                    // geo: [latitude, longitude],
                    zip: values.zip,
                    // country,
                    // additionalInstructions: values.additionalInstructions
                }));

                const payload = mapDataForHomeLocation(values, { ...signUpDetails.user, _id: draftId });

                await homeLocationDetailUpload(payload);

                if (prevURL) {
                    navigate(prevURL);
                // } else if (
                //     signUpDetails.basicDetails.carPoolPreference === "OFFER" ||
                //     signUpDetails.basicDetails.carPoolPreference === "BOTH"
                // ) {
                //     navigate("/signup/schedule");
                } else {
                    // navigate("/signup/summary");
                    navigate("/signup/carpool-preference")
                }
            } catch (error) {
                enqueueSnackbar("Must Add Address", { variant: 'error' })
            }
        }
    });

    useEffect(() => {
        if (Object.keys(location).length > 0) {
            const { line2, city, homeLocation, zip } = location;

            formik.setFieldValue("line1", homeLocation)
            formik.setFieldValue("line2", line2)
            formik.setFieldValue("city", city)
            formik.setFieldValue("zip", zip ?? "")
        }

        //eslint-disable-next-line
    }, [location])

    //init location object
    useEffect(() => {
        // if (!draftId) navigate("/");

        const { homeLocation } = signUpDetails;

        setLocation({
            ...location,
            city: homeLocation.city,
            state: homeLocation.state,
            // longitude: homeLocation?.geo[1],
            // latitude: homeLocation?.geo[0],
            zip: homeLocation.zip,
            homeLocation: homeLocation.line1,
            line2: homeLocation.line2,
            country: homeLocation.country,
            additionalInstructions: homeLocation.additionalInstructions
        })

        //eslint-disable-next-line
    }, [draftId])

    return (
        <div id='home-location'>

            <Header
                canGoBack={true}
                rightIcon={<img alt='profile' className='header-img-icon' src={
                    (signUpDetails.basicDetails.photoPath as string) ?? DefaultUserIcon
                } />}
                signUp={true}
            />
            <Box className='padding-x rounded-top-green'>
                <div className="sub-header">
                    <div className="sub-header-icon-container">
                        <img alt='home' src={Home} />
                    </div>

                    <p className='sub-text-new sub-text font-1-5rem '>
                        Home location    
                    </p>
                </div>
            </Box>

            {/* <p className="section-head">Home location</p> */}

            <form onSubmit={formik.handleSubmit}>
                <Box className='margin-x' style={{ paddingBottom: '16px' }}>
                    {/* <Box className='margin-y'>

                        <label className='custom-label' htmlFor="first_name">Set your location</label>

                        <GoogleMap
                            label={''}
                            placeholder='Enter your postcode or use my location'
                            setLocation={setLocation}
                            value={formik.values.line1}
                            handleChange={formik.handleChange}
                            name="line1"
                        />
                    </Box> */}

                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="line1">Street address</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='line1'
                            name="line1"
                            // disabled
                            value={formik.values.line1.split(',')[0]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.line1 && Boolean(formik.errors.line1)}
                            helperText={formik.touched.line1 && formik.errors.line1}
                            placeholder='House/flat number and street name'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        signUpDetails.basicDetails.isVerified !== undefined &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    {/* <Box className='margin-y'>
                        <label className='custom-label' htmlFor="line2">Line 2</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='line2'
                            name="line2"
                            // disabled
                            value={formik.values.line2.split(',')[0]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.line2 && Boolean(formik.errors.line2)}
                            helperText={formik.touched.line2 && formik.errors.line2}
                            placeholder='Line 2 (optional)'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        signUpDetails.basicDetails.isVerified !== undefined &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box> */}

                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="city">Town/City</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='city'
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            // disabled
                            onBlur={formik.handleBlur}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                            placeholder='Town or city'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        signUpDetails.basicDetails.isVerified !== undefined &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="state">County</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='state'
                            name="state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            // disabled
                            onBlur={formik.handleBlur}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                            placeholder='County name'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        signUpDetails.basicDetails.isVerified !== undefined &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="zip">Post Code</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='zip'
                            name="zip"
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            // disabled
                            onBlur={formik.handleBlur}
                            error={formik.touched.zip && Boolean(formik.errors.zip)}
                            helperText={formik.touched.zip && formik.errors.zip}
                            placeholder='Postal Code eg. 567841'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        signUpDetails.basicDetails.isVerified !== undefined &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>
{/* 
                    <Box className="location-next-button-container">
                        <Box className="d-flex justify-center items-center button-width mx-auto">
                            <Button className='next-btn custom-btn btn-width px-15rem' fullWidth type={"submit"}>Next</Button>
                        </Box>
                    </Box> */}
                    <CustomFixedButton disable={false} text={'Next'}></CustomFixedButton>
                </Box>
            </form>
        </div>
    )
}

export default HomeLocation;
