import "./style.scss";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import Config from "../../../assets/images/config.svg";
import { Box, Button } from "@mui/material";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import { useEffect, useState } from "react";
// import CustomDatePicker from "../../../components/time-picker";
import UseSwitchesBasic from "../../../components/switch";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { setDecodedLoading } from "../../../slice/redirect-data";
import { useNavigate, useParams } from "react-router-dom";
import {
  // getDefaultSchedule,
  updateWeeklySchedule,
} from "../../../services/schedule";
// import { formateScheduleDateTime } from "../../../utilities/helper";
import { enqueueSnackbar } from "notistack";
// import DropDown from "../../../components/drop-down";
// import { pairsDropOff, pairsPickUp } from "../../../data/book-seat";
// import { Moment } from "moment";
import moment from "moment";
import LoadingScreen from "../../../components/loading-screen";

interface KeyActiveTime {
  label: string;
  key: string;
  active: boolean;
  pickUp: string;
  dropOff: string;
}

const WeeklySchedule = () => {
  //eslint-disable-next-line
  const [currDaySchedule, setCurrDaySchedule] = useState<Array<KeyActiveTime>>(
    getKeyActiveTime()
  );
  const [
    flexibility,
    // setFlexibility
  ] = useState("");
  const [scheduleType, setScheduleType] = useState("");
  const [tabSelected, setTabSelected] = useState("Next week");

  const { decodedData, decodedDataLoading } = useSelector(
    (store: RootState) => store.redirectDataReducer
  );
  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let { data: base64Data } = useParams();
  const decodedDataObj = JSON.parse(atob(String(base64Data)));

  // set decoded loading to false if decoded data is already fetched
  useEffect(() => {
    if (Object.keys(decodedData)?.length > 0) {
      dispatch(setDecodedLoading(false));
      setScheduleType(decodedData.type);
    } else if (Object.keys(decodedData)?.length === 0) {
      // if user comes directly to this page, then redirect to redirection landing page
      navigate(`/redirect/${base64Data}`);
    }
    //eslint-disable-next-line
  }, []);

  // fetch default schedule to pre-populate
  // useEffect(() => {
  //   getDefaultSchedule({ locationId: decodedData.locationId })
  //     .then((res: any) => {
  //       const schedule = res?.data?.dailySchedule.schedule as Array<any>;

  //       if (decodedData.type === 'Drop Off') {
  //         setFlexibility(res.data.dailySchedule.dropOffFlexibility)
  //       } else {
  //         setFlexibility(res.data.dailySchedule.pickUpFlexibility)
  //       }

  //       if (decodedData.type === "Drop Off") {
  //         setScheduleType("Drop-off")
  //         schedule.forEach((sch) => {
  //           // because of one based indexing
  //           let targetIndex = getIndexFromDay(sch.day) - 1;
  //           currDaySchedule[targetIndex].active = (sch.dropOff && (sch.dropOff !== '')) ? true : false;
  //           if ((sch.dropOff && (sch.dropOff !== ''))) {
  //             currDaySchedule[targetIndex].dropOff = formateScheduleDateTime(targetIndex + 1, sch.dropOff);;
  //           }
  //           setCurrDaySchedule([...currDaySchedule] as KeyActiveTime[]);
  //         })
  //       } else {
  //         setScheduleType("Pick-up")
  //         schedule.forEach((sch) => {

  //           // because of one based indexing
  //           let targetIndex = getIndexFromDay(sch.day) - 1;
  //           currDaySchedule[targetIndex].active = (sch.pickUp && (sch.pickUp !== '')) ? true : false;;
  //           if ((sch.pickUp && (sch.pickUp !== ''))) {
  //             currDaySchedule[targetIndex].pickUp = formateScheduleDateTime(targetIndex + 1, sch.pickUp);
  //           }
  //           setCurrDaySchedule([...currDaySchedule] as KeyActiveTime[])
  //         });
  //       }

  //       if (!!res.data.dailySchedule.dropOffFlexibility) {
  //         setFlexibility(res.data.dailySchedule.dropOffFlexibility);
  //       } else {
  //         setFlexibility(res.data.dailySchedule.pickUpFlexibility);
  //       }

  //     }).catch((er) => {
  //       console.log(er)
  //       enqueueSnackbar('Unable to fetch default schedule', { variant: 'error' })
  //     });

  //   //eslint-disable-next-line
  // }, []);

  // DEPRECATED: As per the new requirements, time is not required
  // format an object based on request data payload requirements
  // const createRequestData = () => {
  //   // extract dates from currentDaySchedule state
  //   let scheduledDates: string[] = [];
  //   const payload = {
  //     weeklySchedule: {} as any,
  //     _favoriteLocation: decodedDataObj.locationId
  //   };

  //   if (decodedData.type === "Pick Up") {
  //     currDaySchedule?.forEach((day) => {
  //       if (day.active && day.pickUp.length > 0) {
  //         scheduledDates.push();
  //       }
  //     });
  //     payload.weeklySchedule.pickUpDates = [...scheduledDates];
  //     payload.weeklySchedule.pickUpFlexibility = `${flexibility}`;
  //   } else {
  //     currDaySchedule?.forEach((day) => {
  //       if (day.active && day.dropOff.length > 0) {
  //         scheduledDates.push(day.dropOff);
  //       }
  //     });
  //     payload.weeklySchedule.dropOffDates = [...scheduledDates];
  //     payload.weeklySchedule.dropOffFlexibility = `${flexibility}`;
  //   }
  //   return payload;
  // };

  // format the request data according to API
  const createRequestData = () => {
    // extract dates from currentDaySchedule state
    let scheduledDates: string[] = [];
    const payload = {
      weeklySchedule: {} as any,
      _favoriteLocation: decodedDataObj.locationId,
    };

    if (decodedData.type === "Pick Up") {
      currDaySchedule?.forEach((day) => {
        if (day.active) {
          // if tab selected is this week, then send this week's dates otherwise send next week's dates
          if (tabSelected === "This week") {
            const ISODate = moment()
              .set("day", getIndexFromDay(day.key))
              .startOf("day")
              .toISOString();
            scheduledDates.push(ISODate);
          } else {
            const ISODate = moment()
              .add(1, "week")
              .set("day", getIndexFromDay(day.key))
              .startOf("day")
              .toISOString();
            scheduledDates.push(ISODate);
          }
        }
      });
      payload.weeklySchedule.pickUpDates = [...scheduledDates];
      payload.weeklySchedule.pickUpFlexibility = `${flexibility}`;
    } else {
      currDaySchedule?.forEach((day) => {
        if (day.active) {
          // if tab selected is this week, then send this week's dates otherwise send next week's dates
          if (tabSelected === "This week") {
            const ISODate = moment()
              .set("day", getIndexFromDay(day.key))
              .startOf("day")
              .toISOString();
            scheduledDates.push(ISODate);
          } else {
            const ISODate = moment()
              .add(1, "week")
              .set("day", getIndexFromDay(day.key))
              .startOf("day")
              .toISOString();
            scheduledDates.push(ISODate);
          }
        }
      });
      payload.weeklySchedule.dropOffDates = [...scheduledDates];
      payload.weeklySchedule.dropOffFlexibility = `${flexibility}`;
    }
    return payload;
  };

  // onclick handler for next button
  const handleNext = () => {
    const data = createRequestData();

    if (
      (data.weeklySchedule.pickUpDates === undefined ||
        data.weeklySchedule.pickUpDates.length === 0) &&
      (data.weeklySchedule.dropOffDates === undefined ||
        data.weeklySchedule.dropOffDates.length === 0)
    ) {
      enqueueSnackbar("Must have atleast one date !", { variant: "warning" });
    } else {
      updateWeeklySchedule(data)
        .then((val) => {
          enqueueSnackbar("Updated Successfully !", { variant: "success" });
          navigate("/dashboard/upcoming");
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Could not update !", { variant: "error" });
        });
    }
  };

  const handleToggle = (e: any, currentItem: KeyActiveTime) => {
    if (e.target.checked) {
      const newList = currDaySchedule.map((schedule) => {
        if (schedule.key === currentItem.key) {
          schedule.active = true;
        }
        return schedule;
      });
      setCurrDaySchedule(newList);
    } else {
      const newList = currDaySchedule.map((schedule) => {
        if (schedule.key === currentItem.key) {
          schedule.active = false;
        }
        return schedule;
      });
      setCurrDaySchedule(newList);
    }
  };

  // check if the toogle switch needs to be disabled or not
  const checkDisabled = (item: KeyActiveTime) => {
    // if tab selected is "This week", and today's day is ahead of schedule's weekday, then set disable to true
    if (decodedData.type === "Drop Off") {
      // if date is not defined for currently iterated weekday, then set the weekday to currently iterated weekday
      let thisWeekMomentObject =
        item.dropOff.length > 0
          ? moment(item.dropOff)
          : moment().set("day", getIndexFromDay(item.key.toUpperCase()));

      // if current iteration moment date is 2 days(48 hours) ahead of today's date, then don't disable the switch
      if (thisWeekMomentObject > moment().add(1, "day")) {
        return false;
      } else {
        return true;
      }
    } else {
      let thisWeekMomentObject =
        item.pickUp.length > 0
          ? moment(item.pickUp)
          : moment().set("day", getIndexFromDay(item.key.toUpperCase()));

      // if current iteration moment date is 2 days(48 hours) ahead of today's date, then don't disable the switch
      if (thisWeekMomentObject > moment().add(1, "day")) {
        return false;
      } else {
        return true;
      }
    }
  };

  // show the dates corresponding to tab selected in the format to display
  const showDateWithMonth = (item: KeyActiveTime) => {
    if (tabSelected === "This week") {
      // create ISO date string
      let dateString = "";
      if (decodedData.type === "Drop Off") {
        if (item.dropOff.trim().length === 0) {
          dateString = moment()
            .set("day", getIndexFromDay(item.key.toUpperCase()))
            .toISOString();
        } else {
          dateString = moment(item.dropOff).toISOString();
        }
      } else {
        if (item.pickUp.trim().length === 0) {
          dateString = moment()
            .set("day", getIndexFromDay(item.key.toUpperCase()))
            .toISOString();
        } else {
          dateString = moment(item.pickUp).toISOString();
        }
      }

      return moment(dateString).format("Do MMM");
    } else {
      // create ISO date string
      let dateString = "";
      if (decodedData.type === "Drop Off") {
        if (item.dropOff.trim().length === 0) {
          dateString = moment()
            .add(1, "week")
            .set("day", getIndexFromDay(item.key.toUpperCase()))
            .toISOString();
        } else {
          dateString = moment(item.dropOff).toISOString();
        }
      } else {
        if (item.pickUp.trim().length === 0) {
          dateString = moment()
            .add(1, "week")
            .set("day", getIndexFromDay(item.key.toUpperCase()))
            .toISOString();
        } else {
          dateString = moment(item.pickUp).toISOString();
        }
      }

      return moment(dateString).format("Do MMM");
    }
  };

  // const handleTimeChange = (currDtate: Moment | null, item: KeyActiveTime) => {
  //   if (currDtate === null) return;
  //   const key = decodedData.type === "Drop Off" ? "dropOff" : "pickUp";
  //   const newList = currDaySchedule.map((it) => {
  //     if (it.key === item.key) {
  //       const tI = getIndexFromDay(item.key);
  //       //if traget day in next week
  //       if (currDtate.day() > tI) {
  //         const numOfDays = tI + 7 - currDtate.day();
  //         currDtate.add(numOfDays, "day")
  //         it[key] = currDtate.toISOString();
  //       } else {
  //         const numOfDays = tI - currDtate.day();
  //         currDtate.add(numOfDays, "day")
  //         it[key] = currDtate.toISOString();
  //       }
  //     }
  //     return it;
  //   })
  //   setCurrDaySchedule(newList)
  // }

  // handle Tab button click
  const handleTabSelected = (selectedTab: string) => {
    // if same tab is selected again, then don't do anything
    if (selectedTab === tabSelected) {
      return;
    }

    // set the state to change tab styling
    setTabSelected(selectedTab);

    // if tab selected is next week, then enable all days whose dropOff/pickUp dates are selected in the local state
    if (selectedTab === "Next week") {
      setCurrDaySchedule((prev: KeyActiveTime[]) => {
        let nextWeekSchedule = prev.map((schedule) => {
          if (decodedData.type === "Drop Off") {
            schedule.active = true;

            return schedule;
          } else {
            schedule.active = true;

            return schedule;
          }
        });

        return nextWeekSchedule;
      });
    } else {
      // if selected tab is "This week", then check if the weekday has already passed(behind current date) or not
      setCurrDaySchedule((prev: KeyActiveTime[]) => {
        let thisWeekSchedule = prev.map((schedule) => {
          if (decodedData.type === "Drop Off") {
            if (
              moment().set("day", getIndexFromDay(schedule.key.toUpperCase())) >
              moment().add(1, "day")
            ) {
              schedule.active = true;
            } else {
              schedule.active = false;
            }

            return schedule;
          } else {
            if (
              moment().set("day", getIndexFromDay(schedule.key.toUpperCase())) >
              moment().add(1, "day")
            ) {
              schedule.active = true;
            } else {
              schedule.active = false;
            }

            return schedule;
          }
        });

        return thisWeekSchedule;
      });
    }
  };

  return (
    <>
      {decodedDataLoading ? (
        <LoadingScreen />
      ) : (
        <div id="pickup-drop">
          <Header
            rightIcon={
              <img
                alt="profile"
                src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
                className="header-profile-image bordered-2 shadow-light"
              />
            }
          />
          <Box className="margin-x">
            <div className="sub-header">
              <img alt="person" src={Config} />
              <p className="sub-text">
                Set your favourite routes and schedule that you would like to
                carpool. You can update these settings later from your route
                preferences.
              </p>
            </div>
          </Box>
          <p className="section-head">Configure route schedule for next week</p>
          <Box className="margin-y">
            <Box className="location-box">
              <div className="inline my-3">
                <p className="custom-label">{decodedData.type}</p>
              </div>
            </Box>
          </Box>
          <CustomDrawer
            anchor="bottom"
            isOpen={true}
            fullScreen={true}
            onClose={() => {}}
          >
            <Box id="pickup-drop-drawer" className="margin-x">
              <p
                className="drawer-title justify-start mb-4"
                style={{ fontSize: "24px" }}
              >
                Set {scheduleType} alerts
              </p>

              {/* Show this week or next week switch */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <p
                  className={`${
                    tabSelected === "This week" ? "pill pill-active" : "pill"
                  }`}
                  onClick={() => handleTabSelected("This week")}
                >
                  This week
                </p>
                <p
                  className={`${
                    tabSelected === "Next week" ? "pill pill-active" : "pill"
                  }`}
                  onClick={() => handleTabSelected("Next week")}
                >
                  Next week
                </p>
              </Box>

              <Box id="pickup-drop-drawer">
                <Box
                  className="options-box"
                  style={{ paddingLeft: "0rem", paddingRight: "0rem" }}
                >
                  {currDaySchedule?.map((item: KeyActiveTime, key: number) => (
                    <Box className="margin-y" key={key}>
                      <Box className="d-flex" alignItems={"center"}>
                        <UseSwitchesBasic
                          handleChange={(e: any) => handleToggle(e, item)}
                          checked={item.active}
                          disabled={
                            tabSelected === "This week"
                              ? checkDisabled(item)
                              : false
                          }
                        />{" "}
                        <div>
                          <p>{item.label}</p>

                          {/* show date and month text */}
                          <span className="text-sm">
                            {showDateWithMonth(item)}
                          </span>
                        </div>
                      </Box>

                      {/* <Box className={"sm-max-100"}>
                        <CustomDatePicker
                          disbled={!item.active}
                          handleChange={(val: Moment | null) => handleTimeChange(val, item)}
                          timeDefault={
                            (item?.dropOff || item?.pickUp) ?
                              decodedData.type === 'Pick Up' ? moment(item?.pickUp).format("HH:mm") : moment(item?.dropOff).format("HH:mm")
                              :
                              ""
                          }
                        />
                      </Box> */}
                    </Box>
                  ))}
                  {/* <>
                    <Box className="option-box-title">
                      {
                        decodedData.type === "Pick Up" ? (
                          <>
                            I’m flexible and willing to leave up to
                          </>
                        ) : (
                          <>
                            I’m flexible and willing to arrive up to
                          </>
                        )
                      }

                    </Box>
                    <Box>
                      <DropDown
                        pairs={decodedData.type === "Pick Up" ? pairsPickUp : pairsDropOff}
                        handleChange={(e: any) => setFlexibility(e.value)}
                        label={""}
                        val={flexibility}
                      />
                    </Box>
                    <Box className="option-box-helper-text">
                      This will help us match you with more members in your
                      community with similar travel schedules
                    </Box>
                  </> */}
                  <Button
                    className="save-btn custom-btn calc-top mt-16 btn-text"
                    fullWidth
                    onClick={() => handleNext()}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
          </CustomDrawer>
        </div>
      )}
    </>
  );
};

export default WeeklySchedule;

function getKeyActiveTime() {
  let res: Array<KeyActiveTime> = [
    {
      key: "MONDAY",
      label: "Monday",
      active: true,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "TUESDAY",
      label: "Tuesday",
      active: true,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "WEDNESDAY",
      label: "Wednesday",
      active: true,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "THURSDAY",
      label: "Thursday",
      active: true,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "FRIDAY",
      label: "Friday",
      active: true,
      pickUp: "",
      dropOff: "",
    },
  ];
  return res;
}

function getIndexFromDay(day: string) {
  switch (day) {
    case "MONDAY":
      return 1;
    case "TUESDAY":
      return 2;
    case "WEDNESDAY":
      return 3;
    case "THURSDAY":
      return 4;
    case "FRIDAY":
      return 5;
    default:
      return 1;
  }
}
