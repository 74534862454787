import React from "react";
import "./style.scss";
//import DefaultProfile from './../../assets/images/default-profile-50x50.svg'
import DefaultPerson from "./../../assets/images/user.svg";

interface PropInterface {
  image: string[];
}
const ImageComponentOverlapping = (props: PropInterface) => {
  let img1 = "";
  let img2 = "";
  let extra = "";
  let defaultImage = "";
  if (props.image.length >= 2) {
    extra = String(props.image.length - 2);
    img1 = props.image[0];
    img2 = props.image[1];
  } else if (props.image.length === 1) {
    img1 = props.image[0];
  } else {
    defaultImage =
      "https://cdn.pixabay.com/photo/2017/11/10/05/48/user-2935527_1280.png";
  }

  return (
    <div>
      <div className="cursor rel-box">
        {!!img1 && (
          <img
            alt="profile"
            id="img-top"
            src={img1}
            style={{ objectFit: "cover" }}
            height="44px"
            width="44px"
            className="rounded-full bordered-2 shadow-light"
          />
        )}
        {!!defaultImage && (
          <img
            alt="profile"
            id="img-top"
            src={DefaultPerson}
            height="44px"
            width="44px"
            className="rounded-full bordered-2 shadow-light"
          />
        )}
        {!!img2 && (
          <img
            alt="profile"
            id="img-bottom"
            style={{ objectFit: "cover" }}
            src={img2}
            height="44px"
            width="44px"
            className="rounded-full bordered-2 shadow-light"
          />
        )}
        {!!extra && Number(extra) > 2 && <div id="info-icon">+{extra}</div>}
      </div>
    </div>
  );
};

export { ImageComponentOverlapping };
