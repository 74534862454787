import { httpRequest } from "../network";

export const getUser = (jti: string) => {
  return httpRequest('GET', `auth/public/get-user?jti=${jti}`).then((response: any) => {
    return response
  }).catch((err) => {
    throw err
  })
}

// get user details by _id
export const getUserById = (id: string) => {
  return httpRequest('GET', `auth/user/${id}`).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const getUserByUniqueId = (uniqueId: string) => {
  return httpRequest('GET', `auth/public/user-by-unique-id/${uniqueId}`).then((response: {data: { "marketPlaceUsersCount": number, "user": { firstName: { lang: string; value: string }[], lastName: { lang: string; value: string }[] } }}) => {
    return response.data;
  }).catch(error => {
    throw error;
  })
}

export const getMarketPlaceUsers = () => {
  return httpRequest('GET', `auth/user/marketplace-users`).then((response: { data: object}) => {
    return response.data;
  }).catch(error => {
    throw error;
  })
}