import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

export interface MultiLanguage {
    lang: string,
    value: string
}

export interface ADDRESS {
    state: Array<MultiLanguage>,
    city: Array<MultiLanguage>,
    country: Array<MultiLanguage>,
    line1: Array<MultiLanguage>,
    line2: Array<MultiLanguage>,
    geo: number[],
    zip: string
}

export interface INVITETRIP {
    _id: string,
    childSeatsBooked: number,
    address: ADDRESS,
    destination: ADDRESS,
    dropDate?: Date | undefined,
    pickUp: ADDRESS,
    scheduleDate?: Date | undefined,
    seatsBooked: number,
    totalChildSeats: number,
    tripStatus: string,
    totalSeats: number,
    status: string,
    _driver: object,
    totalTripTime: number,
    totalTripDistance: number,
    driverToPassengerDistance?: number,
    passengerToDestinationDistance?: number,
    driverToPassengerTime?: number,
    passengerToDestinationTime?: number,
}


interface INVITEDUSERTRIP {
    trip?: INVITETRIP;
}

const initialState: INVITEDUSERTRIP = {
    trip: {} as INVITETRIP
};

export const inviteTripSlice = createSlice({
    name: 'inviteTripReducer',
    initialState,
    reducers: {
        setInviteUserTrip: (state, action: PayloadAction<INVITETRIP>) => {
            state.trip = action.payload
        }
    },
})


export const {
    setInviteUserTrip
} = inviteTripSlice.actions;


export default inviteTripSlice.reducer
