import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import './style.scss';
import Logo from '../../assets/images/logo-wth-name.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import BackArrow from '../../assets/images/Arrow-Narrow-Left.svg'
import HamburgerMenu from '../../assets/images/hamburgerMenu.svg'
import { open } from '../../slice/drawer'
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';


interface props {
  rightIcon?: JSX.Element | ReactNode
  backLink?: string,
  canGoBack?: boolean,
  showBackIcon?: boolean,
  login?: boolean,
  type?: string,
  setType?: Dispatch<SetStateAction<"login" | "signup">>;
  signUp?: boolean,
  publicSignUp?: boolean,
  privateSignUp?: boolean
}
//eslint-disable-next-line
const Header: FC<props> = ({ rightIcon = <></>, backLink = "/profile", canGoBack = false, showBackIcon = false, login = false, type = 'login', setType = undefined, signUp = false, publicSignUp, privateSignUp }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userDetailsReducer = useSelector((state: RootState) => state.userDetailsReducer);

  const handleClick = () => {
    if (userDetailsReducer.user.email) {
      navigate("/dashboard/home");
    } else {
      navigate("/");
    }
  }

  const handleClickRightIcon = () => {
    dispatch(open());
  }

  const handleButtonClick = () => {
    if (pathname === '/') {
      // navigate('/signup');
      window.location.href = "https://www.ecopooling.co.uk/signup"
    } else if (pathname === '/signup' || publicSignUp || privateSignUp) {
      navigate('/');
    }
  };


  return (
    <>
      <div id='mobile-header' className={'header-box parent'}>
        <Link to={backLink} className={`${showBackIcon ? 'd-block' : 'hidden'}`}>
          <img alt='backArrow' className={`cursor-pointer`} src={BackArrow} />
        </Link>
        <img alt='logo' className='cursor-pointer' onClick={handleClick} src={Logo} />

        {
          login === true && setType !== undefined ? (
            <Button
              onClick={() => {
                type === "login" ? setType("signup") : setType("login");
                //handleCickRightButton();
              }}
              variant="outlined"
              className="login-signup-button"
            >
              {type === "login" ? "Sign up" : "Log in"}
            </Button>
          ) : (
            <div className='d-flex items-center gap-05'>
              {rightIcon}
              {signUp === false && (
                <img className='cursor-pointer' src={HamburgerMenu} alt='hamburger-menu' onClick={handleClickRightIcon} />
              )
              }
              {
                (publicSignUp || privateSignUp) && <Button
                  variant="outlined"
                  className="login-signup-button"
                  onClick={handleButtonClick}
                >
                  {pathname === '/' ? "Sign up" : "Log in"}
                </Button>
              }
            </div>
          )
        }

      </div >
    </>
  )
}

Header.defaultProps = {
  publicSignUp: false,
  privateSignUp: false
}

export default Header
