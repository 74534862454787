import axios from "axios";
import { enqueueSnackbar } from "notistack";
axios.defaults.responseType = "json";
// axios.defaults.baseURL = "http://192.168.1.35:80";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
function httpRequest<T>(
  method: "GET" | "POST" | "PUT" | "DELETE" | "OPTION",
  url: string,
  data = {},
  params = {}
) {
  return new Promise<T>((resolve, reject) => {
    axios({
      method,
      url,
      data,
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
        "Content-Type": "application/json",
      },
    })
      .then((response: any) => {
        if (response.data.meta && response.data.meta.maintenance === true) {
          window.location.href=`${process.env.REACT_APP_WEBSITE_URL}/maintenance`;
          return
        }
        if (String(response.status).match(/20/gi)) {
          resolve(response.data);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err: any) => {
        // if (err.status === false && err.statusCode)
        console.log("inside catch", err.response);
        reject(err);
      });
  });
}

// for file upload requests
function httpFormRequest<T>(url: string, file: any = {}) {
  return new Promise<T>((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("originalname", file.name);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
        "Content-Type": "multipart/form-data",
        "x-locale": `${localStorage.getItem("locale") || "en"}`,
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        if (String(response.status).match(/20/gi)) {
          resolve(response.data);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err) => {
        // localStorage.removeItem('token');
        console.log("inside catch", err);
        reject(err);
      });
  });
}

function httpRequestDownload<T>(
  method: "GET" | "POST" | "PUT" | "DELETE" | "OPTION",
  url: string,
  data = {},
  params = {}
) {
  return new Promise<T>((resolve, reject) => {
    axios({
      method,
      url,
      data,
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
        "Content-Type": "application/json",
      },
      responseType: "blob",
    })
      .then((response: any) => {
        if (String(response.status).match(/20/gi)) {
          resolve(response.data);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err: any) => {
        // if (err.status === false && err.statusCode)
        console.log("insdie catch", err.response);
        reject(err);
      });
  });
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Check if the error response has a status code of 401
    if (error.response && error.response.status === 401) {
      enqueueSnackbar("Session expired. Please login again", {
        variant: "error",
      });
      localStorage.removeItem("token");
      // window.location.href = 'https://ecopooling-web.relinns.co.in';
      window.location.href = `${process.env.REACT_APP_WEBSITE_URL}`;
      // You can redirect the user to a login page or refresh access token, etc.
    }

    return Promise.reject(error);
  }
);

export { httpRequest, axios, httpFormRequest, httpRequestDownload };
