import { httpRequest } from "../network";

export const generateGoogleCode = () => {
  return httpRequest('GET', 'auth/login/user/generate-google-code').then((response: any) => {
    return response
  }).catch((err) => {
      throw err
    })
}

export const generateFBCode = () => {
  return httpRequest('GET', 'auth/login/user/generate-google-code').then((response: any) => {
    return response
  }).catch((err) => {
      throw err
    })
}

export const setCredentials = (data: object) => {
  return httpRequest('POST', 'auth/login/user/verify-credentials', data).then((response: any) => {
    console.log(response)
    return response
  }).catch((err) => {
      throw err
    })
}

export const setFacebookCredentials = (data: object) => {
  return httpRequest('GET', 'auth/login/user/verify-facebook-credentials', {}, data).then((response: any) => {
    console.log(response)
    return response
  }).catch((err) => {
      throw err
    })
}

export const setAppleCredentials = (data: object) => {
  return httpRequest('GET', 'auth/login/user/verify-apple-credentials', {}, data).then((response: any) => {
    console.log(response)
    return response
  }).catch((err) => {
      throw err
    })
}


export const getUserFromDraft = (data:any) => {
  return httpRequest('POST', '/auth/signup/get-draft',data).then((response: any) => {
    return response.data
  }).catch((err) => {
      throw err
    })
}

export const getUserFromDraftById = (data:any) => {
  return httpRequest('POST', '/auth/signup/get-draft-id',data).then((response: any) => {
    return response.data
  }).catch((err) => {
      throw err
    })
}