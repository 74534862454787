import React, { Fragment, useEffect, useState } from 'react'
import MatchResultListItem from '../../Common components/matched-result-list';
import noMatchIcon from "../../../../assets/images/no-match.svg";
import './style.scss';
import {Box,Button} from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../../components/loading-screen';


const NoMatchScreen = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate()
  const location = useLocation()

  // if user is directly coming to this page, then redirect to home
  useEffect(() => {
    if(!location.state?.fromOfferCarpool) {
      navigate("/dashboard/home")
    } else {
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  const handleButtonClick = () => {
       navigate("/dashboard/home")
  }

  return (
    <Fragment>
      {
        loading ? (
          <LoadingScreen />
        ) : (
         <>
          <div className="no-match d-flex justify-center items-center p-1">
            <MatchResultListItem
              icon={noMatchIcon}
              heading="No matches on your route"
              text="There’s currently no passengers in your carpool community on your route. We’ll notify you when we find a match!"
            />
    
          </div>
    
          <Box className="next-btn-box" style={{ position: "sticky" }}>
                      <Box className="margin-x d-flex justify-center items-center">
                        <Button
                          className="next-btn custom-btn btn-width"
                          fullWidth
                        onClick={handleButtonClick}
                        >
                        Try another route
                        </Button>
                      </Box>
          </Box>
         </> 
        )
      }
    </Fragment>
  );
}

export default NoMatchScreen
