import './style.scss';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../../components/header';
import DefaultProfile from '../../../../../assets/images/default-profile.svg';
import Copy from '../../../../../assets/images/copy.svg';
import share from '../../../../../assets/images/share.svg';
import ContactBook from '../../../../../assets/images/whatsapp.svg';
import ShareImage from '../../../../../assets/images/share-screen_img.svg';

const ShareDetail = () => {
    const navigate = useNavigate();

    return (
        <div id='share-detail'>
            <Header
                rightIcon={<img alt='profile' src={DefaultProfile} />}
            />
            <Box className='margin-x' >
                <div className="sub-header">
                    <img alt='person' src={share} />
                    <p className='sub-text'>Refer and earn! Invite friends to join EcoPooling and get £5 gift card if they sign up and make a trip.</p>
                </div>
            </Box>
            {/* <p className="section-head">Vehicle details</p> */}
            <div className="divider-line" style ={{borderBottom:'1px solid #EEEEEE'}}></div>
            <Box className='margin-x'>
                <Box textAlign='center' marginY='24px'>
                    <img alt='share' src={ShareImage} />
                </Box>
                <Box className='share-benefits'>
                    <p className='sub-text-16'>How do you earn the reward?</p>
                    <Box marginTop='8px'>
                        <ul>
                            <li className='sub-text-14'>Invite your friends to sign up</li>
                            <li className='sub-text-14'>If 5 friends sign up and make at least 1 trip you receive £5 in your preferred gift card</li>
                        </ul>
                    </Box>
                </Box>
                <Box className='referral' marginBottom='24px'>
                    <p className='sub-text-14'>Referral code</p>
                    <Box className='copy-code center'>
                        <p className='sub-text-14'>REF34353534</p>
                        <img alt='person' src={Copy} />
                    </Box>
                </Box>
                <Button className='next-btn custom-btn' fullWidth onClick={() => navigate('/dashboard/upcoming')}>
                    <img alt='person' src={ContactBook} />
                    Invite from contacts
                </Button>
            </Box>
        </div>
    )
}

export default ShareDetail;