import { Outlet, useNavigate } from "react-router-dom";
import { getProfile } from "../../services/loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { initUserState } from "../../slice/login";
import { useEffect, useState } from "react";
import Drawer from './../../components/drawer';
import LoadingScreen from "../../components/loading-screen";
import { marketPlaceUserACL } from "../../utilities/helper";

export const Guard = () => {

  const userDetailsReducer = useSelector((state: RootState) => state.userDetailsReducer);
  const { user } = userDetailsReducer;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getUser = async () => {
    setLoading(true);
    try {
      const res = await getProfile();

      res.userPreference === 'MARKET_PLACE' &&
      !marketPlaceUserACL.includes(window.location.pathname) &&
      navigate('/dashboard/market-place');

      dispatch(initUserState(res));
    } catch (error) {
      localStorage.removeItem("token");

      navigate("/");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user.email === "") {
      getUser();
    } else {
      setLoading(false)
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      {loading ?
        <LoadingScreen />
        : <Outlet />}
      <Drawer />
    </div>
  )
}

export default Guard
