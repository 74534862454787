import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ScheduleData } from '../../interface/favourite-location'

export interface multiLanguage {
    lang: string,
    value: string
}

export interface ScheduleInterface {
    day: string
    dropOff: string
    pickUp: string
}

export interface DailyScheduleInterface {
    dropOffFlexibility: string
    pickUpFlexibility: string
    schedule: Array<ScheduleInterface>
}



export interface FavLocInterface {
    categoryName: Array<multiLanguage>
    locationName: Array<multiLanguage>
    categoryId: string
    locationId: string
    dailySchedule: DailyScheduleInterface,
    weeklySchedule?: any
}

export interface Utility {
    favLocation: Array<FavLocInterface>,
    scheduleResponse: ScheduleData,
    verificationInitialCall?: boolean,
    redirectURL?: string,
    allFavLocationsOfUser ?: any[],
    locationDetail ?: any,
    locationAdminSignUp ?: boolean
}

const initialState: Utility = {
    favLocation: [] as FavLocInterface[],
    scheduleResponse: {} as ScheduleData,
    verificationInitialCall: true,
    redirectURL: "",
    allFavLocationsOfUser: [],
    locationDetail : {},
    locationAdminSignUp : false
}

export const utilitySlice = createSlice({
    name: 'utilityReducer',
    initialState,
    reducers: {
        updateFavLocationUtility: (state, action: PayloadAction<FavLocInterface[]>) => {
            state.favLocation = action.payload
        },
        setScheduleResponse: (state, action) => {
            state.scheduleResponse = action.payload
        },
        setVerificationInitialCall: (state, action) => {
            state.verificationInitialCall = action.payload
        },

        setRedirectURL: (state, action) => {
            state.redirectURL = action.payload
        },
        setUsersAllFavLocation: (state,action) => {
            state.allFavLocationsOfUser = action.payload
        },
        setLocationDetail : (state,action) => {
            state.locationDetail = action.payload
        },
        setLocationAdminSignUp : (state, action) => {
            state.locationAdminSignUp= action.payload
        }

    },
})

export const {
    updateFavLocationUtility,
    setScheduleResponse,
    setVerificationInitialCall,
    setRedirectURL,
    setUsersAllFavLocation,
    setLocationDetail,
    setLocationAdminSignUp
} = utilitySlice.actions

export default utilitySlice.reducer
