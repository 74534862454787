import { Box } from "@mui/material"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Header from "../../../../components/header";
import './style.scss';
import { getMarketPlaceUsers } from "../../../../services/user";
import { enqueueSnackbar } from "notistack";
import LoadingScreen from "../../../../components/loading-screen";

type ILanguage = { value: string; lang: string; }[];


const MarketPlaceFriendsList = () => {
  const { userDetailsReducer } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState(true)

  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    try {
      const marketplaceUsers = await getMarketPlaceUsers() as { firstName: ILanguage, lastName: ILanguage, phone: string }[];
      const modifiedMarketPlaceUsers = marketplaceUsers.map(user => {
        const { firstName, lastName, phone } = user;
        const name = `${firstName && firstName[0] && firstName[0].value.slice(0, 1).toUpperCase() + firstName[0].value.slice(1)} ${lastName && lastName[0] && lastName[0].value.slice(0, 1).toUpperCase() + lastName[0].value.slice(1)}`
        return {
          name,
          phone,
        }
      });
  
      setUsers(modifiedMarketPlaceUsers);
    } catch (error) {
      enqueueSnackbar("unable to get user data. Please try again later.", { variant: "error" })
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);


  return (
    <Box id="marketplace-friends-list">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
      />

      {
        loading ? <LoadingScreen /> : (
          <div className="marketplace-friends-list-content">
            <h3>My rewards group members</h3>
            {
              users.length > 0 && <div className="marketplace-friends-list-container">
                {
                  users.map((item, index) => {
                    return (<Friend user={item} key={item.phone} borderTop={index !== 0} />)
                  })
                }
              </div>
            }
            {
              users.length <= 0 && (<div className="text-center p-1"> No marketplace users</div>)
            }
          </div>
        )
      }
    </Box>
  )
}

const Friend = ({ user, borderTop }) => {
  return (
    <Box className={`friend-container ${borderTop === true ? 'border-top' : ''}`}>
      <b>{user.name}</b>
      <small><b>{user.phone}</b></small>
    </Box>
  )
}

export default MarketPlaceFriendsList;