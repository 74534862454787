import "./style.scss";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import Config from "../../../assets/images/config.svg";
import ArrowDown from "../../../assets/images/ArrowDown.svg";
import { Box, TextField } from "@mui/material";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import { useEffect, useState } from "react";
import CustomDatePicker from "../../../components/time-picker";
import UseSwitchesBasic from "../../../components/switch";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { setDecodedLoading } from "../../../slice/redirect-data";
import { useNavigate, useParams } from "react-router-dom";
import {
  createMultipleTrips,
  resetTripState,
  resetTripSuccess,
  setInitialRender,
} from "../../../slice/trip";
import { getSingleLocation, resetLocationState } from "../../../slice/category";
import { IDailySchedule, IRequestBody } from "../../../interface/offer-carpool";
import { enqueueSnackbar } from "notistack";
import {
  getDefaultSchedule,
  updateWeeklySchedule,
} from "../../../services/schedule";
import moment, { Moment } from "moment";
import { 
  // formateScheduleDateTime, 
  getWeekdayName } from "../../../utilities/helper";
import LoadingScreen from "../../../components/loading-screen";
import { updateUserDailySchedule } from "../../../slice/user";
import { setScheduleResponse } from "../../../slice/utility";
// import { ScheduleItem } from "../../../interface/favourite-location";
import React from "react";
import { resetParkingDiscount } from "../../../slice/invites";
import CustomFixedButton from "../../../components/custom-fixed-button";

interface KeyActiveTime {
  label: string;
  key: string;
  active: boolean;
  pickUp: string;
  dropOff: string;
  momentObj?: Moment;
}

const OfferCarpool = () => {
  //eslint-disable-next-line
  const [currDaySchedule, setCurrDaySchedule] = useState(getKeyActiveTime());
  const [flexibility, setFlexibility] = useState("");
  const [dataFetching, setDataFetching] = useState(true);
  // const [tabSelected, setTabSelected] = useState("This week");
  const [locationData, setLocationData] = useState({locationName: [{value: ""}]});
  const [datesExpanded, setDatesExpanded] = useState(false);
  const [scheduleData, setScheduleData] = useState<KeyActiveTime[]>([])

  const { decodedData, decodedDataLoading } = useSelector(
    (store: RootState) => store.redirectDataReducer
  );
  const { location, locationError } = useSelector(
    (store: RootState) => store.categoryReducer
  );
  const { user } = useSelector((store: RootState) => store.userReducer);
  const { trips, tripError, tripSuccess, initialRender } = useSelector(
    (store: RootState) => store.tripReducer
  );
  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let { data: base64Data } = useParams();

  // set decoded loading to false if decoded data is already fetched
  useEffect(() => {
    if (Object.keys(decodedData).length > 0) {
      dispatch(setDecodedLoading(false));
    } else if (Object.keys(decodedData).length === 0) {
      // if user comes directly to this page, then redirect to redirection landing page
      navigate(`/redirect/${base64Data}`);
    }
    //eslint-disable-next-line
  }, []);

  // as soon as location data is fetched, call the handleNext button click handler again to create the trips
  useEffect(() => {
    if (Object.keys(location).length > 0 && initialRender) {
      handleNext();
    }
    //eslint-disable-next-line
  }, [location]);

  // if there is some error in fetching the location data, then show the error to user and reset the location state
  useEffect(() => {
    if (locationError.length > 0) {
      enqueueSnackbar("Location not found");

      // reset the location state
      dispatch(resetLocationState());
    }
    //eslint-disable-next-line
  }, [locationError]);

  // if trip creation fails, then show the appropriate error
  useEffect(() => {
    if (tripError.length > 0) {
      enqueueSnackbar("Trip already exists", { variant: "warning" });
      // reset the location state
      dispatch(resetTripState());
    }
    //eslint-disable-next-line
  }, [tripError]);

  // as soon as trips are created, redirect to select matches screen to make driver select users to send invite to
  useEffect(() => {
    if (tripSuccess) {
      // redirect to matches screen
      navigate(`/redirect-page/matches/all/${base64Data}`);

      // reset the trip success
      dispatch(resetTripSuccess());

      // make initialRender state to false
      dispatch(setInitialRender());
    }
    //eslint-disable-next-line
  }, [trips]);

  // fetch the default schedule
  useEffect(() => {
    setDataFetching(true);

    getDefaultSchedule({ locationId: decodedData.locationId })
      .then((res: any) => {
        setLocationData(res.meta)

        // const schedule = res?.data?.dailySchedule?.schedule as Array<any>;

        // store the response in redux to use later when tab is switched
        dispatch(setScheduleResponse(res?.data?.dailySchedule));

        // setSchedule(schedule, tabSelected);
        createScheduleData(res?.data?.dailySchedule?.schedule);
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => {
        // set loading to false
        setDataFetching(false);
      });

    //eslint-disable-next-line
  }, []);

  // reset discount and parking
  useEffect(() => {
    dispatch(resetParkingDiscount());

    // eslint-disable-next-line
  }, []);

  // create schedule data for ten days
  const createScheduleData = (schedule: [{day: string, dropOff: string, pickUp: string}]) => {
    let daysSet = new Set();

    schedule.forEach((scheduleObj) => {
      if(decodedData.type === "Drop Off") {
        if(scheduleObj.dropOff && scheduleObj.dropOff !== "") {
          daysSet.add(scheduleObj.day)
        }
      } else {
        if(scheduleObj.pickUp && scheduleObj.pickUp !== "") {
          daysSet.add(scheduleObj.day)
        }
      }
    })

    let daysArray = Array.from(daysSet)

    // create 10 rows of data
    let scheduleData: KeyActiveTime[] = [];
    // let startDate = moment().add(2, "day").startOf("day");
    // let skippedDays = 0;
    let counter = 0

    for(let i = 0; counter < 10; i++ ) {
      let momentObj = moment().add(2, "day").startOf("day").add(i, 'day')

      if(momentObj.day() === 6 || momentObj.day() === 0) {
        // skippedDays += 1

        continue
      }

      let dropOffTime = ""
      let pickUpTime = ""
      let activeFlag = false

      const dayName = getWeekdayName(momentObj.day())

      // check if current schedule matches with the previous schedule
      if(daysArray.includes(dayName)) {
        activeFlag = true

        if(decodedData.type === "Drop Off") {
          dropOffTime = schedule.find((scheduleObj) => scheduleObj.day === dayName)?.dropOff

          momentObj.set({"hour": parseInt(dropOffTime.split(":")[0]), "minute": parseInt(dropOffTime.split(":")[1])})

          dropOffTime = momentObj.toISOString()
          
          daysArray.splice(daysArray.indexOf(dayName), 1)
        } else {
          pickUpTime = schedule.find((scheduleObj) => scheduleObj.day === dayName)?.pickUp

          momentObj.set({"hour": parseInt(pickUpTime.split(":")[0]), "minute": parseInt(pickUpTime.split(":")[1])})

          pickUpTime = momentObj.toISOString()

          daysArray.splice(daysArray.indexOf(dayName), 1)
        }
      }
      
      scheduleData[counter] = {
        // label: getWeekdayName(startDate.day() + i - skippedDays),
        // key: getWeekdayName(startDate.day() + i - skippedDays).toUpperCase(),
        label: getWeekdayName(momentObj.day()),
        key: getWeekdayName(momentObj.day()).toUpperCase(),
        active: activeFlag,
        pickUp: pickUpTime,
        dropOff: dropOffTime,
        momentObj
      }

      // keep count of number of days
      counter += 1
    }

    setScheduleData(scheduleData)
    setCurrDaySchedule(scheduleData.slice(0,5))
  }

  // // set the schedule according to fetched schedule
  // const setSchedule = (schedule: ScheduleItem[], selectedTab: string) => {
  //   if (decodedData.type === "Drop Off") {
  //     schedule?.forEach((sch) => {
  //       // because of one based indexing
  //       let targetIndex = getIndexFromDay(sch.day.toUpperCase()) - 1;

  //       // if tab selected is 'This week' and current day is ahead of schedule's day, then set active to false
  //       if (selectedTab === "This week") {
  //         // set tomorrow's date
  //         let tomorrow = moment().add(1, "day").endOf("day");

  //         if (
  //           tomorrow >=
  //           moment().set("day", getIndexFromDay(sch.day.toUpperCase()))
  //         ) {
  //           currDaySchedule[targetIndex].active = false;
  //         } else {
  //           currDaySchedule[targetIndex].active =
  //           sch.dropOff && sch.dropOff !== "" ? true : false;
  //         }
  //       } else {
  //         currDaySchedule[targetIndex].active =
  //           sch.dropOff && sch.dropOff !== "" ? true : false;
  //       }

  //       if (sch.dropOff && sch.dropOff !== "") {
  //         currDaySchedule[targetIndex].dropOff = formateScheduleDateTime(
  //           targetIndex + 1,
  //           sch.dropOff,
  //           selectedTab
  //         );
  //       }

  //       setCurrDaySchedule([...currDaySchedule] as KeyActiveTime[]);
  //     });
  //   } else {
  //     schedule?.forEach((sch) => {
  //       // because of one based indexing
  //       let targetIndex = getIndexFromDay(sch.day.toUpperCase()) - 1;

  //       // if tab selected is 'This week' and current day is ahead of schedule's day, then set active to false
  //       if (selectedTab === "This week") {
  //         // set tomorrow's date
  //         let tomorrow = moment().add(1, "day").endOf("day");

  //         if (
  //           tomorrow >=
  //           moment().set("day", getIndexFromDay(sch.day.toUpperCase()))
  //         ) {
  //           currDaySchedule[targetIndex].active = false;
  //         } else {
  //           currDaySchedule[targetIndex].active =
  //             sch.pickUp && sch.pickUp !== "" ? true : false;
  //         }
  //       } else {
  //         currDaySchedule[targetIndex].active =
  //           sch.pickUp && sch.pickUp !== "" ? true : false;
  //       }

  //       if (sch.pickUp && sch.pickUp !== "") {
  //         currDaySchedule[targetIndex].pickUp = formateScheduleDateTime(
  //           targetIndex + 1,
  //           sch.pickUp,
  //           selectedTab
  //         );
  //       }

  //       setCurrDaySchedule([...currDaySchedule] as KeyActiveTime[]);
  //     });
  //   }
  // };

  // format an object based on request data payload requirements
  const createRequestData = () => {
    // extract dates from currentDaySchedule state
    let scheduledDates: string[] = [];
    let dailyScheduleObj = { schedule: [] } as IDailySchedule;

    if (decodedData.type === "Drop Off") {
      currDaySchedule.forEach((daySchedule) => {
        if (daySchedule.active && daySchedule.dropOff.length > 0) {
          scheduledDates.push(daySchedule.dropOff);

          // set daily schedule values to update in DB
          dailyScheduleObj.dropOffFlexibility = flexibility;

          dailyScheduleObj.schedule.push({
            day: daySchedule.label,
            dropOff: moment(daySchedule.dropOff).format("HH:mm"),
          });
        }
      });
    } else {
      currDaySchedule.forEach((daySchedule) => {
        if (daySchedule.active && daySchedule.pickUp.length > 0) {
          scheduledDates.push(daySchedule.pickUp);

          // set daily schedule values to update in DB
          dailyScheduleObj.pickUpFlexibility = flexibility;

          dailyScheduleObj.schedule.push({
            day: daySchedule.label,
            pickUp: moment(daySchedule.pickUp).format("HH:mm"),
          });
        }
      });
    }

    const requestData: IRequestBody = {
      _driver: user._id,
      scheduleDate: scheduledDates,
    };

    if (decodedData.type === "Pick Up") {
      requestData.pickUp = location.address;

      requestData.pickUpLocationId = decodedData.locationId;
    } else {
      requestData.destination = location.address;

      requestData.destinationLocationId = decodedData.locationId;
    }

    return [requestData, dailyScheduleObj];
  };

  const createPayload = () => {
    let scheduledDates: string[] = [];
    const payload = {
      weeklySchedule: {} as any,
      _favoriteLocation: decodedData.locationId,
    };

    if (decodedData.type === "Pick Up") {
      currDaySchedule?.forEach((day) => {
        if (day.active && day.pickUp.length > 0) {
          scheduledDates.push();
        }
      });
      payload.weeklySchedule.pickUpDates = [...scheduledDates];
      payload.weeklySchedule.pickUpFlexibility = `${flexibility}`;
    } else {
      currDaySchedule?.forEach((day) => {
        if (day.active && day.dropOff.length > 0) {
          scheduledDates.push(day.dropOff);
        }
      });
      payload.weeklySchedule.dropOffDates = [...scheduledDates];
      payload.weeklySchedule.dropOffFlexibility = `${flexibility}`;
    }
    return payload;
  };

  // onclick handler for next button
  const handleNext = () => {
    if (decodedData.redirect === "Offer") {
      // if type is pick up, then the destination address will be the home location of the driver and vice-versa
      // fetch the address of the location based on location Id

      if (Object.keys(location).length === 0) {
        dispatch(getSingleLocation(decodedData.locationId));
        return;
      }

      const [requestDataObj, dailyScheduleObj] = createRequestData();

      // if no day is selected, then show the alert message
      if (
        (requestDataObj as { scheduleDate: string[] }).scheduleDate.length === 0
      ) {
        enqueueSnackbar("Please select schedule first", { variant: "error" });
        return;
      }

      // make an API request to create trips and update daily schedule
      dispatch(createMultipleTrips(requestDataObj));

      dispatch(
        updateUserDailySchedule({
          dailySchedule: dailyScheduleObj,
          _favoriteLocation: decodedData.locationId,
        })
      );
    }

    if (decodedData.redirect === "Finder") {
      const payload = createPayload();
      updateWeeklySchedule(payload)
        .then(() => navigate("/dashboard/upcoming"))
        .catch((err) => console.log(err));
    }
  };

  const handleToggle = (e: any, currentItem: KeyActiveTime) => {
    if (e.target.checked) {
      const newList = currDaySchedule.map((schedule) => {

        if (schedule.momentObj === currentItem.momentObj) {
          schedule.active = true;
        }

        return schedule;
      });

      setCurrDaySchedule(newList);
    } else {
      const newList = currDaySchedule.map((schedule) => {
        if (schedule.momentObj === currentItem.momentObj) {
          schedule.active = false;
        }

        return schedule;
      });

      setCurrDaySchedule(newList);
    }
  };

  const handleTimeChange = (currDtate: Moment | null, item: KeyActiveTime) => {
    if (currDtate === null) return;

    const key = decodedData.type === "Drop Off" ? "dropOff" : "pickUp";

    const newList = currDaySchedule.map((it) => {
      if (it.momentObj === item.momentObj) {
        it[key] = item.momentObj.set({"hour": currDtate.hour(), "minute": currDtate.minute()}).toISOString()
      }

      return it;
    });

    setCurrDaySchedule(newList);
  };

  // // check if the toogle switch needs to be disabled or not
  // const checkDisabled = (item: KeyActiveTime) => {
  //   // if tab selected is "This week", and today's day is ahead of schedule's weekday, then set disable to true
  //   if (decodedData.type === "Drop Off") {
  //     // if date is not defined for currently iterated weekday, then set the weekday to currently iterated weekday
  //     let thisWeekMomentObject =
  //       item.dropOff.length > 0
  //         ? moment(item.dropOff)
  //         : moment().set("day", getIndexFromDay(item.key.toUpperCase()));
  //     // if current iteration moment date is 2 days(48 hours) ahead of today's date, then don't disable the switch
  //     if (thisWeekMomentObject > moment().add(1, "day")) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     let thisWeekMomentObject =
  //       item.pickUp.length > 0
  //         ? moment(item.pickUp)
  //         : moment().set("day", getIndexFromDay(item.key.toUpperCase()));
  //     // if current iteration moment date is 2 days(48 hours) ahead of today's date, then don't disable the switch
  //     if (thisWeekMomentObject > moment().add(1, "day")) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  // };

  // // show the dates corresponding to tab selected in the format to display
  // const showDateWithMonth = (item: KeyActiveTime) => {
  //   if (tabSelected === "This week") {
  //     // return moment().set('day', getIndexFromDay(item.key)).startOf('day').format('Do MMM')

  //     // create ISO date string
  //     let dateString = "";
  //     if (decodedData.type === "Drop Off") {
  //       if (item.dropOff.trim().length === 0) {
  //         dateString = moment()
  //           .set("day", getIndexFromDay(item.key.toUpperCase()))
  //           .toISOString();
  //       } else {
  //         dateString = moment(item.dropOff).toISOString();
  //       }
  //     } else {
  //       if (item.pickUp.trim().length === 0) {
  //         dateString = moment()
  //           .set("day", getIndexFromDay(item.key.toUpperCase()))
  //           .toISOString();
  //       } else {
  //         dateString = moment(item.pickUp).toISOString();
  //       }
  //     }

  //     return moment(dateString).format("Do MMM");
  //   } else {
  //     // return moment().add(1, "week").set('day', getIndexFromDay(item.key)).startOf('day').format('Do MMM')

  //     // create ISO date string
  //     let dateString = "";
  //     if (decodedData.type === "Drop Off") {
  //       if (item.dropOff.trim().length === 0) {
  //         dateString = moment()
  //           .add(1, "week")
  //           .set("day", getIndexFromDay(item.key.toUpperCase()))
  //           .toISOString();
  //       } else {
  //         dateString = moment(item.dropOff).toISOString();
  //       }
  //     } else {
  //       if (item.pickUp.trim().length === 0) {
  //         dateString = moment()
  //           .add(1, "week")
  //           .set("day", getIndexFromDay(item.key.toUpperCase()))
  //           .toISOString();
  //       } else {
  //         dateString = moment(item.pickUp).toISOString();
  //       }
  //     }

  //     return moment(dateString).format("Do MMM");
  //     // return moment(decodedData.type === "Drop Off" ? item.dropOff : item.pickUp).format('Do MMM')
  //   }
  // };

  // // handle Tab button click
  // const handleTabSelected = (selectedTab: string) => {
  //   // if same tab is selected again, then don't do anything
  //   if (selectedTab === tabSelected) {
  //     return;
  //   }

  //   // set the state to change tab styling
  //   setTabSelected(selectedTab);

  //   // if tab selected is next week, then enable all days whose dropOff/pickUp dates are selected in the local state
  //   if (selectedTab === "Next week") {
  //     setCurrDaySchedule((prev: KeyActiveTime[]) => {
  //       let nextWeekSchedule = prev.map((schedule) => {
  //         if (decodedData.type === "Drop Off") {
  //           if (schedule.dropOff.trim().length > 0) {
  //             schedule.active = true;
  //             schedule.dropOff = moment(schedule.dropOff)
  //               .add(1, "week")
  //               .toISOString();
  //           }

  //           return schedule;
  //         } else {
  //           if (schedule.pickUp.trim().length > 0) {
  //             schedule.active = true;
  //             schedule.pickUp = moment(schedule.pickUp)
  //               .add(1, "week")
  //               .toISOString();
  //           }

  //           return schedule;
  //         }
  //       });

  //       return nextWeekSchedule;
  //     });
  //   } else {
  //     // if selected tab is "This week", then check if the weekday has already passed(behind current date) or not
  //     setCurrDaySchedule((prev: KeyActiveTime[]) => {
  //       let thisWeekSchedule = prev.map((schedule) => {
  //         if (decodedData.type === "Drop Off") {
  //           // if time is set for this date, then update the date and activate/deactivate it
  //           if (schedule.dropOff.trim().length > 0) {
  //             // extract day, hour and minute values to update the date
  //             const weekday = moment(schedule.dropOff).get("day");
  //             const hour = moment(schedule.dropOff).get("hour");
  //             const minute = moment(schedule.dropOff).get("minute");

  //             if (
  //               moment().set({ day: weekday, hour, minute }) >
  //               moment().add(1, "day")
  //             ) {
  //               schedule.active = true;
  //               schedule.dropOff = moment()
  //                 .set({ day: weekday, hour, minute })
  //                 .toISOString();
  //             } else {
  //               schedule.active = false;
  //               schedule.dropOff = moment()
  //                 .set({ day: weekday, hour, minute })
  //                 .toISOString();
  //             }
  //           }

  //           return schedule;
  //         } else {
  //           // if time is set for this date, then update the date and activate/deactivate it
  //           if (schedule.pickUp.trim().length > 0) {
  //             const weekday = moment(schedule.pickUp).get("day");
  //             const hour = moment(schedule.pickUp).get("hour");
  //             const minute = moment(schedule.pickUp).get("minute");

  //             if (
  //               moment().set({ day: weekday, hour, minute }) >
  //               moment().add(1, "day")
  //             ) {
  //               schedule.active = true;
  //               schedule.pickUp = moment()
  //                 .set({ day: weekday, hour, minute })
  //                 .toISOString();
  //             } else {
  //               schedule.active = false;
  //               schedule.pickUp = moment()
  //                 .set({ day: weekday, hour, minute })
  //                 .toISOString();
  //             }
  //           }

  //           return schedule;
  //         }
  //       });

  //       return thisWeekSchedule;
  //     });
  //   }
  // };

  return (
    <>
      {decodedDataLoading || dataFetching ? (
        <LoadingScreen />
      ) : (
        <div id="pickup-drop">
          <Header
            rightIcon={
              <img
                alt="profile"
                src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
                className="header-profile-image bordered-2 shadow-light"
              />
            }
          />

          <Box className="padding-x" style={{ background: "#fff" }}>
            <div className="sub-header">
              <img alt="person" src={Config} />

              <p className="sub-text">
                Set your favourite routes and schedule that you would like to
                carpool. You can update these settings later from your route
                preferences.
              </p>
            </div>
          </Box>

          <p className="section-head">Configure route schedule for next week</p>

          <Box
            className="margin-y"
            style={{ background: "#fff", display: "flex" }}
          >
            <Box
              className="location-box"
              style={{ margin: "1rem", width: "100%", display: "inline-flex" }}
            >
              <div className="inline" style={{ width: "100%" }}>
                <p
                  className="custom-label"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {decodedData.type}

                  <img
                    src={ArrowDown}
                    alt="chevron-down"
                    style={{ margin: "0" }}
                  />
                </p>
              </div>
            </Box>
          </Box>

          <CustomDrawer
            anchor="bottom"
            isOpen={true}
            fullScreen={true}
            onClose={() => {navigate('/')}}
            backlink="/"
            overflowDesktop={false}
          >
            <Box
              id="pickup-drop-drawer"
              className="schedule-drawer-content d-flex flex-column"
              position="relative"
              style={{ maxHeight: "calc(100vh - 150px)" }}
            >
              <p className="drawer-title text-center my-1 mb-2"> Schedule</p>

              <p className="text-center my-1 mb-5 text-gray">
                {
                  decodedData.type === "Drop Off" ? 
                  `Home to ${locationData.locationName[0].value}` : 
                  `${locationData.locationName[0].value} to Home`
                }
              </p>

              {/* Show this week or next week switch */}
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <p
                  className={`${
                    tabSelected === "This week" ? "pill pill-active" : "pill"
                  }`}
                  onClick={() => handleTabSelected("This week")}
                >
                  This week
                </p>

                <p
                  className={`${
                    tabSelected === "Next week" ? "pill pill-active" : "pill"
                  }`}
                  onClick={() => handleTabSelected("Next week")}
                >
                  Next week
                </p>
              </Box> */}

              <Box id="pickup-drop-drawer" className="overflow-auto mb-100 d-flex flex-column flex-1">
                <p className="d-flex items-center px-1 text-700 text-sm-2 sm-max-100 ml-auto" style={{ width: "42%"}}>
                  {
                    decodedData.type === "Drop Off" ? "Arrival time" : "Departure time"
                  }
                  </p>

                <Box className="d-flex flex-col flex-1 options-box">
                  {currDaySchedule?.map((item: KeyActiveTime, key: number) => (
                    <Box className="between mb-3" key={key}>
                      <Box className="d-flex" alignItems={"center"}>
                        <UseSwitchesBasic
                          handleChange={(e: any) => handleToggle(e, item)}
                          checked={item.active}
                        />
                        {"  "}

                        <div>
                          <p>{item.label}</p>

                          <p className="text-sm">{item.momentObj.format("Do MMM")}</p>
                        </div>
                      </Box>

                      {
                        <Box className={"sm-max-100 dr"}>
                          <CustomDatePicker
                            disbled={!item.active}
                            handleChange={(val: Moment | null) =>
                              handleTimeChange(val, item)
                            }
                            timeDefault={
                              item?.dropOff || item?.pickUp
                                ? decodedData.type === "Pick Up"
                                  ? moment(item?.pickUp).format("HH:mm")
                                  : moment(item?.dropOff).format("HH:mm")
                                : ""
                            }
                          />
                        </Box>
                      }
                    </Box> 
                  ))}

                  {/* {
                    !datesExpanded && (
                      <Button fullWidth onClick={() => {
                        document.getElementById("scheduleNextButton").style.position = "sticky";
                        document.getElementById("scheduleNextButton").style.bottom = "0rem";

                        setCurrDaySchedule([...currDaySchedule, ...scheduleData.slice(5)]);

                        setDatesExpanded(true);
                        }}
                        style={{borderWidth: "2px", padding: "1rem"}}
                        variant="outlined"
                        className="text-700 mt-4 btn-text"
                        >
                        Later
                      </Button>
                    )
                  } */}

                  {decodedData.redirect === "Finder" && (
                    <>
                      <Box className="option-box-title">
                        I’m flexible and willing to arrive up to
                      </Box>

                      <Box>
                        <TextField
                          className="custom-input"
                          value={flexibility}
                          onChange={(e) => setFlexibility(e.target.value)}
                        />
                      </Box>

                      <Box className="option-box-helper-text">
                        This will help us match you with more members in your
                        community with similar travel schedules
                      </Box>
                    </>
                  )}

                    {/* <div
                      id="scheduleNextButton"
                      style={{
                        position: "relative",
                        background: "#fff"
                      }}
                    >
                      <Button
                        className="next-btn save-btn custom-btn mb-40 btn-text"
                        fullWidth
                        onClick={() => handleNext()}
                      >
                        Next
                      </Button>
                    </div> */}

                    <div
                     id="scheduleNextButton"
                     style={{
                       position: "relative",
                       background: "#fff"
                     }}>
                    <CustomFixedButton text={"Next"} handleSubmit={handleNext} disable={false}
                      showUpperButton={!datesExpanded} upperButtonText={"Later"}
                      isDrawerButton={true}
                      upperButtonHandler={() => {
                        document.getElementById("scheduleNextButton").style.position = "sticky";
                        document.getElementById("scheduleNextButton").style.bottom = "0rem";

                        setCurrDaySchedule([...currDaySchedule, ...scheduleData.slice(5)]);

                        setDatesExpanded(true);
                        }}
                    />
                    </div>
                </Box>
              </Box>
            </Box>
          </CustomDrawer>
        </div>
      )}
    </>
  );
};

export default OfferCarpool;

function getKeyActiveTime() {
  let res: Array<KeyActiveTime> = [
    {
      key: "MONDAY",
      label: "Monday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "TUESDAY",
      label: "Tuesday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "WEDNESDAY",
      label: "Wednesday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "THURSDAY",
      label: "Thursday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "FRIDAY",
      label: "Friday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
  ];
  return res;
}

// function getIndexFromDay(day: string) {
//   switch (day) {
//     case "MONDAY":
//       return 1;
//     case "TUESDAY":
//       return 2;
//     case "WEDNESDAY":
//       return 3;
//     case "THURSDAY":
//       return 4;
//     case "FRIDAY":
//       return 5;
//     default:
//       return 1;
//   }
// }
