import React, { ReactNode } from 'react'
import './style.scss'


interface props {
    children : ReactNode;
}
const Card = ({children}:props) => {
  return (
    <div className="card-component">
       {children}
    </div>
  )
}

export default Card
