import Header from "../../../components/header";
import { Button } from "@mui/material";
import whatsApp from "../../../assets/images/whatsapp.svg";
import { WHATSAPP_LOGIN_TEXT } from "../../../constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetSignUpDetailsSlice } from "../../../slice/sign-up";
import LoadingScreen from "../../../components/loading-screen";

const NewLogin = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // remove any redux persisted data
  localStorage.removeItem("persist:root");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard/home");
    } else {
      localStorage.removeItem("draftId");
      dispatch(resetSignUpDetailsSlice());
    }
    setLoading(false);

    //eslint-disable-next-line
  }, [localStorage.getItem("token")]);

  const handleClick = () => {
    window.open(
      `${process.env.REACT_APP_WHATSAPP_SIGN_ME_IN_URL}?text=${WHATSAPP_LOGIN_TEXT}`,
      "_self"
    );
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div>
          <Header type="signup" signUp={true} publicSignUp={true} />
          <div className="heading p-1">Log in</div>
          <div className="p-1 d-flex flex-column justify-center items-center">
            <Button
              className="next-btn custom-btn btn-width px-15-rem"
              fullWidth
              onClick={handleClick}
            >
              <img alt="person" src={whatsApp} />
              &nbsp;Log in with WhatsApp
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default NewLogin;
