import "./style.scss";
import { Box } from "@mui/material";
import PathIcon from "../../assets/images/path.svg";
import ClockIcon from "../../assets/images/clock.svg";
import ShareIcon from "../../assets/images/shareIcon.svg";

interface IProps {
  type: string;
  text?: any;
  heading?: string;
  description?: string;
  buttonText?: string;
  handleButtonClick?: () => void;
}

const MarketPlaceRestrictedDialog = ({text, type, heading, description, buttonText, handleButtonClick}: IProps) => {
  const getIcon = () => {
    switch (type.toLowerCase()) {
      case "warning":
        return PathIcon;
      case "info":
        return ClockIcon;
      case "infowithbutton":
        return ShareIcon;
      default:
        break;
    }
  }

  const getBackgroundColorClass = () => {
    switch (type.toLowerCase()) {
      case "warning":
        return "yellow-background";
      case "info":
        return "green-background";
      case "infowithbutton":
        return "yellow-background";
      default:
        break;
    }
  }

  return (
    <>
      {
        Boolean(type) === true && (Boolean(text) === true || Boolean(heading) === true) && (
        <Box className={`rounded-12 ${getBackgroundColorClass()} mt-1rem ml-3 mr-3`}>
          <div className="dialog-container">

            <div className="icon">
              <img src={getIcon()} alt="banner icon"/>
            </div>

            {
              text && (
                <div className="text-container" dangerouslySetInnerHTML={{__html: text || ""}}>
                </div>
              )
            }

            {
              heading && description && (
                <div className="text-container">
                  <h4 className="m-0 mb-1">
                    {heading}
                  </h4>

                  <div className="d-flex items-start justify-between gap-1">
                    <p>
                      {description}
                    </p>

                    <div className="bg-black text-white rounded-4 no-wrap cursor-pointer text-700" style={{ padding: "0.5rem 1rem"}} onClick={handleButtonClick}>
                      {buttonText}
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </Box> 
        )
      }
    </>
  
  )
};

export default MarketPlaceRestrictedDialog;