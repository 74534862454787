import "./style.scss";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";

import FilledLeap from "../../../assets/images/filled-leap.svg";
import LocationPin from "../../../assets/images/location-pin.svg";
import VerifiedPerson from "../../../assets/images/verified-person.svg";

import CheckedBlack from "../../../assets/images/checkmark_black.svg";
import { Box, Button } from "@mui/material";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import CloseIcon from "@mui/icons-material/Close";
import CustomDrawer from "../../../components/mui/SwipeableDrawer";
import moment from "moment";
import {
  addItemToSlected,
  getInvites,
  removeItemFromSlected,
  // selectAllSeat,
  // unSelectAllSeat,
} from "../../../slice/book-seat";
import { Trip } from "../../../interface/book-seat";
import { enqueueSnackbar } from "notistack";
import { getSeatAvailabilityMultiple } from "../../../services/book-seat";
import {
  capitalizeFirstLetter,
  getName,
  getOrganisationLogo,
  getOrganizationName,
  handleGoToWhatsapp,
  // showMapDuration,
} from "../../../utilities/helper";
import LoadingScreen from "../../../components/loading-screen";
import userVerified from "../../../assets/images/user-verified.svg";
import userNotVerified from "../../../assets/images/user-not-verified.svg";
import CarStearingIcon from "../../../assets/images/stearing.svg";
import { BasicDetails } from "../../../slice/login";
import UserProfile from "../../../components/user-profile";
import CustomFixedButton from "../../../components/custom-fixed-button";
import { BackToWhatsAppButton } from "../../../components/back-to-whatsapp";

const BookSeatFiltered = () => {
  const [dataFetching, setDataFetching] = useState(false);
  //eslint-disable-next-line
  const [drawer, setDrawer] = useState(false);
  const [userDetail, setUserDetail] = useState<{
    showUserDetail: boolean;
    userDetail: BasicDetails;
    locationName: string;
    organisationLogo: string;
  }>({
    showUserDetail: false,
    userDetail: {
      firstName: [],
      lastName: [],
      email: "",
      gender: "",
      phone: "",
      photo: "",
      userPreference: "",
      occupation: "",
      createdAt: "",
    },
    locationName: "",
    organisationLogo: "",
  });

  const navigate = useNavigate();

  const {
    bookSeatReducer: { selectedTripResponse, invites, inviteLoading },
    userDetailsReducer,
  } = useSelector((state: RootState) => state);

  let { data: base64Data } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  // fetch the invites data for this user
  useEffect(() => {
    if (invites.length === 0) {
      dispatch(getInvites());
    }

    // eslint-disable-next-line
  }, []);

  // //Open Route on Map
  // const getAllCordinates = (
  //   e: React.MouseEvent<HTMLDivElement>,
  //   trip: Trip
  //   // matechesArray: { filteredUsers: { array: { address: { geo: any[]; }; }[]; }; }, tripDetails: { trip: { pickUp: { geo: any[]; }; destination: { geo: any[]; }; }; }
  // ) => {
  //   if (
  //     (e.currentTarget.firstElementChild as HTMLParagraphElement)
  //       .textContent === "View on map"
  //   ) {
  //     (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
  //       "Hide map";
  //   } else {
  //     (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
  //       "View on map";
  //   }

  //   // set the height of the map div if its height is not already 400px else set the height to 0px
  //   if (
  //     (e.currentTarget.nextElementSibling as HTMLDivElement).style.height ===
  //     "400px"
  //   ) {
  //     (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
  //       "0px";
  //   } else {
  //     (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
  //       "400px";
  //   }

  //   // toggle the chevron and cross icons
  //   // if down chevron is visible, then set it to hidden and set visibility of cross to visible and vice-versa
  //   if (
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .firstElementChild as SVGSVGElement
  //     ).style.visibility === "visible"
  //   ) {
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .firstElementChild as SVGSVGElement
  //     ).style.visibility = "hidden";
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .lastElementChild as SVGSVGElement
  //     ).style.visibility = "visible";
  //   } else {
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .firstElementChild as SVGSVGElement
  //     ).style.visibility = "visible";
  //     (
  //       (e.currentTarget.lastElementChild as HTMLDivElement)
  //         .lastElementChild as SVGSVGElement
  //     ).style.visibility = "hidden";
  //   }

  //   const arr: Array<{ lat: number; lng: number }> = [];
  //   arr.push({
  //     lat: parseFloat(String(trip.pickUp.geo[0])),
  //     lng: parseFloat(String(trip.pickUp.geo[1])),
  //   });

  //   // add user home location
  //   arr.push({
  //     lat: parseFloat(String(userDetailsReducer.address.geo[0])),
  //     lng: parseFloat(String(userDetailsReducer.address.geo[1])),
  //   });

  //   arr.push({
  //     lat: parseFloat(String(trip.destination.geo[0])),
  //     lng: parseFloat(String(trip.destination.geo[1])),
  //   });

  //   // sending the index of the map
  //   initMap(e.currentTarget.dataset.index as string, arr);
  // };

  // const initMap = (
  //   index: string,
  //   tripCordinate: { lat: number; lng: number }[]
  // ) => {
  //   var map = new google.maps.Map(
  //     (document as any).getElementById(`map${index}`),
  //     {
  //       center: tripCordinate[0],
  //       zoom: 8,
  //     }
  //   );

  //   // Create a new DirectionsService object
  //   var directionsService = new google.maps.DirectionsService();

  //   // Create a new DirectionsRenderer object
  //   var directionsRenderer = new google.maps.DirectionsRenderer({
  //     map: map,
  //   });

  //   // Define the start and end locations
  //   var start = new google.maps.LatLng(
  //     tripCordinate[0].lat,
  //     tripCordinate[0].lng
  //   );

  //   var end = new google.maps.LatLng(
  //     tripCordinate[tripCordinate.length - 1].lat,
  //     tripCordinate[tripCordinate.length - 1].lng
  //   );

  //   // Configure the DirectionsRequest object
  //   var request = {
  //     origin: start,
  //     destination: end,
  //     waypoints: tripCordinate.slice(1, -1).map((coordinate) => ({
  //       location: new google.maps.LatLng(coordinate.lat, coordinate.lng),
  //       stopover: true,
  //     })),
  //     travelMode: google.maps.TravelMode.DRIVING,
  //   };

  //   // Call the DirectionsService to get the route
  //   directionsService.route(request, function (result, status) {
  //     if (status === google.maps.DirectionsStatus.OK) {
  //       // Display the route on the map
  //       directionsRenderer.setDirections(result);

  //       // get the directions response of the route
  //       let directionsResult = directionsRenderer.getDirections()!;

  //       // loop through each leg and show the duration for each leg in info window
  //       let prevDistance = 0;
  //       for (let i = 0; i < directionsResult.routes[0].legs.length; i++) {
  //         prevDistance = showMapDuration(
  //           directionsResult,
  //           map,
  //           i,
  //           prevDistance
  //         );
  //       }
  //     }
  //   });
  // };

  const select = (id: string) => {
    setDataFetching(true);

    getSeatAvailabilityMultiple([id])
      .then((value: any) => {
        // if seat is not available, then show the alert/notification
        if (value.tripSeatDetail[0].seatsAvailable === 0) {
          enqueueSnackbar("Seat is not available for this trip", {
            variant: "warning",
          });
          return;
        }

        dispatch(addItemToSlected(id));
      })
      .catch((err: any) => {
        enqueueSnackbar("Seat is not available for this trip", {
          variant: "warning",
        });
        // setDrawer(true);
      })
      .finally(() => {
        setDataFetching(false);
      });
  };

  const unSelect = (id: string) => {
    dispatch(removeItemFromSlected(id));
  };

  // const selectAll = () => {
  //   if (invites.length === selectedTripResponse.length) {
  //     return dispatch(unSelectAllSeat(null));
  //   }

  //   setDataFetching(true);
  //   const allTripId = invites.map((it) => it.tripDetail._id);

  //   getSeatAvailabilityMultiple(allTripId)
  //     .then((value) => {
  //       // loop through all trips and check if any trip has zero seats available
  //       for (let trip of value.tripSeatDetail) {
  //         if (trip.seatsAvailable === 0) {
  //           const invite = invites.find(
  //             (invite) =>
  //               invite.tripDetail._id.toString() === trip._tripId.toString()
  //           );

  //           enqueueSnackbar(
  //             `Sorry, no seat is available for ${moment(
  //               invite?.tripDetail.scheduleDate
  //             ).format("Do MMM")}`,
  //             { variant: "warning" }
  //           );

  //           return;
  //         }
  //       }
  //       dispatch(selectAllSeat(null));
  //     })
  //     .catch((error: any) => {
  //       console.log("error ***************** ", error);

  //       // loop through all trips and check if any trip has zero seats available
  //       for (let trip of error.response.data.data.tripSeatDetail) {
  //         if (trip.seatsAvailable === 0) {
  //           const invite = invites.find(
  //             (invite) =>
  //               invite.tripDetail._id.toString() === trip._tripId.toString()
  //           );

  //           enqueueSnackbar(
  //             `Sorry, no seat is available for ${moment(
  //               invite?.tripDetail.scheduleDate
  //             ).format("Do MMM")}`,
  //             { variant: "warning" }
  //           );

  //           return;
  //         }
  //       }

  //       // setDrawer(true);
  //     })
  //     .finally(() => {
  //       setDataFetching(false);
  //     });
  // };

  const isSelected = (item: Trip) => {
    let found = false;

    selectedTripResponse.forEach((it) => {
      if (item._id === it._id) found = true;
    });

    return found;
  };

  const handleNavigate = () => {
    if (selectedTripResponse.length === 0) {
      enqueueSnackbar("Must select at least one seat !", {
        variant: "warning",
      });
    } else {
      navigate(`/redirect-page/find-carpool-confirm/${base64Data}`);
    }
  };

  useEffect(() => {
    //if no data i.e refreshed then move back to prev
    if (invites.length === 0 && !inviteLoading) {
      // // hit the whatsApp webhook to notify passenger
      // whatsAppWebHook(
      //   userDetailsReducer.basicDetails.phone,
      //   "NOMATCH",
      //   undefined,
      //   userDetailsReducer.basicDetails.userPreference
      // );

      setDrawer(true);
    }

    //eslint-disable-next-line
  }, [invites, inviteLoading]);

  const handleDrawerClose = () => {
    navigate("/dashboard/upcoming");
  };

  return (
    <div id="matches-find-carpool" className="find-carpool-wrapper">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
        showBackIcon={true}
        backLink={`/redirect-page/find-carpool-schedule/${base64Data}`}
      />

      <Box
        className="matches-box"
        style={{ height: "auto", overflow: "unset" }}
      >

      <BackToWhatsAppButton />
        <div className="sub-header">
          <p className="heading">
            Select carpools
            ({selectedTripResponse.length}/
                    {(invites as []).length})
            </p>

          {/* <p
            className="sub-text-14 primary-color click"
            onClick={() => selectAll()}
          >
            {invites.length !== 0 &&
              invites.length === selectedTripResponse.length
              ? "De-select all"
              : "Select all"}
          </p> */}
        </div>

        {invites.map((item, i) => (
          <Box className={`trip-person-info ${isSelected(item.tripDetail) ? "invited-person" : "uninvited-user"}`} key={i}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" className="margin-y items-center">
                  <img
                    alt="profile"
                    src={item?.driverDetail?.photo ?? DefaultProfile}
                    height="44px"
                    width="44px"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = DefaultProfile;
                    }}
                    className="rounded-full bordered-2 shadow-light object-fit-cover cursor-pointer"
                    onClick={() => setUserDetail({ showUserDetail: true, userDetail: item?.driverDetail, locationName: getOrganizationName(item?.locationDetail, {userDetail: item?.driverDetail}), organisationLogo: getOrganisationLogo(item?.locationDetail, {userDetail: item?.driverDetail}) })}
                  />

                <Box marginLeft="18px" className="info">
                  <p className="sub-text-14 driver-name d-flex items-center gap-4">
                    {capitalizeFirstLetter(
                      getName(item?.driverDetail?.firstName, "EN")
                    )}

                    {
                      !item?.locationDetail?.verificationType.includes("NONE") && (
                        <img
                          src={
                            item?.driverDetail?.isVerified
                              ? userVerified
                              : userNotVerified
                          }
                          alt="user-verify-status"
                        />
                      )
                    }

                    {
                      item.driverDetail.isDriverVerified && (
                        <img
                          src={CarStearingIcon}
                          alt="driver verified"
                        />
                      )
                    }
                  </p>

                  {item?.driverDetail?.loginType === "FACEBOOK" && (
                    <Box display="flex" marginY="4px">
                      <img
                        alt="verified"
                        src={VerifiedPerson}
                        onClick={() =>
                          window.open(
                            `https://www.facebook.com/profile.php?id=${item.driverDetail.socialId}`,
                            "_blank"
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  )}

                  <p className="sub-text text-faded">
                    {moment(item.estimatedDropTime).format("Do MMM")
                      // " arrive @" +
                      // moment(item.estimatedDropTime).format("HH:mm")
                    }
                  </p>
                </Box>
              </Box>

              {isSelected(item.tripDetail) ? (
                <Box
                  onClick={() => unSelect(item.tripDetail._id)}
                  className="invite-btn invite-btn-active center cursor-pointer"
                >
                  <p className="select-button-text d-flex">
                    <img alt="check" src={CheckedBlack} /> Book
                  </p>
                </Box>
              ) : (
                <Box
                  onClick={() => select(item.tripDetail._id)}
                  className="invite-btn center cursor-pointer not-booked"
                >
                  <p className="select-button-text">Book seat</p>
                </Box>
              )}
            </Box>

            <Box marginBottom="8px" className="sub-text">
              <Box className="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" width={"80%"}>
                  <img alt="circle" src={FilledLeap} />

                  <p className="sub-text-14 ml-2">
                    {item.tripDetail.destinationLocationId === undefined
                      ? item?.locationDetail?.locationName[0]?.value ?? "N/A"
                      : "Home"}
                  </p>
                </Box>

                <p className="text-600 text-faded w-20 align-right">
                  {item.tripDetail.destinationLocationId !== undefined &&
                    "ETA "}
                  {moment(item.estimatedPickUpTime).format("HH:mm")}
                </p>
              </Box>

              <Box
                color="#E0E0E0"
                className="center"
                justifyContent="space-between"
                marginLeft="10px"
              >
                |
              </Box>

              <Box className="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" width={"80%"}>
                  <img alt="circle" src={LocationPin} />
                  <p className="sub-text-14 ml-2">
                    {item.tripDetail.destinationLocationId === undefined
                      ? "Home"
                      : item?.locationDetail?.locationName[0]?.value ?? "N/A"}
                  </p>
                </Box>

                <p className="sub-text primary2-color text-600 w-20 align-right">
                  {item.tripDetail.destinationLocationId === undefined &&
                    "ETA "}
                  {moment(item.estimatedDropTime).format("HH:mm")}
                </p>
              </Box>
            </Box>

            <Box
              style={{
                backgroundColor: "#F5F5F5",
                height: "34px",
                display: "flex",
                alignItems: "center",
                padding: "8px",
                justifyContent: "space-between",
                borderRadius: "4px",
              }}
              className="mb-05"
            >
              <Box>
                <b>Price</b>
              </Box>
              <Box>
                <b>£{item.perSeatPrice.toFixed(2)}</b>
              </Box>
            </Box>

            {/* <Box
              data-index={i}
              className="map-view cursor-pointer"
              marginTop="16px"
              marginBottom="8px"
              onClick={(e) => getAllCordinates(e, item.tripDetail)}
            >
              <p className="view sub-text-14 text-700 primary-color">
                View on map
              </p>

              <div style={{ position: "relative" }}>
                <ExpandMoreIcon
                  style={{ visibility: "visible" }}
                  className="primary-color"
                />

                <CloseIcon
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                  className="primary-color"
                />
              </div>
            </Box>

            <div id={`map${i}`} className="expand-transition rounded"></div> */}
          </Box>
        ))}
      </Box>

      {(dataFetching || inviteLoading) && <LoadingScreen />}

      {/* <Box className="next-btn-box" style={{ position: "sticky" }}>
        <Box className="margin-x">
          <Box className="d-flex justify-center items-center">
            <Button
              className="next-btn custom-btn btn-width btn-text"
              fullWidth
              onClick={() => handleNavigate()}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box> */}

      {(dataFetching || inviteLoading) ? <></> : (<CustomFixedButton text={'Next'} handleSubmit={handleNavigate} disable={false}/>)}

      <CustomDrawer
        anchor="bottom"
        isOpen={drawer}
        fullScreen={true}
        onClose={handleDrawerClose}
      >
        <div
          className="h-100 d-flex items-center flex-column justify-center gap-2 bg-dialog"
          style={{ minHeight: "68vh" }}
        >
          <Box
            className="seat-unavailable-message-box p-05 "
            style={{ background: "#fff" }}
          >
            <Box className="seat-unavailable">
              <h3 className="pl-15 pr-15 pt-0">
                🙁 Currently no open invites for this route
              </h3>
            </Box>
            <Box sx={{ marginTop: "15px" }} className="seat-unavailable">
              <p className="px-15 pt-0">
                We'll notify you once drivers share invites for carpool seats on
                this route.
              </p>
            </Box>
          </Box>
        </div>
        <Box className="button-container">
          <Button
            className="next-btn custom-btn btn-text"
            fullWidth
            onClick={handleGoToWhatsapp}
          >
            Back To Whatsapp
          </Button>
        </Box>
      </CustomDrawer>

      <UserProfile 
        isOpen={userDetail.showUserDetail}
        onClose={() => setUserDetail(prev => ({...prev, showUserDetail: false}))}
        userDetail={{...userDetail.userDetail, locationName: userDetail.locationName, organisationLogo: userDetail.organisationLogo}}
      />
    </div>
  );
};

export default BookSeatFiltered;
