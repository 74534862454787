import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import signUpDetailsSlice from '../slice/sign-up';
import loginSlice from '../slice/login';
import categorySlice from '../slice/category';
import utilitySlice from '../slice/utility';
import inviteSlice from '../slice/invites';
import userSlice from '../slice/user';
import inviteTripSlice from '../slice/invite-trip-details';
import tripSlice from '../slice/trip';
import redirectDataSlice from '../slice/redirect-data';
import bookSeatSlice from '../slice/book-seat';
import drawerSlice from '../slice/drawer';
import marketPlaceSlice from '../slice/marketplace';
import myEcoSavingsSlice from '../slice/eco-saving';

// Create a persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['signUpDetails',
    // 'redirectDataReducer', 'bookSeatReducer', 'userReducer', 'inviteReducer', 'utilityReducer', 'tripReducer', 'inviteTripReducer', 'userDetailsReducer'
  ]
};

const rootReducer = combineReducers({
  'signUpDetails': signUpDetailsSlice,
  'userDetailsReducer': loginSlice,
  'categoryReducer': categorySlice,
  'utilityReducer': utilitySlice,
  'inviteReducer': inviteSlice,
  'inviteTripReducer': inviteTripSlice,
  'tripReducer': tripSlice,
  'userReducer': userSlice,
  'redirectDataReducer': redirectDataSlice,
  'bookSeatReducer': bookSeatSlice,
  'drawerReducer': drawerSlice,
  'marketPlaceReducer': marketPlaceSlice,
  'myEcoSavingReducer' : myEcoSavingsSlice
})


// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure and create the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false, // Ignore non-serializable values
  }),
});

// Create a persisted store
const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export { store, persistor };

