import { Button, Box } from "@mui/material";
import identityVerified from "../../../assets/images/identity-verified.svg";
import "./style.scss";
import verifiedMark from "../../../assets/images/verify-tick-20X20.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { verifyUser } from "../../../services/verify";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { setVerificationInitialCall } from "../../../slice/utility";
import LoadingScreen from "../../../components/loading-screen";
import { enqueueSnackbar } from "notistack";
import { updateUserVerificationStatus } from "../../../slice/login";
import { paymentButtonHandler } from "../../../utilities/helper";
import {
  checkSelectedTripConfirmed,
  generatePaymentLink,
} from "../../../services/payment";
import InfoBox from "../../New Screens/Common components/InfoBox";
import CustomList from "../../New Screens/Common components/CustomList";
import { getSeatAvailabilityMultiple } from "../../../services/book-seat";
import moment from "moment";

const IdentityVerified = () => {
  let userVal = {
    name: "",
    address: "",
    addressVerification: false,
  };

  const [loading1, setLoading1] = useState(true);

  let [userDetail1, setUserDetail1] = useState(userVal);
  
  // eslint-disable-next-line
  const [searchParam, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let location = useLocation();

  const { user } = useSelector((state: RootState) => state.userDetailsReducer);
  const { basicDetails } = useSelector(
    (state: RootState) => state.userDetailsReducer
  );

  let code = searchParam.get("code");

  const userVerification = async () => {
    verifyUser({ code, name: `${basicDetails.firstName[0].value} ${basicDetails.lastName[0].value}` })
      .then((res: any) => {
        dispatch(setVerificationInitialCall(false));

        setSearchParams("");

        setLoading1((prev) => {
          return false;
        });

        setUserDetail1({
          ...userDetail1,
          name: res.account_holder_name,
        });
      })
      .catch((err) => {
        setSearchParams("");

        navigate("/verification/failure", { state: { first_name: basicDetails.firstName[0].value, last_name: basicDetails.lastName[0].value } });
      });
  };

  useEffect(() => {
    // if unable to get the authorization code itself, then redirect to failure page
    if (searchParam.get("error")) {
      navigate("/verification/failure");
    } else if (code && loading1) {
      if (user.email.length) {
        userVerification();
      }
    } else if (!code && !location.state) {
      // if user is directly visiting this page, then redirect to dashboard
      navigate("/dashboard/upcoming");
    }
    
    // eslint-disable-next-line
  }, [user]);

  const handleClick = async () => {
    if (basicDetails.isVerified === false) {
      dispatch(updateUserVerificationStatus(true));
    }

    if (localStorage.getItem("selectedTripResponseNew")) {
      const selectedTripResponseNew = JSON.parse(
        localStorage.getItem("selectedTripResponseNew")
      );

      // if coming from book seat flow, then start the payment process
      try {
        setLoading1(true);

        const tripResponse = await checkSelectedTripConfirmed({
          ids: selectedTripResponseNew.map((it) => it.tripDetail._id),
        });

        if (tripResponse.data && tripResponse.data.length) {
          setLoading1(false);

          enqueueSnackbar(
            `Seat booking window has been closed for following dates ${tripResponse.data.toString()}`,
            { variant: "error" }
          );

          // redirect to my upcoming trips page
          navigate("/dashboard/upcoming");
        }

        // check seat availability
        const value = await getSeatAvailabilityMultiple(selectedTripResponseNew.map((it) => it.tripDetail._id))

        // if seat is not available, then show the alert/notification
        if (value.tripSeatDetail[0].seatsAvailable === 0) {
          enqueueSnackbar("Seat is not available for this trip", {
            variant: "warning",
          });

          setLoading1(false);

          // redirect to my upcoming trips page
          navigate("/dashboard/upcoming");

          return;
        }

        // make a request to API to get the payment link
        const paymentLinkResponse = await generatePaymentLink({
          amount: Number(
            selectedTripResponseNew
              .reduce((sum, it) => (sum += it.perSeatPrice), 0)
              .toFixed(2)
          ),

          tripIds: selectedTripResponseNew.map((it) => it.tripDetail._id),

          tripUniqueId: selectedTripResponseNew.map(
            (it) => it.tripDetail.uniqueId
          ),
        });

        if (paymentLinkResponse.success === true) {
          // encapsulate payment process
          paymentButtonHandler(paymentLinkResponse);
        } else {
          throw new Error();
        }
      } catch (error) {
        enqueueSnackbar("Unable to create payment link", { variant: "error" });

        setLoading1(false);

        navigate(`/dashboard/upcoming`);
      }
    } else if (
      localStorage.getItem("selectedTripResponse") &&
      localStorage.getItem("selectedInvites")
    ) {
      // calculate total price
      const calculateTotalPrice = () => {
        // sum price of all the invites which are selected
        return selectedInvites.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.perSeatPrice,
          0
        );
      };

      const selectedTripResponse = JSON.parse(
        localStorage.getItem("selectedTripResponse")
      );

      const selectedInvites = JSON.parse(
        localStorage.getItem("selectedInvites")
      );

      // if coming from find carpool/review invites flow, then start the appropriate payment process
      try {
        setLoading1(true);

        const tripResponse = await checkSelectedTripConfirmed({
          ids: selectedTripResponse.map((it) => it._id),
        });

        if (tripResponse.data && tripResponse.data.length) {
          setLoading1(false);
          
          enqueueSnackbar(
            `Seat booking window has been closed for following date ${tripResponse.data.toString()}, please update your selection`,
            { variant: "error" }
          );

          // redirect to my upcoming trips page
          navigate("/dashboard/upcoming");
        }

        
        // check seat availability
        const value = await getSeatAvailabilityMultiple(selectedTripResponse.map((it) => it._id))

        // if seat is not available, then show the alert/notification
        if (value.tripSeatDetail[0].seatsAvailable === 0) {
          enqueueSnackbar("Seat is not available for this trip", {
            variant: "warning",
          });

          setLoading1(false);

          // redirect to my upcoming trips page
          navigate("/dashboard/upcoming");

          return;
        }
        

        // make a request to API to get the payment link
        const paymentLinkResponse = await generatePaymentLink({
          amount: Number(calculateTotalPrice().toFixed(2)),
          tripIds: selectedTripResponse.map((it) => it._id),
          tripUniqueId: selectedTripResponse.map((it) => it.uniqueId),
        });

        if (paymentLinkResponse.success === true) {
          // encapsulate payment process
          paymentButtonHandler(paymentLinkResponse);
        } else {
          throw new Error();
        }
      } catch (error) {
        enqueueSnackbar("Unable to create payment link", { variant: "error" });
        setLoading1(false);

        navigate(`/dashboard/upcoming`);
      }
    } else if (localStorage.getItem("inviteData")) {
      // if coming from offer carpool flow
      const inviteData = JSON.parse(localStorage.getItem("inviteData"));

      // redirect to get-verified screen for redirecting to driver-verification
      navigate("/get-verified", {state: { inviteRequests: inviteData, utcOffset: moment().utcOffset(), driverVerification: true }})
    } else {
      navigate(`/dashboard/upcoming`);
    }
  };

  return (
    <>
      {loading1 === true ? (
        <LoadingScreen />
      ) : (
        <div
          id="verification-success-new"
          className="user-verified-wrapper-main d-flex flex-column"
        >
          <div className="user-verified-wrapper flex-1">
            <div className="p-1">
              <div className="p-1 mb-3 d-flex flex-column justify-center items-center">
                <div className="success-mark-container">
                  <img src={identityVerified} alt="verified" />
                </div>

                <div className="success-text mt-3">Identity Verified</div>
              </div>
            </div>

            <div className="p-1 mb-3">
              <InfoBox border="blue">
                {/*name and address will be replace user's actual name and address */}
                <CustomList
                  icon={verifiedMark}
                  text={userDetail1.name}
                  bordered
                  path=""
                />
              </InfoBox>
            </div>
          </div>

          <Box className="d-flex p-1 btn-padding-responsive justify-center items-center">
            <Button
              className="next-btn custom-btn btn-width mt-3 btn-text"
              fullWidth
              onClick={handleClick}
            >
              {localStorage.getItem("selectedTripResponseNew") ||
                localStorage.getItem("selectedTripResponse") ? (
                <>Complete seat booking</>
              ) : (
                <>Next</>
              )}
            </Button>
          </Box>
        </div>
      )}
    </>
  );
};

export default IdentityVerified;
