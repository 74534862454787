import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useFormik } from "formik";
import { Box, TextField, InputAdornment } from "@mui/material";
import VerifiedTickIcon from '../../../assets/images/verified-tick.svg'
import Header from "../../../components/header-1";
import { updateUser } from "../../../services/update/user-profile";
import { initUserState } from "../../../slice/login";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Home from '../../../assets/images/home.svg';
import { validationSchema } from "./validation";
import "./style.scss";
import CustomFixedButton from "../../../components/custom-fixed-button";

const HomeLocationUpdate = () => {

    const [location, setLocation] = useState<any>({});
    const userDetailsReducer = useSelector((state: RootState) => state.userDetailsReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mapAddressDataToFormik = () => {
        const { address: { line1, line2, state, country, zip, geo, additionalInstructions } } = userDetailsReducer;

        return {
            line1: line1[0].value,
            line2: line2[0]?.value,
            state: state[0].value,
            city: state[0].value,
            country: country[0]?.value,
            zip: zip,
            geo: geo,
            additionalInstructions: additionalInstructions.length ? additionalInstructions[0].value : ""
        }
    }

    const mapFormikDataToAddress = (formikData: any) => {
        const { line1, state, city, zip, geo } = formikData;

        return {
            line1: [{ lang: "EN", value: line1 }],
            line2: (formikData.line2 !== "" && formikData.line2 !== undefined) ? [{ "lang": "EN", "value": formikData.line2 }] : undefined,
            state: [{ lang: "EN", value: state }],
            city: [{ lang: "EN", value: city }],
            // country: [{ lang: "EN", value: country }],
            zip: zip ?? "NA",
            geo: geo,
            // additionalInstructions: [{ lang: "EN", value: additionalInstructions }],
        }
    }

    const updateFormWithNewLocation = () => {
        const { homeLocation, line2, state, country, city, zip, longitude, latitude, additionalInstructions } = location;

        formik.setFieldValue("line1", homeLocation);
        formik.setFieldValue("line2", line2);
        formik.setFieldValue("state", state);
        formik.setFieldValue("city", city);
        formik.setFieldValue("country", country);
        formik.setFieldValue("zip", zip ?? "");
        formik.setFieldValue("geo", [latitude, longitude]);
        formik.setFieldValue("additionalInstructions", additionalInstructions)
    }

    const formik = useFormik({
        initialValues: mapAddressDataToFormik(),
        validationSchema,
        onSubmit: async (values) => {
            try {
                const data = {
                    address: mapFormikDataToAddress(values)
                }

                const res = await updateUser(data);

                dispatch(initUserState(res as any))

                enqueueSnackbar("User updated successfully !", { variant: 'success' });

                navigate("/dashboard/profile")
            } catch (error: any) {
                console.log(error);

                enqueueSnackbar("Unable to update. Please try again", { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        updateFormWithNewLocation();
        //eslint-disable-next-line
    }, [location])

    //init location object
    useEffect(() => {
        const { address } = userDetailsReducer;
        setLocation({
            ...location,
            city: address.city[0].value,
            state: address.state[0].value,
            longitude: address.geo[1],
            latitude: address.geo[0],
            zip: address.zip,
            homeLocation: address.line1[0].value,
            line2: address.line2[0]?.value,
            country: address.country[0]?.value,
            additionalInstructions: address.additionalInstructions.length ? address.additionalInstructions[0].value : ""
        })
        //eslint-disable-next-line
    }, [])

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
          enqueueSnackbar("Select address from the dropdown only",{variant:"info"})
        }
      }

    return (
        <div id='home-location'>
            <Header rightIcon={userDetailsReducer.basicDetails.photo} />

            <Box className='padding-x rounded-top-green'>
                <div className="sub-header">
                    <div className="sub-header-icon-container">
                        <img alt='person' src={Home} />
                    </div>
                    <p className='sub-text sub-text-new'>Please update your location</p>
                </div>
            </Box>

            <p className="section-head section-head-bg-light-green">Home location</p>

            <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown} className="d-flex flex-column flex-1">
                <Box className='margin-x d-flex flex-column flex-1 btn-padding-responsive'>
                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="line1">Street address</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='line1'
                            name="line1"
                            // disabled
                            value={formik.values.line1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.line1 && Boolean(formik.errors.line1)}
                            helperText={formik.touched.line1 && formik.errors.line1}
                            placeholder='House/flat number and street name'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        (userDetailsReducer.basicDetails.isAddressVerified === true
                                        ) &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    {/* <Box className='margin-y'>
                        <label className='custom-label' htmlFor="line2">Line 2</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='line2'
                            name="line2"
                            // disabled
                            value={formik.values.line2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.line2 && Boolean(formik.errors.line2)}
                            helperText={formik.touched.line2 && formik.errors.line2}
                            placeholder='Line 2 (optional)'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        userDetailsReducer.basicDetails.isVerified === true &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box> */}

                    <Box className='mb-3'>
                        <label className='custom-label' htmlFor="city">Town/City</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='city'
                            name="city"
                            // disabled
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                            placeholder='Town or city'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        (userDetailsReducer.basicDetails.isAddressVerified === true
                                        ) &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    <Box className='mb-3'>
                        <label className='custom-label' htmlFor="state">County</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='state'
                            name="state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            // disabled
                            onBlur={formik.handleBlur}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                            placeholder='County name'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        userDetailsReducer.basicDetails.isAddressVerified === true &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    <Box className='mb-3'>
                        <label className='custom-label' htmlFor="first_name">Post Code</label>
                        <TextField
                            className='custom-input margin-y-label'
                            id='zip'
                            name="zip"
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            // disabled
                            onBlur={formik.handleBlur}
                            error={formik.touched.zip && Boolean(formik.errors.zip)}
                            helperText={formik.touched.zip && formik.errors.zip}
                            placeholder='Postal Code eg. 567841'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        (userDetailsReducer.basicDetails.isAddressVerified === true
                                        ) &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    {/* <Box className="d-flex justify-center items-end flex-1">
                        <Button className='next-btn custom-btn btn-width btn-text' fullWidth type={"submit"}>
                            {formik.isSubmitting ? "LOADING..." : "UPDATE"}
                        </Button>
                    </Box> */}

                    <CustomFixedButton disable={formik.isSubmitting} text={formik.isSubmitting ? "LOADING..." : "UPDATE"}></CustomFixedButton>
                </Box>
            </form>
        </div>
    )
}

export default HomeLocationUpdate;
