import { httpRequest } from "../network";

export const whatsAppWebHook = (
  phone: string,
  type: string,
  locationId?: string,
  userPreference?: string
) => {
  return httpRequest("POST", `whatsapp/template/webhook`, {
    whatsAppNumber: phone,
    type,
    locationId,
    userPreference,
  })
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

// hit webhook for sending first message for onboarded location
export const whatsAppWebHookFirstMessage = (
  type: string,
  locationName: string,
  users: [{ whatsAppNumber: string; userPreference: string; jti: string }]
) => {
  return httpRequest("POST", `whatsapp/template/webhook`, {
    type,
    locationName,
    users,
  })
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

// driver or passenger cancels trip
export const whatsAppWebHookCancelTrip = (
  type: string,
  locationName: string,
  driverName: string,
  from: string,
  to: string,
  date: string,
  price: string[],
  whatsAppNumber: string[],
  jti: string[],
  pickUpTime: string[],
  dropOffTime: string[],
  tripType: string,
  passengerName: string = undefined
) => {
  return httpRequest("POST", `whatsapp/template/webhook`, {
    type,
    locationName,
    driverName,
    passengerName,
    from,
    to,
    date,
    price,
    whatsAppNumber,
    jti,
    pickUpTime,
    dropOffTime,
    tripType,
  })
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

// whatsApp webhook service for join community approval request
export const whatsAppWebhookApprovalRequest = (
  adminPhone: string,
  jti: string,
  type: string,
  locationName: string,
  userName: string
) => {
  return httpRequest("POST", `whatsapp/template/webhook`, {
    whatsAppNumber: adminPhone,
    jti,
    type,
    locationName,
    userName,
  })
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
}

// Send notification to community member after approval success
export const whatsAppWebhookApprovalSuccess = (
  memberPhone: string,
  jti: string,
  type: string,
  locationName: string,
) => {
  return httpRequest("POST", `whatsapp/template/webhook`, {
    whatsAppNumber: memberPhone,
    jti,
    type,
    locationName,
  })
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
}
