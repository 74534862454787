import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserFromDraftById, setFacebookCredentials } from '../../../services/social-login';
import { Box } from '@mui/material';
import { updateBasicDetails, updateHomeLocation, updateUser } from '../../../slice/sign-up';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { initUserState } from '../../../slice/login';
import LoadingScreen from '../../../components/loading-screen';

const FacebookLogin = () => {
    // eslint-disable-next-line 
    const [searchParam, setSearchParam] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initSignUpDetails = (user: any) => {
        const {
            firstName,
            lastName,
            email,
            photo,
            userPreference,
            phone,
            gender,
            _id
        } = user;

        const payload = {
            firstName: firstName.length > 0 ? firstName[0].value : "",
            lastName: lastName.length > 0 ? lastName[0].value : "",
            email: email ?? "",
            photoPath: photo ?? "",
            carPoolPreference: userPreference ?? "OFFER",
            phoneNumber: phone ?? "",
            gender: gender ?? "MALE",
            profilePicture: ""
        }

        // create home location payload to store in redux
        if (user.address) {
            const homeLocationPayload = {
                state: user?.address?.state[0].value ?? '',
                city: user?.address?.city[0].value ?? '',
                line1: user?.address?.line1[0].value ?? '',
                line2: user?.address?.line2[0].value ?? '',
                country: user?.address?.country[0].value ?? '',
                zip: user?.address?.zip,
                geo: user?.address?.geo,
                additionalInstructions: user?.address?.additionalInstructions[0].value ?? ''
            }

            dispatch(updateHomeLocation(homeLocationPayload))
        }
        dispatch(updateUser({ email, _id }))
        dispatch(updateBasicDetails(payload));
    }

    const navigateAccordingly = (res: any) => {
        switch (res.steps[res.steps.length - 1]) {
            case 1:
                navigate("/signup/basic-detail");
                break;
            case 2:
                navigate("/signup/vehicle-detail");
                break;
            case 3:
                navigate("/signup/declaration");
                break;
            case 4:
                navigate("/signup/home-location-detail");
                break;
            case 5:
                navigate("/signup/favorite-location");
                break;
            case 6:
                navigate("/signup/summary");
                break;
            case 7:
                navigate("/signup/summary");
                break;
            default:
                navigate("/signup/basic-detail");
        }
    }

    const fetchData = async () => {
        // const code = localStorage.getItem("code");
        console.log({ ss: searchParam.get("code") });

        if (searchParam.get("code")) {
            setFacebookCredentials({
                code: searchParam.get("code"),
            })
                .then((res) => {
                    console.log('response data: ', res.data)
                    // if user is in draft
                    if (res.data?.isDraft) {
                        //set draftd in localstorage
                        localStorage.setItem("draftId", res.data?._id);
                        //get all details of drafted user
                        getUserFromDraftById({ id: res.data?._id }).then((dataFromDraft: any) => {
                            console.log('dataFromDraft: ******************* ', dataFromDraft)
                            initSignUpDetails(dataFromDraft);
                            navigateAccordingly(dataFromDraft);
                        })
                    } else {
                        localStorage.setItem("token", res.data?.token);
                        dispatch(initUserState(res.data as any))
                        navigate("/dashboard/upcoming", { replace: true });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    const msg = err?.message ?? "Something went wrong !";
                    enqueueSnackbar(msg, { variant: 'error', })
                    navigate("/");
                });
        } else {
            navigate("/");
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line 
    }, []);

    return <Box className="center w-100" height="100vh">
        <LoadingScreen />
    </Box>;
}

export default FacebookLogin;
