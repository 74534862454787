import './style.scss';
import Header from '../../../../../components/header';
import { Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import DefaultUserIcon from '../../../../../assets/images/defaultUser.svg';
// import ArrowDown from '../../../../../assets/images/ArrowDown.svg';
// import CheckedCircle from '../../../../../assets/images/checked-circle.svg';
// import Notes from '../../../../../assets/images/notes.svg';
// import Download from '../../../../../assets/images/download.svg';
import NotePad from '../../../../../assets/images/note-pad.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store';
import { enqueueSnackbar } from 'notistack';
import { createUser } from '../../../../../services/signup';
import { FavLocationInterface, getDraftUserFavouriteLocation, getDraftUserHomeDetail, getDraftUserVehicleDetail, resetFavouriteLocationError, resetHomeLocationError, resetSignUpDetailsSlice, resetVehicleError } from '../../../../../slice/sign-up';
import { useEffect, useState } from 'react';
// import { downloadDeclration } from '../../../../../services/declaration';
import { communityMemberRequest, getLocation, updateLocationAdmin } from '../../../../../services/location';
import { whatsAppWebhookApprovalRequest } from '../../../../../services/whatsApp';
import { getUserById } from '../../../../../services/user';
import CustomFixedButton from '../../../../../components/custom-fixed-button';

const SummaryDetail = () => {
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const signUpDetails = useSelector((state: RootState) => state.signUpDetails);
  const userDetailsReducer = useSelector((state: RootState) => state.userDetailsReducer)

  const { basicDetails, vehicleDetails, homeLocation, favLocation, vehicleError, favouriteLocationError, homeLocationError } = signUpDetails;

  const draftId = localStorage.getItem("draftId")

  // show the error message if vehicle details are not fetched
  useEffect(() => {
    if (vehicleError) {
      enqueueSnackbar('Vehicle details cannot be fetched at the moment', { variant: 'error' })
      dispatch(resetVehicleError())
    } else if (favouriteLocationError) {
      enqueueSnackbar('FavouriteLocations cannot be fetched at the moment', { variant: 'error' })
      dispatch(resetFavouriteLocationError())
    } else if (homeLocationError) {
      enqueueSnackbar('Home location details cannot be fetched at the moment', { variant: 'error' })
      dispatch(resetHomeLocationError())
    }
    // eslint-disable-next-line
  }, [vehicleError, favouriteLocationError])

  // fetch vehicle details and favourite locations 
  useEffect(() => {
    if (draftId) {
      if (favLocation.length === 0) {
        dispatch(getDraftUserFavouriteLocation(draftId))
      }

      if (vehicleDetails.carRegistrationNumber === '') {
        dispatch(getDraftUserVehicleDetail(draftId))
      }

      // fetch home location details if line1 is empty string
      if (homeLocation.line1 === '') {
        dispatch(getDraftUserHomeDetail(draftId))
      }
    }
    // eslint-disable-next-line
  }, [favLocation, vehicleDetails, homeLocation, draftId])

  const getPrefText = (pref: string) => {
    switch (pref) {
      case "OFFER":
        return "I will be looking to offer carpools"
      case "FINDER":
        return "I’m looking to book passenger seats on carpools "
      case "BOTH":
        return "Both driver and passenger"
      default:
        return "Text "
    }
  }

  const handleConfirm = async () => {
    try {
      setLoading(true)

      const user = await createUser(draftId as string);

      localStorage.setItem('token', user.token)

      const profilePicture = signUpDetails.basicDetails.photoPath as string ?? signUpDetails.basicDetails.profilePicture as string

      dispatch(resetSignUpDetailsSlice());

      localStorage.removeItem("draftId");

      // store the user's id as community admin-id and as a member in location details
      const locationId = localStorage.getItem("favLocation")

      const locationDetails = await getLocation(locationId)

      if (locationDetails.communityType === "PRIVATE" && locationDetails.whiteListType === "EMAIL") {
        if (!locationDetails.adminId) {
          // save admin in the location's member's list and as an admin
          await updateLocationAdmin(locationId, user.user._id)
        } else {
          // get the admin user details for sending whatsApp notification
          const adminUser = await getUserById(locationDetails.adminId)

          // save user in location's members list
          await communityMemberRequest(locationId, [user._id])

          if (adminUser !== undefined) {
            try {
              // send a whatsApp webhook for approval from community admin
              whatsAppWebhookApprovalRequest(
                adminUser.phone,
                adminUser.jti,
                "ADMINAPPROVAL",
                locationDetails.locationName[0].value,
                `${user.user.firstName[0].value} ${user.user.lastName[0].value}`)
            } catch (error) {
              console.log("Unable to send whatsApp message")
            }
          }
        }
      }

      navigate('/signup/how-works', { state: { profilePicture: profilePicture } });
    } catch (error: any) {
      const msg = error?.response?.data?.message ?? "Something went wrong";
      enqueueSnackbar(msg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  // const downloadPDF = async () => {
  //   try {
  //     await downloadDeclration();
  //     enqueueSnackbar("File downloaded successfully", { variant: "success" });
  //   } catch (error) {
  //     console.log(error);
  //     enqueueSnackbar("Could not download file", { variant: "error" });
  //   }

  // };

  return (
    <div id='summary-detail'>
      <Header
        rightIcon={<img alt='profile' className={"header-img-icon"} src={
          signUpDetails.basicDetails.photoPath as string ??
          signUpDetails.basicDetails.profilePicture as string ?? DefaultUserIcon
        } />}
        signUp={true}
      />
      <Box className='padding-x rounded-top-green'>
        <Box className="sub-header" alignItems='start'>
          <div className="sub-header-icon-container">
            <img className='header-icon' alt='person' src={NotePad} />
          </div>
          <div>
            <p className='sub-text-new sub-text mt-2 font-1-5rem '>Profile Summary</p>
          </div>
        </Box>
      </Box>
      {/* <Box className="sub-header header-2">
        <p className='heading'>Summary</p>
      </Box> */}
      <Box className='section-head section-head-bg-light-green center' justifyContent='space-between'>
        <p>
          Your details
        </p>
        <p className="link edit-new">
          <Link to="/signup/basic-detail?prev_url=/signup/summary" className='edit-link edit-link-color-new' style={{ textDecoration: 'none' }}>Edit</Link>
        </p>
      </Box>

      <Box marginX='16px' marginTop='16px' marginBottom='24px' className='your-detail'>
        <Box marginBottom='16px' className="center" flexDirection='column'>
          <img alt='profile' src={basicDetails.photoPath ? basicDetails.photoPath : DefaultUserIcon} />
          <p className='sub-text-16 name'>{basicDetails.firstName} {basicDetails.lastName}</p>
          <p className='sub-text-14'>{basicDetails.email}</p>
        </Box>
        <Box marginBottom='16px' className='d-flex' justifyContent='space-between'>
          <Box width='168px'>
            <p className='custom-label'>Gender</p>
            <p className='sub-text-14'>{basicDetails.gender.toUpperCase()}</p>
          </Box>
          <Box width='168px'>
            <p className='custom-label'>Phone number</p>
            <p className='sub-text-14'>{basicDetails.phoneNumber}</p>
          </Box>
        </Box>
        <Box>
          <p className='custom-label'>Carpool preferences</p>
          <p className='sub-text-14'>{getPrefText(userDetailsReducer.basicDetails.userPreference)}</p>
        </Box>
      </Box>

      {userDetailsReducer.basicDetails.userPreference !== "FINDER" && <Box className='section-head section-head-bg-light-green center' justifyContent='space-between'>
        <p>
          Vehicle details
        </p>
        <p className="link edit-new">
          <Link to="/signup/vehicle-detail?prev_url=/signup/summary" className='edit-link edit-link-color-new' style={{ textDecoration: 'none' }}>Edit</Link>
        </p>
      </Box>}

      {userDetailsReducer.basicDetails.userPreference !== "FINDER" && <Box marginX='16px' marginTop='16px' marginBottom='24px' className='car-detail'>
        <Box marginBottom='16px' className="d-flex" justifyContent='space-between'>
          <Box width='168px'>
            <p className='custom-label'>Car registration</p>
            <p className='sub-text-14'>{vehicleDetails?.carRegistrationNumber}</p>
          </Box>
          <Box width='168px'>
            <p className='custom-label'>Number of passenger seats</p>
            <p className='sub-text-14'>{vehicleDetails.numOfSeatsAvailableForCarPoolPassengers}</p>
          </Box>
        </Box>

        <Box width='168px' marginBottom='18px'>
          <p className='custom-label'>Number of child seats</p>
          <p className='sub-text-14'>{vehicleDetails.numOfSeatsForChild}</p>
        </Box>

        {/* <Box className='declarations-box' marginBottom='24px'>
          <p className='custom-label'>You made the following declarations</p>
          <Box marginTop='8px' className='center sub-text-14 declarations-points' justifyContent='start'>
            <img alt='checked-circle' src={CheckedCircle} />
            <p className='sub-text-14'>I have a valid driving license</p>
          </Box>
          <Box className='center sub-text-14 declarations-points' justifyContent='start'>
            <img alt='checked-circle' src={CheckedCircle} />
            <p className='sub-text-14'>My vehicle has a valid MOT</p>
          </Box>
          <Box className='center sub-text-14 declarations-points' justifyContent='start'>
            <img alt='checked-circle' src={CheckedCircle} />
            <p className='sub-text-14'>I have an active car insurance policy</p>
          </Box>
        </Box> */}

        {/* <Box className='location-box cursor-pointer' onClick={downloadPDF}>
          <div className="inline">
            <Box display='flex' className={"cursor-pointer"} alignItems='center'>
              <img alt='doc' src={Notes} />
              <p className='custom-label' onClick={downloadPDF}>Download declaration details</p>
            </Box>
            <img alt='doc' src={Download} />
          </div>
        </Box> */}
      </Box>}

      {
        // line1 will be 'undefined' in case of beta-user signup
        homeLocation.line1 !== 'undefined' &&
        <>
          <Box className='section-head section-head-bg-light-green center' justifyContent='space-between'>
            <p>
              Home location
            </p>
            <p className="link edit-new">
              <Link to="/signup/home-location-detail?prev_url=/signup/summary" className='edit-link edit-link-color-new' style={{ textDecoration: 'none' }}>Edit</Link>
            </p>
          </Box>

          <Box className='address-box' margin='24px 16px'>
            <Box>
              <p className='custom-label'>My address</p>
              <p className='sub-text-14'>
                {
                  homeLocation?.line1 + ', ' + homeLocation?.city + ', ' + homeLocation?.state + ', ' + homeLocation?.zip
                }
                </p>
            </Box>
          </Box>
        </>
      }


      <Box className='section-head section-head-bg-light-green center' justifyContent='space-between'>
        <p>
          Destinations
        </p>
      </Box>

      {
        favLocation.map((val: FavLocationInterface, key: number) => <Box key={key} className='margin-x'>
          {
            <Box className='margin-y' key={key}>
              <Box className='location-box d-flex items-center justify-between py-1'>
                <div className="inline d-flex flex-column items-start">
                  <p className='custom-label-1-0'>{val.categoryName}</p>
                  <p className="text-sm" style={{ fontSize: '12px', color: '#616161', marginBottom: '4px' }}>{val.locationName}</p>
                  {/* <span className='custom-label blue-text' style={{ fontSize: '12px !important' }}>{formatData1(val, "dropOff")}</span> */}
                </div>
              </Box>
            </Box>
          }

          
        </Box>)
      }

      {/* <Box className='gift-box mb-40 d-flex flex-column justify-center items-center' marginTop='32px' marginX='16px'>
        <Button className='next-btn custom-btn w-auto px-15-rem signup-summary-btn' disabled={loading} fullWidth onClick={handleConfirm}>
          <img alt='person' src={TickMark} />
          &nbsp;Complete Registration
        </Button>
        <p className={'helper-btn-text'} >Complete registration and get started in WhatsApp</p> 
      </Box> */}

      <CustomFixedButton disable={loading} text={"Complete Registration"} handleSubmit={handleConfirm}></CustomFixedButton>
    </div>
  )
}

export default SummaryDetail;
