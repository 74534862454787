import { httpRequest } from "../network"
export const getEcoSavings = () => {
    return httpRequest('GET', `/aggregation/eco-saving/user/my-eco-savings`).then((response: any) => {
      return response.data
    }).catch((err) => {
        throw err
      })
  }

export const getEcoTokens = () => {
  return httpRequest("GET",`/aggregation/marketplace/user/eco-token`).then((res:any)=>{
    return res.data
  }).catch((err)=>{
    throw err
  })
}
export const getGroupedGiftCards = () => {
  return httpRequest("GET",`/configuration/giftcards`).then((res:any)=>{
    return res.data
  }).catch((err)=>{
    throw err
  })
}

export const makeGiftCardPayment = (payload:object) => {
  return httpRequest('POST','/payment/true-layer/create-payment-link-gift',payload)
  .then((res)=> res)
  .catch((er)=>{
    throw er
  })
}

export const checkPaymentStatus = (payload: object) => {
  return httpRequest('POST','/payment/true-layer/check-payment-status',payload)
  .then((res)=> res)
  .catch((er)=>{
    throw er
  })
}

export const purchaseUsingToken = (payload:object) => {
  return httpRequest('POST','/payment/transaction/purchase-using-token',payload)
  .then((res)=> res)
  .catch((er)=>{
    throw er
  })
}

export const getWeeklyTripsEarnings = (page: number) => {
  return httpRequest("GET",`/aggregation/marketplace/user/eco-savings-list?page=${page}`).then((res:any)=>{
    return res
  }).catch((err)=>{
    throw err
  })
}

export const getTotalSavings = () => {
  return httpRequest("GET", '/payment/transaction/total-savings')
    .then((res: any) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    })
}

export const listPromosWeekly = (page) => {
  return httpRequest("GET", `/aggregation/promos/user/list?page=${page}`)
    .then((res: any) => res)
    .catch((err) => {
      throw err;
    });
}

export const getApplicablePromos = (price: number) => {
  return httpRequest("GET", `/aggregation/promos/user/applicable-promos?price=${price}`)
    .then((res: any) => res.data)
    .catch((err) => {
      throw err;
    })
}