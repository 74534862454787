import "./style.scss";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import FilledLeap from "../../../assets/images/filled-leap.svg";
import LocationPin from "../../../assets/images/location-pin.svg";
import VerifiedPerson from "../../../assets/images/verified-person.svg";
import EmptyCircle from "../../../assets/images/empty-circle.svg";
import SearchIcon from "../../../assets/images/search-icons.svg";
import CheckedBlack from "../../../assets/images/checkmark_black.svg";
import { Box } from "@mui/material";
import MatchResultListItem from "../../New Screens/Common components/matched-result-list";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  removeSelectedUser,
  setSelectedUsers,
  // removeAllSelectedUser,
  IUSER,
  // IUSERWITHTRIPINFO,
  setFilteredUsersAll,
  IUserDataAll,
  INVITETRIP,
  updateSelectedUsers,
} from "../../../slice/invites";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import {
  getFilteredUsersAll,
  getFindCarpoolUsersAll,
} from "../../../services/invites";
import {
  capitalizeFirstLetter,
  getName,
  getOrganisationLogo,
  getOrganizationName,
  showMapDuration,
  // showMapDuration,
} from "../../../utilities/helper";
import { 
  TripInterface,
  // TripInterface, 
  setNewMatchTrip } from "../../../slice/trip";
import { enqueueSnackbar } from "notistack";
import userVerified from "../../../assets/images/user-verified.svg";
import userNotVerified from "../../../assets/images/user-not-verified.svg";
import CarStearingIcon from "../../../assets/images/stearing.svg";
import UserProfile from "../../../components/user-profile";
import { BasicDetails } from "../../../slice/login";
import CustomFixedButton from "../../../components/custom-fixed-button";

const MatchesAll = () => {
  const [dataFetching, setDataFetching] = useState(true);
  const [filteredUsersToRender, setFilteredUsersToRender] = useState([]);
  const [tabSelected, setTabSelected] = useState("");
  const [userDetail, setUserDetail] = useState<{
    showUserDetail: boolean;
    userDetail: BasicDetails;
  }>({
    showUserDetail: false,
    userDetail: {
      firstName: [],
      lastName: [],
      email: "",
      gender: "",
      phone: "",
      photo: "",
      userPreference: "",
      occupation: "",
      createdAt: "",
    },
  });

  const { decodedData } = useSelector(
    (store: RootState) => store.redirectDataReducer
  );
  const { filteredUsersAll, selectedUsers, selectedUsersId, tripDateUserMap } =
    useSelector((state: RootState) => state.inviteReducer);
  const { trips } = useSelector((store: RootState) => store.tripReducer);
  const { location } = useSelector((store: RootState) => store.categoryReducer);
  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { data: base64Data } = useParams();
  const [searchParams] = useSearchParams();

  // filter users based on the component filter
  useEffect(() => {
    if(tabSelected === "male") {
      let maleUsers = [];

      filteredUsersAll.forEach((user) => {
        // select only male users
        if(user.user.gender.toLowerCase() === "male") {
          maleUsers.push(user)
        }
      })

      // filter only the male users in the selected users list
      const updatedSelectedUsers = maleUsers.map((user) => {
        return {
          ...user.user,
          ...user
        }
      })

      dispatch(updateSelectedUsers(updatedSelectedUsers));

      setFilteredUsersToRender(maleUsers)
    } else if(tabSelected === "female") {
      let femaleUsers = [];
      
      filteredUsersAll.forEach((user) => {
        // select only male users
        if(user.user.gender.toLowerCase() === "female") {
          femaleUsers.push(user)
        }
      })

      // filter only the female users in the selected users list
      const updatedSelectedUsers = femaleUsers.map((user) => {
        return {
          ...user.user,
          ...user
        }
      })

      dispatch(updateSelectedUsers(updatedSelectedUsers));

      setFilteredUsersToRender(femaleUsers)
    } else if(tabSelected === "my org") {
      let organisationUsers = [];

      filteredUsersAll.forEach((user) => {
        if(user.user.email.split("@")[1].toLowerCase() === userDetailsReducer.basicDetails.email.split("@")[1].toLowerCase()) {
          organisationUsers.push(user)
        }
      })

      // filter only same organisation users in selected users list
      const updatedSelectedUsers = organisationUsers.map((user) => {
        return {
          ...user.user,
          ...user
        }
      })

      dispatch(updateSelectedUsers(updatedSelectedUsers));

      setFilteredUsersToRender(organisationUsers)
    } else {
      // set all users in selected users
      const updatedSelectedUsers = filteredUsersAll.map((user) => {
        return {
          ...user.user,
          ...user
        }
      })

      dispatch(updateSelectedUsers(updatedSelectedUsers));

      // if no tab is selected
      setFilteredUsersToRender(filteredUsersAll)
    }

    // eslint-disable-next-line
  }, [tabSelected])

  // redirect to landing page if decoded data is not found
  useEffect(() => {
    if (Object.keys(decodedData).length === 0) {
      // if user comes directly to this page, then redirect to redirection landing page
      navigate(`/redirect/${base64Data}`);
    }
    // eslint-disable-next-line
  }, []);

  // get all filtered users if not already fetched
  useEffect(() => {
    if (filteredUsersAll.length === 0) {
      if (!searchParams.get("newMatch")) {
        // extract trip _ids for all trips
        const tripIds = trips.map((trip) => {
          return trip._id;
        });

        // send array of trip ids
        getFilteredUsers(tripIds);
      } else {
        getFilteredUsers(decodedData.tripIds as string[]);
      }
    } else {
      setDataFetching(false);
    }
    // eslint-disable-next-line
  }, []);

  //DEPRECATED AS PER NEW REQUIREMENT
  // if filtered users is empty, then send the notification to driver on whatsApp
  // useEffect(() => {
  //   if (dataFetching === false && filteredUsersAll.length === 0) {
  //     // hit the whatsApp webhook
  //     whatsAppWebHook(
  //       userDetailsReducer.basicDetails.phone,
  //       "NOMATCH",
  //       undefined,
  //       userDetailsReducer.basicDetails.userPreference
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [dataFetching, filteredUsersAll]);

  // get the data of filtered users for all trips
  const getFilteredUsers = async (tripIds: string[]) => {
    try {
      // make the correct request according to the flow
      let response;
      if (!searchParams.get("newMatch")) {
        // make API request to get the data
        response = await getFilteredUsersAll(tripIds);
      } else {
        response = await getFindCarpoolUsersAll(
          tripIds,
          decodedData.userId as string
        );
      }

      // extract user data to show in the UI
      const filteredUsersArr: IUserDataAll[] = [];
      const filteredUsersIdArr: string[] = [];
      const userTripDateMap: any = {};
      const userTripIdMap: any = {};

      response.data.tripResponse.forEach(
        (trip: {
          invitees: {
            user: IUSER;
            adminServiceFee: number;
            fuelContribution: number;
            finalCost: number;
            totalPrice: number;
            passengerToDestinationDistance: number;
            passengerToDestinationTime: number;
            driverToPassengerDistance: number;
            driverToPassengerTime: number;
            tripId: string;
          }[];
          tripDetails: INVITETRIP;
          totalTripDistance: number;
          totalTripTime: number;
        }) => {
          trip.invitees.forEach((invitee) => {
            // check if the user already exists in filtered users, then just add the date
            if (filteredUsersIdArr.includes(invitee.user._id)) {
              // push the trip date into the user trip map
              userTripDateMap[invitee.user._id].push(
                String(trip.tripDetails.scheduleDate)
              );

              // push the trip id into the user tripId map
              userTripIdMap[invitee.user._id].push(
                String(trip.tripDetails._id)
              );

              // skip next steps in this iteration
              return;
            }

            filteredUsersArr.push({
              user: invitee.user,
              adminServiceFee: invitee.adminServiceFee,
              fuelContribution: invitee.fuelContribution,
              finalCost: invitee.finalCost,
              totalPrice: invitee.totalPrice,
              passengerToDestinationDistance:
                invitee.passengerToDestinationDistance,
              passengerToDestinationTime: invitee.passengerToDestinationTime,
              driverToPassengerDistance: invitee.driverToPassengerDistance,
              driverToPassengerTime: invitee.driverToPassengerTime,
              tripDetails: {
                ...trip.tripDetails,
                totalTripTime: trip.totalTripTime,
                totalTripDistance: trip.totalTripDistance,
              },
            });

            // store id of filtered users
            filteredUsersIdArr.push(invitee.user._id);

            // store the trip dates for user
            userTripDateMap[invitee.user._id] = userTripDateMap[
              invitee.user._id
            ]
              ? [
                ...userTripDateMap[invitee.user._id],
                String(trip.tripDetails.scheduleDate),
              ]
              : [String(trip.tripDetails.scheduleDate)];

            // store the trip ids for user
            userTripIdMap[invitee.user._id] = userTripIdMap[invitee.user._id]
              ? [
                ...userTripIdMap[invitee.user._id],
                String(trip.tripDetails._id),
              ]
              : [String(trip.tripDetails._id)];
          });
        }
      );

      if (searchParams.get("newMatch")) {
        dispatch(setNewMatchTrip(response.data.tripResponse[0].tripDetails));
      }

      // set filtered users in global state
      dispatch(
        setFilteredUsersAll({
          filteredUsersArr,
          filteredUsersIdArr,
          userTripDateMap,
          userTripIdMap,
        })
      );

      // render from this list of users
      setFilteredUsersToRender(filteredUsersArr);

      const selectedUsers = filteredUsersArr.map((user) => {
        return {
          ...user.user,
          adminServiceFee: user.adminServiceFee,
          driverToPassengerDistance: user.driverToPassengerDistance,
          driverToPassengerTime: user.driverToPassengerTime,
          finalCost: user.finalCost,
          fuelContribution: user.fuelContribution,
          passengerToDestinationDistance: user.passengerToDestinationDistance,
          passengerToDestinationTime: user.passengerToDestinationTime,
          totalPrice: user.totalPrice,
          tripDetails: user.tripDetails,
        }
      })

      dispatch(setSelectedUsers(selectedUsers));
    } catch (error) {
      console.log("Error fetching data");
    }

    setDataFetching(false);
  };

  // check if user is selected for invite or not
  const checkInvitedOrNot = (id: string) => {
    return selectedUsersId.includes(id);
  };

  // select button onclick handler
  const selectUserToInvite = (
    user: IUSER,
    adminServiceFee: number,
    fuelContribution: number,
    metaInfo: object
  ) => {
    // if user is already selected, then remove it from selected users
    if (selectedUsersId.includes(user._id)) {
      dispatch(removeSelectedUser([user]));
    } else {
      const updatedUser = {
        ...user,
        adminServiceFee,
        fuelContribution,
        ...metaInfo,
      };

      // if user is not selected, then add it to selected users
      dispatch(setSelectedUsers([updatedUser]));
    }
  };

  // // select All User
  // const SelectAllUser = () => {
  //   // get ids of all the filtered users
  //   let users: IUSERWITHTRIPINFO[] = [];
  //   filteredUsersAll?.forEach((user) => {
  //     // check if current id already exists in the selected users list, update the travel details to use in next screen
  //     if (filteredUsersAll.length === selectedUsers.length) {
  //       return dispatch(removeAllSelectedUser([]));
  //     }
  //     if (!selectedUsersId.includes(user.user._id)) {
  //       const updatedUserData = {
  //         ...user.user,
  //         driverToPassengerTime: user.driverToPassengerTime,
  //         driverToPassengerDistance: user.driverToPassengerDistance,
  //         passengerToDestinationDistance: user.passengerToDestinationDistance,
  //         passengerToDestinationTime: user.passengerToDestinationTime,
  //         finalCost: user.finalCost,
  //         totalPrice: user.totalPrice,
  //         adminServiceFee: user.adminServiceFee,
  //         fuelContribution: user.fuelContribution,
  //         tripDetails: {
  //           totalTripTime: user.tripDetails.totalTripTime,
  //         }
  //       };

  //       users.push(updatedUserData);
  //     }
  //   });

  //   dispatch(setSelectedUsers(users));
  // };

  //Open Route on Map
  const getAllCordinates = (
    e: React.MouseEvent<HTMLDivElement>,
    user: IUserDataAll,
    tripDetails: TripInterface
  ) => {
    // variable to check if it is a map closing request
    let mapClosingRequest = false;

    if (
      (e.currentTarget.firstElementChild as HTMLParagraphElement)
        .textContent === "View on map"
    ) {
      (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
        "Hide map";
    } else {
      (e.currentTarget.firstElementChild as HTMLParagraphElement).textContent =
        "View on map";

      mapClosingRequest = true;
    }

    // set the height of the map div if its height is not already 400px else set the height to 0px
    if (
      (e.currentTarget.nextElementSibling as HTMLDivElement).style.height ===
      "400px"
    ) {
      (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
        "0px";
    } else {
      (e.currentTarget.nextElementSibling as HTMLDivElement).style.height =
        "400px";
    }

    // toggle the chevron and cross icons
    // if down chevron is visible, then set it to hidden and set visibility of cross to visible and vice-versa
    if (
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .firstElementChild as SVGSVGElement
      ).style.visibility === "visible"
    ) {
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .firstElementChild as SVGSVGElement
      ).style.visibility = "hidden";
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .lastElementChild as SVGSVGElement
      ).style.visibility = "visible";
    } else {
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .firstElementChild as SVGSVGElement
      ).style.visibility = "visible";
      (
        (e.currentTarget.lastElementChild as HTMLDivElement)
          .lastElementChild as SVGSVGElement
      ).style.visibility = "hidden";
    }

    // do not make a call to the google maps API if it is a map closing request
    if(mapClosingRequest) {
      return
    }

    const arr: Array<{ lat: number; lng: number }> = [];
    // push latitude and longitude of trip pickup location
    arr.push({
      lat: parseFloat(String(tripDetails?.pickUp?.geo[0])),
      lng: parseFloat(String(tripDetails?.pickUp?.geo[1])),
    });

    // push latitude and longitude of user home location
    arr.push({
      lat: parseFloat(String(user.user?.address?.geo[0])),
      lng: parseFloat(String(user.user?.address?.geo[1])),
    });

    // push latitude and longitude of trip destination location
    arr.push({
      lat: parseFloat(String(tripDetails?.destination?.geo[0])),
      lng: parseFloat(String(tripDetails?.destination?.geo[1])),
    });

    // sending the index of the map
    initMap(e.currentTarget.dataset.index as string, arr);
  };

  const initMap = (
    index: string,
    tripCordinate: { lat: number; lng: number }[]
  ) => {
    var map = new google.maps.Map(
      (document as any).getElementById(`map${index}`),
      {
        center: tripCordinate[0],
        zoom: 8,
      }
    );

    // Create a new DirectionsService object
    var directionsService = new google.maps.DirectionsService();

    // Create a new DirectionsRenderer object
    var directionsRenderer = new google.maps.DirectionsRenderer({
      map: map,
    });

    // Define the start and end locations
    var start = new google.maps.LatLng(
      tripCordinate[0].lat,
      tripCordinate[0].lng
    );
    var end = new google.maps.LatLng(
      tripCordinate[tripCordinate.length - 1].lat,
      tripCordinate[tripCordinate.length - 1].lng
    );

    // Configure the DirectionsRequest object
    var request = {
      origin: start,
      destination: end,
      waypoints: tripCordinate.slice(1, -1).map((coordinate) => ({
        location: new google.maps.LatLng(coordinate.lat, coordinate.lng),
        stopover: true,
      })),
      travelMode: google.maps.TravelMode.DRIVING,
    };

    // Call the DirectionsService to get the route
    directionsService.route(request, function (result, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        // Display the route on the map
        directionsRenderer.setDirections(result);

        // get the directions response of the route
        let directionsResult = directionsRenderer.getDirections()!;

        // loop through each leg and show the duration for each leg in info window
        let prevDistance = 0;
        for (let i = 0; i < directionsResult.routes[0].legs.length; i++) {
          prevDistance = showMapDuration(
            directionsResult,
            map,
            i,
            prevDistance
          );
        }
      }
    });
  };

  const formatDate = (user: IUserDataAll) => {
    let formattedDate = "";
    const dates = tripDateUserMap[String(user.user._id)];
    const sortedDates = [...dates].sort((a, b) => a.localeCompare(b));
    sortedDates.forEach((date, index) => {
      if (index === sortedDates.length - 1) {
        formattedDate += moment(new Date(date)).format("Do");
        formattedDate +=
          " " + moment(new Date(date)).format("MMM");
        return formattedDate
      }
      formattedDate += moment(new Date(date)).format("Do");
      (sortedDates.length > 1 && index === sortedDates.length - 2) ? formattedDate +=
        " " + moment(new Date(date)).format("MMM") + " & " :
        formattedDate +=
        " " + moment(new Date(date)).format("MMM") + ", "
    })
    //   
    // });

    // return formattedDate;
    return formattedDate
  };

  const handleClickNext = () => {
    if (selectedUsers.length === 0) {
      enqueueSnackbar("Must Select at least one trip", { variant: "warning" });
      return;
    }

    if (searchParams.get("newMatch")) {
      navigate(
        `/redirect-page/offer-carpool-summary/${base64Data}?NewMatch=true`
      );
    } else {
      navigate(`/redirect-page/offer-carpool-summary/${base64Data}`);
    }
  };

  // toggle and set the tab
  const handleTabSelected = (event: React.MouseEvent<HTMLDivElement>) => {
    if(tabSelected === (event.target as HTMLDivElement).innerText.toLowerCase()) {
      setTabSelected("");

      (event.target as HTMLDivElement).classList.remove("selected-filter")

      return
    }

    if(["Male", "Female", "My Org"].includes((event.target as HTMLDivElement).innerText)) {
      setTabSelected((event.target as HTMLDivElement).innerText.toLowerCase());

      for(let filter of event.currentTarget.children) {
        (filter as HTMLDivElement).classList.remove("selected-filter")
      }

      (event.target as HTMLDivElement).classList.add("selected-filter")

    }
  }

  // // get organisation name from location details
  // const getOrganizationName = () => {
  //   if(location.domainData) {
  //     // get the email of the user and match its domain against the domain data of location, if domain found, set the organization name, else set the locationName
  //     const emailDomain = userDetail.userDetail.email.split("@")[1];
      
  //     if(location.domainData[emailDomain]) {
  //       return location.domainData[emailDomain].organisationName
  //     }   
  //   }
    
  //   return getName(location.locationName, "EN")
  // }
  
  // // get organisation logo from location details
  // const getOrganisationLogo = () => {
  //   if(location.domainData) {
  //     // get the email of the user and match its domain against the domain data of location, if domain found, set the organization name, else set the locationName
  //     const emailDomain = userDetail.userDetail.email.split("@")[1];

  //     if(location.domainData[emailDomain]) {
  //       return location.domainData[emailDomain].imageData
  //     }   
  //   }

  //   return ""
  // }

  return (
    <div id="matches-offer" className="offer-carpool-wrapper">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
        showBackIcon={true}
        backLink={
          searchParams.get("newMatch")
            ? "/"
            : `/redirect-page/offer-carpool/${base64Data}`
        }
      />

      {/* if filter users are being fetched, then show the loading message */}
      {dataFetching ? (
        <div
          style={{
            height: "80%",
            minHeight: "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // padding: "2rem",
              borderRadius: "1rem",
              //  border: "1px solid #eee",
              margin: "0 1rem",
              fontSize: "0.8rem",
              textAlign: "left",
              lineHeight: "1.3rem",
              backgroundColor: "#FFF",
              width: "100%",
            }}
          >
            <MatchResultListItem
              icon={SearchIcon}
              heading="Searching for matches"
              text={`We're looking for other members on your route to ${getName(
                location.locationName,
                "EN"
              )}...`}
            />
          </div>
        </div>
      ) : (
        <>
          {filteredUsersAll.length === 0 ? (
            navigate("/no-match", { state: {fromOfferCarpool: true} })
          ) : (
            <>
              <Box className="matches-box pb-100">
                <div className="d-flex items-center gap-1 text-md py-1" onClick={handleTabSelected}>
                  <span className="pr-1 text-gray">
                    Filter
                  </span>

                  <span className="filter-button cursor-pointer">Male</span>
                  <span className="filter-button cursor-pointer">Female</span>
                  <span className="filter-button cursor-pointer">My Org</span>
                </div>

                <div className="sub-header mb-0">
                  <p className="heading">
                    Select matches ({selectedUsers.length}/
                    {(filteredUsersToRender as []).length})
                  </p>
                  {/* <p
                    className="sub-text-14 primary-color click"
                    onClick={() => SelectAllUser()}
                  >
                    {filteredUsersAll.length !== 0 &&
                      filteredUsersAll.length === selectedUsers.length
                      ? "De-select all"
                      : "Select all"}
                  </p> */}
                </div>

                {(filteredUsersToRender || []).map((item: any, i: number) => (
                  <Box className={`trip-person-info ${checkInvitedOrNot(item.user._id) ? "invited-person" : "uninvited-user" }`} key={i}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" className="margin-y items-center">
                        <img
                          alt="profile"
                          src={
                            item.user.photo ? item.user.photo : DefaultProfile
                          }
                          height="44px"
                          width="44px"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = DefaultProfile;
                          }}
                          className="rounded-full bordered-2 shadow-light object-fit-cover cursor-pointer"
                          onClick={() => setUserDetail({ showUserDetail: true, userDetail: item.user })}
                        />

                        <Box marginLeft="18px" className="info">
                          <p className="sub-text-14 driver-name d-flex gap-4 ">
                            {capitalizeFirstLetter(
                              getName(item.user.firstName, "EN")
                            )}{" "}

                            {
                              !location.verificationType.includes("NONE") && (
                                <img
                                  src={
                                    item.user.isVerified
                                      ? userVerified
                                      : userNotVerified
                                  }
                                  alt=""
                                />
                              )
                            } 
                            
                            {
                              item.user.isDriverVerified && (
                                <img
                                  src={CarStearingIcon}
                                  alt="driver verified"
                                />
                              )
                            }
                          </p>

                          <Box display="flex" marginY="4px">
                            {item.loginType === "FACEBOOK" && (
                              <img
                                alt="verified"
                                src={VerifiedPerson}
                                onClick={() =>
                                  window.open(
                                    `https://www.facebook.com/profile.php?id=${item.socialId}`,
                                    "_blank"
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </Box>
                          <p className="sub-text text-faded">
                            {formatDate(item)}
                          </p>
                        </Box>
                      </Box>

                      <Box
                        className={`invite-btn invite-btn-active center cursor-pointer d-flex items-center justify-center ${checkInvitedOrNot(item.user._id) && "invite-active"}`}
                        onClick={(e) =>
                          selectUserToInvite(
                            item.user,
                            item.adminServiceFee,
                            item.fuelContribution,
                            {
                              driverToPassengerTime: item.driverToPassengerTime,
                              driverToPassengerDistance:
                                item.driverToPassengerDistance,
                              passengerToDestinationDistance:
                                item.passengerToDestinationDistance,
                              passengerToDestinationTime:
                                item.passengerToDestinationTime,
                              finalCost: item.finalCost,
                              totalPrice: item.totalPrice,
                              tripDetails: {
                                totalTripTime: item.tripDetails.totalTripTime
                              }
                            }
                          )
                        }
                      >
                        <p className="select-button-text d-flex select-none text-700 items-center">
                          {checkInvitedOrNot(item.user._id) ? (
                            <>
                              <img alt="check" src={CheckedBlack} className="mr-0" /> {" "} invite
                            </>
                          ) : "Ignore"}
                        </p>
                      </Box>
                    </Box>

                    <Box marginBottom="8px" className="sub-text">
                      <Box className="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center" width={"80%"}>
                          <img alt="circle" src={EmptyCircle} />

                          <p className="sub-text-14 ml-2">
                            {item.tripDetails.destinationLocationId ===
                              undefined
                              ? getName(location.locationName, "EN")
                              : "Home"}
                          </p>
                        </Box>
                      </Box>

                      <Box
                        color="#E0E0E0"
                        className="center"
                        justifyContent="space-between"
                        marginLeft="10px"
                      >
                        |
                      </Box>

                      <Box className="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center" width={"80%"}>
                          <img alt="circle" src={FilledLeap} />

                          <p className="sub-text-14 ml-2">
                            {getName(item.user?.address.line1, "EN")}
                          </p>
                        </Box>

                        <p className="sub-text text-faded text-600 w-20 align-right">
                          +
                          {item.tripDetails.destinationLocationId ===
                            undefined ? (
                            <>
                              {Math.abs(
                                Number(
                                  (
                                    Number(
                                      item?.passengerToDestinationTime || 0
                                    ) *
                                    60 +
                                    Number(item?.driverToPassengerTime || 0) *
                                    60 -
                                    Number(
                                      item.tripDetails.totalTripTime.toFixed(2)
                                    ) *
                                    60
                                  ).toFixed()
                                )
                              ).toFixed() + " mins"}
                            </>
                          ) : (
                            <>
                              {Math.abs(
                                Number(
                                  (
                                    Number(item?.driverToPassengerTime || 0) *
                                    60 +
                                    Number(
                                      item?.passengerToDestinationTime || 0
                                    ) *
                                    60 -
                                    Number(
                                      item.tripDetails.totalTripTime.toFixed(2)
                                    ) *
                                    60
                                  ).toFixed()
                                )
                              ).toFixed() + " mins"}
                            </>
                          )}
                        </p>
                      </Box>

                      <Box
                        color="#E0E0E0"
                        className="center"
                        justifyContent="space-between"
                        marginLeft="10px"
                      >
                        |
                      </Box>

                      <Box className="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center" width={"80%"}>
                          <img alt="circle" src={LocationPin} />

                          <p className="sub-text-14 ml-2">
                            {item.tripDetails.destinationLocationId ===
                              undefined
                              ? "Home"
                              : getName(location.locationName, "EN")}
                          </p>
                        </Box>
                        
                        <p className="sub-text primary2-color text-600 w-20 align-right">
                          {(
                            Number(item?.driverToPassengerTime || 0) * 60 +
                            Number(item?.passengerToDestinationTime || 0) * 60
                          ).toFixed() + " min"}
                        </p>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        backgroundColor: "#F5F5F5",
                        height: "34px",
                        display: "flex",
                        alignItems: "center",
                        padding: "8px",
                        justifyContent: "space-between",
                        borderRadius: "4px",
                      }}
                    >
                      <Box>
                        <b>Price</b>
                      </Box>

                      <Box>
                        <b>£{(item?.finalCost).toFixed(2)}</b>
                      </Box>
                    </Box>

                    <Box
                      data-index={i}
                      className="map-view cursor-pointer"
                      marginTop="16px"
                      marginBottom="8px"
                      onClick={(e) =>
                        getAllCordinates(e, item, item.tripDetails)
                      }
                    >
                      <p className="view sub-text-14 text-700 primary-color">
                        View on map
                      </p>
                      <div style={{ position: "relative" }}>
                        <ExpandMoreIcon
                          style={{ visibility: "visible" }}
                          className="primary-color"
                        />
                        <CloseIcon
                          style={{
                            visibility: "hidden",
                            position: "absolute",
                            right: "0",
                            top: "0",
                          }}
                          className="primary-color"
                        />
                      </div>
                    </Box>

                    <div
                      id={`map${i}`}
                      className="expand-transition rounded"
                    ></div>
                  </Box>
                ))}
              </Box>

              {/* <Box className="next-btn-box" style={{ position: "sticky" }}>
                <Box className="margin-x d-flex justify-center items-center">
                  <Button
                    className="next-btn custom-btn btn-width btn-text"
                    fullWidth
                    onClick={handleClickNext}
                  >
                    Next
                  </Button>
                </Box>
              </Box> */}
              <CustomFixedButton text={'Next'} disable={false} handleSubmit={handleClickNext} />

              <UserProfile 
                isOpen={userDetail.showUserDetail}
                onClose={() => setUserDetail(prev => ({...prev, showUserDetail: false}))}
                userDetail={{...userDetail.userDetail, locationName: getOrganizationName(location, userDetail), organisationLogo: getOrganisationLogo(location, userDetail)}}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MatchesAll;
