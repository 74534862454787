import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {useNavigate, useRouteError} from "react-router-dom";
import { RootState } from "../../store";

const PageNotFound = () => {
    const error: any = useRouteError();
    const {userDetailsReducer: { user : {email}, basicDetails }} = useSelector((state:RootState)=>state);
    const navigate = useNavigate();
    let [show,setShow] = useState(false);;

    useEffect(()=>{
        if(email !== ""){
            basicDetails.userPreference === 'MARKET_PLACE' ? navigate('/dashboard/market-place') : navigate("/dashboard/upcoming");
        }else{
            basicDetails.userPreference === 'MARKET_PLACE' ? navigate('/dashboard/market-place') : navigate("/")
        }
        setShow(true);
        //eslint-disable-next-line
    },[email]);

    return (
        <>
            {show ? <div id="error-page">
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error?.statusText || error?.message || ''}</i>
                </p>
            </div>
            :
             <div></div>}
        </>
    );
}

export default PageNotFound;
