import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IMyEcoSaving {
  categoryId: string;
  categoryName: string;
  count: number;
  ecoCredits: number;
  carbonSavings: number | null;
  carbonOffset: number
};


const initialState = {
  myEcoSavings: [] as IMyEcoSaving[],
};

export const myEcoSavingsSlice = createSlice({
  name: "myEcoSavings",
  initialState,
  reducers: {
    setMyEcoSavings: (state, action: PayloadAction<any>) => {
      state.myEcoSavings = action.payload;
    },
  },
});

export const {
    setMyEcoSavings
} = myEcoSavingsSlice.actions
export default myEcoSavingsSlice.reducer
