import "./style.scss";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Header from "../../../components/header";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import EmptyCircle from "../../../assets/images/empty-circle.svg";
import LocationPin from "../../../assets/images/location-pin.svg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";

const CustomTrip = () => {
  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  return (
    <div id="custom-detail">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
      />

      <Box margin="16px">
        <Box className="seat-info">
          <Box marginBottom="8px" className="sub-text">
            Tues 25th Apr, 08:00
          </Box>
          <Box marginBottom="8px" className="sub-text">
            2 seats (1 child car seat)
          </Box>
        </Box>
        <Box className="" marginTop="16px">
          <Box marginBottom="8px" className="sub-text">
            <Box className="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <img alt="circle" src={EmptyCircle} />
                <p className="sub-text-14 ml-2">Home</p>
              </Box>
              <p className="sub-text">25th Apr, 08:00</p>
            </Box>
            <Box
              color="#E0E0E0"
              className="center"
              justifyContent="space-between"
              marginLeft="10px"
            >
              |
            </Box>
            <Box className="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <img alt="circle" src={LocationPin} />
                <p className="sub-text-14 ml-2">Home</p>
              </Box>
              <p className="sub-text primary-color">25 min</p>
            </Box>
          </Box>
        </Box>
      </Box>

      <p className="section-head"></p>

      <Box marginY="24px" marginX="16px">
        <p className="heading text-center">Add trip details</p>

        <Box marginTop="30px">
          <p className="custom-label">Pickup date/time</p>
          <TextField
            className="custom-input margin-y-label"
            id="first_name"
            name="carRegistrationNumber"
            placeholder="01/07/1022, 08:00"
            InputProps={{
              style: {
                height: "48px",
              },
            }}
          />
        </Box>
        <Box marginTop="30px">
          <p className="custom-label">Seats available</p>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="margin-y-label"
              name="numOfSeatsAvailableForCarPoolPassengers"
              // size='small'
              defaultValue={2}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box className="center" marginTop="30px" justifyContent="space-between">
          <p className="sub-text-14">Child car seats</p>
          <Box display="flex" alignItems="center">
            <Box className="count-box" id="minus">
              <RemoveIcon sx={{ width: "20px" }} />
            </Box>
            <Box marginX="20px" fontWeight="600" fontSize="18px">
              1
            </Box>
            <Box className="count-box" id="plus">
              <AddIcon sx={{ width: "20px" }} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="next-btn-box">
        <Box className="margin-x">
          <Button className="next-btn custom-btn" fullWidth>
            Next
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default CustomTrip;
