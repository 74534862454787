import { Box } from '@mui/material';
import leftArrow from '../../assets/images/left-arrow.svg';

interface props {
  logo: string,
  status: string,
  date: any,
  amount: string,
  onClick: () => any
}

const PurchaseHistory = ({ logo, status, date, amount, onClick }: props) => {
  return (
    <>
      <Box className="purchase-items" sx={{ padding: "16px 16px 0px 16px" }}>
        <Box
          className="list-box"
          sx={{
            borderBottom: "1px solid #EEEEEE",
            padding: "16px 0px",
            display: "flex",
            alignItems: "center",
            cursor: 'pointer'
          }}
          onClick={onClick}
        >
          <Box
            className="card"
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: '4px',
              alignItems: "center",
              height: "56px",
              width: "56px",
              border: "2px solid #E6F0E7",
              borderRadius: "12px",
              boxShadow: "2px 2px 4px 0px #E6F0E7"
            }}
          >
            <img
              style={{ height: "50px", width: "50px", borderRadius: "5px" }}
              src={logo}
              alt=""
            />
          </Box>

          <Box
            className="card-content"
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "4px",
              marginLeft: "16px",
              width: "80%"
            }}
          >
            <b className='mb-2' style={{ textTransform: "capitalize" }}>{status.toLowerCase()}</b>
            <p className='text-md'>{date}</p>
          </Box>
          <Box
            className="price"
            sx={{ display: "flex", gap: "24px", alignItems: "center" }}
          >
            <Box className="amount" sx={{ display: "inline-block" }}>
              <b>{amount}</b>
            </Box>
            <Box
              className="left-arrow-icon"
              sx={{ display: "inline-block" }}
            >
              <img src={leftArrow} alt="" />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default PurchaseHistory
