import "./style.scss";
import { FC, ReactNode, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Modal from "@mui/material/Modal";
import Header from "../../header";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import DefaultProfile from "../../../assets/images/default-profile.svg";
import CloseIcon from "../../../assets/images/Close.svg";
import { useNavigate } from "react-router-dom";
import { backArrowIcon } from "../../../assets/images";

// import Hammer from "hammerjs";

interface props {
  anchor: "top" | "left" | "bottom" | "right";
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  fullScreen?: boolean;
  backlink?: string;
  overflowDesktop?: boolean
  isContent75vh?: boolean;
  backButtonHandler?: any
}

const CustomDrawer: FC<props> = ({
  anchor,
  isOpen,
  onClose,
  children,
  fullScreen,
  backlink,
  overflowDesktop=true,
  isContent75vh=true,
  backButtonHandler
}) => {
  const [width, setWidth] = useState(1024);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  const navigate = useNavigate();

  const getWindowWidth = () => {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  };

  const handleWindowResize = () => {
    const width = getWindowWidth();
    setWidth(width);
  };

  // const handleSwipeDown = function (ev: HammerInput) {
  //   if (ev.deltaY > 80) {
  //     onClose();
  //   }
  // };

  useEffect(() => {
    try {
      window.addEventListener("resize", handleWindowResize);

      handleWindowResize();
    } catch (err) {
      console.log(err);
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };

    //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (isOpen && width < 800) {
  //     setTimeout(() => {
  //       var myElement = document.getElementById("custom-drawer");
  //       var cotentElement = document.getElementById("drawer-content");

  //       [myElement, cotentElement].forEach((element) => {
  //         try {
  //           var mc = new Hammer(element as HTMLElement);

  //           mc.get("swipe").set({
  //             direction: Hammer.DIRECTION_DOWN,
  //             threshold: 1,
  //             velocity: 0.1,
  //           });

  //           // listen to events...
  //           mc.on("swipedown", handleSwipeDown);
  //         } catch (err) {
  //           console.log("swipe error: ", err);
  //         }
  //       });
  //     }, 100);
  //   }

  //   //eslint-disable-next-line
  // }, [isOpen]);

  return (
    <>
      {width < 800 && (
        <div>
          <Drawer
            id={fullScreen ? "custom-drawer-full-screen" : "custom-drawer"}
            anchor={anchor}
            open={isOpen} // Use the local state for the drawer
            onClose={onClose}
            sx={{overflow: "auto"}}
          >


            <div className="center">
              {
                  (!fullScreen && !!backButtonHandler) && (
                    <div className="absolute cursor-pointer drawer-back-icon" onClick={backButtonHandler}>
                      <div style={{width: "1.7rem", height: "1.7rem"}} className="rounded-full d-flex items-center justify-center">
                            <img src={backArrowIcon} alt="back-button" /> 
                      </div>
                      <b>
                        Back
                        </b>
                    </div>
                  )
                } 
              <div
                className={
                  fullScreen ? "pillar margin-y d-none" : "pillar margin-y"
                }
                onClick={onClose}
              ></div>
            </div>

                {
                  !fullScreen && (
                    <div className="absolute cursor-pointer drawer-close-icon" onClick={onClose}>
                      <div style={{background: "#eee", width: "1.7rem", height: "1.7rem"}} className="rounded-full d-flex items-center justify-center">

                      <img src={CloseIcon} alt="close-drawer" />
                      </div>
                    </div>
                  )
                }

            {fullScreen && (
              <Header
                rightIcon={
                  <img
                    alt="profile"
                    src={
                      userDetailsReducer.basicDetails.photo ?? DefaultProfile
                    }
                    className="header-profile-image bordered-2 shadow-light"
                    onClick={() => navigate("/")}
                  />
                }
                showBackIcon={true}
                backLink={backlink ? backlink : "/dashboard/schedule-detail"}
              />
            )}

            <div id="drawer-content" style={{height: "100%"}} className={`overflow-auto ${isContent75vh ? 'height-75vh ' : ""} `}>{children}</div>
          </Drawer>
        </div>
      )}

      {width >= 800 && (
        <div>
          <Modal id="custom-drawer" open={isOpen} onClose={onClose}>
            <div className={`${overflowDesktop ? 'overflow-auto': ''} sm-h-80 pt-1 bg-w`}>
              <div className="absolute cursor-pointer drawer-close-icon">
                <div style={{background: "#eee", width: "1.7rem", height: "1.7rem"}} className="rounded-full d-flex items-center justify-center">

                <img src={CloseIcon} onClick={onClose} alt="close-drawer" />
                </div>
              </div>

              {children}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

CustomDrawer.defaultProps = {
  fullScreen: false,
};

export default CustomDrawer;
