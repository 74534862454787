import moment from "moment";
import { httpRequest } from "../network";

export const createInvites = (
  tripIds: { _tripId: string; _user: string }[]
) => {
  return httpRequest(
    "POST",
    `aggregation/invite/multiple?utcOffset=${moment().utcOffset()}`,
    {
      inviteRequests: tripIds,
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getFilteredUsersAll = (tripIds: string[]) => {
  return httpRequest(
    "POST",
    `aggregation/invite/filter-users-straight-multiple-trips`,
    { tripIds }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getFindCarpoolUsersAll = (tripIds: string[], userId: string) => {
  return httpRequest("POST", `aggregation/trip/user/new-match`, {
    tripIds,
    userId,
  })
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

// set invite status to cancelled
export const cancelInviteStatus = (
  tripId: string,
  cancellationReason: string
) => {
  return httpRequest("PUT", `aggregation/invite/cancel-invite/${tripId}`, {
    cancellationReason,
  })
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

// check if there are matches for a particular location
export const findMatch = (locationId: string) => {
  return httpRequest("GET", `aggregation/invite/find-match/${locationId}`)
    .then((response: any) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};
