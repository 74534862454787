import "./style.scss";
import testimonialItem from "../../../assets/images/testimonial-item.svg";
import verifyPerson from "../../../assets/images/identity-verified.svg";
import steeringWheel from "../../../assets/images/steering-wheel.svg";
import { Button } from "@mui/material";
import Description from "../../New Screens/Common components/Description";
import { getDriverVerifiedContents, getVerifiedDescriptionContents } from "../../New Screens/Common components";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "../../../components/loading-screen";

const GetVerified = () => {
  const [driverVerification, setDriverVerification] = useState(false)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const location  = useLocation()

  // check if coming for driver verification, if yes, then show appropriate UI 
  useEffect(() => {
    if(location.state && location.state.driverVerification) {
      setDriverVerification(true)
    }

    setLoading(false)
  }, [location.state])

  const handleSubmit = () => {
    if(!driverVerification) {
      // const url = `${process.env.REACT_APP_TRUE_LAYER_SIGNUP_PLUS_URL}&redirect_uri=${process.env.REACT_APP_TRUE_LAYER_REDIRECT_URI}&client_id=${process.env.REACT_APP_TRUE_LAYER_CLIENT_ID}`;
      const url = process.env.REACT_APP_TRUE_LAYER_AUTH_URL;
      
      window.open(url, "_self");
    } else {
      navigate("/get-check-code", {state: { inviteRequests: location.state.inviteRequests, utcOffset: location.state.utcOffset }});
    }
  };

  return (
    <>
      {
        loading ? <LoadingScreen /> : (
          <div id="get-verified-screen" className="d-flex flex-column">
            <div className="d-flex flex-column justify-center items-center p-1 pt-2">
              <div className="get-verified-image">
                <img src={!driverVerification ? verifyPerson : steeringWheel} alt="" />
              </div>
              <div className="get-verified-text sub-text-new font-32 p-1 pt-0">
                <p>
                  {
                    !driverVerification ? "Get verified" : "Get driver verified"
                  }
                  </p>
              </div>
              <div className="get-verified-sub-text text-sm-2 text-center p-1 pt-0 mb-1 sub-text-new">
                <p>
                  {
                    !driverVerification ? (
                      <>
                        Your safety is our priority. That’s why we ask all users to verify
                        their identity and address before they can carpool.
                      </>
                    )
                    :
                    (
                      <>
                        The safety of our drivers and passengers is our priority. That’s why we ask all users to verify their driver’s license, MOT and tax.
                      </>
                    )
                  }
                </p>
              </div>

              <div className="get-verified-testimonial-item mb-5">
                <img src={testimonialItem} alt="" />
              </div>

              <div className="get-verified-sub-text sub-text-new">
                {
                  !driverVerification ? (
                    <>
                      Verification without all the faff
                    </>
                  ) : (
                    <>
                      How it works
                    </>
                  )
                }
              </div>
            </div>

            <div className="get-verified-description-container text-sm p-1">
              {
              !driverVerification ? getVerifiedDescriptionContents.map((contents, key) => (
                <Description
                  key={key}
                  uniqueKey={contents.id}
                  icon={contents.icon}
                  description={contents.description}
                />
              )) : getDriverVerifiedContents.map((contents, key) => (
                <Description
                  key={key}
                  uniqueKey={contents.id}
                  icon={contents.icon}
                  description={contents.description}
                />
              ))
              }
            </div>

            <div className="d-flex justify-center items-end p-1 flex-1 btn-padding-responsive">
              <Button
                className="next-btn custom-btn btn-width px-15rem fw-700 btn-text"
                fullWidth
                onClick={handleSubmit}
              >
                Lets get started
              </Button>
            </div>
          </div>
        )
      }
    </>
  );
};

export default GetVerified;
