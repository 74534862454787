import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store";
import { useEffect } from "react";
import { getUserByJti } from "../../../slice/user";
import { setDecodedData } from "../../../slice/redirect-data";
import { getUser } from "../../../services/user";
import { initUserState } from "../../../slice/login";
import LoadingScreen from "../../../components/loading-screen";
import { enqueueSnackbar } from "notistack";
import { marketPlaceUserACL } from "../../../utilities/helper";

const RedirectPage = () => {
  const { user, userLoading } = useSelector(
    (store: RootState) => store.userReducer
  );
  const { decodedData } = useSelector(
    (store: RootState) => store.redirectDataReducer
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  let { data: base64Data } = useParams();

  // remove any redux persisted data
  localStorage.removeItem("persist:root");

  // decode the base64 encoded url param string and fetch the corresponding user
  useEffect(() => {
    try {
      const decodedDataObj = JSON.parse(atob(String(base64Data)));

      // check if jti is available in decoded data
      if (decodedDataObj.jti !== undefined) {
        // store decoded data in global state
        dispatch(setDecodedData(decodedDataObj));

        // get user data from jti
        getUser(decodedDataObj.jti)
          .then((res) => {
            // remove old token if any
            localStorage.removeItem("token");
            // set new token
            localStorage.setItem("token", res.data.token);
            dispatch(initUserState(res.data));

            res.data.userPreference === 'MARKET_PLACE' &&
            !marketPlaceUserACL.includes(window.location.pathname) &&
            navigate('/dashboard/market-place');

          })
          .catch(() => {
            enqueueSnackbar("Unable to get user details", { variant: "error" });
            // throw new Error("Unable to get user details")
          });

        // fetch the user data from jti value
        dispatch(getUserByJti(decodedDataObj.jti));
      } else {
        throw new Error("JTI not found");
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }

    //eslint-disable-next-line
  }, []);

  // redirect to appropriate url
  useEffect(() => {
    // if fetch request is complete, then check if user is fetched successfully
    if (userLoading === false && Object.keys(user).length > 0) {
      //redirecting to target page
      if (decodedData.redirect === "BookSeat") {
        navigate(`/redirect-page/book-seat/${base64Data}`);
      } else if (decodedData.redirect === "Offer") {
        //redirecting to offer carpool page
        navigate(`/redirect-page/offer-carpool/${base64Data}`);
      } else if (decodedData.redirect === "Finder") {
        //redirecting to finder carpool page
        navigate(`/redirect-page/find-carpool-list/${base64Data}`);
      } else if (decodedData.redirect === "UpdateWeeklySchedule") {
        //redirecting to set-invite-alerts page
        navigate(`/dashboard/invite-alerts`);
      } else if (decodedData.redirect === "MyTrips") {
        //redirect to my trip page
        navigate("/dashboard/upcoming");
      } else if (decodedData.redirect === "MyAccount") {
        //redirect to my profile page
        navigate("/dashboard/profile");
      } else if (decodedData.redirect === "ConfirmSchedule") {
        navigate(`/redirect-page/book-seat`);
      } else if (decodedData.redirect === "ConfirmCarpool") {
        // redirect to confirm carpool page
        navigate("/invites/book-seat/confirm");
      } else if (decodedData.redirect === "MarketPlace") {
        navigate("/dashboard/market-place");
      } else if (decodedData.redirect === "TripDetail") {
        // navigate('/dashboard/trip-detail?redirect=true')
        navigate("/dashboard/home?passenger=true");
        // navigate("/dashboard/upcoming");
      } else if (decodedData.redirect === "JoinCommunity") {
        navigate(`/redirect-page/what-next`, { state: { fromRedirect: true } });
      } else if (decodedData.redirect === "Home") {
        navigate(`/dashboard/home`);
      } else if (decodedData.redirect === "Alerts") {
        navigate(`/dashboard/schedule-detail`);
      } else if (decodedData.redirect === "EcoSavings") {
        navigate(`/dashboard/eco-saving`);
      } else if (decodedData.redirect === "JoinedCommunity") {
        navigate(`/redirect-page/what-next`, { state: { fromRedirect: true } });
      } else if (decodedData.redirect === "ApproveRequest") {
        navigate(`/redirect-page/approve-requests`);
      } else if (decodedData.redirect === "MyBookings") {
        //redirect to my trip page
        navigate("/dashboard/upcoming?passenger=true");
      } else if (decodedData.redirect === 'MySchedule') {
        navigate(`/dashboard/schedule`);
      } else if (decodedData.redirect === 'MyCarpoolGroup') {
        navigate(`/dashboard/group`);
      } else if (decodedData.redirect === 'SeatBooking') {
        navigate(`/redirect-page/whatsapp-seat-booking/${base64Data}`);
      } else if (decodedData.redirect === 'PurchaseHistory') {
        navigate('/dashboard/market-place?tab=purchaseHistory');
      }
    } else if (userLoading === false && Object.keys(user).length === 0) {
      // redirect to login page
      navigate("/");
    }
    // eslint-disable-next-line
  }, [userLoading, user]);

  return (
    <>
      <LoadingScreen />
    </>
  );
};

export default RedirectPage;
