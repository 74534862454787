import React from 'react'
import groupedGiftCards from "../../../../assets/images/groupedGiftCards.png";
import shoppingBagBlack from "../../../../assets/images/shopping-bag-2.svg";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import './style.scss'

interface props {
  isBordered : boolean
  fromHome?: boolean
}

const MarketPlaceCard = ({isBordered, fromHome}:props) => {

  const navigate = useNavigate()
  const getClassName = () => {
    return isBordered
      ? "what-next-grouped-gift-cards-container-main text-center rounded-12 bordered shadow-light-2"
      : "d-flex justify-center items-center flex-column text-center";
  };

  const getButtonClass = () => {
    return isBordered
      ? `d-flex flex-column eco-rewards-btn p-1 pb-2 pt-0 w-auto justify-center items-center ${fromHome && 'width-100'}`
      :  `eco-rewards-btn p-1 pb-2 pt-0 ${fromHome && 'width-100'}`;
  };

  return (
    <div className={getClassName()}>
          <div className="grouped-gift-card p-1 pb-0">
            <div className="all-gift-cards">
              {/* <p className='text-primary fw-700' style={{ fontSize: "1.1rem" }}>
                Some of the top brands waiting for you
              </p> */}

              <img src={groupedGiftCards} alt="" />
            </div>
          </div>

          <div className={getButtonClass()}>
            <Button
              className="next-btn custom-btn px-15-rem"
              fullWidth
              style={{
                width: '90%'
              }}
              onClick={() => navigate("/dashboard/market-place")}
            >
              <img alt="shopping-bag" src={shoppingBagBlack} />
              &nbsp; Save with eco rewards
            </Button>
          </div>
        </div>
  )
}

MarketPlaceCard.defaultProps = {
  isBordered : true
}

export default MarketPlaceCard
