export const data = {} as any

export const pairsDropOff = [
  { key: "0 mins early", value: "0 mins" },
  { key: "10 mins early", value: "10 mins" },
  { key: "20 mins early", value: "20 mins" },
  { key: "30 mins early", value: "30 mins" },
  { key: "45 mins early", value: "45 mins" },
  { key: "60 mins early", value: "60 mins" },
]

export const pairsPickUp = [
  { key: "0 mins later", value: "0 mins" },
  { key: "10 mins later", value: "10 mins" },
  { key: "20 mins later", value: "20 mins" },
  { key: "30 mins later", value: "30 mins" },
  { key: "45 mins later", value: "45 mins" },
  { key: "60 mins later", value: "60 mins" },
]
