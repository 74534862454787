import './App.scss';
import './assets/css/bootstrap/style.scss';
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from './assets/theme';
import routes from './routes';
import { SnackbarProvider } from 'notistack'
import { store } from './store/index';
import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import { inactivityLogout } from './utilities/helper';

function App() {

  useEffect(() => {
    inactivityLogout();
  }, [])

  return (
    <div id='app-container'>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider autoHideDuration={5000} preventDuplicate={true} anchorOrigin={{ "horizontal": 'right', "vertical": "top" }}>
            <RouterProvider router={routes} />
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
