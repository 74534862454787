import { httpRequest } from "../network";

interface WeeklySchedule {
  dropOffFlexibility: string;
  pickUpFlexibility: string;
  dates: string[];
  type: string;
}


export const getSeatAvailability = (id: string) => {
  return httpRequest('GET', `/aggregation/trip/user/seat-availability/${id}`).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const getSeatAvailabilityMultiple = (_tripIds: string[]) => {
  return httpRequest('POST', `/aggregation/trip/user/seat-availability-multiple`, { _tripIds }).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const updateStatus = (_tripIds: string[]) => {
  return httpRequest('PUT', `aggregation/invite/status-multiple`, { _tripIds, inviteStatus: "ACCEPTED" }).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}


export const getSeatDetails = () => {
  return httpRequest('GET', `/aggregation/invite/multiple-detail`, {}).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

// get all invites for trips from a particular driver
export const getInvites = (driverId: string) => {
  return httpRequest('GET', `/aggregation/invite/multiple-detail/${driverId}`, {}).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}


export const findCarPoolAccToSchedule = (data: WeeklySchedule) => {
  return httpRequest('POST', `/aggregation/trip/user/find-carpool-trips`, data).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export const checkInvites = () => {
  return httpRequest('GET', `/aggregation/invite/check-invites-exists`, {}).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}
