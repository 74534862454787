import "./style.scss";
import Header from "../../components/header";
import DefaultProfile from "../../assets/images/default-profile.svg";
import Config from "../../assets/images/config.svg";
import { Box, Button } from "@mui/material";
import CustomDrawer from "../../components/mui/SwipeableDrawer";
import { useEffect, useState } from "react";
import CustomDatePicker from "../../components/time-picker";
import UseSwitchesBasic from "../../components/switch";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { setDecodedLoading } from "../../slice/redirect-data";
import { useNavigate, useParams } from "react-router-dom";
import { getDefaultSchedule } from "../../services/schedule";
import { formateScheduleDateTime } from "../../utilities/helper";
import { findCarPoolAccToSchedule } from "../../services/book-seat";
import { enqueueSnackbar } from "notistack";
import { initBookSeat } from "../../slice/book-seat";
import DropDown from "../../components/drop-down";
import { pairsPickUp, pairsDropOff } from "../../data/book-seat";
import moment, { Moment } from "moment";
import LoadingScreen from "../../components/loading-screen";

interface KeyActiveTime {
  label: string;
  key: string;
  active: boolean;
  pickUp: string;
  dropOff: string;
}

const FindCarpoolSchedule = () => {
  //eslint-disable-next-line
  const [currDaySchedule, setCurrDaySchedule] = useState<Array<KeyActiveTime>>(
    getKeyActiveTime()
  );
  const [flexibility, setFlexibility] = useState(pairsPickUp[0].value);
  const [loading, setLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState("This week");

  const { decodedData, decodedDataLoading } = useSelector(
    (store: RootState) => store.redirectDataReducer
  );
  //eslint-disable-next-line
  const { location, locationLoading, locationError } = useSelector(
    (store: RootState) => store.categoryReducer
  );
  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let { data: base64Data } = useParams();
  const decodedDataObj = JSON.parse(atob(String(base64Data)));

  // when loader is showing, then hide the scrollbar
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else if (!loading) {
      document.body.style.overflow = "auto";
    }
  }, [loading]);

  const handleTimeChange = (currDate: Moment | null, item: KeyActiveTime) => {
    if (currDate === null) return;
    const key = decodedData.type === "Drop Off" ? "dropOff" : "pickUp";
    const newList = currDaySchedule.map((it) => {
      if (it.key === item.key) {
        const targetIndex = getIndexFromDay(item.key);
        //if traget day in next week
        if (currDate.day() > targetIndex) {
          const numOfDays = targetIndex + 7 - currDate.day();
          currDate.add(numOfDays, "day");
          it[key] = currDate.toISOString();
        } else {
          const numOfDays = targetIndex - currDate.day();
          currDate.add(numOfDays, "day");
          it[key] = currDate.toISOString();
        }
      }
      return it;
    });
    setCurrDaySchedule(newList);
  };

  // set decoded loading to false if decoded data is already fetched
  useEffect(() => {
    if (Object.keys(decodedData)?.length > 0) {
      dispatch(setDecodedLoading(false));
    } else if (Object.keys(decodedData)?.length === 0) {
      // if user comes directly to this page, then redirect to redirection landing page
      navigate(`/redirect/${base64Data}`);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDefaultSchedule({ locationId: decodedData.locationId })
      .then((res: any) => {
        const schedule = res?.data?.dailySchedule.schedule as Array<any>;

        if (decodedData.type === "Drop Off") {
          setFlexibility(res.data.dailySchedule.dropOffFlexibility);
        } else {
          setFlexibility(res.data.dailySchedule.pickUpFlexibility);
        }

        if (decodedData.type === "Drop Off") {
          schedule?.forEach((sch) => {
            // because of one based indexing
            let targetIndex = getIndexFromDay(sch.day) - 1;
            currDaySchedule[targetIndex].active =
              sch.dropOff !== "" ? true : false;
            if (sch.dropOff !== "")
              currDaySchedule[targetIndex].dropOff = formateScheduleDateTime(
                targetIndex + 1,
                sch.dropOff
              );
            setCurrDaySchedule([...currDaySchedule] as KeyActiveTime[]);
          });
        } else {
          schedule?.forEach((sch) => {
            // because of one based indexing
            let targetIndex = getIndexFromDay(sch.day) - 1;
            currDaySchedule[targetIndex].active =
              sch.pickUp !== "" ? true : false;
            currDaySchedule[targetIndex].pickUp = formateScheduleDateTime(
              targetIndex + 1,
              sch.pickUp
            );
            setCurrDaySchedule([...currDaySchedule] as KeyActiveTime[]);
          });
        }
      })
      .catch((er) => {
        console.log("wwwwwww", er);
      });
    //eslint-disable-next-line
  }, []);

  // format an object based on request data payload requirements
  const createRequestData = () => {
    // extract dates from currentDaySchedule state
    let scheduledDates: string[] = [];
    const payload = {
      weeklySchedule: {
        dropOffFlexibility: "",
        pickUpFlexibility: "",
        dates: [] as string[],
      },
      type: "",
      locationId: decodedDataObj.locationId,
    };

    if (decodedData.type === "Pick Up") {
      currDaySchedule?.forEach((day) => {
        if (day.active && day.pickUp.length > 0) {
          scheduledDates.push(day.pickUp);
        }
      });
      payload.type = "pickUp";
      payload.weeklySchedule.dates = [...scheduledDates];
      payload.weeklySchedule.pickUpFlexibility = `${flexibility}`;
    } else {
      currDaySchedule?.forEach((day) => {
        if (day.active && day.dropOff.length > 0) {
          scheduledDates.push(day.dropOff);
        }
      });
      payload.type = "dropOff";
      payload.weeklySchedule.dates = [...scheduledDates];
      payload.weeklySchedule.dropOffFlexibility = `${flexibility}`;
    }

    // const requestData: IRequestBody = {
    //   _driver: user._id,
    //   scheduleDate: scheduledDates,
    // };

    // if (decodedData.type === "Pick Up") {
    //   requestData.pickUp = location.address;
    //   requestData.pickUpLocationId = decodedData.locationId;
    // } else {
    //   requestData.destination = location.address;
    //   requestData.destinationLocationId = decodedData.locationId;
    // }

    return payload;
  };

  // onclick handler for next button
  const handleNext = () => {
    const data = createRequestData();
    if (data.weeklySchedule.dates.length === 0) {
      enqueueSnackbar("Must have atleast one date !", { variant: "warning" });
    } else {
      setLoading(true);
      findCarPoolAccToSchedule(data as any)
        .then((it) => {
          dispatch(initBookSeat(it));
          document.body.style.overflow = "auto";
          setLoading(false);
          navigate(`/redirect-page/find-carpool-list/${base64Data}`);
        })
        .catch((error) => {
          setLoading(false);
          document.body.style.overflow = "auto";
          enqueueSnackbar("Unable to find trips at the moment", {
            variant: "warning",
          });
          navigate(`/`);
          console.log(error);
        });
    }
  };

  const handleToggle = (e: any, currentItem: KeyActiveTime) => {
    if (e.target.checked) {
      const newList = currDaySchedule.map((schedule) => {
        if (schedule.key === currentItem.key) {
          schedule.active = true;
        }
        return schedule;
      });
      setCurrDaySchedule(newList);
    } else {
      const newList = currDaySchedule.map((schedule) => {
        if (schedule.key === currentItem.key) {
          schedule.active = false;
        }
        return schedule;
      });
      setCurrDaySchedule(newList);
    }
  };

  return (
    <>
      {decodedDataLoading || loading ? (
        <LoadingScreen />
      ) : (
        <div id="pickup-drop">
          <Header
            rightIcon={
              <img
                alt="profile"
                src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
                className="header-profile-image bordered-2 shadow-light"
              />
            }
          />
          <Box className="margin-x">
            <div className="sub-header">
              <img alt="person" src={Config} />
              <p className="sub-text">
                Set your favourite routes and schedule that you would like to
                carpool. You can update these settings later from your route
                preferences.
              </p>
            </div>
          </Box>
          <p className="section-head">Configure route schedule for next week</p>
          <Box className="margin-y">
            <Box
              className="location-box"
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
            >
              <div className="inline">
                <p className="custom-label">{decodedData.type}</p>
              </div>
            </Box>
          </Box>
          <CustomDrawer
            anchor="bottom"
            fullScreen={true}
            isOpen={true}
            onClose={() => {}}
          >
            <Box id="pickup-drop-drawer" className="margin-x">
              <p className="drawer-title text-center my-3">
                {decodedData.type} schedule
              </p>

              {/* Show this week or next week switch */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <p
                  className={`${
                    tabSelected === "This week" ? "pill pill-active" : "pill"
                  }`}
                  onClick={() => setTabSelected("This week")}
                >
                  This week
                </p>
                <p
                  className={`${
                    tabSelected === "Next week" ? "pill pill-active" : "pill"
                  }`}
                  onClick={() => setTabSelected("Next week")}
                >
                  Next week
                </p>
              </Box>

              <Box id="pickup-drop-drawer">
                <Box className="options-box">
                  {currDaySchedule?.map((item: KeyActiveTime, key: number) => (
                    <Box className="between margin-y" key={key}>
                      <Box className="d-flex" alignItems={"center"}>
                        <UseSwitchesBasic
                          handleChange={(e: any) => handleToggle(e, item)}
                          checked={item.active}
                        />{" "}
                        <p>{item.label}</p>
                      </Box>
                      <Box className={"sm-max-100"}>
                        <CustomDatePicker
                          disbled={!item.active}
                          handleChange={(val: Moment | null) =>
                            handleTimeChange(val, item)
                          }
                          timeDefault={
                            item?.dropOff || item?.pickUp
                              ? decodedData.type === "Pick Up"
                                ? moment(item?.pickUp).format("HH:mm")
                                : moment(item?.dropOff).format("HH:mm")
                              : ""
                          }
                        />
                      </Box>
                    </Box>
                  ))}
                  <>
                    <Box className="option-box-title">
                      {/* I’m flexible and willing to arrive up to */}
                      {decodedData.type === "Pick Up" ? (
                        <>I’m flexible and willing to leave up to</>
                      ) : (
                        <>I’m flexible and willing to arrive up to</>
                      )}
                    </Box>
                    <Box>
                      {/* <TextField
                          className="custom-input"
                          value={flexibility}
                          onChange={(e) => setFlexibility(e.target.value)}
                        /> */}
                      <DropDown
                        pairs={
                          decodedData.type === "Pick Up"
                            ? pairsPickUp
                            : pairsDropOff
                        }
                        handleChange={(e: any) => setFlexibility(e.value)}
                        label={""}
                        val={flexibility}
                      />
                    </Box>
                    <Box className="option-box-helper-text">
                      This will help us match you with more members in your
                      community with similar travel schedules
                    </Box>
                  </>
                  <Button
                    className="save-btn custom-btn calc-top"
                    fullWidth
                    onClick={() => handleNext()}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
          </CustomDrawer>
        </div>
      )}
    </>
  );
};

export default FindCarpoolSchedule;

function getKeyActiveTime() {
  let res: Array<KeyActiveTime> = [
    {
      key: "MONDAY",
      label: "Monday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "TUESDAY",
      label: "Tuesday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "WEDNESDAY",
      label: "Wednesday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "THURSDAY",
      label: "Thursday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
    {
      key: "FRIDAY",
      label: "Friday",
      active: false,
      pickUp: "",
      dropOff: "",
    },
  ];
  return res;
}

function getIndexFromDay(day: string) {
  switch (day) {
    case "MONDAY":
      return 1;
    case "TUESDAY":
      return 2;
    case "WEDNESDAY":
      return 3;
    case "THURSDAY":
      return 4;
    case "FRIDAY":
      return 5;
    default:
      return 1;
  }
}
