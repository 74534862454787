// import { useState } from "react";
import { checkSelectedTripConfirmed, generatePaymentLink } from "../../../services/payment";
import LoadingScreen from "../../../components/loading-screen";
import { useEffect } from "react";
import { paymentButtonHandler } from "../../../utilities/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { getSeatAvailabilityMultiple } from "../../../services/book-seat";

const WhatsappBooking = () => {

  const url = window.location.href.split('/');

  const base64 = url[url.length - 1];
  const location = useLocation();

  const navigate = useNavigate();

  // const modifiedBase64 = base64.includes("?") === true ? base64.split('?')[0] : base64;
  // const fromPayment = new URLSearchParams(window.location.search).get('fromPayment')
  const {amount, tripIds, tripUniqueId} = JSON.parse(atob(base64));
  
  localStorage.setItem("failureRedirect", location.pathname);
  localStorage.setItem("cancelRedirect", '/');
  localStorage.setItem("amount", amount);
  localStorage.setItem("tripIds", tripIds);
  localStorage.setItem("tripUniqueId", tripUniqueId);
  useEffect(() => {

    // if (fromPayment === 'true') {
      generatePayment({ amount: (amount * tripIds.length), tripIds, tripUniqueId })
      .then(console.log)
      .catch(console.log);
    // }
     //eslint-disable-next-line
  }, []);

  const generatePayment = async (payload) => {
    try {
      // check if seat booking window is open or not
      const tripResponse = await checkSelectedTripConfirmed({
        ids: tripIds,
      });

      if (tripResponse.data && tripResponse.data.length) {
        enqueueSnackbar(
          `Seat booking window has been closed for following dates ${tripResponse.data.toString()}`,
          { variant: "error" }
        );

        navigate("/")

        return
      }

      try {
        // check seat availability
        const value = await getSeatAvailabilityMultiple(tripIds)

        // if seat is not available, then show the alert/notification
        if (value.tripSeatDetail[0].seatsAvailable === 0) {
          enqueueSnackbar("Seat is not available for this trip", {
            variant: "warning",
          });

          navigate("/")

          return;
        }
      } catch (error) {
        
        enqueueSnackbar("Seat is not available for this trip", {
          variant: "warning",
        });

        navigate("/")

        return
      }

      const paymentLinkResponse = await generatePaymentLink({...payload});
      if (paymentLinkResponse.success === true) {
        // store the data in local state to trigger the payment process later
      // localStorage.setItem(
      //   "selectedTripResponse",
      //   JSON.stringify(selectedTripResponse)
      // );

      // localStorage.setItem("selectedInvites", JSON.stringify(selectedInvites));

      paymentButtonHandler(paymentLinkResponse);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <LoadingScreen />
    </>
  )
};

export { WhatsappBooking };