import userNotVerified from "../../../assets/images/exclamation-mark.svg";
import notVerified from "../../../assets/images/exclamation-mark-24X24.svg";
import { useLocation } from "react-router-dom";
import InfoBox from "../../New Screens/Common components/InfoBox";
import CustomList from "../../New Screens/Common components/CustomList";
import "./style.scss";
import CustomFixedButton from "../../../components/custom-fixed-button";

const VerificationFailed = () => {
  const {
    state: { first_name, last_name },
  } = useLocation();
  
  const handleClick = () => {
    const url = process.env.REACT_APP_TRUE_LAYER_AUTH_URL;
      
    window.open(url, "_self");
  };

  return (
    <div className="verification-failed-wrapper-main d-flex flex-column">
      <div className="verification-failed-wrapper d-flex flex-column justify-center items-center pt-5 p-1">
        <div className="verification-failed-mark">
          <img src={userNotVerified} alt="user-not-verifed" />
        </div>

        <div className="success-text mt-3">Identity verification failed!</div>
      </div>

      <div className="p-1">
        <InfoBox border="blue">
          <CustomList
            icon={notVerified}
            text={`${first_name} ${last_name}`}
            bordered
            path=""
          />
        </InfoBox>
      </div>

      {/* <div className="p-1">
        <div className="verification-failed-text rounded-12 bordered shadow-light-2 text-sm-2 text-center text-error p-1">
          <p>
            The post code on your profile doesn’t match the post code registered
            on your bank account (shown above)
          </p>
        </div>
      </div>

      <div className="text-center text-sm-2 p-1">
        To ensure a secure carpool community we require all users to verify
        their home address via their bank.
      </div>

      <div className="text-center text-sm-2 p-1">
        To resolve, we need to update your profile address to the address held
        on your bank account.
      </div>

      <div className="text-center text-sm-2 p-1">
        You will then be able to start booking seats on future carpool invites.
      </div> */}

      {/* <div className="d-flex justify-center items-end flex-1 mt-3 p-1 btn-padding-responsive">
        <Button
          className="next-btn custom-btn btn-width px-15rem fw-700 btn-text"
          fullWidth
          onClick={handleClick}
        >
          Try again
        </Button>
      </div> */}

      <CustomFixedButton text={"Try again"} handleSubmit={handleClick} disable={false} />
    </div>
  );
};

export default VerificationFailed;
