import { createBrowserRouter } from "react-router-dom";
import PageNotFound from "../components/page-not-found";
import BasicDetail from "../screens/auth/signup-detail/basic-detail";
import CarDetail from "../screens/auth/signup-detail/car-detail";
import Declaration from "../screens/auth/signup-detail/declaration";
import HomeLocation from "../screens/auth/signup-detail/home-location-detail";
import SignUpFavoriteLocation from "../screens/auth/signup-detail/location/favorite-location";
import SignUpPickup from "../screens/auth/signup-detail/location/pickup-drop";

import GiftProvider from "../screens/gift-provider";
import SignUpSummaryDetail from "../screens/auth/signup-detail/summary/detail";

import SignUpShareDetail from "../screens/auth/signup-detail/summary/share";
import UpcomingTrip from "../screens/dashboard/upcoming-trip";
import Profile from "../screens/dashboard/profile";
import VehicleDetail from "../screens/dashboard/vehicle";
import ScheduleDetail from "../screens/dashboard/schedule";
import TripDetail from "../screens/dashboard/trip-detail-updated";
import Payment from "../screens/payment";
import PaymentSuccess from "../screens/payment/success";
import CheckoutForm from "../screens/payment/checkout";
import GoogleLogin from "../screens/social-login/google-login";
import FacebookLogin from "../screens/social-login/facebook-login";
import AppleLogin from "../screens/social-login/apple-login";
import Matches from "../screens/invites/matches";
import ProfileUpdate from "../screens/update/profile";
import HomeLocationUpdate from "../screens/update/location";
import VehicleUpdate from "../screens/update/vehicle";
import PickupUpdate from "../screens/dashboard/pickOff-dropOff";
import CustomTrip from "../screens/invites/custom-trip";
//import EcoSaving from "../screens/dashboard/eco-saving";
import MarketPlace from "../screens/market place";
import BookSeat from "../screens/invites/book-seat-new";
import RedirectPage from "../screens/redirect/landing";
import OfferCarpool from "../screens/redirect/offer-carpool";
import MatchesAll from "../screens/redirect/matches-all";
import OfferCarpoolSummary from "../screens/redirect/offer-carpool-summary";
import ConfirmSeat from "../screens/invites/confirm-book-seat-1";
import Guard from "../screens/guard";
import BookSeatPaymentHandler from "../screens/invites/handler-payment";
import FindCarpoolSchedule from "../screens/find-carpool";
import BookSeatFiltered from "../screens/find-carpool/book-seat";
import FindCarPoolConfirmSeat from "../screens/find-carpool/confirm-book-seat";
import FinderBookSeatPaymentHandler from "../screens/find-carpool/payment-handler";
import PaymentFinder from "../screens/payment-finder";
import CheckoutFormFinder from "../screens/payment-finder/checkout";
import WeeklySchedule from "../screens/redirect/weekly-schedule-update";
import Home from "../screens/home";
import PaymentDone from "../screens/market place/all done";
// import CardDetail from "../screens/market place/card detail";
import GetVerified from "../screens/verifications/get-verified";
import IdentityVerified from "../screens/verifications/verify";
import EligibilitySuccess from "../screens/New Screens/Eligibility check";
import PublicSignUp from "../screens/New Screens/Public community/sign up";
import PrivateSignUp from "../screens/New Screens/Private community/signup";
import EligibilityCheck from "../screens/New Screens/Private community/eligibility/eligibility-check";
import OTPScreen from "../screens/New Screens/OTP screen";
import NewLogin from "../screens/New Screens/Login";
import VerificationFailed from "../screens/verifications/verification-failed";
import NewSignup from "../screens/New Screens/signup";
import PaymentFailed from "../screens/market place/payment-failed";
import React from "react";
import ReferAndEarn from "../screens/New Screens/Refer-and-earn";
import WhatNextNew from "../screens/New Screens/phase-4/what-next-new";
import Dashboard from "../screens/New Screens/phase-4/dashboard";
// import SearchMatchScreen from "../screens/New Screens/phase-4/search-matches";
import NoMatchScreen from "../screens/New Screens/phase-4/no-match";
import BookingConfirmed from "../screens/New Screens/phase-4/confirm-booking-new";
import CancelTrip from "../screens/New Screens/phase-4/cancel-trip";
import Loading from "../screens/New Screens/phase-4/loading";
import { Approval,
  //  EcoSavingsNew,
  //  CheckVerificationCode, DriverVerificationDone, GetDriverVerified
   } from "../screens/New Screens/phase-5"
import PaymentCancelled from "../screens/New Screens/phase-5/payment/payment-cancelled";
import PaymentFail from "../screens/New Screens/phase-5/payment/payment-failed";
import DriverVerification from "../screens/verifications/driver-verification";
import GiftCard from "../screens/gift-card";
import GiftCardFailure from "../screens/gift-card-failure";
import SignupMySchedule from "../screens/auth/signup-detail/my-schedule";
import Schedule from "../screens/dashboard/my-schedule";
import Group from "../screens/dashboard/group";
import { WhatsappBooking } from "../screens/redirect/whatspapp-booking";
import InviteMarketPlaceFriends from "../screens/market place/invite-friends";
import MarketPlaceFriendsList from "../screens/market place/invite-friends/list";
import HowWorks from "../screens/auth/signup-detail/how-works";
import MarketPlaceHowWorks from "../screens/market place/how-marketplace-works";
import InviteAlertsPage from "../screens/auth/signup-detail/invite-alerts";
import { CarpoolingPreferences } from "../screens/auth/signup-detail/carpool-preference";
import Maintenance from "../screens/maintenance";
import { MarketPlaceUserAuth } from "../screens/auth/marketplace-auth";

export default createBrowserRouter([
  {
    element: <Home />,
    errorElement: <PageNotFound />,

    children: [
      {
        path: "",
        element: <NewLogin />,
        errorElement: <PageNotFound />,
      },
      {
        path: "marketplace-login",
        element: <MarketPlaceUserAuth />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/google-login",
        element: <GoogleLogin />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/facebook-login",
        element: <FacebookLogin />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/apple-login",
        element: <AppleLogin />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/get-verified",
        element: <GetVerified />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/get-check-code",
        element: <Guard />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "",
            element: <DriverVerification />,
            errorElement: <PageNotFound />,
          }
        ]
      },

      {
        path: "/eligibility-success",
        element: <EligibilitySuccess />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/public-signup",
        element: <PublicSignUp />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/private-signup",
        element: <PrivateSignUp />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/what-next",
        element: <WhatNextNew />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/eligibility-check",
        element: <EligibilityCheck />,
        errorElement: <PageNotFound />,
      },
      {
        path: "/otp-screen",
        element: <OTPScreen />,
        errorElement: <PageNotFound />,
      },
      // {
      //   path: "basic-detail",
      //   element: <BasicDetail />,
      //   errorElement: <PageNotFound />,
      // },
      {
        path: "payment-failed",
        errorElement: <PageNotFound />,
        element: <PaymentFail />,
      },
      {
        path: "signup",
        // element: <SignUpGuard />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "",
            element: <NewSignup />,
            errorElement: <PageNotFound />,
          },
          {
            path: "basic-detail",
            element: <BasicDetail />,
            errorElement: <PageNotFound />,
          },
          {
            path: "vehicle-detail",
            element: <CarDetail />,
            errorElement: <PageNotFound />,
          },
          {
            path: "declaration",
            element: <Declaration />,
            errorElement: <PageNotFound />,
          },
          {
            path: "home-location-detail",
            element: <HomeLocation />,
            errorElement: <PageNotFound />,
          },
          {
            path: "carpool-preference",
            element: <CarpoolingPreferences />,
            errorElement: <PageNotFound />,
          },
          {
            path: "schedule",
            element: <SignupMySchedule />,
            errorElement: <PageNotFound />
          },
          {
            path: "invite-alerts",
            element: <InviteAlertsPage />,
            errorElement: <PageNotFound />
          },
          {
            path: "favorite-location",
            element: <SignUpFavoriteLocation />,
            errorElement: <PageNotFound />,
          },
          {
            path: "pickup-drop",
            element: <SignUpPickup />,
            errorElement: <PageNotFound />,
          },
          {
            path: "summary",
            element: <SignUpSummaryDetail />,
            errorElement: <PageNotFound />,
          },
          {
            path: "share",
            element: <SignUpShareDetail />,
            errorElement: <PageNotFound />,
          },
          {
            path: "how-works",
            element: <HowWorks />,
            errorElement: <PageNotFound />,
          }
        ],
      },
      {
        path: "gift-provider",
        element: <GiftProvider />,
        errorElement: <PageNotFound />,
      },
      {
        path: "verification",
        element: <Guard />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "success",
            element: <IdentityVerified />,
            errorElement: <PageNotFound />,
          },
          {
            path: "failure",
            element: <VerificationFailed />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: "dashboard",
        element: <Guard />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "group",
            element: <Group />,
            errorElement: <PageNotFound />
          },
          {
            path: "upcoming",
            element: <UpcomingTrip />,
            errorElement: <PageNotFound />,
          },
          {
            path: "profile",
            element: <Profile />,
            errorElement: <PageNotFound />,
          },
          {
            path: "vehicle-detail",
            element: <VehicleDetail />,
            errorElement: <PageNotFound />,
          },
          {
            path: "schedule",
            element: <Schedule/>,
            errorElement: <PageNotFound />
          },
          {
            path: "schedule-detail",
            element: <ScheduleDetail />,
            errorElement: <PageNotFound />,
          },
          {
            path: "invite-alerts",
            element: <InviteAlertsPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "my-destination",
            element: <ScheduleDetail />,
            errorElement: <PageNotFound />,
          },
          {
            path: "pick-detail/:id",
            element: <PickupUpdate />,
            errorElement: <PageNotFound />,
          },
          {
            path: "trip-detail",
            element: <TripDetail />,
            errorElement: <PageNotFound />,
          },
          // {
          //   path: "eco-saving",
          //   // element: <EcoSaving />,
          //   element: <EcoSavingsNew />,
          //   errorElement: <PageNotFound />,
          // },
          {
            path: "refer-and-earn",
            element: <ReferAndEarn />,
          },
          {
            path: "market-place/invite",
            element: <InviteMarketPlaceFriends />
          },
          {
            path: "market-place/invites/list",
            element: <MarketPlaceFriendsList />
          },
          {
            path: "market-place",
            errorElement: <PageNotFound />,
            element: <MarketPlace />,
          },
          {
            path: "market-place/how-works",
            errorElement: <PageNotFound />,
            element: <MarketPlaceHowWorks />
          },
          {
            path: "market-place/card-detail",
            errorElement: <PageNotFound />,
            element: <></>,
          },
          {
            path: "market-place/payment-done",
            errorElement: <PageNotFound />,
            element: <PaymentDone />,
          },
          {
            path: "market-place/payment-failed",
            errorElement: <PageNotFound />,
            element: <PaymentFailed />,
          },
          {
            path: "home",
            element: <Dashboard />,
          },
          {
            path: "cancel-trip",
            element: <CancelTrip />,
          },
          {
            path: "redirect-to-home",
            element: <Loading />,
          },
        ],
      },
      {
        path: "invites",
        errorElement: <PageNotFound />,
        children: [
          {
            path: "custom-trip",
            element: <CustomTrip />,
            errorElement: <PageNotFound />,
          },
          {
            path: "matches/:id",
            element: <Matches />,
            errorElement: <PageNotFound />,
          },
          {
            path: "book-seat",
            element: <BookSeat />,
            errorElement: <PageNotFound />,
          },
          {
            path: "book-seat/confirm",
            element: <ConfirmSeat />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: "payment",
        element: <Payment />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "",
            element: <CheckoutForm />,
            errorElement: <PageNotFound />,
          },
          {
            path: "success",
            element: <PaymentSuccess />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: "payment-finder",
        element: <PaymentFinder />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "",
            element: <CheckoutFormFinder />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: "invites",
        errorElement: <PageNotFound />,
        children: [
          {
            path: "custom-trip",
            element: <CustomTrip />,
            errorElement: <PageNotFound />,
          },
          {
            path: "matches/:id",
            element: <Matches />,
            errorElement: <PageNotFound />,
          },
        ],
      },

      {
        path: "update",
        element: <Guard />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "",
            element: <ProfileUpdate />,
            errorElement: <PageNotFound />,
          },
          {
            path: "home-location",
            element: <HomeLocationUpdate />,
            errorElement: <PageNotFound />,
          },
          {
            path: "vehicle",
            element: <VehicleUpdate />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: "redirect/:data",
        element: <RedirectPage />,
        errorElement: <PageNotFound />,
      },
      {
        path: "redirect-page",
        element: <Guard />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "whatsapp-seat-booking/:data",
            element: <WhatsappBooking />,
            errorElement: <PageNotFound />
          },
          {
            path: "book-seat/:data",
            element: <BookSeat />,
            errorElement: <PageNotFound />,
          },
          {
            path: "confirm-seat/:data",
            element: <ConfirmSeat />,
            errorElement: <PageNotFound />,
          },
          {
            path: "book-seat-payment-handler",
            element: <BookSeatPaymentHandler />,
            errorElement: <PageNotFound />,
          },
          // offer carpoll pages
          {
            path: "offer-carpool/:data",
            element: <OfferCarpool />,
            errorElement: <PageNotFound />,
          },
          {
            path: "matches/all/:data",
            element: <MatchesAll />,
            errorElement: <PageNotFound />,
          },
          {
            path: "offer-carpool-summary/:data",
            element: <OfferCarpoolSummary />,
            errorElement: <PageNotFound />,
          },
          // find a carpool pages
          {
            path: "find-carpool-schedule/:data",
            element: <FindCarpoolSchedule />,
            errorElement: <PageNotFound />,
          },
          {
            path: "find-carpool-list/:data",
            element: <BookSeatFiltered />,
            errorElement: <PageNotFound />,
          },
          {
            path: "find-carpool-confirm/:data",
            element: <FindCarPoolConfirmSeat />,
            errorElement: <PageNotFound />,
          },
          {
            path: "finder-book-seat-payment-handler",
            element: <FinderBookSeatPaymentHandler />,
            errorElement: <PageNotFound />,
          },
          //update weekly schedule
          {
            path: "update-weekly-schedule/:data",
            element: <WeeklySchedule />,
            errorElement: <PageNotFound />,
          },
          // redirect market-place
          {
            path: "update-weekly-schedule/:data",
            element: <WeeklySchedule />,
            errorElement: <PageNotFound />,
          },
          {
            path: "what-next",
            element: <WhatNextNew />,
            errorElement: <PageNotFound />,
          },
          {
            path: "approve-requests",
            element: <Approval />
          }
        ],
      },
      // to show gift card purchase from tillo in an iframe (not-functional as gift-cards are not allowed to be rendered in an iframe)
      {
        path: "gift-card",
        element: <Guard />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: "",
            element: <GiftCard />,
            errorElement: <PageNotFound />,
          }
        ]
      },
      {
        path: "payment-submitted",
        element: <BookingConfirmed />,
      },
      {
        path: "no-match",
        element: <NoMatchScreen />,
      },
      {
        path: "payment-cancelled",
        element: <PaymentCancelled />
      },
      {
        path: "/maintenance",
        element: <Maintenance />
      },

      // UI testing routes - TEMPORARY
      {
        path: "gift-failure",
        element: <GiftCardFailure />
      }
      // {
      //   path: "refer-and-earn",
      //   element: <ReferAndEarn />,
      // },
      // {
      //   path: "whatsapp-signup",
      //   element: <NewSignup />,
      // },
      // {
      //   path: "what-next-new",
      //   element: <WhatNextNew />,
      // },
      // {
      //   path: "search-match",
      //   element: <SearchMatchScreen />,
      // },
      // {
      //   path: "driver-verify",
      //   element: <GetDriverVerified />
      // },
      // {
      //   path: "check-code",
      //   element: <CheckVerificationCode />
      // },
      // {
      //   path: "driver-verification-done",
      //   element: <DriverVerificationDone />
      // },
      // {
      //   path: "eco-savings-new",
      //   element: <EcoSavingsNew />
      // },
    ],
  },
]);
