import { Fragment } from "react";
import whatsApp from "../../../../assets/images/whatsapp.svg";
import "./style.scss";
import PhoneInput from "react-phone-input-2";
import CustomFixedButton from "../../../../components/custom-fixed-button";

interface IProps {
  type ?: string;
  value: string | number | undefined;
  showLabel ?: boolean;
  placeHolderText ?:string;
  btnText ?: string;
  whatsAppSignUp ?: boolean;
  onChange: (event:any) => void;
  handleSubmit: () => void;
}
export const CustomInputBox = ({
  value,
  showLabel,
  type,
  placeHolderText,
  btnText,
  whatsAppSignUp,
  onChange,
  handleSubmit,

}: IProps) => {

  return (
    <Fragment>
      <div className="user-mobile-number-input mb-4">
        {showLabel && 
         <div className="label-container">
         <label htmlFor="">{type === "email"? "Email": "Phone"}</label>
         </div>
        }
        {type === "email" && <input
          type={type}
          placeholder={placeHolderText}
          value={value}
          onChange={onChange}
        />}
        {type !== "email" && <PhoneInput
          country={"gb"}
          specialLabel=''
          placeholder={placeHolderText}
          inputClass='custom-phone-input margin-y-label'
          value={value as string}
          onChange={(val: any)=>{onChange(val)}}
        />}
      </div>
      {/* <div className="sign-up-button d-flex flex-column justify-center items-center my-3">
        <Button
          className="next-btn custom-btn btn-width px-15-rem"
          fullWidth
          onClick={handleSubmit}
        >
            {whatsAppSignUp && <img alt="person" src={whatsApp} />}
          
          &nbsp;{btnText}
        </Button>
      </div> */}
      <CustomFixedButton text={btnText}
        icon={whatsAppSignUp && whatsApp}
        disable={false}
        handleSubmit={handleSubmit}
        style={whatsAppSignUp ? {
          border: "none",
          backgroundColor: "#171717"
        } : {}}
      />
    </Fragment>
  );
};

CustomInputBox.defaultProps = {
    type : "tel",
    placeHolderText : "Enter your mobile number",
    showLabel: false,
    whatsAppSignUp: true,
    btnText : "Sign up with WhatsApp"
}



export default CustomInputBox;
