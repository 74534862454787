import { httpRequest } from "../network";


export const uploadBasicDetails = (data: any) => {
  return httpRequest('POST', '/auth/signup/basic-detail', data).then((response: any) => {
    return response.data
  })
    .catch((err) => {
      throw err
    })
}
export const uploadUserBasicDetails = (data: any) => {
  return httpRequest('POST', '/auth/signup/user-basic-detail', data).then((response: any) => {
    return response.data
  })
    .catch((err) => {
      throw err
    })
}

export function mapFormToRequestForBasicDetails (values: any): any{
  console.log(values,"pppppppppppppppp")
    const res = {
      firstName: [{
        lang: "EN",
        value: values.firstName
      }],
      lastName:[{
        lang: "EN",
        value: values.lastName
      }],
      email: values.email,
      phone: values.phoneNumber,
      gender: values.gender,
      photo: values.photo,
      draftId: values._id,
      occupation: values.occupation
    }
    return res;
}
