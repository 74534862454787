import React, { useEffect, useState } from 'react'
import "./style.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/loading-screen';

const GiftCard = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation()

  // check if user is trying to react this page directly
  useEffect(() => {
    if(!location.state || !location.state.giftURL) {
      navigate("/")
    } else {
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      {
        loading ? <LoadingScreen /> : (
          <div className='gift-card-container'>
            <iframe src={location.state.giftURL} title="Gift Card" width="100%" />
          </div>
        )
      }
    </>
  )
}

export default GiftCard