import React, { useEffect, useState } from "react";
import checkMark from "../../../../assets/images/check_mark.svg";
import MatchResultListItem from "../../Common components/matched-result-list";
import BellIcon from "../../../../assets/images/bell-icon.svg";
import GiftIcon from "../../../../assets/images/giftIcon.svg";
import { Box } from "@mui/material";
import "./style.scss";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getTilloGiftCard } from "../../../../utilities/helper";
import LoadingScreen from "../../../../components/loading-screen";
import { enqueueSnackbar } from "notistack";
import CustomFixedButton from "../../../../components/custom-fixed-button";
//import { getAllTripsWithPopulatedDetails } from "../../../../services/trips";

// const description = [
//   {
//     icon: shoppingBag,
//     text: "Rewards marketplace",
//     bordered: true,
//     hasArrow: true,
//     path: "/dashboard/market-place",
//   },
//   {
//     icon: leaf,
//     text: "My eco savings",
//     hasArrow: true,
//     path: "/dashboard/eco-saving",
//   },
// ];

const BookingConfirmed = () => {
  const [giftCardUrl, setGiftCardUrl] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // make a request to get gift card details
    if(location.state && location.state?.redirect && location.state?.redirect === "marketplace") {
      const giftDetails = JSON.parse(localStorage.getItem("giftData"));

      if(giftDetails.deliveryType && giftDetails.deliveryType === "AUTO") {
        // get the gift card url from tillo
        fetchGiftCard(giftDetails);
      } else {
        setLoading(false)
      }
    } else {
      // if directly visiting this page
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [location.state])
  
  // make a request to configuration service to get gift card details from tillo 
  const fetchGiftCard = async (giftDetails: { card_name: string, card_value: number, tilloIdentifier: string }) => {
    try {
      if(!localStorage.getItem("transactionUniqueId")) {
        enqueueSnackbar("Something went wrong. Please contact support", { variant: "error" })

        navigate("/")

        return
      }

      // retry one time if gift card fetch fails
      let tilloGiftCard: {data: {url: string}} = { data: { url: "" } };
      try {
        tilloGiftCard = await getTilloGiftCard(localStorage.getItem("transactionUniqueId"), giftDetails.tilloIdentifier || giftDetails.card_name, giftDetails.card_value) as {data: {url: string}};
      } catch (error) {
        tilloGiftCard = await getTilloGiftCard(localStorage.getItem("transactionUniqueId"), giftDetails.tilloIdentifier || giftDetails.card_name, giftDetails.card_value) as {data: {url: string}}; 
      }

      
      setGiftCardUrl(tilloGiftCard.data.url)
    } catch (error) {
      // enqueueSnackbar("Unable to process gift card. Please contact support", { variant: "error" })
      
      // redirect to gift card error page
      navigate("/gift-failure", { state: { fromCardDetail: true, transactionUniqueId: localStorage.getItem("transactionUniqueId") } })
    } finally {
      // remove transactionId from localStorage
      localStorage.removeItem("transactionUniqueId");
      
      setLoading(false)
    }

  }

  const handleClick = () => {
    const giftDetails = JSON.parse(localStorage.getItem("giftData"));

    if(giftDetails?.deliveryType && giftDetails.deliveryType === "AUTO" && location?.state && location.state.redirect && location.state.redirect === "marketplace") {
      window.open(giftCardUrl, "_blank");

      // navigate("/gift-card", { state: { giftURL: giftCardUrl } });
    } else {
      navigate("/dashboard/redirect-to-home");
    }
  };

  return (
    <>
    {
      loading ? <LoadingScreen /> : (
        <>
          {
            !location.state || !location.state.redirect ? (
              <Navigate to="/" />
            ) : (
              <div
                id="booking-confirm"
                className="d-flex justify-start items-center flex-column"
              >
                <div className="confirmed-check-mark pt-5 mb-3">
                  <img src={checkMark} alt="confirmed" />
                </div>

                <div className="fw-700 mb-5">
                  <p>
                    {
                      giftCardUrl !== "" ? "All done!" : "Your payment is submitted"
                    }
                  </p>
                </div>

                <div className="mb-5 p-1">
                  <MatchResultListItem
                    icon={giftCardUrl ? GiftIcon : BellIcon}
                    text={
                      <div className="text-md">
                        <p className="mb-3">
                          {
                            giftCardUrl ? "We’ve successfully processed your order!" : "We’re processing your payment, its normally instant, but can take up to 24 hours."
                          }
                        </p>

                        <p>
                          {
                            giftCardUrl ? "Your gift card is now ready to spend!" : "We’ll notify you via WhatsApp once its done."
                          }
                        </p>
                      </div>
                    }
                  />
                </div>

                {/* {
                  location.state?.redirect === "bookSeat" && (
                    <div className="mb-5 confirm-seat-info-list p-1">
                      <InfoBox border="green">
                        {description.map((item, index) => (
                          <CustomList key={index} {...item} />
                        ))}
                      </InfoBox>
                    </div>
                  )
                } */}


                <Box className="done-btn d-flex flex-column flex-end gap-1 items-center flex-1 p-1 btn-padding-responsive">
                  {
                    giftCardUrl && (
                        // <Button
                        //   className="custom-btn color-primary"
                        //   fullWidth
                        //   variant="outlined"
                        //   style={{ borderWidth: "2px" }}
                        //   onClick={() => navigate("/dashboard/market-place?tab=purchaseHistory")}
                        // >
                        //   Purchase history
                        // </Button>
                        // <CustomFixedButton text={'Purchase history'} disable={false} handleSubmit={() => navigate("/dashboard/market-place?tab=purchaseHistory")} />
                        <></>
                    
                        )
                  }

                  {/* <Button
                    className="custom-btn"
                    fullWidth
                    onClick={handleClick}
                  >
                    {
                      giftCardUrl ? "Open gift card" : "Done"
                    }
                  </Button> */}
                  <CustomFixedButton text={giftCardUrl ? "Open gift card" : "Done"} disable={false}
                    handleSubmit={handleClick}
                    upperButtonDisable={false}
                    upperButtonText="Purchase history"
                    upperButtonHandler={() => navigate("/dashboard/market-place?tab=purchaseHistory")}
                    showUpperButton={!!giftCardUrl}
                  />
                </Box>
              </div>
            )
          }
        </>
      )
    }
    </>
  );
};

export default BookingConfirmed;
