import './style.scss';
import Header from '../../../../components/header';
import DefaultUserIcon from '../../../../assets/images/defaultUser.svg';
import ddIcon from '../../../../assets/images/ddIcon.svg';
import Car from '../../../../assets/images/car.svg';
import { Box, FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { AppDispatch, RootState } from '../../../../store';
import { validationSchema } from '../car-detail/validation';
import { getDraftUserVehicleDetail, resetVehicleError, updateVehicleDetails } from '../../../../slice/sign-up';
import { carDetailUpload, mapFormToRequestForCarDetails } from '../../../../services/car-detail';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import LoadingScreen from '../../../../components/loading-screen';
import CustomFixedButton from '../../../../components/custom-fixed-button';

const CarDetail = () => {
    const { signUpDetails } = useSelector((state: RootState) => state);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const prevURL = searchParams.get('prev_url')

    const draftId = localStorage.getItem("draftId");

    const formik = useFormik({
        initialValues: signUpDetails.vehicleDetails,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                dispatch(updateVehicleDetails(values));
                const payload = mapFormToRequestForCarDetails({
                    ...values,
                    ...signUpDetails.user,
                    ...{ _id: draftId }

                });

                // if (!payload.isMOT || !payload.isDrivingLicence || !payload.isInsurance) {
                //     enqueueSnackbar(
                //         "Mark all the checkboxes", { variant: 'error' }
                //     )
                //     return
                // }
                
                await carDetailUpload(payload);
                if (prevURL) {
                    navigate(prevURL)
                } else {
                    if (signUpDetails.basicDetails.carPoolPreference !== "OFFER") {
                        navigate("/signup/invite-alerts");
                    } else {
                        navigate("/signup/summary");
                    }
                }

            } catch (error) {
                console.log(error);
                enqueueSnackbar("Something went wrong", { variant: 'error' })
            }
        }
    });

    useEffect(() => {
        if (!draftId) {
            navigate("/");
        } else {
            console.log('inside else')
            // get vehicle details
            if (signUpDetails.vehicleDetails.carRegistrationNumber === "") {
                dispatch(getDraftUserVehicleDetail(draftId))
            }
        }
        //eslint-disable-next-line
    }, [draftId])

    // show error message if there is some error fetching vehicle details
    useEffect(() => {
        if (signUpDetails.vehicleError) {
            enqueueSnackbar('vehicle details cannot be loaded. Please re-enter the details to update or try after some time', { variant: 'error' })
            dispatch(resetVehicleError())
        }
        //eslint-disable-next-line
    }, [signUpDetails.vehicleError])

    return (
        <div id='car-detail'>
            <Header
                canGoBack={true}
                rightIcon={<img alt='profile' className='header-img-icon' src={
                    (signUpDetails.basicDetails.photoPath as string) ?? DefaultUserIcon
                } />}
                signUp={true}
            />
            <Box className='padding-x rounded-top-green'>
                <div className="sub-header">
                    <div className="sub-header-icon-container">
                        <img alt='person' src={Car} />
                    </div>
                    <p className='sub-text-new sub-text font-1-5rem '>Vehicle details</p>
                </div>
            </Box>
            {/* <p className="section-head">
                Vehicle details
            </p> */}
            <form onSubmit={formik.handleSubmit}>
                <Box className='margin-x'>
                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="first_name">Car registration</label>
                        <TextField
                            className='custom-input margin-y-label vehicle-detail-input'
                            id='first_name'
                            name="carRegistrationNumber"
                            value={formik.values.carRegistrationNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.carRegistrationNumber && Boolean(formik.errors.carRegistrationNumber)}
                            helperText={formik.touched.carRegistrationNumber && formik.errors.carRegistrationNumber}
                            placeholder='For example CU57ABC or ABC1234'
                            InputProps={{
                                style: {
                                    height: '48px'
                                }
                            }}
                        />
                    </Box>
                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="first_name">Number of seats available for carpool passengers</label>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className='margin-y-label'
                                name="numOfSeatsAvailableForCarPoolPassengers"
                                value={formik.values.numOfSeatsAvailableForCarPoolPassengers}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.numOfSeatsAvailableForCarPoolPassengers && Boolean(formik.errors.numOfSeatsAvailableForCarPoolPassengers)}
                                IconComponent={() =>
                                    <img alt={"car"} style={{ paddingRight: '10px' }} src={ddIcon} />}
                                renderValue={(selected) => {
                                    if (selected === 0) {
                                        return <span style={{ fontSize: "14px", color: "#9E9E9E" }}>Please select an option</span>;
                                    }

                                    return selected;
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                }}
                            >
                                <MenuItem disabled value={0}>
                                    <span style={{ fontSize: "14px" }}>Please select an option</span>
                                </MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </Select>
                            {formik.touched.numOfSeatsAvailableForCarPoolPassengers && (
                                <FormHelperText sx={{ color: 'error.main', marginLeft: "0 !important" }}>{formik.errors.numOfSeatsAvailableForCarPoolPassengers}</FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                    
                    {/* <Box className='margin-y'>
                        <label className='custom-label' htmlFor="first_name">Number of child car seats</label>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className='margin-y-label'
                                name="numOfSeatsForChild"
                                value={formik.values.numOfSeatsForChild}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                IconComponent={() =>
                                    <img alt={"car"} style={{ paddingRight: '10px' }} src={ddIcon} />}
                                renderValue={(selected) => {
                                    if (selected === 0) {
                                        return <span style={{ fontSize: "14px", color: "#9E9E9E" }}>Please select an option</span>;
                                    }

                                    return selected;
                                }}
                            >
                                <MenuItem disabled value={0}>
                                    <span style={{ fontSize: "14px" }}>Please select an option</span>
                                </MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </Select>
                        </FormControl>
                    </Box> */}

                    {/* <Box className='options-box my-4'>
                        <p className='custom-label dark-label mb-3'>Please confirm the following statements are correct</p>

                        <form onSubmit={formik.handleSubmit}>
                            <Box marginBottom="19px">
                                <div className="checkbox-container">
                                    <Checkbox
                                        name={"isDrivingLicence"}
                                        checked={formik.values.isDrivingLicence}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                    <p className='sub-text'>I have a valid driving license</p>
                                </div>

                                <div className="checkbox-container">
                                    <Checkbox color='primary'
                                        name={"isMOT"}
                                        checked={formik.values.isMOT}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                    <p className='sub-text'>My vehicle has a valid MOT</p>
                                </div>

                                <div className="checkbox-container">
                                    <Checkbox color='primary'
                                        name={"isInsurance"}
                                        checked={formik.values.isInsurance}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                    <p className='sub-text'>I have an active car insurance policy</p>
                                </div>
                            </Box>
                        </form>
                    </Box> */}

                    {
                        signUpDetails.vehicleLoading && (
                            <LoadingScreen />
                        )
                    }

                    {/* <Box className="d-flex justify-center items-center mb-40">
                        <Button className='next-btn custom-btn btn-width px-15rem' disabled={formik.isSubmitting} fullWidth type={"submit"} >Next</Button>
                    </Box> */}
                    <CustomFixedButton text={"Next"} disable={formik.isSubmitting}></CustomFixedButton>
                </Box>
            </form>

        </div>
    )
}

export default CarDetail;
