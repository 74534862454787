import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { getPaymentStatus } from "../services/payment";
import { NavigateFunction } from "react-router-dom";
import { IPaymentLinkResponse } from "../interface/payment";
import { Buffer } from "buffer";
import { httpRequest } from "../services/network";

// format the schedule date of the trip
export const returnFormattedDate = (xyz: any) => {
  return moment(xyz.scheduleDate).format("LLL");
};

// extract the name of the filtered user
export const getName = (
  arrayVal: Array<{ value: string; lang: string }> | undefined,
  lang: string | undefined
) => {
  return (arrayVal || []).find((obj) => (obj as { lang: string }).lang === "EN")
    ?.value;
};

//eslint-disable-next-line
function formatDate(dateString: string) {
  const momentObj = moment(dateString, "h:mm A");
  const isoString = momentObj.format("YYYY-MM-DDTHH:mm:ssA");
  return isoString;
}

function getWeekDates(): string[] {
  const today: Date = new Date();
  const nextMonday: Date = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + ((1 + 7 - today.getDay()) % 7)
  );

  const dates: string[] = [];
  for (let i = 1; i < 6; i++) {
    const date: Date = new Date(nextMonday.getTime() + i * 24 * 60 * 60 * 1000);
    console.log("date", date);
    dates.push(date.toISOString().slice(0, 10));
  }

  return dates;
}

export function formatDateAndTime(date: string, time: string): string {
  const arr = time.split(":");
  const today = moment();
  if (arr.length === 2) {
    today.set({
      hour: parseInt(arr[0]),
      minute: parseInt(arr[1]),
      second: 0,
      millisecond: 0,
    });
  }
  return today.toISOString();
}

export function formatDateAndTimes(time: string): string {
  const arr = time.split(":");
  const today = moment();
  if (arr.length === 2) {
    today.set({
      hour: parseInt(arr[0]),
      minute: parseInt(arr[1]),
      second: 0,
      millisecond: 0,
    });
  }
  return today.toISOString();
}

export const formatSchedule = (array: any) => {
  const format: any = [
    {
      key: "MONDAY",
      label: "Monday",
      active: "",
      pickUp: "",
      dropOff: "",
    },
    {
      key: "TUESDAY",
      label: "Tuesday",
      active: "",
      pickUp: "",
      dropOff: "",
    },
    {
      key: "WEDNESDAY",
      label: "Wednesday",
      active: "",
      pickUp: "",
      dropOff: "",
    },
    {
      key: "THURSDAY",
      label: "Thursday",
      active: "",
      pickUp: "",
      dropOff: "",
    },
    {
      key: "FRIDAY",
      label: "Friday",
      active: "",
      pickUp: "",
      dropOff: "",
    },
  ];

  const weekDates = getWeekDates();
  for (let i = 0; i < array?.length; i++) {
    const schedule = array[i];

    const index = format.findIndex((item: any) => item.key === schedule.day);

    if (index >= 0) {
      format[index].active = true;
      // format[index]._id = schedule._id;
      format[index]["pickUp"] = schedule["pickUp"];
      format[index]["dropOff"] = schedule["dropOff"];
      format[index]["formattedPickUp"] = formatDateAndTime(
        weekDates[index],
        schedule["pickUp"]
      );
      format[index]["formattedDropOff"] = formatDateAndTime(
        weekDates[index],
        schedule["dropOff"]
      );
    }
  }

  for (let i = 0; i < format.length; i++) {
    if (!format[i].active) {
      format[i].active = false;
    }
  }

  return format;
};

export const getIndexFromDay = (day: string) => {
  switch (day) {
    case "MONDAY":
      return 1;
    case "TUESDAY":
      return 2;
    case "WEDNESDAY":
      return 3;
    case "THURSDAY":
      return 4;
    case "FRIDAY":
      return 5;
    default:
      return 1;
  }
};

export function formateScheduleDateTime(
  weekDay: number,
  time: string,
  tabSelected: string = "Next week"
): string {
  const arr = time.split(":");
  const currentDate = moment();
  if (arr.length === 2) {
    // if (weekDay <= currentDate.day()) {
    //   currentDate.add("days", 6 + weekDay - 1).set({ hour: parseInt(arr[0]), minute: parseInt(arr[1]), second: 0, millisecond: 0 });
    // } else {
    //   currentDate.add("days", weekDay - currentDate.day()).set({ hour: parseInt(arr[0]), minute: parseInt(arr[1]), second: 0, millisecond: 0 });
    // }

    // if tab selected is "This week", then set the time for this week
    if (tabSelected === "This week") {
      // set the dates for this week
      currentDate.set({
        day: weekDay,
        hour: parseInt(arr[0]),
        minute: parseInt(arr[1]),
        second: 0,
        millisecond: 0,
      });
    } else {
      // set the dates for next week
      currentDate.add(1, "week").set({
        day: weekDay,
        hour: parseInt(arr[0]),
        minute: parseInt(arr[1]),
        second: 0,
        millisecond: 0,
      });
    }
  }
  return currentDate.toISOString();
}

export const handleGoToWhatsapp = () => {
  if (isMobileBrowser()) {
    console.log("Mobile browser detected");
    // Perform mobile-specific actions or load mobile-friendly content
    const deepLink = `whatsapp://send?phone=${process.env.REACT_APP_WHATSAPP_NUMBER}`; // Replace with your deep link URL

    // Create an anchor element with the deep link URL
    const linkElement = document.createElement("a");
    linkElement.setAttribute("href", deepLink);

    // Simulate a click event to trigger the app redirection
    const clickEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    linkElement.dispatchEvent(clickEvent);
  } else {
    console.log("Web browser detected");
    window.open(
      `https://web.whatsapp.com/send/?phone=${process.env.REACT_APP_WHATSAPP_NUMBER}&text&type=phone_number&app_absent=0`,
      '_self'
    );
    // Perform actions specific to web browsers
  }
};

function isMobileBrowser() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

// handle payment button click
export const handlePaymentButton = async (
  setPaymentLoading: (value: React.SetStateAction<boolean>) => void,
  navigate: NavigateFunction,
  paymentLinkResponse: IPaymentLinkResponse,
  fromMarketplace = false
) => {
  const paymentScreenWidth = 548;
  const paymentScreenHeight = 650;

  // open the payment link in a new window
  const paymentWindow = window.open(
    paymentLinkResponse.data.url,
    "_blank",
    `popup=1,width=${paymentScreenWidth},
          height=${paymentScreenHeight},
          left=${window.screen.width / 2 - paymentScreenWidth / 2},
          top=${window.screen.height / 2 - paymentScreenHeight / 2}`
  );


  // create and interval to call check-payment-status every 5 seconds
  let intervalId = setInterval(async () => {
    try {
      // if user closes the popup, then show the message and disable the loading
      if (paymentWindow?.closed) {
        setPaymentLoading(false);

        // stop making the request
        clearInterval(intervalId);

        // clear 5 minutes timeout function
        clearTimeoutFunc();

        // navigate to cancelled payment page
        navigate("/payment-cancelled", {state: {redirect: fromMarketplace ? 'marketplace' : 'bookSeat'}});
      }

      // make a request to server to get payment status
      const paymentStatusResponse = await getPaymentStatus({
        transactionId: paymentLinkResponse.data.transactionId,
      });

      if (paymentStatusResponse.data.paymentStatus === "SUCCESS") {
        // close the payment window
        paymentWindow?.close();

        setPaymentLoading(false);

        // stop making the request
        clearInterval(intervalId);

        // clear 5 minutes timeout function
        clearTimeoutFunc();

        enqueueSnackbar("Payment successful", { variant: "success" });

        // remove user data from local host in case of unverified user for book seat flow
        localStorage.removeItem("selectedTripResponseNew");

        // remove data from local storage in case of unverified user for find carpool/review invites flow
        localStorage.removeItem("selectedTripResponse");
        localStorage.removeItem("selectedInvites");

        // navigate to payment completion page
        navigate("/payment-submitted", {state: {redirect: fromMarketplace ? 'marketplace' : 'bookSeat'}});
      } else if (paymentStatusResponse.data.paymentStatus === "FAILURE") {
        // close the payment window
        paymentWindow?.close();

        setPaymentLoading(false);

        // stop making the request
        clearInterval(intervalId);

        // clear 5 minutes timeout function
        clearTimeoutFunc();

        if((paymentStatusResponse.data.callBackPayload.failure_reason === "canceled") || (paymentStatusResponse.data.callBackPayload.failure_reason === "user_canceled_at_provider") || (paymentStatusResponse.data.callBackPayload.failure_reason === "not_authorized") || (paymentStatusResponse.data.callBackPayload.failure_reason === "not_authorised")) {
          // navigate to cancelled payment page
          navigate("/payment-cancelled", {state: {redirect: fromMarketplace ? 'marketplace' : 'bookSeat'}});
        } else {
          // navigate to failed payment page
          navigate("/payment-failed", { state: {paymentStatusResponse: paymentStatusResponse.data} });
        }
      }
    } catch (error) {
      // close the payment window
      paymentWindow?.close();

      setPaymentLoading(false);

      // stop making the request
      clearInterval(intervalId);

      // clear 5 minutes timeout function
      clearTimeoutFunc();
    }
  }, 5000);

  // after 5 minutes, if the payment is not successful/not done, then cancel the payment window and retain the user at the same screen for another payment
  let timeoutId = setTimeout(() => {
    setPaymentLoading(false);

    clearInterval(intervalId);

    paymentWindow?.close();

    enqueueSnackbar("Payment is unsuccessful. Please try again", {
      variant: "error",
    });
  }, 300000);

  let clearTimeoutFunc = () => {
    clearTimeout(timeoutId);
  };
};

// handle payment button click
export const paymentButtonHandler = async (
  // setPaymentLoading: (value: React.SetStateAction<boolean>) => void,
  // navigate: NavigateFunction,
  paymentLinkResponse: IPaymentLinkResponse,
  fromMarketplace = false
) => {
  // remove marketplace from local storage
  localStorage.removeItem("fromMarketplace");

  // remove any old paymentId
  localStorage.removeItem("paymentId");

  // save the new paymentId
  localStorage.setItem("paymentId", paymentLinkResponse.data.transactionId);

  if(fromMarketplace) {
    // save the fromMarketplace
    localStorage.setItem("fromMarketplace", fromMarketplace.toString());
  }

  // open the payment link in a new window
  window.open(
    paymentLinkResponse.data.url,
    "_self",
  );

  // // create and interval to call check-payment-status every 5 seconds
  // let intervalId = setInterval(async () => {
  //   try {
  //     // if user closes the popup, then show the message and disable the loading
  //     if (paymentWindow?.closed) {
  //       setPaymentLoading(false);

  //       // stop making the request
  //       clearInterval(intervalId);

  //       // clear 5 minutes timeout function
  //       clearTimeoutFunc();

  //       // navigate to cancelled payment page
  //       navigate("/payment-cancelled", {state: {redirect: fromMarketplace ? 'marketplace' : 'bookSeat'}});
  //     }

  //     // make a request to server to get payment status
  //     const paymentStatusResponse = await getPaymentStatus({
  //       transactionId: paymentLinkResponse.data.transactionId,
  //     });

  //     if (paymentStatusResponse.data.paymentStatus === "SUCCESS") {
  //       // close the payment window
  //       paymentWindow?.close();

  //       setPaymentLoading(false);

  //       // stop making the request
  //       clearInterval(intervalId);

  //       // clear 5 minutes timeout function
  //       clearTimeoutFunc();

  //       enqueueSnackbar("Payment successful", { variant: "success" });

  //       // remove user data from local host in case of unverified user for book seat flow
  //       localStorage.removeItem("selectedTripResponseNew");

  //       // remove data from local storage in case of unverified user for find carpool/review invites flow
  //       localStorage.removeItem("selectedTripResponse");
  //       localStorage.removeItem("selectedInvites");

  //       // navigate to payment completion page
  //       navigate("/payment-submitted", {state: {redirect: fromMarketplace ? 'marketplace' : 'bookSeat'}});
  //     } else if (paymentStatusResponse.data.paymentStatus === "FAILURE") {
  //       // close the payment window
  //       paymentWindow?.close();

  //       setPaymentLoading(false);

  //       // stop making the request
  //       clearInterval(intervalId);

  //       // clear 5 minutes timeout function
  //       clearTimeoutFunc();

  //       if((paymentStatusResponse.data.callBackPayload.failure_reason === "canceled") || (paymentStatusResponse.data.callBackPayload.failure_reason === "user_canceled_at_provider") || (paymentStatusResponse.data.callBackPayload.failure_reason === "not_authorized") || (paymentStatusResponse.data.callBackPayload.failure_reason === "not_authorised")) {
  //         // navigate to cancelled payment page
  //         navigate("/payment-cancelled", {state: {redirect: fromMarketplace ? 'marketplace' : 'bookSeat'}});
  //       } else {
  //         // navigate to failed payment page
  //         navigate("/payment-failed", { state: {paymentStatusResponse: paymentStatusResponse.data} });
  //       }
  //     }
  //   } catch (error) {
  //     // close the payment window
  //     paymentWindow?.close();

  //     setPaymentLoading(false);

  //     // stop making the request
  //     clearInterval(intervalId);

  //     // clear 5 minutes timeout function
  //     clearTimeoutFunc();
  //   }
  // }, 5000);

  // // after 5 minutes, if the payment is not successful/not done, then cancel the payment window and retain the user at the same screen for another payment
  // let timeoutId = setTimeout(() => {
  //   setPaymentLoading(false);

  //   clearInterval(intervalId);

  //   paymentWindow?.close();

  //   enqueueSnackbar("Payment is unsuccessful. Please try again", {
  //     variant: "error",
  //   });
  // }, 300000);

  // let clearTimeoutFunc = () => {
  //   clearTimeout(timeoutId);
  // };
};

export const formatDateWithSuffix = (originalDateString: string) => {
  const originalDate = new Date(originalDateString);
  const day = originalDate.getDate();
  const suffix =
    day >= 11 && day <= 13
      ? "th"
      : { 1: "st", 2: "nd", 3: "rd" }[day % 10] || "th";
  return `${day}${suffix} ${originalDate.toLocaleString("default", {
    month: "short",
  })}`;
};

// show duration in the map
export const showMapDuration = (
  directionsResult: google.maps.DirectionsResult,
  map: google.maps.Map,
  legIndex: number,
  prevDistance: number
) => {
  let route = directionsResult.routes[0];
  let totalDistance = 0;
  let legs = route.legs;

  // distance of current leg
  totalDistance = legs[legIndex].distance!.value;

  let tempDistanceSum = 0;
  let dist = 0;
  let distanceFromOrigin = 0;
  let point: any;

  // add last leg's distance to current leg's center point to show the info window at correct location
  distanceFromOrigin = 0.5 * totalDistance + prevDistance;

  let resultObj: any = {};
  resultObj.routes = [];
  resultObj.routes[0] = route;

  // loop through the route path and set the appropriate coordinates to show the info window
  for (var i in resultObj.routes[0].overview_path) {
    if (parseInt(i) > 0) {
      // calculate distance between two points on the route
      dist = google.maps.geometry.spherical.computeDistanceBetween(
        resultObj.routes[0].overview_path[i],
        resultObj.routes[0].overview_path[parseInt(i) - 1]
      );
    }

    tempDistanceSum += dist;
    // stop in the middle of a leg and store the coordinates of the point
    if (tempDistanceSum > distanceFromOrigin) {
      point = resultObj.routes[0].overview_path[i];
      break;
    }
  }

  // set the content of info window
  let content = `<div class="map-duration-container">${
    legs[legIndex].duration!.text
  }</div>`;

  // create info window object
  let infowindow = new google.maps.InfoWindow({
    content: content,
    position: point,
  });

  // show info window
  infowindow.open(map);

  return totalDistance + prevDistance;
};

export const validate = (type: "email" | "phone", value: string) => {
  const errorText = type === "phone" ? "Phone number" : "Email";

  if (!value) {
    enqueueSnackbar(`${errorText} is required`, { variant: "error" });
    return false;
  }

  if (type === "email") {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      enqueueSnackbar("Invalid email address", { variant: "error" });
      return false;
    }
  } else {
    // Phone number validation
    if (value.length < 10 || value.length > 13) {
      enqueueSnackbar("Invalid phone number", { variant: "error" });
      return false;
    }
  }

  return true;
};

export const jsonToBase64 = (object) => {
  const json = JSON.stringify(object);
  return Buffer.from(json).toString("base64");
};

// parking fee calculation formula
export const calculateParkingFee = (parkingFee: number, totalSeats: number) => {
  return parkingFee / (totalSeats + 1) / 2;
};

export const getMyInvitesUrl = (jti: string) => {
  let object = {
    redirect: "Finder",
    jti,
  };
  return jsonToBase64(object);
};

// clear data from local storage before user verification flow
export const clearLocalStorageBeforeVerification = () => {
  // remove already existing data from local storage
  localStorage.removeItem("locationId");
  localStorage.removeItem("inviteData");
  localStorage.removeItem("selectedTripResponseNew");
  localStorage.removeItem("selectedTripResponse");
  localStorage.removeItem("selectedInvites");
};

// service fee calculation function based on fuelCost + parkingCost - discountedPrice
export function calculateServiceFee(afterDiscountPrice: number) {
  // return static service fee as per ticket EC3-T921, EC3-T985
  return 0.35;

  // const serviceFeeBracket = {
  //   bracketOne: { min: 0, max: 0.5, serviceFee: 0.5 },
  //   bracketTwo: { min: 0.6, max: 1, serviceFee: 0.5 },
  //   bracketThree: { min: 1.1, max: 2, serviceFee: 0.5 },
  //   bracketFour: { min: 3, max: 4, serviceFee: 0.75 },
  //   bracketFive: { min: 5, max: 7, serviceFee: 1 },
  //   bracketSix: { min: 8, max: 10, serviceFee: 1.5 },
  //   bracketSeven: { min: 11, max: 14, serviceFee: 2 },
  //   bracketEight: { min: 15, max: 18, serviceFee: 2.5 },
  //   bracketNine: { min: 19, max: 21, serviceFee: 3 },
  //   bracketTen: { min: 22, max: 25, serviceFee: 3.5 },
  //   bracketEleven: { min: 26, max: 29, serviceFee: 4 },
  //   bracketTwelve: { min: 30, max: 33, serviceFee: 4.5 },
  //   bracketThirteen: { min: 34, max: 37, serviceFee: 5 },
  //   bracketFourteen: { min: 38, max: 41, serviceFee: 5.5 },
  //   bracketFifteen: { min: 42, max: 45, serviceFee: 6 },
  // };

  // switch (true) {
  //   case afterDiscountPrice <= serviceFeeBracket.bracketOne.max:
  //     return serviceFeeBracket.bracketOne.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketTwo.max:
  //     return serviceFeeBracket.bracketTwo.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketThree.max:
  //     return serviceFeeBracket.bracketThree.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketFour.max:
  //     return serviceFeeBracket.bracketFour.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketFive.max:
  //     return serviceFeeBracket.bracketFive.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketSix.max:
  //     return serviceFeeBracket.bracketSix.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketSeven.max:
  //     return serviceFeeBracket.bracketSeven.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketEight.max:
  //     return serviceFeeBracket.bracketEight.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketNine.max:
  //     return serviceFeeBracket.bracketNine.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketTen.max:
  //     return serviceFeeBracket.bracketTen.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketEleven.max:
  //     return serviceFeeBracket.bracketEleven.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketTwelve.max:
  //     return serviceFeeBracket.bracketTwelve.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketThirteen.max:
  //     return serviceFeeBracket.bracketThirteen.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketFourteen.max:
  //     return serviceFeeBracket.bracketFourteen.serviceFee;
  //   case afterDiscountPrice <= serviceFeeBracket.bracketFifteen.max:
  //     return serviceFeeBracket.bracketFifteen.serviceFee;
  //   default:
  //     return serviceFeeBracket.bracketFifteen.serviceFee;
  // }
}

export const capitalizeFirstLetter = (string: any) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
};


export const navgiateToTermsAndConditionPage = () => {
  window.open("https://drive.google.com/file/d/1H_ktE1jW7c-ztjrFqOlOarF10mlSoDdV/view","_blank")
}

export const navigateToPrivacyPolicyPage = () => {
  window.open("https://drive.google.com/file/d/1fEdSHCZKcQAsVxsYb0bFQWtAFEHz0ySf/view?usp=sharing", "_blank");
};

// get weekday name based on the index
export const getWeekdayName = (day: number) => {
  // start from the beginning of the week after friday
  while(day > 5) {
    day -= 5
  }

  switch (day) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    default:
      return "";
  }
} 

// get gift card from tillo
export const getTilloGiftCard = (tripUniqueId: string, brand: string, amount: number) => {
  return httpRequest("POST", `/configuration/giftcards/gift-card-url?tripUniqueId=${tripUniqueId}`, { brand, amount })
  .then(response => response)
  .catch(err => {
    throw err
  })
}

// get organisation name from location details
export const getOrganizationName = (location, userBasicDetail) => {
  if(location.domainData) {
    // get the email of the user and match its domain against the domain data of location, if domain found, set the organization name, else set the locationName
    const emailDomain = userBasicDetail.userDetail.email.split("@")[1];
    
    if(location.domainData[emailDomain]) {
      return location.domainData[emailDomain].organisationName
    }   
  }
  
  return getName(location.locationName, "EN")
}

// get organisation logo from location details
export const getOrganisationLogo = (location, userBasicDetail) => {
  if(location.domainData) {
    // get the email of the user and match its domain against the domain data of location, if domain found, set the organization name, else set the locationName
    const emailDomain = userBasicDetail.userDetail.email.split("@")[1];

    if(location.domainData[emailDomain]) {
      return location.domainData[emailDomain].imageData
    }   
  }

  return ""
}

export const inactivityLogout = () => {
  let timerId;
  const eventName = detectDeviceType() === 'Mobile' ? 'touchstart' : 'mousemove';

  const inactivityEventHandler =  () => {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      localStorage.removeItem('token');
      const newWindow = window.open('https://www.google.com', '_self')
      newWindow.close();
    }, 1000 * 60 * 60 * 4 );
  }

  if (localStorage.getItem('token') !== undefined) {
    window.addEventListener('load', inactivityEventHandler);
    window.addEventListener(eventName, inactivityEventHandler);
  } else {
    window.removeEventListener('load', inactivityEventHandler);
    window.removeEventListener(eventName, inactivityEventHandler);
  }

}

export const detectDeviceType = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? 'Mobile'
    : 'Desktop';

export const marketPlaceUserACL = [
  '/dashboard/market-place',
  '/dashboard/redirect-to-home',
  '/payment-submitted',
  '/payment-cancelled',
  '/payment-failed'
];
