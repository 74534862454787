import { httpRequest } from "../network";

interface IProps {
  zip: string,
  geo: string,
  country: string,
  city: string,
  state: string,
  line1: string,
  line2: string,
  type: string
}

export const homeLocationDetailUpload = (data: IProps) => {
  return httpRequest('POST', '/auth/signup/home-location-detail', data).then((response: any) => {
    return response.data
  }).catch((err) => {
      throw err
    })
}

export function mapDataForHomeLocation(formikData:any,user:any): any{
  const payload = {
    state: [{ "lang": "EN", "value": formikData.state }],
    city:  [{ "lang": "EN", "value": formikData.city}],
    // country:  [{ "lang": "EN", "value": location.country }],
    // geo:[location.latitude,location.longitude],
    zip: formikData.zip,
    line1:  [{ "lang": "EN", "value": formikData.line1 }],
    line2: formikData.line2 !== "" ? [{ "lang": "EN", "value": formikData.line2 }] : undefined,
    draftId: user._id,
    // additionalInstructions: [{ "lang": "EN", "value": formikData.additionalInstructions }],
  }
  
  return payload;
}