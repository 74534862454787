import { Box } from "@mui/material"
import Header from "../../../../components/header";
import { defaultProfile, greenTick, infoPic } from "../../../../assets/images";
import CustomFixedButton from "../../../../components/custom-fixed-button";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";

const HowWorks = () => {
  const navigate = useNavigate();
  const location = useLocation()

  const { profilePicture } = location.state

  return (
    <Box id="how-works-container">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={
              profilePicture ?? defaultProfile
            }
            className="header-profile-image bordered-2 shadow-light"
          />
        }
      />
      <Box className="how-works-content">
        <Box className="how-works-heading">
          <h2 className="text-xl mt-2">Welcome to EcoPooling!</h2>
        </Box>
        <Box className="text-center">
          <img className="how-works-image" src={infoPic} alt={"How it works"} />
        </Box>

        <h2 className="text-center mb-05">How it works</h2>

        <Box className="how-works-body">
          <Box className="how-works-body-line">
            <img className="how-works-green-tick" src={greenTick} alt="Green tick" />
            <div>
              Share your commute with others and save money on your travel costs
            </div>
          </Box>
          <Box className="how-works-body-line">
            <img className="how-works-green-tick" src={greenTick} alt="Green tick" />
            <div>
              Drivers - as soon as another member joins your route, you'll get an invitation
              via WhatsApp to include them in your carpool group. You can then start arranging
              carpools together
            </div>
          </Box>
          <Box className="how-works-body-line">
            <img className="how-works-green-tick" src={greenTick} alt="Green tick" />
            <div>
             Passengers - sit back and relax. You'll receive invites to carpools from
             other members of your community via Whatsapp 
            </div>
          </Box>
        </Box>
        <CustomFixedButton text={'Next'} disable={false} handleSubmit={() => {
          navigate('/redirect-page/what-next', {state: { fromSignup: true }} )
        }} />
      </Box>
    </Box>
  )
};

export default HowWorks;