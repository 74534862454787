import { httpRequest } from "../network";

interface IProps {
  registrationNo: string,
  noOfSeatAvailable: number,
  noOfChildSeatAvailable: number,
  type: string
}

export const carDetailUpload = (data: IProps) => {
  return httpRequest('POST', '/auth/signup/car-detail', data).then((response: any) => {
    return response.data
  })
    .catch((err) => {
      throw err
    })
}

export const getCarDetails = () => {
  return httpRequest('GET', '/auth/vehicle/user').then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

// get vehicle details for draft user
export const getDraftVehicleDetails = (draftId: string) => {
  return httpRequest('GET', `/auth/public/draft-vehicle?draftId=${draftId}`).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

// get favourite location details for draft user
export const getDraftFavouriteLocations = (draftId: string) => {
  return httpRequest('GET', `/auth/public/draft-favourite-location?draftId=${draftId}`).then((response: any) => {
    return response.data
  }).catch((err) => {
    throw err
  })
}

export function mapFormToRequestForCarDetails(value: any): any {
  const payload = {
    registrationNo: value.carRegistrationNumber,
    noOfSeatAvailable: value.numOfSeatsAvailableForCarPoolPassengers,
    noOfChildSeatAvailable: value.numOfSeatsForChild,
    isMOT : value.isMOT,
    isDrivingLicence : value.isDrivingLicence,
    isInsurance : value.isInsurance,
    draftId: value._id
  }
  return payload;
}
