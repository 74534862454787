import React from 'react'

interface IDescription {
    uniqueKey: number;
    icon: string;
    description: string;
    textColor ?: string;
  }
const Description: React.FC<IDescription> = ({ uniqueKey, icon, description, textColor }) => {
    return (
      <div key={uniqueKey} className={`description-1 d-flex items-center mb-3 padding-x text-color-${textColor}`}>
        <div className="list-icon-container mr-3">
          <img src={icon} alt="icon" />
        </div>
        <div className={`list-description fw-400`}>
          <p>{description}</p>
        </div>
      </div>
    );
  };

  Description.defaultProps={
    textColor:"white"
  }

export default Description
