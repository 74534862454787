import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface MultiLanguage {
    lang: string,
    value: string
}

export interface user {
    email: string,
    _id: string,
    _draft: string,
    status: string,
    jti ?: string
}

export interface BasicDetails {
    firstName: Array<MultiLanguage>
    lastName: Array<MultiLanguage>
    email: string
    gender: string
    phone: string
    photo: string
    userPreference: string,
    isVerified?: boolean,
    isAddressVerified?: boolean,
    isDriverVerified?: boolean,
    occupation?: string,
    createdAt?: string,
    showHelpVideo?: boolean
    marketPlaceAccessUntil?: Date,
    marketPlaceAccess?: 'no-access' | 'eco-tokens-access' | 'full-access',
    uniqueId?: string,
    tripsCount?: number,
    marketPlaceAccessMessages?: {key: string, message: string, enable: boolean}[]
}

export interface VehicleDetails {
    carRegistrationNumber: string
    numOfSeatsForChild: number
    numOfSeatsAvailableForCarPoolPassengers: number
}

export interface Confirmation {
    isDrivingLicence: boolean
    isMOT: boolean
    isInsurance: boolean
}

export interface carDetails {
    noOfChildSeatAvailable: number
    noOfSeatAvailable: number
    registrationNo: string
}

export interface Address {
    state: Array<MultiLanguage>,
    city: Array<MultiLanguage>,
    country: Array<MultiLanguage>,
    line1: Array<MultiLanguage>,
    line2: Array<MultiLanguage>,
    geo: number[],
    zip: string
    additionalInstructions: Array<MultiLanguage>

}


export interface UserDetails {
    user: user
    basicDetails: BasicDetails
    confirmation: Confirmation
    address: Address,
    vehicleDetails: carDetails

}

const initialState: UserDetails = {
    user: {
        email: "",
        _id: "",
        _draft: "",
        status: "",
        jti : "",
    },
    basicDetails: {
        firstName: [{ lang: "EN", value: "" }],
        lastName: [{ lang: "EN", value: "" }],
        email: "",
        gender: "MALE",
        phone: "",
        photo: "",
        userPreference: "BOTH",
        isVerified: false,
        isAddressVerified: false,
        isDriverVerified: false,
        occupation: "",
        createdAt: "",
        showHelpVideo: true,
        marketPlaceAccessUntil: new Date(),
        marketPlaceAccess: 'no-access',
        // marketPlaceAccess: 'full-access',
        uniqueId: "",
        tripsCount: 0,
        marketPlaceAccessMessages: [{
            key: "message1",
            enable: false,
            message: ''
        }, {
            key: "message2",
            enable: false,
            message: ''
        }]
    },
    confirmation: {
        isInsurance: false,
        isMOT: false,
        isDrivingLicence: false
    },
    address: {
        state: [{ lang: "EN", value: "" }],
        city: [{ lang: "EN", value: "" }],
        line1: [{ lang: "EN", value: "" }],
        line2: [{ lang: "EN", value: "" }],
        country: [{ lang: "EN", value: "" }],
        zip: "0000",
        geo: [0, 0],
        additionalInstructions: [{ lang: "EN", value: "" }],
    },
    vehicleDetails: {
        noOfChildSeatAvailable: 0,
        noOfSeatAvailable: 0,
        registrationNo: ""
    }
}

export const loginSlice = createSlice({
    name: 'userDetailsReducer',
    initialState,
    reducers: {
        updateVehicleDetails: (state: UserDetails, action: PayloadAction<carDetails>) => {
            state.vehicleDetails = action.payload;
        },
        updateBasicDetails: (state: UserDetails, action: PayloadAction<any>) => {
            state.basicDetails = {...state.basicDetails, ...action.payload }
        },
        initUserState: (state: UserDetails, action: PayloadAction<any>) => {
            //user
            const { email, _id, _draft, status,jti } = action.payload;

            state.user = { email, _id, _draft, status,jti };

            //basic details
            const { firstName, lastName, phone, photo, gender, userPreference, isVerified, isAddressVerified, isDriverVerified, occupation,
                createdAt, showHelpVideo, marketPlaceAccessUntil, marketPlaceAccessMessages, uniqueId } = action.payload;

                // if user is a marketplace user, then set its marketPlaceAccess time and message from its parents
            state.basicDetails = { firstName, email, lastName, phone, photo, gender, marketPlaceAccessUntil: userPreference === "MARKET_PLACE" ? action.payload._parent.marketPlaceAccessUntil : marketPlaceAccessUntil, isVerified, userPreference, isAddressVerified, isDriverVerified, occupation, createdAt, showHelpVideo, 
                marketPlaceAccess: 'no-access', marketPlaceAccessMessages: userPreference === "MARKET_PLACE" ? action.payload._parent.marketPlaceAccessMessages : marketPlaceAccessMessages, uniqueId
                // marketPlaceAccess: 'full-access' 
            };

            // confirmation
            const { isInsurance, isDrivingLicence, isMOT } = action.payload

            state.confirmation = { isInsurance, isDrivingLicence, isMOT };

            //address
            const { address: { state: _state, city, line1, line2, zip, geo, country, additionalInstructions } } = action.payload;

            state.address = {
                state: _state, city, line1, line2, zip, geo, country,
                additionalInstructions: additionalInstructions ?? [{ lang: "EN", value: "" }] as MultiLanguage[]
            };
        },
        updateUserVerificationStatus: (state: UserDetails, action: PayloadAction<boolean>) => {
            state.basicDetails.isVerified = action.payload;
        },
        resetUserState: (state: UserDetails) => {
            state.address = initialState.address;
            state.basicDetails = initialState.basicDetails
            state.confirmation = initialState.confirmation
            state.user = initialState.user
            state.vehicleDetails = initialState.vehicleDetails
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    initUserState,
    updateVehicleDetails,
    updateUserVerificationStatus,
    resetUserState,
    updateBasicDetails
} = loginSlice.actions

export default loginSlice.reducer
