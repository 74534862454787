import React, { Fragment, useEffect, useState } from "react";
// import pathIcon from "../../../../assets/images/path-icon.svg";
// import drivingWheelIcon from "../../../../assets/images/driving-wheel.svg";
import bellIcon from "../../../../assets/images/bell.svg";
// import videoCamIcon from "../../../../assets/images/videoCam.svg";
import whatsAppIcon from "../../../../assets/images/whatsapp-icon.svg";
import whatsAppBlackIcon from "../../../../assets/images/whatsapp.svg";
import MarketPlaceCard from "../../Common components/market-place-card.tsx";
import MatchResultListItem from "../../Common components/matched-result-list";
import "./style.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  communityMemberRequest,
  getLocationPublic,
  updateFavLocationOfUser,
  updateLocationAdmin,
} from "../../../../services/location";
import { whatsAppWebhookApprovalRequest } from "../../../../services/whatsApp";
import { enqueueSnackbar } from "notistack";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../../../../components/loading-screen";
import { getUserById } from "../../../../services/user";
import CustomFixedButton from "../../../../components/custom-fixed-button";
import { messageIcon } from "../../../../assets/images";

const getLayout = (
  role: string,
  fromDriverVerification: boolean,
  isMatched?: boolean,
  navigate?: NavigateFunction,
  adminApprovalRequired: boolean = false,
) => {
  if(fromDriverVerification) {
    return (
      <MatchResultListItem
              icon={bellIcon}
              text={
                <div className="text-md">
                  <p className="mb-3">
                    We’ve got all the information we need. It can take up to 24 hours for us to process the checks, so sit back and relax. 
                  </p>
  
                  <p>
                    We’ll notify you via WhatsApp once the verification is complete.
                  </p>
                </div>
              }
            />
    )
  } else if(adminApprovalRequired) {
      return (
        <div className="matched-result mb-3">
          <MatchResultListItem
            icon={bellIcon}
            text=" We’ll notify you via WhatsApp once the carpool community admin has approved your request to join."
          />
        </div>
      );
  } else {
    return (
      <>
        <MatchResultListItem
          icon={messageIcon}
          heading="Great news!"
          text={
            <>
              <div className="text-md">
                  <p className="mb-3 line-y-md">
                    There's no need to faff around with website logins, Ecopooling is powered by WhatsApp.
                    {/* No need to fuss with website logins! */}
                  </p>

                  <p className="mb-3 line-y-md">
                    To get started, just say 'Hi' to our chatbot, and within seconds, you'll be able to Effortlessly
                    offer rides, view your trips, manage invites, access rewards and more.
                    {/* EcoPooling is powered by WhatsApp, simply send our WhatsApp bot “hi” to open your dashboard and enjoy all our carpooling features at your fingertips.  */}
                  </p>

                  {/* <p className="mb-3">
                    Effortlessly choose to offer rides, view your trips, manage invites, access rewards and more.
                  </p> */}
              </div>

            </>
          }
          // bottomContent={
          //     <div className="d-flex items-center justify-center rounded-12" style={{height: "15rem", width: "8rem", margin: "auto", background: "#ddd", overflow: "hidden"}}>
          //       <iframe src={process.env.REACT_APP_HELPER_VIDEO} 
          //       width="100%" 
          //       height="100%" 
          //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          //       frameBorder="0" 
          //       allowFullScreen={true}
          //       title="Ecopooling demo video" 
          //       />
          //     </div>
          // }
        />

        {/* <div
        className="p-15 mx-3 mt-5 green-bordered d-flex items-center justify-center gap-05 cursor-pointer rounded-6 border-box what-next-whatsapp-button"
        style={{background: "#5ED497"}}
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_WHATSAPP_SIGN_ME_IN_URL}?text=${"Hi"}`,
            "_blank"
          );
        }}
      >
        <img src={whatsAppBlackIcon} alt="whatsapp-icon" />

        <span className="fw-500">
          Get started in WhatsApp
        </span>
      </div> */}
      <CustomFixedButton text={'Get started in WhatsApp'} icon={whatsAppBlackIcon} disable={false} 
        handleSubmit={() => {
          window.open(
            `${process.env.REACT_APP_WHATSAPP_SIGN_ME_IN_URL}/?text=${encodeURIComponent("Hi")}`,
            "_self"
          );
        }}
      />
      </>
    )
  }

  // switch (role === "BOTH" || role === "OFFER" || role === "FINDER") {
  //   case adminApprovalRequired:
  //     return (
  //       <div className="matched-result mb-3">
  //         <MatchResultListItem
  //           icon={bellIcon}
  //           text=" We’ll notify you via WhatsApp once the carpool community admin has approved your request to join."
  //         />
  //       </div>
  //     );

  //   case role === "BOTH" && isMatched:
  //     return (
  //       <Fragment>
  //         <div className="matched-result mb-3">
  //           <MatchResultListItem
  //             heading="Looking for a carpool?"
  //             icon={pathIcon}
  //             text=" You’ll receive invites to carpools from other members of your
  //         community via whatsapp."
  //           />
  //         </div>
  //         <GetStartedButton
  //           icon={drivingWheelIcon}
  //           text="Get started by offering a carpool"
  //           handleClick={() => navigate("/")}
  //         />
  //       </Fragment>
  //     );

  //   case role === "BOTH" && !isMatched:
  //     return (
  //       <Fragment>
  //         <div className="matched-result mb-5">
  //           <MatchResultListItem
  //             heading="Looking for a carpool?"
  //             icon={pathIcon}
  //             text=" You’ll receive invites to carpools from other members of your
  //         community via whatsapp."
  //           />
  //         </div>
  //         <div className="matched-result">
  //           <MatchResultListItem
  //             heading="Looking to offer a carpool?"
  //             icon={drivingWheelIcon}
  //             text=" As soon as at least one other member sign’s up that’s on your route, you’ll receive an invite to start scheduling carpools with them via the EcoPooling chatbot on WhatsApp."
  //           />
  //         </div>
  //       </Fragment>
  //     );

  //   case role === "OFFER" && isMatched:
  //     return (
  //       <GetStartedButton
  //         icon={drivingWheelIcon}
  //         text="Get started by offering a carpool"
  //         handleClick={() => navigate("/")}
  //       />
  //     );

  //   case role === "OFFER" && !isMatched:
  //     return (
  //       <div className="matched-result mb-3">
  //         <MatchResultListItem
  //           heading="Looking to offer a carpool?"
  //           icon={drivingWheelIcon}
  //           text=" As soon as at least one other member sign’s up that’s on your route, you’ll receive an invite to start scheduling carpools with them via the EcoPooling chatbot on WhatsApp."
  //         />
  //       </div>
  //     );

  //   default:
  //     return (
  //       <div className="matched-result mb-3">
  //         <MatchResultListItem
  //           heading="Looking for a carpool?"
  //           icon={pathIcon}
  //           text=" You’ll receive invites to carpools from other members of your
  //         community via whatsapp."
  //         />
  //       </div>
  //     );
  // }
};

const WhatNextNew = () => {
  const { decodedData } = useSelector(
    (store: RootState) => store.redirectDataReducer
  );

  const { user, basicDetails } = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  const locationId = decodedData.locationId;

  const [loading, setLoading] = useState(true);
  const [locationName, setLocationName] = useState(
    localStorage.getItem("locationName") ?? ""
  );
  const [matchFound] = useState(false);
  const [adminApprovalRequired, setAdminApprovalRequired] = useState(false);

  const navigate = useNavigate();
  const location  = useLocation()

  let fromDriverVerification = false

  if(location.state) {
    fromDriverVerification = location.state.fromDriverVerification
  }

  // get location details and save as user's favourite location in case of already registered user
  useEffect(() => {
    if (locationId) {
      getLocationPublic(locationId)
        .then((res) => {
          setLocationName(res.locationName[0].value);

          let payload = {
            favouriteLocations: [
              { _favoriteLocation: locationId, _categoryId: res.categoryId },
            ],
          };

          // variable to store member approval status, if location is of type "community-admin"
          let memberApproved = false

          // check if the location requires approval from community admin, then show different UI
          if (res.whiteListType === "EMAIL" && res.adminId) {
            // check if the user is already approved
            let userFound = false
            for (let member of res._members) {
              if ((member._user === user._id.toString())) {
                userFound = true

                if (!member.isApproved) {
                  setAdminApprovalRequired(true)
                } else {
                  memberApproved = true
                }

                break
              }
            }

            // if user is not already added in the members list, then show the approval request screen
            if (!userFound) {
              setAdminApprovalRequired(true)
            }
          }

          updateFavLocationOfUser(payload)
            .then(async () => {
              if (res.communityType === "PRIVATE" && res.whiteListType === "EMAIL") {
                if (!res.adminId) {
                  await updateLocationAdmin(locationId, user._id)

                } else if (!memberApproved) {
                  // get the admin user details for sending whatsApp notification
                  const adminUser = await getUserById(res.adminId)

                  // save user in location's members list
                  await communityMemberRequest(locationId, [user._id])

                  if (adminUser !== undefined) {
                    try {
                      // send a whatsApp webhook for approval from community admin
                      whatsAppWebhookApprovalRequest(
                        adminUser.phone,
                        adminUser.jti,
                        "ADMINAPPROVAL",
                        res.locationName[0].value,
                        `${basicDetails.firstName[0].value} ${basicDetails.lastName[0].value}`)
                    } catch (error) {
                      console.log("Unable to send whatsApp message")
                    }
                  }
                }
              }

              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
              enqueueSnackbar("Location update failed", { variant: "error" });
              navigate("dashboard/upcoming");
            });
        })
        .catch(() => {
          setLoading(false);
          enqueueSnackbar("Couldn't get the location", { variant: "error" });
          navigate("dashboard/upcoming");
        });
    }

    // eslint-disable-next-line
  }, [locationId]);

  // check if user is visiting this page directly
  useEffect(() => {
    setLoading(true);

    if(!location.state || (!location.state.fromSignup && !location.state.fromDriverVerification && !location.state.fromRedirect)) {
      navigate("/")
      
      return
    }

    setLoading(false)
    
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <LoadingScreen />
  ) : (
    <div id="what-next-new" style={{ minHeight: "100vh" }} className="d-flex flex-row align-center">
      <div className="main-wrapper d-flex justify-start items-center flex-column px-1 pb-80 pt-10vh mb-6">
        <div className="d-flex justify-center items-center what-next-header-icon-container">
          <img className="what-next-header-icon" src={whatsAppIcon} alt="done-mark" />
        </div>

        <h2 className="mb-5">Lets get started</h2>

        {
          adminApprovalRequired ? (
            <div className="heading text-center mb-5">
              Your request to join {locationName} community has been submitted
            </div>
          ) : (
            <div className="heading text-center mb-5">
            {
              fromDriverVerification ? (
                <p className="mt-5">
                  All done!
                </p>
              ) : (
                <>
                {/* You joined {locationName} community */}

                </>
              )
            }
            </div>
          )
        }

        {
          getLayout(basicDetails.userPreference, fromDriverVerification, matchFound, navigate, adminApprovalRequired)
        }

        {
          (fromDriverVerification || adminApprovalRequired) && (
            <>
              <div className="my-5 d-flex fw-700 justify-center items-center">
                Whilst you wait...
              </div>

              <MarketPlaceCard />
            </>
          )
        }
      </div>
    </div>
  );
};

export default WhatNextNew;
