import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserFromDraft, setAppleCredentials } from '../../../services/social-login';
import { Box } from '@mui/material';
import { updateBasicDetails, updateUser } from '../../../slice/sign-up';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { initUserState } from '../../../slice/login';
import LoadingScreen from '../../../components/loading-screen';

const AppleLogin = () => {
    // eslint-disable-next-line 
    const [searchParam, setSearchParam] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initSignUpDetails = (user: any) => {
        const { steps } = user;
        for (let step in steps) {
            switch (parseInt(step)) {
                case 1: {
                    const {
                        firstName,
                        lastName,
                        email,
                        photo,
                        userPreference,
                        phone,
                        gender,
                        _id
                    } = user;

                    const payload = {
                        firstName: firstName.length > 0 ? firstName[0].value : "",
                        lastName: lastName.length > 0 ? lastName[0].value : "",
                        email: email ?? "",
                        photoPath: photo ?? "",
                        carPoolPreference: userPreference ?? "OFFER",
                        phoneNumber: phone ?? "",
                        gender: gender ?? "MALE",
                        profilePicture: ""
                    }
                    dispatch(updateUser({ email, _id }))
                    dispatch(updateBasicDetails(payload));
                    break;
                }
                case 2: {

                }
            }
        }
    }
    const navigateAccordingly = (res: any) => {
        switch (res.steps[res.steps.length - 1]) {
            case 1:
                navigate("/signup/basic-detail");
                break;
            case 2:
                navigate("/signup/vehicle-detail");
                break;
            case 3:
                navigate("/signup/declaration");
                break;
            case 4:
                navigate("/signup/home-location-detail");
                break;
            case 5:
                navigate("/signup/favorite-location");
                break;
            case 7:
                navigate("/signup/summary");
                break;
            default:
                navigate("/signup/basic-detail");
        }
    }

    const fetchData = async () => {
        // const code = localStorage.getItem("code");
        console.log({ ss: searchParam.get("code") });

        if (searchParam.get("code")) {
            setAppleCredentials({
                code: searchParam.get("code"),
            })
                .then((res) => {
                    console.log({ aaaaaaa: res });
                    localStorage.setItem("token", res.data?.token);
                    // if user is in draft
                    if (res.data?.isDraft && res.data?.email) {
                        //set draftId in localStorage
                        localStorage.setItem("draftId", res.data?._id);
                        //get all details of drafted user
                        getUserFromDraft({ email: res.data?.email }).then((dataFromDraft: any) => {
                            initSignUpDetails(dataFromDraft);
                            navigateAccordingly(dataFromDraft);
                        })
                    } else if (res.data?.isDraft) {
                        localStorage.setItem("draftId", res.data?._id);
                        navigate("/signup/basic-detail");
                    } else {
                        dispatch(initUserState(res.data as any))
                        navigate("/dashboard/upcoming");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    const msg = err?.message ?? "Something went wrong !";
                    enqueueSnackbar(msg, { variant: 'error', })
                    navigate("/");
                });
        } else {
            navigate("/");
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line 
    }, []);

    return <Box className="center w-100" height="100vh">
        <LoadingScreen />
    </Box>;
}

export default AppleLogin;
