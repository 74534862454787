import { httpRequest } from "../network";


export const getDefaultSchedule = (locationId: object) => {
    return httpRequest("GET", "/auth/favourite-location/user/default-schedule", {}, locationId)
        .then((res) => res)
        .catch((err) => {
            throw err
        })
}


export const updateWeeklySchedule = (payload: object) => {
    return httpRequest("PUT", "/auth/favourite-location/user/weekly-schedule", payload)
        .then((res) => res)
        .catch((err) => {
            throw err
        })
}


