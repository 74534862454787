import Header from "../../components/header-1";
import React from "react";
//import shoppingBag from "../../assets/images/shopping-bag.svg";
import leaf from "../../assets/images/leaf.svg";
import closeIcon from "../../assets/images/close-small-green.svg";
import {
  Box,
  Button,
  FormControl,
  TextField as InputField,
  Modal,
} from "@mui/material";
import OfferCards from "../../components/offer-cards";
import "./style.scss";
import CustomDrawer from "../../components/mui/SwipeableDrawer";
import leftArrow from "../../assets/images/left-arrow.svg";
import { useState, useEffect } from "react";
import PurchaseHistory from "../../components/Purchase History";
import { RootState } from "../../store";
import SearchIcon from "../../assets/images/search-icons.svg";
import { getEcoTokens, getGroupedGiftCards, getTotalSavings, getWeeklyTripsEarnings, listPromosWeekly } from "../../services/eco-saving";
import { getGiftCardReceipt, getPurchaseHistory } from "../../services/payment";
import { useSelector, useDispatch } from "react-redux";
import {
  setAllCards,
  setAllPurchasedCard,
  setTokenNumberAndValue,
} from "../../slice/marketplace";
import LoadingScreen from "../../components/loading-screen";
//import { enqueueSnackbar } from "notistack";
import { getName } from "../../utilities/helper";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
//import { addItemToSlected } from "../../slice/book-seat";
import moment from "moment";
//import { Tag } from "./card detail";
import CardDetail, { getAvailabilityTags } from "./card detail";
import { styled } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import shoppingBag from "../../assets/images/shopping-bag.svg";
import { updateUser } from "../../services/update/user-profile";
import MarketPlaceRestrictedDialog from "../../components/marketplace-restrict-dialog";
import { updateBasicDetails } from "../../slice/login";
import { getTripsCount } from "../../services/trips";
import CustomFixedButton from "../../components/custom-fixed-button";
import { Earnings } from "./earnings";
import { giftIcon, receiptIcon } from "../../assets/images";
import PromoCodeList from "./promo-code";
import { getUserByUniqueId } from "../../services/user";

// const cardArray = [
//   {
//     price: 10,
//     tokens: 960,
//   },
//   {
//     price: 20,
//     tokens: 1920,
//   },
//   {
//     price: 50,
//     tokens: 4800,
//   },
//   {
//     price: 100,
//     tokens: 9600,
//   },
// ];

function getMonthName(monthNumber: number) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return months[monthNumber - 1];
}

export const TextField = styled(InputField)`
  & input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const MarketPlace = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [marketPlaceStates, setMarketPlaceStates] = useState({
    activeTab: "gift-card",
    giftCardDrawer: false,
    purchaseHistoryDrawer: false,
    ecoTokens: 0,
    ecoTokenValue: 0,
    cards: [],
    loading: false,
    otherAmount: "",
    otherAmountErr: false,
    otherAmountErrorText: "",
    selectedCard: {
      image: "",
      discount: "",
      name: "",
      cardId: "",
      giftId: "",
      termsURL: "",
      availability: "",
      denominations: [],
      min: undefined,
      max: undefined,
      deliveryType: "",
      tilloDiscount: undefined,
      notes: "",
      redemptionInstructionsUrl: "",
      tilloIdentifier: "",
      unit: undefined
    },
    purchaseHistory: [],
    purchasedCard: {
      image: "",
      discount: "",
      name: "",
      giftUrl: "",
      purchasedOn: "",
      giftStatus: "",
      amount: "",
      _id: ""
    },
  });
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [showHelpNotification, setShowHelpNotification] = useState(true);

  const [showCardDetails, setShowCardDetails] = useState(false);
  const [showRedemptionDetails, setShowRedemptionDetails] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [totalSavings, setTotalSavings] = useState(0);
  const [marketPlaceUsersCount, setMarketPlaceUsersCount] = useState(0)

  const dispatch = useDispatch();

  const [weeklyEarnings, setWeeklyEarnings] = useState({
    weeklyTrips: [],
    loader: false,
    page: 1,
    hideLoadMore: false
  });
  const [promosList, setPromosList] = useState({
    page: 1,
    hideLoadMore: false,
    loader: false,
    promos: []
  });

  const getWeeklyPromos = async () => {
    try {
      const promos = await listPromosWeekly(promosList.page);

      if (promos.data.length > 0) {
        setPromosList(prev => ({
          ...prev,
          promos: [...promosList.promos, ...promos.data],
          hideLoadMore: promos.meta.totalPromoCount <= [...promosList.promos, ...promos.data].length ? true : false
        }));
      } else {
        setPromosList(prev => ({
          ...prev,
          hideLoadMore: true
        }))
      }

    } catch (error) {
      enqueueSnackbar(error.message ?? 'Error occurred while fetching promos list', {
        variant: 'error'
      })
    } finally {
      setPromosList(prev => ({ ...prev, loader: false }))
    }
  }

  useEffect(() => {
    getWeeklyPromos();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getWeeklyPromos();
    //eslint-disable-next-line
  }, [promosList.page])

  const getWeeklyEarningsData = async () => {
    try {
      const weeklyTrips = await getWeeklyTripsEarnings(weeklyEarnings.page);
      setWeeklyEarnings(prev => ({
        ...prev,
        weeklyTrips: [
          ...prev.weeklyTrips, ...weeklyTrips.data.filter(week => {
            let include = true;
            prev.weeklyTrips.forEach(prevWeek => {
              if (prevWeek.weekDate === week.weekDate) {
                include = false;
              }
            });
            return include;
          })
        ],
        hideLoadMore: weeklyTrips.meta.groupedTripsCount <= [
          ...prev.weeklyTrips, ...weeklyTrips.data.filter(week => {
            let include = true;
            prev.weeklyTrips.forEach(prevWeek => {
              if (prevWeek.weekDate === week.weekDate) {
                include = false;
              }
            });
            return include;
          })
        ].length ? true : false,

        // hideLoadMore: !Boolean(weeklyTrips.length),
        loader: false
      }));
    } catch (error) {
      setWeeklyEarnings(prev => ({
        ...prev,
        hideLoadMore: true,
        loader: false
      }));
    }
  }

  const { userDetailsReducer } = useSelector((state: RootState) => state);
  const { marketPlaceReducer } = useSelector((state: RootState) => state);

  const { tokens, tokenValue, cards, purchasedCards } = marketPlaceReducer;

  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  let message = userDetailsReducer.basicDetails.marketPlaceAccessMessages?.filter(
    item => item?.enable === true && item?.key === 'message2'
  )[0];

  if (Boolean(message) === false) {
    message = userDetailsReducer.basicDetails.marketPlaceAccessMessages?.filter(
      item => item?.enable === true && item?.key === 'message1'
    )[0];
  }

  const getSavings = async () => {
    const savings = await getTotalSavings();
    setTotalSavings(savings.savings);
 }

  // if coming back from gift-card-details page, then open the drawer with selected gift card
  useEffect(() => {
    if (location.state && location.state.selectedGiftCard) {
      setMarketPlaceStates((prev) => {
        return {
          ...prev,
          selectedCard: location.state.selectedGiftCard,
          giftCardDrawer: !marketPlaceStates.giftCardDrawer,
          otherAmountErr: false,
          otherAmount: "",
        }
      })
    }

    localStorage.removeItem("selectedGiftCard")

    getWeeklyEarningsData();

    // eslint-disable-next-line
  }, [])

  // set the show help video state based on user data
  useEffect(() => {
    if (userDetailsReducer.basicDetails.showHelpVideo === false) {
      setShowHelpNotification(false)
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSavings();
  }, [marketPlaceStates.purchaseHistory])

  useEffect(() => {
    if (new Date(userDetailsReducer.basicDetails.marketPlaceAccessUntil).getTime() > new Date().getTime()) {
      dispatch(updateBasicDetails({ marketPlaceAccess: 'full-access' }));
    } else if (tokens >= 2000) {
      dispatch(updateBasicDetails({ marketPlaceAccess: 'eco-tokens-access' }));
    } else {
      dispatch(updateBasicDetails({ marketPlaceAccess: 'no-access' }));
    }
  }, [tokens, userDetailsReducer.basicDetails.marketPlaceAccessUntil, dispatch]);

  // if redirecting from payment-success page, then select purchase-history tab
  useEffect(() => {
    if (searchParams.get("tab") && (searchParams.get("tab") === "purchaseHistory")) {
      setMarketPlaceStates((prev) => {
        return {
          ...prev,
          activeTab: "purchase-history",
        };
      });
    }

    if (userDetailsReducer.basicDetails.userPreference !== 'MARKET_PLACE') {
      // get marketplace user invite count data to show the information banner
      getUserByUniqueId(userDetailsReducer.basicDetails.uniqueId)
      .then((res) => {
        setMarketPlaceUsersCount(res.marketPlaceUsersCount)
      })
      .catch((error) => {
        console.log('error: ', error)
      })
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getWeeklyEarningsData();
    //eslint-disable-next-line
  }, [weeklyEarnings.page]);

  const handleTabSwitch = (tabName: string) => {
    switch(tabName.toLowerCase()) {
      case "purchase-history": {
        setMarketPlaceStates((prev) => ({
          ...prev,
          activeTab: "purchase-history"
        }))
        return;
      }
      case "earnings": {
        setMarketPlaceStates((prev) => ({
          ...prev,
          activeTab: "earnings"
        }));
        return;
      }
      case "promo-code": {
        setMarketPlaceStates((prev) => ({
          ...prev,
          activeTab: 'promo-code'
        }));
        return;
      }
      default: {
        setMarketPlaceStates((prev) => ({
          ...prev,
          activeTab: "gift-card"
        }))
      }
    }
  };

  const manageGiftCardDrawer = () => {
    setMarketPlaceStates((prev) => {
      return {
        ...prev,
        giftCardDrawer: !marketPlaceStates.giftCardDrawer,
        otherAmountErr: false,
        otherAmount: "",
      };
    });
    setShowCardDetails(false);
    setShowPaymentDetails(true);
    setShowRedemptionDetails(false);
  };

  const managePurchaseHistoryDrawer = (
    _id: string,
    image: string,
    discount: string | number,
    name: string,
    giftUrl: string,
    purchasedOn: string,
    giftStatus: string,
    amount: string
  ) => {
    setMarketPlaceStates((prev: any) => {
      return {
        ...prev,
        purchaseHistoryDrawer: !marketPlaceStates.purchaseHistoryDrawer,
        purchasedCard: {
          ...prev.purchasedCard,
          image,
          discount,
          name,
          giftUrl,
          purchasedOn,
          giftStatus,
          amount,
          _id,
        },
      };
    });
  };

  const loadAllData = async () => {
    try {
      //Change this logic
      const res = await getTripsCount(new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date());
      dispatch(updateBasicDetails({ tripsCount: res.data.tripsCount }));

      const res1 = await getEcoTokens();

      if (res1) {
        //set ecotoken
        dispatch(setTokenNumberAndValue(res1));
      }

      if (cards.length === 0) {
        const res2 = await getGroupedGiftCards();

        //set the state of giftcard
        dispatch(setAllCards(res2));

        setMarketPlaceStates((prev) => {
          return {
            ...prev,
            cards: res2,
          };
        });

        setLoading(false);

        try {
          const res3 = await getPurchaseHistory({
            userId: userDetailsReducer.user._id,
          });

          //set the states of purchasecard
          dispatch(setAllPurchasedCard(res3));

          setMarketPlaceStates((prev) => {
            return {
              ...prev,
              purchaseHistory: res3,
            };
          });
        } catch (error) {
          setLoading(false);
        }
      } else {
        setLoading(false)
      }


    } catch (error) {
      console.log(error);
      setLoading(false);
      enqueueSnackbar("Could not get the data!", { variant: "error" });
    }
  };

  useEffect(() => {
    loadAllData();
    if (cards.length > 0) {
      setMarketPlaceStates((prev) => {
        return {
          ...prev,
          cards: cards
        };
      });
    }

    if (purchasedCards.length > 0) {
      setMarketPlaceStates((prev) => {
        return {
          ...prev,
          purchaseHistory: purchasedCards,
        };
      });
    }
    // eslint-disable-next-line
  }, [marketPlaceStates.activeTab]);

  const getPayableValue = (giftToken: number, userToken: number) => {
    if (giftToken <= userToken) {
      return 0;
    } else {
      let payableAmount = (
        (giftToken -
          (giftToken * Number(marketPlaceStates.selectedCard.discount)) / 100 -
          userToken) *
        0.01
      ).toFixed(2);
      if (Number(payableAmount) > 0) {
        return payableAmount;
      } else {
        return 0;
      }
    }
  };

  // const getAmountToPay = (
  //   tokenRedeemedAmount: number,
  //   discount: number,
  //   cardPrice: number
  // ) => {
  //   let payableAmount =
  //     cardPrice - (tokenRedeemedAmount + (cardPrice * discount) / 100);
  //   if (payableAmount <= 0) return 0;
  //   else return payableAmount.toFixed(2);
  // };

  // const payableToken = (userToken: number, cardPrice: number) => {
  //   if (cardPrice * 100 < userToken) {
  //     return cardPrice * 100;
  //   } else {
  //     return userToken;
  //   }
  // };

  const handleGiftCardClick = (
    image: string,
    discount: string | number,
    name: string | undefined,
    cardId: string,
    giftId: string,
    termsURL: string,
    availability: string,
    denominations?: number[],
    min?: number,
    max?: number,
    deliveryType?: string,
    tilloDiscount?: number,
    notes?: string,
    redemptionInstructionsUrl?: string,
    tilloIdentifier?: string,
    unit?: number
  ) => {
    if (userDetailsReducer.basicDetails.marketPlaceAccess === 'no-access') {
      return;
    }

    setMarketPlaceStates((prev: any) => {
      return {
        ...prev,
        selectedCard: {
          image,
          discount,
          name,
          cardId,
          giftId,
          termsURL,
          availability,
          denominations,
          min,
          max,
          deliveryType,
          tilloDiscount,
          notes,
          redemptionInstructionsUrl,
          tilloIdentifier,
          unit
        },
      };
    });

    manageGiftCardDrawer();
  };

  const handleGiftPayment = (
    amount: number | string,
    giftId: string,
    // ecoTokens: number,
    discount: number | string,
    giftUniqueId: string,
    card_price: number,
    termsURL: string,
    availability: string,
    deliveryType?: string
  ) => {
    const data: { [key: string]: string | number | object } = {
      amount,
      giftId,
      // ecoTokens,
      discount,
      giftUniqueId,
      termsURL,
      availability,
      deliveryType
    };

    data["_user"] = userDetailsReducer.user._id;
    data["card_name"] = marketPlaceStates.selectedCard.name;
    data["card_logo"] = marketPlaceStates.selectedCard.image;
    data["card_value"] = card_price;
    data["deliveryType"] = marketPlaceStates.selectedCard.deliveryType
    data["tilloDiscount"] = marketPlaceStates.selectedCard.tilloDiscount
    data["ecoTokens"] = tokens;
    data["notes"] = marketPlaceStates.selectedCard.notes
    data["redemptionInstructionsUrl"] = marketPlaceStates.selectedCard.redemptionInstructionsUrl
    data["tilloIdentifier"] = marketPlaceStates.selectedCard.tilloIdentifier

    // clear any existing data from local storage
    localStorage.removeItem("giftData")

    // store selected gift card data
    localStorage.setItem("selectedGiftCard", JSON.stringify(marketPlaceStates.selectedCard))

    // navigate("/dashboard/market-place/card-detail", { state: data });
    localStorage.setItem("giftData", JSON.stringify(data))
    setShowCardDetails(true);
    setShowPaymentDetails(true);
    
  };

  const handleOtherAmountChange = (e: any, min?: number, max?: number) => {
    const re = /^(?!^\.)(?:\d*\.)?\d*$/; // regex to match only digits

    if (e.target.value === "" || re.test(e.target.value)) {
      // if number is a decimal number and numbers after decimal are more than two, then dont set the other amount state
      if(((Number(e.target.value) % 1) !== 0) && !/\d*\.\d{0,2}$/.test(e.target.value)) {
        return
      }

      setMarketPlaceStates((prev) => {
        return {
          ...prev,
          otherAmountErr: false,
          otherAmount: e.target.value,
        };
      });
    }

    // check for min and max values
    if (min && max && e.target.value !== "" && (Number(e.target.value) < min || Number(e.target.value) > max)) {
      setMarketPlaceStates((prev) => {
        return {
          ...prev,
          otherAmountErr: true,
          otherAmountErrorText: "Invalid value"
        };
      });
    }
    
    // check if unit value is 1 and other amount is not a whole number
    if(marketPlaceStates.selectedCard.unit === 1 && e.target.value !== "" && Number(e.target.value) % 1 !== 0) {
      setMarketPlaceStates((prev) => {
        return {
          ...prev,
          otherAmountErr: true,
          otherAmountErrorText: "This brand only accepts whole number values, such as £10.00, £20.00. Please re-enter."
        };
      });
    }
  };

  const handleOtherAmountClick = () => {
    if (marketPlaceStates.otherAmount && !marketPlaceStates.otherAmountErr) {
      setMarketPlaceStates((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });

      handleGiftPayment(
        getPayableValue(Number(marketPlaceStates.otherAmount) * 10, tokens),
        marketPlaceStates.selectedCard.cardId,
        // payableToken(tokens, Number(marketPlaceStates.otherAmount)),
        marketPlaceStates.selectedCard.discount,
        marketPlaceStates.selectedCard.giftId,
        Number(marketPlaceStates.otherAmount),
        marketPlaceStates.selectedCard.termsURL,
        marketPlaceStates.selectedCard.availability
      );
    }
  };

  const searchByCardName = (data: any, searchQuery: string) => {
    const lowercaseSearchQuery = searchQuery.toLowerCase();

    return data
      .map((category: any) => {
        const matchingCards = category.cards.filter((card: any) => {
          return card.name.some((nameObj: any) =>
            nameObj.value.toLowerCase().includes(lowercaseSearchQuery)
          );
        });

        return { ...category, cards: matchingCards };
      })
      .filter((category: any) => category.cards.length > 0);
  };

  function searchGiftCardsByGiftName(data: any, searchTerm: string) {
    return data
      .map((giftCardsArray: any) => {
        const matchingGiftCards = giftCardsArray.giftCards.filter(
          (giftCard: any) => {
            // Check if any gift name in any language matches the search term
            return giftCard.giftName?.some((giftName: any) =>
              giftName.value.toLowerCase().includes(searchTerm.toLowerCase())
            );
            //  console.log(giftCard)
          }
        );
        return { ...giftCardsArray, giftCards: matchingGiftCards };
      })

      .filter((matchedResults: any) => matchedResults.giftCards.length > 0);
  }

  const handleSearch = (data: any, searchQuery: string) => {
    if (marketPlaceStates.activeTab === "gift-card") {
      let allMatchingResult = searchByCardName(data, searchQuery);
      // if (allMatchingResult.length > 0) {
      setMarketPlaceStates((prev) => {
        return {
          ...prev,
          cards: allMatchingResult,
        };
      });
      // }
      if (searchQuery === "") {
        setMarketPlaceStates((prev) => {
          return {
            ...prev,
            cards: cards,
          };
        });
      }
    } else {
      const allMatchingResults = searchGiftCardsByGiftName(
        marketPlaceStates.purchaseHistory,
        searchQuery
      );
      if (allMatchingResults.length > 0) {
        setMarketPlaceStates((prev) => {
          return {
            ...prev,
            purchaseHistory: allMatchingResults,
          };
        });
      }
      if (searchQuery === "") {
        setMarketPlaceStates((prev) => {
          return {
            ...prev,
            purchaseHistory: purchasedCards,
          };
        });
      }
    }
  };

  // function to calculate eco tokens for an amount
  const calculateEcoTokens = (amount: number) => {
    return 0.01 * amount;
  }

  // onClick handler for help video modal
  const showHelpVideoHandler = () => {
    // setShowVideoModal(true)
    navigate('/dashboard/market-place/how-works')
  }

  return (
    <div>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Header rightIcon={userDetailsReducer.basicDetails.photo} />

          <Box className="padding-x rounded-top-green">
            <div className="sub-header">
              <div className="sub-header-icon-container">
                <img alt="signature" src={shoppingBag} />
              </div>
              <p className="heading sub-text-new">Rewards marketplace</p>
            </div>
          </Box>

          <Box className="eco-rewards-wrapper">
            {
              showHelpNotification && (
                <div className="p-1 d-flex items-center justify-between gap-1imp border-box w-100 border-bottom cursor-pointer bg-f5" onClick={showHelpVideoHandler}>
                  <img src={giftIcon} alt="see how it works" />

                  <div className="flex-1">
                    <p className="text-700 text-md">
                      See how it works
                    </p>

                    <p className="text-sm">
                      {/* A quick video to get started with marketplace */}
                      Lets get started on the Rewards Marketplace!
                    </p>
                  </div>

                  <img src={closeIcon} alt="close see how it works" onClick={async (e) => {
                    e.stopPropagation()

                    setShowHelpNotification(false)

                    try {
                      // make an API call to not show video again to the user
                      await updateUser({ showHelpVideo: false })

                    } catch (error) {
                      enqueueSnackbar("Unable to update user", { variant: "error" })
                    }
                  }} />
                </div>
              )
            }
            {/* {userDetailsReducer.basicDetails.tripsCount >= 4 ? <></> :
              <MarketPlaceRestrictedDialog
                type={userDetailsReducer.basicDetails.marketPlaceAccess === 'full-access' ? 'info' : 'warning'}
                text={userDetailsReducer.basicDetails.marketPlaceAccess === 'full-access' ?
                  `Marketplace active ${moment(new Date(userDetailsReducer.basicDetails.marketPlaceAccessUntil)).fromNow().replace('in', 'for')} You need to complete ${4 - userDetailsReducer.basicDetails.tripsCount} carpool trips by ${moment().endOf('month').format("DD/MM/YYYY")} to extend access.` :
                  userDetailsReducer.basicDetails.marketPlaceAccess === 'eco-tokens-access' ?
                    `You need to complete ${4 - userDetailsReducer.basicDetails.tripsCount} more carpool trips this month to unlock access to the Rewards Marketplace discounts. You can still redeem your eco tokens for full price gift cards.` :
                    `You need to complete ${4 - userDetailsReducer.basicDetails.tripsCount} more carpool trips this month to unlock access to the Rewards Marketplace. Every ride gets you closer to exclusive discounts!`
                } />
            } */}

            {
              (marketPlaceUsersCount < 10) && (userDetailsReducer.basicDetails.userPreference !== 'MARKET_PLACE') && (
                <MarketPlaceRestrictedDialog 
                  type={'infoWithButton'}
                  heading={`${10 - marketPlaceUsersCount} invites available`}
                  description="Invite your friends to start saving in the marketplace today!" 
                  buttonText="Invite now"
                  handleButtonClick={() => navigate("/dashboard/market-place/invite")}
                />
              )
            }

            <MarketPlaceRestrictedDialog type={'warning'} text={message?.message} />

            <Modal
              open={showVideoModal}
              onClose={() => setShowVideoModal(false)}
            >
              <div className="d-flex justify-center items-center" style={{ width: "100%", height: "100%" }} onClick={() => setShowVideoModal(false)}>
                <iframe src={process.env.REACT_APP_MARKETPLACE_VIDEO}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  allowFullScreen={true}
                  title="Ecopooling demo video"
                  className="marketplace-video-frame"
                />
              </div>
            </Modal>

            <Box className={`heading-title pt-1 mb-3`}>
              {/* <img className="shopping-bag-icon" src={shoppingBag} alt="" />
              <span className="eco-title">Eco rewards marketplace</span> */}
              <Box className="search-box">
                <img
                  className="search-icon"
                  src={SearchIcon}
                  alt="search-icon"
                />
                <input
                  className="search-input"
                  name="search"
                  placeholder="Search brands"
                  onKeyUp={(e: any) => {
                    handleSearch(marketPlaceStates.cards, e.target.value);
                  }}
                />
              </Box>
            </Box>

            {/* {
              tokens > 0 && (
                <Box className="eco-rewards-marketplace">
                  {
                    tokens > 0 && (
                      <Box className="token-balance-card-container">
                      <Box className="card-header">
                        <img src={leaf} alt="leaf-png" />
                        <h3 className="token-header">Eco token balance</h3>
                      </Box>
                        <Box className="loyalty-card">
                          <Box className="card-content">

                            <Box className="token-count">
                              {tokens.toFixed()} <span className="count">tokens</span>
                            </Box>

                            <Box className="token-value">
                              Value £{tokenValue.toFixed(2)}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  }
                </Box>
              )
            } */}

              <Box className="eco-rewards-marketplace">

                { tokens > 0 && 
                  <Box className="eco-token-balance-container mb-3">
                    <img src={leaf} alt="leaf-png" />
                    <h4 className="token-header m-0">{tokens} token balance ({Number(tokenValue).toFixed(2)})</h4>
                  </Box>
                }

                { totalSavings > 0 &&
                  <Box className="token-balance-card-container">
                    <p>💰 Total Savings</p>
                    <h2 className="m-0">£{Number(totalSavings).toFixed(2)}</h2>
                    {/* <Box className="card-header">
                        <p className="token-header">Eco token balance</p>
                      </Box>
                        <Box className="loyalty-card">
                          <Box className="card-content">

                            <Box className="token-count">
                              £ {tokens.toFixed()} 
                            </Box>
                          </Box>
                        </Box> */}
                  </Box>
                }
              </Box>

            <Box className="pill-bar-main-wrapper">
                {
                  <Box
                    className={
                      marketPlaceStates.activeTab === "gift-card"
                        ? "gift-card active-btn text-white bg-black"
                        : "gift-card disable-btn text-400"
                    }
                    onClick={() => handleTabSwitch("gift-card")}
                  >
                    Gift cards
                  </Box>
                }
                {
                  <Box
                    className={
                      marketPlaceStates.activeTab === "purchase-history"
                        ? "purchase-history active-btn text-white bg-black"
                        : "purchase-history disable-btn text-400"
                    }
                    onClick={() => handleTabSwitch("purchase-history")}
                  >
                    Purchase history
                  </Box>
                }
                {
                  weeklyEarnings.weeklyTrips.length > 0 && (
                    <Box
                      className={
                        marketPlaceStates.activeTab === "earnings"
                          ? "gift-card active-btn text-white bg-black"
                          : "gift-card disable-btn text-400"
                        }
                      onClick={() => handleTabSwitch("earnings")}
                    >
                      Earnings
                    </Box>
                  )
                }

                {
                  promosList.promos.length > 0 && (
                    <Box
                      className={
                        marketPlaceStates.activeTab === "promo-code"
                          ? "gift-card active-btn text-white bg-black"
                          : "gift-card disable-btn text-400"
                      }
                      onClick={() => handleTabSwitch("promo-code")}
                    >
                      Promo code
                    </Box>
                  )
                }
              </Box>
          </Box>

          {marketPlaceStates.activeTab === "gift-card" && (
            <div className="position-relative">
              {userDetailsReducer.basicDetails.marketPlaceAccess === 'no-access' ? <div className="disable-click-container"></div> : <></>}
              {marketPlaceStates.cards?.map((card: any, key: number) => (
                <React.Fragment key={key}>
                  <Box className="gift-card-title">
                    <p className="text-md">{getName(card.categoryName, "EN")}</p>
                  </Box>

                  <Box
                    className="market-place-card-container"
                  >
                    {card.cards.map((item: any, index: number) => (
                      <OfferCards
                        isClickable={true}
                        key={index}
                        logo={item.image}
                        text={`${item.discount}%`}
                        handleClick={() =>
                          handleGiftCardClick(
                            item.image,
                            item.discount,
                            getName(item.name, "EN"),
                            item.cardId,
                            item.giftId,
                            item.termsAndCondition,
                            item.availability,
                            item.denominations,
                            item.min,
                            item.max,
                            item.deliveryType,
                            item.tilloDiscount,
                            item.notes,
                            item.redemptionInstructionsUrl,
                            item.tilloIdentifier,
                            item.unit
                          )
                        }
                      />
                    ))}
                  </Box>
                </React.Fragment>
              ))}

              <CustomDrawer
                anchor="bottom"
                isOpen={marketPlaceStates.giftCardDrawer}
                onClose={manageGiftCardDrawer}
                isContent75vh={false}
                backButtonHandler={showCardDetails ? () => {
                    if (showRedemptionDetails === true) {
                      setShowPaymentDetails(true);
                      setShowRedemptionDetails(false);
                      setShowCardDetails(true);
                    } else if (showPaymentDetails === true) {
                      setShowCardDetails(false);
                      setShowRedemptionDetails(false);
                    }
                } : undefined }
              >
                  {showCardDetails === false ? (
                  <Box >
                    <Box className="drawer-header">
                      <OfferCards
                        logo={marketPlaceStates.selectedCard.image}
                        text={`${marketPlaceStates.selectedCard.discount}% OFF`}
                      />
                    </Box>

                    <Box className="drawer-title">
                      {marketPlaceStates.selectedCard.name && (
                        <h3>{marketPlaceStates.selectedCard.name}</h3>
                      )}
                    </Box>

                    <Box
                      className=""
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {getAvailabilityTags(
                        marketPlaceStates.selectedCard.availability
                      )}
                    </Box>

                    {
                      marketPlaceStates.selectedCard.termsURL && (
                        <Box
                          className="mt-3"
                          style={{
                            fontWeight: "400",
                            lineHeight: "15px",
                            fontSize: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <a
                            style={{ color: "#3142C4", textDecoration: "none" }}
                            href={marketPlaceStates.selectedCard.termsURL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms and conditions
                          </a>
                        </Box>
                      )
                    }

                    <Box className="list">
                      {
                        (marketPlaceStates.selectedCard.denominations && marketPlaceStates.selectedCard.denominations.length > 0) ? (
                          <>
                            {
                              JSON.parse(JSON.stringify(marketPlaceStates.selectedCard.denominations)).sort((a, b) => {
                                if (a < b) return -1
                                if (a > b) return 1
                                return 0
                              }).map((denomination: number, index: number) => (
                                <Box
                                  key={index}
                                  className="list-box"
                                  onClick={() => {
                                    if (userDetailsReducer.basicDetails.marketPlaceAccess !== 'no-access') {

                                    handleGiftPayment(
                                      getPayableValue(calculateEcoTokens(denomination), tokens),
                                      marketPlaceStates.selectedCard.cardId,
                                      // payableToken(tokens, denomination),
                                      marketPlaceStates.selectedCard.discount,
                                      marketPlaceStates.selectedCard.giftId,
                                      denomination,
                                      marketPlaceStates.selectedCard.termsURL,
                                      marketPlaceStates.selectedCard.availability
                                    )
                                    }
                                  }
                                  }
                                >
                                  <Box className="card">
                                    <b>£{denomination}</b>
                                  </Box>

                                  <Box className="card-content">
                                    <b>Buy Now</b>
                                  </Box>

                                  <Box className="price">
                                    <Box
                                      className="amount"
                                    >
                                      <b>
                                        £
                                        {
                                          (denomination - ((Number(marketPlaceStates.selectedCard.discount) / 100) * denomination)).toFixed(2)
                                        }
                                      </b>

                                      {/* <p style={{ fontSize: "12px" }}>
                                    {payableToken(tokens, denomination) > 0 &&
                                      -payableToken(tokens, denomination) + " tokens"}
                                  </p> */}
                                    </Box>

                                    <Box
                                      className="right-arrow-icon"
                                      onClick={() => {
                                        if (userDetailsReducer.basicDetails.marketPlaceAccess !== 'no-access') {

                                        handleGiftPayment(
                                          getPayableValue(calculateEcoTokens(denomination), tokens),
                                          marketPlaceStates.selectedCard.cardId,
                                          // payableToken(tokens, denomination),
                                          marketPlaceStates.selectedCard.discount,
                                          marketPlaceStates.selectedCard.giftId,
                                          denomination,
                                          marketPlaceStates.selectedCard.termsURL,
                                          marketPlaceStates.selectedCard.availability
                                        )
                                        }
                                      }
                                      }
                                    >
                                      <img src={leftArrow} alt="" />
                                    </Box>
                                  </Box>
                                </Box>
                              ))
                            }
                          </>
                        ) : (
                          // <>
                          //   {cardArray.map((value, idx) => (
                          //     <Box
                          //       key={idx}
                          //       className="list-box"
                          //       onClick={() =>
                          //         handleGiftPayment(
                          //           getPayableValue(value.tokens, tokens),
                          //           marketPlaceStates.selectedCard.cardId,
                          //           // payableToken(tokens, value.price),
                          //           marketPlaceStates.selectedCard.discount,
                          //           marketPlaceStates.selectedCard.giftId,
                          //           value.price,
                          //           marketPlaceStates.selectedCard.termsURL,
                          //           marketPlaceStates.selectedCard.availability
                          //         )
                          //       }
                          //     >
                          //       <Box className="card">
                          //         <b>£{value.price}</b>
                          //       </Box>

                          //       <Box className="card-content">
                          //         <b>Buy Now</b>
                          //       </Box>

                          //       <Box className="price">
                          //         <Box
                          //           className="amount"
                          //         >
                          //           <b>
                          //             £
                          //             {
                          //               (value.price - ((Number(marketPlaceStates.selectedCard.discount) / 100) * value.price)).toFixed(2)
                          //             }
                          //           </b>

                          //           {/* <p style={{ fontSize: "12px" }}>
                          //           {payableToken(tokens, value.price) > 0 &&
                          //             -payableToken(tokens, value.price) + " tokens"}
                          //         </p> */}
                          //         </Box>

                          //         <Box
                          //           className="right-arrow-icon"
                          //           onClick={() =>
                          //             handleGiftPayment(
                          //               getPayableValue(value.tokens, tokens),
                          //               marketPlaceStates.selectedCard.cardId,
                          //               // payableToken(tokens, value.price),
                          //               marketPlaceStates.selectedCard.discount,
                          //               marketPlaceStates.selectedCard.giftId,
                          //               value.price,
                          //               marketPlaceStates.selectedCard.termsURL,
                          //               marketPlaceStates.selectedCard.availability
                          //             )
                          //           }
                          //         >
                          //           <img src={leftArrow} alt="" />
                          //         </Box>
                          //       </Box>
                          //     </Box>
                          //   ))}
                          // </>
                          <>
                            <Box className="other-amount-wrapper">
                              <Box className="other-amount-title">
                                <b>
                                  Enter gift card amount
                                </b>
                              </Box>

                              <Box className="inner-wrapper items-center">
                                <Box className="form-box">
                                  <FormControl className="w-90">
                                    <TextField
                                      type="number"
                                      className="text-center"
                                      inputProps={{
                                        // pattern: "[0-9]*",
                                        step: "0.01",
                                        style: {
                                          textAlign: 'center'
                                        }
                                      }}
                                      placeholder="0.00"
                                      
                                      value={marketPlaceStates.otherAmount}
                                      onChange={(e: any) => handleOtherAmountChange(e, marketPlaceStates.selectedCard.min, marketPlaceStates.selectedCard.max)}
                                    ></TextField>

                                    {marketPlaceStates.otherAmountErr && (
                                      <small style={{ color: "red" }}>
                                       { marketPlaceStates.otherAmountErrorText }
                                      </small>
                                    )}
                                  </FormControl>

                                </Box>
                                {
                                  marketPlaceStates.selectedCard.min && marketPlaceStates.selectedCard.max && (
                                    <span className="text-400 text-md">
                                      (min: £{marketPlaceStates.selectedCard.min} max: £{marketPlaceStates.selectedCard.max})
                                    </span>
                                  )
                                }

                                <Box className="price-entered">
                                  {/* <Box className="amount">
                        

                        <p style={{ fontSize: "12px" }}>
                          {marketPlaceStates.otherAmount &&
                            payableToken(
                              tokens,
                              Number(marketPlaceStates.otherAmount)
                            ) > 0 &&
                            Number(marketPlaceStates.otherAmount) !== 0
                            ? "-" +
                            payableToken(
                              tokens,
                              Number(marketPlaceStates.otherAmount)
                            ) +
                            "tokens"
                            : ""}
                        </p>
                      </Box> */}

                                  <Box
                                    className="right-arrow-icon"
                                    onClick={handleOtherAmountClick}
                                  >
                                    {/* <img
                          className={
                            (marketPlaceStates.otherAmount !== undefined &&
                              marketPlaceStates.otherAmount !== "0.00" &&
                              Number(marketPlaceStates.otherAmount) !== 0) && marketPlaceStates.otherAmountErr === false
                              ? "other-amount-arrow-button"
                              : "other-amount-arrow-button-disabled"
                          }
                          src={floatingActionButton}
                          alt="arrow-btn"
                        /> */}
                                    <Button className="next-btn custom-btn btn-width mt-3 btn-text p-2" disabled={marketPlaceStates.otherAmountErr || !marketPlaceStates.otherAmount}>
                                      <b>
                                        Buy now {" "}
                                        {marketPlaceStates.otherAmount
                                          ?
                                          // `£ ${getAmountToPay(
                                          //   payableToken(
                                          //     tokens,
                                          //     Number(marketPlaceStates.otherAmount)
                                          //   ) * 0.01,
                                          //   Number(marketPlaceStates.selectedCard.discount),
                                          //   Number(marketPlaceStates.otherAmount)
                                          // )}`
                                          `£ ${
                                            (Math.round(Number(Number(Number(marketPlaceStates.otherAmount) - (Number(marketPlaceStates.selectedCard.discount) /100) * Number(marketPlaceStates.otherAmount))
                                            .toLocaleString("en", {
                                              useGrouping: false,
                                              maximumFractionDigits: 2,
                                            })) * 100) /100).toFixed(2)
                                            // (Math.round(Number(Number(Number(marketPlaceStates.otherAmount) - ((Number(marketPlaceStates.selectedCard.discount) / 100) * Number(marketPlaceStates.otherAmount))).toFixed(2)) * 100) / 100).toFixed(2)
                                          }`
                                          : `£${"0.00"}`}
                                      </b>
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )
                      }
                    </Box>
                  </Box>
                   ) : 
                    (<Box>
                      <CardDetail
                      showRedemptionDetails={showRedemptionDetails}
                      setShowPaymentDetails={(value) => {
                        setShowPaymentDetails(value);
                      }}
                      setShowRedemptionDetails={(value) => {
                        setShowRedemptionDetails(value);
                      }}
                      showLoader={setLoading}
                      />
                    </Box>)
                  }
              </CustomDrawer>
            </div>
          )}

          {marketPlaceStates.activeTab === "purchase-history" && marketPlaceStates.purchaseHistory.length > 0 &&
            marketPlaceStates.purchaseHistory?.map((item: any, key: number) => (
              <React.Fragment key={key}>
                <Box className="gift-card-title">
                  <p className="text-md">
                    {getMonthName(item.purchaseMonth?.month) +
                      " " +
                      item.purchaseMonth?.year}
                  </p>
                </Box>

                {item.giftCards?.map((gifts: any) => (
                  <PurchaseHistory
                    key={gifts._id}
                    logo={gifts?.giftImage}
                    status={gifts?.giftStatus}
                    date={moment(gifts?.createdAt).format("DD/MM/YYYY")}
                    amount={"£" + Number(gifts?.amount?.actualAmount ?? gifts?.amount?.totalAmount).toFixed(2)}
                    onClick={() =>
                      managePurchaseHistoryDrawer(
                        gifts?._id,
                        gifts?.giftImage,
                        gifts?.discount,
                        getName(gifts.giftName, "EN") as string,
                        gifts.giftUrl,
                        gifts.createdAt,
                        gifts.giftStatus,
                        gifts.amount.actualAmount ?? gifts?.amount?.totalAmount
                      )
                    }
                  />
                ))}
              </React.Fragment>
            ))}

          {
            marketPlaceStates.activeTab === "purchase-history" && marketPlaceStates.purchaseHistory.length <= 0 &&
              <Box className="no-purchase-history-container pt-1">
                <NoPurchaseHistoryCard />
              </Box>
          }

          {
            marketPlaceStates.activeTab === 'earnings' && (
              <Earnings state={weeklyEarnings} setState={setWeeklyEarnings}/>
            )
          }

          {
            marketPlaceStates.activeTab === 'promo-code' && (
              <PromoCodeList state={promosList} setState={setPromosList}/>
            )
          }

          <CustomDrawer
          isContent75vh={false}
            anchor="bottom"
            isOpen={marketPlaceStates.purchaseHistoryDrawer}
            onClose={() => {
              managePurchaseHistoryDrawer("", "", "", "", "", "", "-", "");
            }}
          >
            <Box className="purchase-history-drawer-main puchase-history-pb-150">
              <OfferCards
                logo={marketPlaceStates.purchasedCard.image}
                text={`${marketPlaceStates.purchasedCard.discount}% OFF`}
              />

              <Box className="amount-container" sx={{ marginTop: "16px" }}>
                <b>
                  £{Number(marketPlaceStates.purchasedCard.amount).toFixed(2)}
                </b>
              </Box>

              <Box
                className="status mb-1"
                sx={{ marginTop: "16px", fontWeight: "600", textTransform: "capitalize" }}
              >
                {marketPlaceStates.purchasedCard.giftStatus.toLowerCase()}
              </Box>

              <Box
                className="purchased-date text-md"
                sx={{
                  marginTop: "8px",
                  marginBottom: "32px",
                  color: "#616161;",
                }}
              >
                Purchased{" "}
                {moment(marketPlaceStates.purchasedCard.purchasedOn).format(
                  "DD/MM/YYYY"
                )}
              </Box>
            </Box>

            {/* <Box className="open-gift-card-btn p-1 pb-40">
              <Button
                className="next-btn custom-btn width-100 btn-text"
                disabled={
                  marketPlaceStates.purchasedCard.giftUrl ? false : true
                }
                onClick={() =>
                  window.open(marketPlaceStates.purchasedCard.giftUrl, "_blank")
                }
              >
                Open gift card
              </Button>
            </Box> */}

            <CustomFixedButton text={"Open gift card"} disable={marketPlaceStates.purchasedCard.giftUrl ? false : true}
                handleSubmit={() =>
                  window.open(marketPlaceStates.purchasedCard.giftUrl, "_blank")
                }
                isDrawerButton={true}
                bottomJSX={<Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.2rem',
                  color: '#5ED497',
                  padding: '0.5rem 0.5rem 0',
                  cursor: 'pointer'
                }}
                onClick={async () => {
                  setLoading(true)
                  try {
                    const link = (await getGiftCardReceipt(marketPlaceStates.purchasedCard._id) as {path: string}).path;
                    // let url = '';
                    // fetch(link)
                    //   .then(response => response.blob())
                    //   .then(blob => {
                    //     url = URL.createObjectURL(blob);
                    //     console.log('url', url)
                    //     const a = document.createElement('a');
                    //     a.href=url;
                    //     a.download="gift_receipt.pdf";
                    //     document.body.appendChild(a);
                    //     a.click();
                    //     document.body.removeChild(a);
                    //   })
                    //   .catch(err => {
                    //     enqueueSnackbar(err.message || 'Error occurred while downloading pdf', {
                    //       variant: 'error'
                    //     });
                    //   })
                    //   .finally(() => {
                    //     URL.revokeObjectURL(url);
                    //   });

                    const a = document.createElement('a');
                    a.href=link;
                    a.download="gift_receipt.pdf";
                    document.body.appendChild(a);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                  } catch (error) {
                    enqueueSnackbar('Error occurred while generating receipt', { variant: 'error' });
                  } finally {
                    setLoading(false);
                  }
                }}>
                  {/* <div> */}
                    <img src={receiptIcon} alt="receipt" />
                  {/* </div> */}
                  <b>
                    &nbsp;Download receipt
                  </b>
                </Box>}
                />
          </CustomDrawer>
        </>
      )}
    </div>
  );
};

const NoPurchaseHistoryCard = () => {
  return (
    <Box id="no-purchase-history-card" className=" shadow-extra-light">
      <Box className="no-purchase-gift-icon-container"><img src={giftIcon} alt="gift-icon" /></Box>
      <Box className="no-purchase-history-description-container">
        <h4>No purchases</h4>
        <p className="text-md">You haven't purchased any discounted gift cards yet</p>
      </Box>
    </Box>
  )
}

export default MarketPlace;
