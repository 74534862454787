import mobileIcon from "../../../assets/images/mobile.svg";
import whatsAppIcon from "../../../assets/images/whatsapp-icon.svg";
import personIcon from "../../../assets/images/person-icon.svg";
import listIcon from "../../../assets/images/list.svg";
import businessIcon from "../../../assets/images/business.svg";
import scannerIcon from "../../../assets/images/scanner.svg";
import shoppingBagIcon from "../../../assets/images/shopping-bag.svg";
import fileIcon from  "../../../assets/images/file-icon-sm.svg"
import carIcon from  "../../../assets/images/car-icon-sm.svg"
import tickMark from  "../../../assets/images/tick-mark-sm.svg"
import smallCar from  "../../../assets/images/small-car.svg"
import fileCode from  "../../../assets/images/file-code.svg"
import checkMark from  "../../../assets/images/checkmark.svg"

export const contents = [
  {
    icon: mobileIcon,
    text: "Enter your mobile number and tap ‘Sign up with WhatsApp’.",
  },
  {
    icon: whatsAppIcon,
    text: "You'll be redirected to the EcoPooling WhatsApp chatbot account with a default message to send i.e. ‘Sign me into EcoPooling’.",
  },
  {
    icon: personIcon,
    text: "A unique login link will be generated. Tap on the link and you'll be logged in and all set to complete the final registration steps.",
  },
];

export const getVerifiedDescriptionContents = [
  {
    id : 1,
    icon: listIcon,
    description:
    "There’s no need to manually enter your details, scan ID documents or take selfies.",
  },
  {
    id: 2,
    icon: businessIcon,
    description:
      "  We simply verify your identity via your bank. They’ve already done the heavy lifting, so we don’t have to.",
  },
  {
    id : 3,
    icon: scannerIcon,
    description:
      " Simply authenticate via face ID on your banking app to share your verified full name and address details, and you’re good to go. It only takes a few secs.",
  },
];

export const getDriverVerifiedContents = [
  {
    id : 1,
    icon: smallCar,
    description:
    "We’ll use the car registration you provided us with when signing up to verify your vehicle’s MOT and tax every month",
  },
  {
    id: 2,
    icon: fileCode,
    description:
      "To verify your driver’s license, you need to share your DVLA ‘check code’, which allows us to look up your license with the DVLA. You can find this code within seconds on the DVLA website",
  },
  {
    id : 3,
    icon: checkMark,
    description:
      "Once you’ve provided us with the DVLA ‘check code’, we’ll verify your license, MOT and tax within 1 business working day",
  },
];

export const eligiblityGuidelines = [
  "Enter your organisation email address below",
  "You’ll receive an OTP code via email in a few secs",
  "Enter your OTP code and proceed to sign up",
];

export const whatNextDescription = [
  {
    icon: whatsAppIcon,
    text: "As soon as at least one other student sign’s up that’s on your route, you’ll receive an invite to start scheduling carpools with them via the EcoPooling chatbot on WhatsApp.",
  },
  {
    icon: shoppingBagIcon,
    text: "Start saving money instantly on groceries, eating out, home, travel, entertainment and more with access to over 50 discounted top brand gift cards",
  },
];

export const driverVerificationDescription = [
  {
    icon : carIcon,
    text : "To verify you vehicles MOT and Tax we’ll use your car registration which you already provided to us on sign up. Nothing more for you to do."
  },
  {
    icon : fileIcon,
    text : "To verify your driving licence we need you to provide us with a DVLA ‘check code’ which allows us to lookup you licence wit the DVLA.  This takes 30secs to obtain from the DVLA site."
  },
  {
    icon : tickMark,
    text : "Once you’ve provided you licence ‘check code’ we’ll verify your licence, MOT and Tax within 24 hours (typically within 2 hours)."
  }
];

export const getCodeGuidelines = [
  "Visit the Gov UK ‘review driver record’ portal,  enter your driving licence number, NI number and post code.",
  "You’ll instantly receive a ‘check code’ on screen.",
  "Enter your ‘check code’ and we’ll use this to verify your license with the DVLA."
]

export const numPadKeys = [
  ["1", ""],
  ["2", "ABC"],
  ["3", "DEF"],
  ["4", "GHI"],
  ["5", "JKL"],
  ["6", "MNO"],
  ["7", "PQRS"],
  ["8", "TUV"],
  ["9", "WXYZ"],
  ["", ""],
  ["0", ""],
  ["⌫", ""],
];
