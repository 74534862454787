import { Box } from "@mui/material"
import './style.scss'

interface ICardProps {
  logo: string;
  text: string;
  handleClick?: () => void;
  isClickable ?: boolean
}

const OfferCards = ({ logo, text,isClickable, handleClick }: ICardProps) => {
  return (
    <>
      <Box className="market-place-cards d-flex flex-column items-center" 
      style={{cursor : isClickable ? "pointer" : ""}}onClick={handleClick}>
        <Box className="image-container border-light-gray rounded-4 shadow-light-small">
          <img className="gift-card-logo" src={logo} alt="gift-logo-img" />
        </Box>
        <Box className="offer text-700">
          {text}
        </Box>
      </Box>
    </>
  )
}

OfferCards.defaultProps = {
  isClickable : false
}

export default OfferCards;
