import { httpRequest } from "../network";

export const verifyUser = (payload: object) => new Promise((resolve, reject) => {
    return httpRequest("POST", "/auth/verification/verify-user", payload)
        .then((res: any) => resolve(res.data))
        .catch(reject)
})





