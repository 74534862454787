import { httpRequest } from "./../../network";



export const updateUser = async (data: any)=>{
  try {
    const res: any = await httpRequest("PUT","/auth/user/web",data);
    return res.data;
  } catch (error) {
    throw error
  }
}

export const updateUserAddress = async (data: any)=>{
  try {
    const res: any = await httpRequest("PUT","/auth/user/update-address",data);
    return res.data;
  } catch (error) {
    throw error
  }
}