import Header from "../../../../../components/header";
import eligibilityCheck from "../../../../../assets/images/eligible-check.svg";
import persons from "../../../../../assets/images/persons.svg";
import CheckedCircle from "../../../../../assets/images/checked-circle.svg"
import { Box } from "@mui/material";
import './style.scss'
import CustomInputBox from "../../../Common components/CustomInputBox";
import { eligiblityGuidelines } from "../../../Common components";
import { useLocation, useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";
import { sendOTP } from "../../../../../services/OTP";
import { enqueueSnackbar } from "notistack";
import { getName, validate } from "../../../../../utilities/helper";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";



const EligibilityCheck = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState<string>()
  const [isAdmin,setIsAdmin] = useState(false)
  const data = location.state;
  const {locationDetail} = useSelector((state:RootState)=> state.utilityReducer)


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  };

  const handleSubmit = () => {
    if (validate('email', email as string)) {
      const payload = {
        email,
        locationId: data.locationId
      }
      if(isAdmin && !locationDetail.whiteListValues.includes(email)){
        enqueueSnackbar("Unauthorized email",{variant:"error"})
        return
      }
      sendOTP(payload)
        .then((res: { data: { OTP: string } }) => {
          enqueueSnackbar("An OTP is sent to your email", { variant: "success" })
          navigate('/otp-screen', { state: payload })
        })
        .catch(() => enqueueSnackbar("Invalid email", { variant: 'error' }))
    }
  };

  useEffect(()=>{
    if(data.admin){
      setIsAdmin(true)
    }
    //eslint-disable-next-line
  },[])


  return (
    <div id="eligibility-check">
      <Header />
      <div className="eligibility-check-main-wrapper p-1 ">
        <div className="eligibility-check-image-container d-flex justify-center items-center mb-3">
          {isAdmin ? (
            <img src={persons} alt="person-icon" />
          ) : (
            <img src={eligibilityCheck} alt="eligibility-check" />
          )}
        </div>
        <div className="eligibility-check-heading heading mb-3 text-center">
          {isAdmin ? (
            <p> Activate {getName(locationDetail.locationName,"EN")} carpool community</p>
          ) : (
            <p> Carpool community eligibility check required</p>
          )}
        </div>
        <div className="eligibility-check-description text-sm-2">
          {isAdmin ? (
            <p>
              This is a private carpool community that requires activation.
              Please follow the steps below to activate and complete community
              admin registration.
            </p>
          ) : (
            <p>
              This is a private carpool community that requires a quick
              eligibility check to confirm you are a member of the destination
              organisation or have been added to the pre-approved list.
            </p>
          )}
        </div>
        <div className="eligibility-check-process-description-container mt-3">
          {eligiblityGuidelines.map((texts, key) => (
            <Box
              key={key}
              className="guideline-container center py-05"
              justifyContent="start"
            >
              <div className="checked-circle-image d-flex items-center mr-2">
                <img alt="checked-circle" src={CheckedCircle} />
              </div>

              <p className="text-sm-2">{texts}</p>
            </Box>
          ))}
        </div>
        <div className="emailInputBox">
          <CustomInputBox
            type="email"
            value={email}
            placeHolderText="name@organisation-email.com"
            showLabel={true}
            whatsAppSignUp={false}
            btnText="Submit"
            onChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default EligibilityCheck;
