import CircularProgress from '@mui/material/CircularProgress';
import "./style.scss";



const LoadingScreen = () => {
  return (
    <div className="wrapper">
      <CircularProgress color="primary" />
    </div>
  );
};


export default LoadingScreen