import "./style.scss";
import Header from "../../components/header";
import DefaultProfile from "../../assets/images/default-profile.svg";
import Gift from "../../assets/images/gift.svg";
import Amazon from "../../assets/images/amazon.svg";
import { Box, Button, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const cards = [
  {
    image: Amazon,
    alt: "amazon",
  },
  {
    image: Amazon,
    alt: "amazon1",
  },
  {
    image: Amazon,
    alt: "amazon2",
  },
  {
    image: Amazon,
    alt: "amazon3",
  },
  {
    image: Amazon,
    alt: "amazon4",
  },
  {
    image: Amazon,
    alt: "amazon5",
  },
];

const GiftProvider = () => {
  const [state, setState] = useState({
    selectedProvider: "",
  });
  const navigate = useNavigate();

  const userDetailsReducer = useSelector(
    (store: RootState) => store.userDetailsReducer
  );

  const handleCardSelect = (selectedProvider: string) => {
    setState((prev) => {
      return {
        ...prev,
        selectedProvider,
      };
    });
  };

  return (
    <div id="gift-provider">
      <Header
        rightIcon={
          <img
            alt="profile"
            src={userDetailsReducer.basicDetails.photo ?? DefaultProfile}
            className="header-profile-image bordered-2 shadow-light"
          />
        }
      />
      <Box className="margin-x">
        <div className="sub-header">
          <img alt="person" src={Gift} />
          <p className="sub-text">
            Please select your preferred gift card provider to receive fuel
            contributions from the journeys you make.
          </p>
        </div>
      </Box>
      <p className="section-head">Select 1 gift card provider</p>
      <div className="gift-bg">
        <Box className="margin-x">
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            paddingTop="12px"
          >
            {cards.map((card) => {
              return (
                <>
                  <div
                    className={`gift-box ${
                      state.selectedProvider === card.alt && "active"
                    }`}
                    onClick={() => handleCardSelect(card.alt)}
                  >
                    <img alt={card.alt} src={card.image} />
                    <Checkbox
                      checked={
                        state.selectedProvider === card.alt ? true : false
                      }
                    />
                  </div>
                </>
              );
            })}
          </Box>

          <Button
            disabled={state.selectedProvider.length ? false : true}
            className="next-btn custom-btn"
            fullWidth
            onClick={() => navigate("/detail/summary")}
          >
            Next
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default GiftProvider;
