import "./style.scss";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { createPaymentIntent } from "../../services/payment";
// import CheckoutForm from "./checkout";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { enqueueSnackbar } from "notistack";

const stripePromise = loadStripe("pk_test_51NAZ9oSGWkPuEZO1mgMcRHkOKLSzSdA8y2Q0N2GtWBFhzHEO82veC0xisdf4Aerk2GN2zjHf34eFrxyCZohSG4gC00NUD93P2G");

const PaymentFinder = () => {
  const [clientSecret, setClientSecret] = useState("");

  const { userDetailsReducer: { user }, bookSeatReducer: { selectedTripResponse, trip } } = useSelector((state: RootState) => state);

  const totalAmount = trip.pricePerSeat * selectedTripResponse.length

  const navigate = useNavigate();

  const fetchData = async () => {
    const trips = selectedTripResponse.map((it) => it._id);
    localStorage.setItem("_trip", JSON.stringify(trips));
    try {
      const intent = await createPaymentIntent({
        _trip: selectedTripResponse.map((it) => it._id),
        _user: user._id,
        amount: {
          totalAmount: Number(totalAmount.toFixed(2)),
          units: "USD",
        }
      });
      setClientSecret(intent.data.clientSecret);
      localStorage.setItem("transactionId", intent.data.transactionId)
      localStorage.setItem("transactionIdByStripe", intent.data.id)
    } catch (error) {
      enqueueSnackbar('Could not initiate transaction', { variant: 'error' });
      navigate("/dashboard/upcoming");
    }
  }

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  } as any;

  return (
    <div
      id='payment-screen'
    >
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <Outlet />
        </Elements>
      )}
    </div>
  )
}

export default PaymentFinder;
