import Header from "../../../components/header";
import { Button } from "@mui/material";
import whatsApp from "../../../assets/images/whatsapp.svg";
import SubText from "../Common components/SubText";
import { WHATSAPP_SIGN_UP_TEXT } from "../../../constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetSignUpDetailsSlice } from "../../../slice/sign-up";
import LoadingScreen from "../../../components/loading-screen";

const NewSignup = () => {
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard/upcoming");
    } else {
      localStorage.removeItem("draftId");
      dispatch(resetSignUpDetailsSlice())
    }
    setLoading(false);

    //eslint-disable-next-line
  }, [localStorage.getItem("token")])

  const handleSubmit = () => {
    window.open(
      `${process.env.REACT_APP_WHATSAPP_SIGN_ME_IN_URL}?text=${WHATSAPP_SIGN_UP_TEXT}`,
      "_blank"
    );
  };
  return (
    <>
      {
        loading ? (
          <LoadingScreen />
        ) : (
          <div className="d-flex col-flex flex-column">
            <Header type="login" signUp={true} publicSignUp={true} />
            <div className="heading p-1">Join EcoPooling</div>
            <p className="pl-1 pr-1">
              The next-generation eco-friendly carpooling solution for schools, commuters and communities.
            </p>
            <div className="p-1 d-flex flex-column justify-center items-center my-3">
              <Button
                className="next-btn custom-btn btn-width px-15-rem"
                fullWidth
                onClick={handleSubmit}
              >
                <img alt="person" src={whatsApp} />
                &nbsp; Sign up with WhatsApp
              </Button>
            </div>
            <SubText />
          </div>
        )
      }
    </>
  );
};

export default NewSignup;
