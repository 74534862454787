import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { createTrips } from "../../services/trips";

export interface MultiLanguage {
  lang: string;
  value: string;
}

export interface AddressInterface {
  country: MultiLanguage[];
  sate: MultiLanguage[];
  geo: number[];
  line1: MultiLanguage[];
  line2: MultiLanguage[];
  zip: string;
}

export interface Driver {
  address: AddressInterface;
  firstName: MultiLanguage[];
  lastName: MultiLanguage[];
  email: string;
  gender: string;
  phone: string;
  photo: string;
  jti: string;
  _id: string;
  loginType?: string;
  socialId?: string;
  isVerified?: boolean;
  isDriverVerified?: boolean;
  userPreference: string;
}

export interface TripInterface {
  childSeatsBooked: number;
  dropDate: string;
  destination: AddressInterface;
  pickUp: AddressInterface;
  pickUpLocationId: string;
  destinationLocationId?: string;
  scheduleDate: string;
  seatsBooked: number;
  totalSeats: number;
  totalChildSeats: number;
  status: string;
  tripStatus: string;
  uniqueId: string;
  _driver: Driver;
  _id: string;
  _user: any[];
  invitedUsers: any[];
  tripEndTime: string;
  tripStartTime: string;
  mapUrl: string;
  mapImageURL: string;
  isBeta?: boolean;
}

export interface TripReducerInterface {
  trips: Array<TripInterface>;
  allTrips: Array<TripInterface>;
  tripsHistory: Array<TripInterface>;
  tripDetail: TripInterface;
  totalPage: number;
  currentPage: number;
  totalRecords: number;
  status: string;
  tripLoading: boolean;
  tripError: string;
  tripSuccess: boolean;
  initialRender: boolean;
  newMatchTrip: TripInterface;
}

const initialState: TripReducerInterface = {
  trips: [],
  allTrips: [],
  tripsHistory: [],
  tripDetail: {} as TripInterface,
  totalPage: 1,
  currentPage: 1,
  totalRecords: 0,
  status: "UPCOMING",
  tripLoading: true,
  tripError: "",
  tripSuccess: false,
  initialRender: true,
  newMatchTrip: {} as TripInterface,
};

// create multiple trips
export const createMultipleTrips = createAsyncThunk(
  "trip/createMultipleTrips",
  async (payload: object, thunkAPI) => {
    try {
      const data: any = await createTrips(payload);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as AxiosError).message);
    }
  }
);

export const tripSlice = createSlice({
  name: "tripReducer",
  initialState,
  reducers: {
    updateTripsData: (state, action: PayloadAction<any>) => {
      const {
        data,
        meta: { totalPage, totalRecords, currentPage },
      } = action.payload;
      state.trips = data;
      state.currentPage = currentPage;
      state.totalPage = totalPage;
      state.totalRecords = totalRecords;
    },
    updateAllTripsData: (state, action: PayloadAction<any>) => {
      const {
        data,
        meta: { totalPage, totalRecords, currentPage },
      } = action.payload;
      state.allTrips = data;
      state.currentPage = currentPage;
      state.totalPage = totalPage;
      state.totalRecords = totalRecords;
    },
    resetAllTripsData: (state) => {
      state.allTrips = initialState.allTrips;
      state.currentPage = initialState.currentPage;
      state.totalPage = initialState.totalPage;
      state.totalRecords = initialState.totalRecords;
    },
    updateTripsHistory: (state, action: PayloadAction<any>) => {
      const {
        data,
        meta: { totalPage, totalRecords, currentPage },
      } = action.payload;
      state.tripsHistory = data;
      state.currentPage = currentPage;
      state.totalPage = totalPage;
      state.totalRecords = totalRecords;
    },
    resetTripsHistory: (state) => {
      state.tripsHistory = initialState.tripsHistory;
      state.currentPage = initialState.currentPage;
      state.totalPage = initialState.totalPage;
      state.totalRecords = initialState.totalRecords;
    },
    updateTripStatus: (state, action: PayloadAction<string>) => {
      const status = action.payload;
      state.status = status;
    },
    updateCurrentPage: (state, action: PayloadAction<number>) => {
      const status = action.payload;
      state.currentPage = status;
    },
    resetTripState: (state) => {
      state.tripError = "";
      state.trips = [];
    },
    resetTripSuccess: (state) => {
      state.tripSuccess = false;
    },
    setInitialRender: (state) => {
      state.initialRender = false;
    },
    setTripDetail: (state, action) => {
      state.tripDetail = action.payload;
    },
    setNewMatchTrip: (state, action) => {
      state.newMatchTrip = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMultipleTrips.pending, (state) => {
        state.tripLoading = true;
      })
      .addCase(createMultipleTrips.fulfilled, (state, action) => {
        state.tripLoading = false;
        state.trips = action.payload.data;
        state.tripSuccess = true;
      })
      .addCase(createMultipleTrips.rejected, (state, action) => {
        state.tripLoading = false;
        state.tripError = action.payload as string;
      });
  },
});

export const {
  updateTripsData,
  updateAllTripsData,
  updateTripsHistory,
  updateCurrentPage,
  updateTripStatus,
  resetTripState,
  resetTripSuccess,
  setInitialRender,
  setTripDetail,
  setNewMatchTrip,
  resetAllTripsData,
  resetTripsHistory
} = tripSlice.actions;
export default tripSlice.reducer;
