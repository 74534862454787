import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { getCategoryByLocationId, getLocation, getSingleCategory } from '../../services/location'
import { ILocationResponse } from '../../interface/location'

export interface MultiLanguage {
    lang: string,
    value: string
}


export interface DailyScheduleInterface {
    dropOffFlexibility: string
    pickUpFlexibility: string
    schedule: Array<ScheduleInterface>
}

export interface ScheduleInterface {
    day: string
    dropOff: string
    pickUp: string
}

export interface Address {
    state: Array<MultiLanguage>,
    city: Array<MultiLanguage>,
    country: Array<MultiLanguage>,
    line1: Array<MultiLanguage>,
    line2: Array<MultiLanguage>,
    geo: number[],
    zip: string
}

export interface Location {
    id: string
    categoryId: string
    locationName: Array<MultiLanguage>
    address: Address
    status: string
}

export interface FavLocationInterface {
    _id: string
    locationName: Array<MultiLanguage>
    order: number
    status: string
    locations: Array<any>
}


//user location ----------------------------------------------->

export interface Schedule {
    day: string[]
    pickOff: string
    dropOff: string
}

export interface _favoriteLocationInterface {
    _id: string
    categoryId: string
    locationName: Array<MultiLanguage>
    address: Address
    status: string
}

export interface UserFavLoactionInterface {
    _id: string
    _user: string
    status: string
    locationName: Array<MultiLanguage>
    _favoriteLocation: string
    dailySchedule: DailyScheduleInterface,
    weeklySchedule?: any,
    _categoryId: string
}


export interface catDetails {
    category: Array<FavLocationInterface>
    userFavLocations: Array<UserFavLoactionInterface>
    locationLoading: boolean,
    location: ILocationResponse,
    locationError: string,
    categoryWithLocation: Array<object>
}

const initialState: catDetails = {
    category: [] as FavLocationInterface[],
    userFavLocations: [] as UserFavLoactionInterface[],
    locationLoading: true,
    location: {},
    locationError: "",
    categoryWithLocation: []
}

export const getSingleLocation = createAsyncThunk("category/getSingleLocation", async (id: string, thunkAPI) => {
    try {
        const data: any = await getLocation(id)

        return data
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message)
    }
})

export const getCategory = createAsyncThunk("category/getSingleCategory", async (id: string, thunkAPI) => {
    try {
        const data: any = await getSingleCategory(id)

        return data
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message)
    }
})

export const getCategoryByLocation = createAsyncThunk("category/getCategoryByLocation", async (locationIds: string[], thunkAPI) => {
    try {
        const data: any = await getCategoryByLocationId(locationIds)

        return data
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message)
    }
})

export const categorySlice = createSlice({
    name: 'categoryReducer',
    initialState,
    reducers: {
        updateCatList: (state, action: PayloadAction<FavLocationInterface[]>) => {
            state.category = action.payload
        },
        updateUserFavLocations: (state, action: PayloadAction<UserFavLoactionInterface[]>) => {
            state.userFavLocations = action.payload
        },
        resetLocationState: (state) => {
            state.locationLoading = true
            state.location = {}
            state.locationError = ""
        }
    },
    extraReducers: builder => {
        builder.addCase(getSingleLocation.pending, (state) => {
            state.locationLoading = true
        }).addCase(getSingleLocation.fulfilled, (state, action) => {
            state.locationLoading = false
            state.location = action.payload
        }).addCase(getSingleLocation.rejected, (state, action) => {
            state.locationLoading = false
            state.locationError = action.payload as string
        }).addCase(getCategory.pending, (state) => {
            state.locationLoading = true
        }).addCase(getCategory.fulfilled, (state, action) => {
            state.locationLoading = false
            state.category = [action.payload]
        }).addCase(getCategory.rejected, (state, action) => {
            state.locationLoading = false
            state.locationError = action.payload as string
        }).addCase(getCategoryByLocation.pending, (state) => {
            state.locationLoading = true
        }).addCase(getCategoryByLocation.fulfilled, (state, action) => {
            state.locationLoading = false
            state.categoryWithLocation = action.payload
        }).addCase(getCategoryByLocation.rejected, (state, action) => {
            state.locationLoading = false
            state.locationError = action.payload as string
        })
    }
})

// Action creators are generated for each case reducer function
export const {
    updateCatList,
    updateUserFavLocations,
    resetLocationState
} = categorySlice.actions

export default categorySlice.reducer

