import './style.scss';
import Header from '../../../../../components/header';
import DefaultProfile from '../../../../../assets/images/default-profile.svg';
import ArrowDown from '../../../../../assets/images/ArrowDown.svg'
import Config from '../../../../../assets/images/config.svg';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from './../../../../../components/mui/SwipeableDrawer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { FavLocationInterface, updateFavLocation } from '../../../../../slice/sign-up';
import { updatePickAndDrop } from '../../../../../services/location';
import { enqueueSnackbar } from 'notistack';
import CustomDatePicker from '../../../../../components/time-picker';
import UseSwitchesBasic from '../../../../../components/switch';
import moment from 'moment';
import DropDown from '../../../../../components/drop-down';
import { pairsDropOff, pairsPickUp } from '../../../../../data/book-seat';

interface KeyActiveTime {
    label: string
    key: string
    active: boolean
    pickUp: string
    dropOff: string
}

interface StateInteface {
    schedule: {
        meta: "pickUp" | "dropOff"
        isOpen: boolean
    }
}
const Pickup = () => {
    const navigate = useNavigate();
    const [currFavLocation, setCurrenFavLocation] = useState<FavLocationInterface>();
    const [currDaySchedule, setCurrDaySchedule] = useState<Array<KeyActiveTime>>(getKeyActiveTime());
    const [helperText, setHelperText] = useState<string>(pairsDropOff[0].value);
    const [state, setState] = useState<StateInteface>({
        schedule: {
            isOpen: false,
            meta: "pickUp"
        }
    });
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const { signUpDetails } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const draftId = localStorage.getItem("draftId");

    // disable the button if schedule is not set for any selected favourite location
    useEffect(() => {
        const { favLocation } = signUpDetails;

        let count = 0;
        // for each selected location, check if its daily schedule is set and it has some dates selected
        favLocation.forEach((it) => {
            if (it.dailySchedule !== undefined && it.dailySchedule?.schedule?.length > 0) count++;
        })
        if (count === 0) {
            setButtonDisabled(true)
        } else if (count !== favLocation.length) {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }
    }, [signUpDetails])

    const handleSave = () => {
        let updatedSchedule: any;
        if (state.schedule.meta === "pickUp") {
            updatedSchedule = {
                pickUpFlexibility: helperText,
                schedule: currDaySchedule.filter((sch) => sch.dropOff || sch.pickUp).map((sch) => ({
                    day: sch.key,
                    ...(!!sch?.pickUp) && { pickUp: sch.pickUp },
                    ...(!!sch?.dropOff) && { dropOff: sch.dropOff }
                }))
            }
        } else {
            updatedSchedule = {
                dropOffFlexibility: helperText,
                schedule: currDaySchedule.filter((sch) => sch.dropOff || sch.pickUp).map((sch) => ({
                    day: sch.key,
                    ...(!!sch?.pickUp) && { pickUp: sch.pickUp },
                    ...(!!sch?.dropOff) && { dropOff: sch.dropOff }
                }))
            }
        }
        let found = false;
        const newCurrFavLocationList = signUpDetails.favLocation.map((favLoc) => {
            if (currFavLocation?.categoryId === favLoc.categoryId) {
                found = true;

                return {
                    ...favLoc,
                    dailySchedule: {
                        ...favLoc.dailySchedule,
                        ...updatedSchedule
                    }
                }
            } else {
                return favLoc;
            }
        })
        if (found === false) {
            let newFavLocation = {
                ...currFavLocation,
                dailySchedule: {
                    ...updatedSchedule
                }
            }
            newCurrFavLocationList.push(newFavLocation as FavLocationInterface);
        }
        dispatch(updateFavLocation(newCurrFavLocationList));
        setCurrenFavLocation(undefined);
        setCurrDaySchedule(getKeyActiveTime());
        setState({
            ...state,
            schedule: {
                ...state.schedule,
                isOpen: false,
            }

        });
    }

    const openSchedule = (favLocation: FavLocationInterface, mode: "pickUp" | "dropOff") => {
        setCurrenFavLocation(favLocation);
        setState({ schedule: { isOpen: true, meta: mode } });
        //initialize the localstate with the curr value
        if (!!favLocation.dailySchedule) {
            if (mode === "dropOff") {
                setHelperText(favLocation.dailySchedule?.dropOffFlexibility ?? '0 mins');
                favLocation.dailySchedule.schedule.forEach((sch) => {
                    let targetIndex = getIndexFromDay(sch.day);
                    currDaySchedule[targetIndex].active = sch.dropOff !== '' && sch.dropOff !== undefined ? true : false;
                    currDaySchedule[targetIndex].dropOff = sch.dropOff;
                    currDaySchedule[targetIndex].pickUp = sch.pickUp;
                    setCurrDaySchedule([...currDaySchedule] as KeyActiveTime[]);
                })
            } else {
                setHelperText(favLocation.dailySchedule?.pickUpFlexibility ?? '0 mins');
                favLocation.dailySchedule.schedule.forEach((sch) => {
                    let targetIndex = getIndexFromDay(sch.day);
                    currDaySchedule[targetIndex].active = sch.pickUp !== '' && sch.pickUp !== undefined ? true : false;;
                    currDaySchedule[targetIndex].dropOff = sch.dropOff;
                    currDaySchedule[targetIndex].pickUp = sch.pickUp;
                    setCurrDaySchedule([...currDaySchedule] as KeyActiveTime[]);
                })
            }
        } else {
            setHelperText('0 mins')
        }
    }

    const handleSchedule = (favLocation: FavLocationInterface, key: "pickUp" | "dropOff") => {
        setCurrenFavLocation(favLocation);
        setState({
            schedule: {
                isOpen: !state.schedule.isOpen,
                meta: key
            }
        });
    }

    const handleNext = async () => {
        const { favLocation } = signUpDetails;

        let count = 0;
        favLocation.forEach((it) => {
            if (it.dailySchedule !== undefined) count++;
        })
        if (count === 0) {
            enqueueSnackbar('Must select one Pick Up or Drop Off', { variant: 'warning' })
            return;
        } else if (count !== favLocation.length) {
            enqueueSnackbar('Must select one Pick Up or Drop Off from all locations', { variant: 'warning' })
            return;
        }
        try {
            await updatePickAndDrop({
                draftId: draftId as string,
                favouriteLocations: favLocation.map((loc) => {
                    return {
                        _categoryId: loc.categoryId,
                        _favoriteLocation: loc.locationId as string,
                        dailySchedule: loc.dailySchedule
                    }
                })
            });
            navigate("/signup/summary")
        } catch (error: any) {
            const msg = error?.response?.data?.message ?? "Something went wrong";
            enqueueSnackbar(msg, { variant: 'error' });
        }
    }

    function convertToShortForm(day: string) {
        if (day.toUpperCase() === 'THURSDAY') {
            return 'Thu';
        }
        const shortForm = day.slice(0, 3).toLowerCase();
        const res = shortForm.charAt(0).toUpperCase() + shortForm.slice(1);
        return res;
    }

    const formatData = (favLocation: FavLocationInterface, key: "pickUp" | "dropOff") => {
        let res = "";
        //let flexibilityDetail = favLocation.dailySchedule;
        if (key === "pickUp") {
            favLocation.dailySchedule?.schedule.forEach((it) => {
                if (!!it.pickUp) {
                    res += convertToShortForm(it.day) + " - " + it.pickUp + "  "
                }
            })
        } else {
            favLocation.dailySchedule?.schedule.forEach((it) => {
                if (!!it.dropOff) {
                    res += convertToShortForm(it.day) + " - " + it.dropOff + " "
                }

            })
        }

        return res;
    }

    const handleToggle = (e: any, currentItem: KeyActiveTime) => {
        if (e.target.checked) {
            const newList = currDaySchedule.map((schedule) => {
                if (schedule.key === currentItem.key) {
                    schedule.active = true;
                    schedule[state.schedule.meta] = '';
                }
                return schedule;
            })
            setCurrDaySchedule(newList);
        } else {
            const newList = currDaySchedule.map((schedule) => {
                if (schedule.key === currentItem.key) {
                    schedule.active = false;
                    schedule[state.schedule.meta] = ""
                }
                return schedule;
            })
            setCurrDaySchedule(newList);
        }
    }

    const handleTime = (time: string, currentItem: KeyActiveTime) => {
        const newList = currDaySchedule.map((schedule) => {
            if (schedule.key === currentItem.key) {
                schedule[state.schedule.meta] = time;
            }
            return schedule;
        })
        setCurrDaySchedule(newList);
    }

    useEffect(() => {
        if (!draftId) navigate("/");
        //eslint-disable-next-line
    }, [draftId]);

    return (
        <div id='pickup-drop' style={{ paddingBottom: '16px' }}>
            <Header
                rightIcon={<img alt='profile' className='header-img-icon' src={
                    (signUpDetails.basicDetails.profilePicture as string) ?? DefaultProfile
                } />}
                signUp={true}
            />
            <Box className='padding-x rounded-top-green'>
                <div className="sub-header">
                <div className="sub-header-icon-container">
                    <img alt='person' src={Config} />
                    </div>
                    <p className='sub-text-new sub-text'>Set your weekly carpool schedule for each of your trip destinations. These will be used to match you with others in your community on the same schedule to each destination.
                        <span className='mt-3 d-inline-block'>
                            We’ll confirm these with you via WhatsApp each week in case your travel plans change for the week ahead.

                        </span>
                    </p>
                </div>
            </Box>
            <p className="section-head">Configure route schedule for each week</p>
            <Box className='padding-x bg-white pt-1 pb-1'>
                {signUpDetails.favLocation.map((val: FavLocationInterface, key: number) => <Box key={key}>
                    <Box className='margin-y'>
                        <Box className='location-box d-flex items-center justify-between cursor-pointer' onClick={() => openSchedule(val, "dropOff")}>
                            <div>
                                <p className='custom-label'>{val.categoryName} Drop-off</p>
                                <p className='custom-label blue-text' style={{ marginBottom: 0 }}>{formatData(val, "dropOff")}</p>
                            </div>
                            <p className='custom-label link d-flex items-center'>
                                <img src={ArrowDown} alt="chevron-down" style={{ margin: 0 }} />
                            </p>
                        </Box>
                    </Box>
                    <Box className='margin-y'>
                        <Box className='location-box d-flex items-center justify-between cursor-pointer' onClick={() => openSchedule(val, "pickUp")}>
                            <div>
                                <p className='custom-label'>{val.categoryName} Pick-Up</p>
                                <p className='custom-label blue-text' style={{ marginBottom: 0 }}>{formatData(val, "pickUp")}</p>
                            </div>
                            <p className='custom-label link d-flex items-center'>
                                <img src={ArrowDown} alt="chevron-down" style={{ margin: 0 }} />
                            </p>
                        </Box>
                    </Box>
                </Box>)}
                <Button className='next-btn custom-btn' disabled={buttonDisabled} fullWidth onClick={handleNext}>Next</Button>
            </Box>


            <CustomDrawer
                anchor='bottom'
                isOpen={state.schedule.isOpen}
                onClose={() => handleSchedule(currFavLocation as FavLocationInterface, "pickUp")}
            >
                <Box id='pickup-drop-drawer' className='margin-x mt-1'>
                    <p className='drawer-title text-center'>{currFavLocation?.categoryName} {state.schedule.meta === "pickUp" ? 'Pick-up' : 'Drop-off'} schedule</p>
                    <Box id='pickup-drop-drawer'>
                        <Box className='options-box'>
                            {currDaySchedule.map((item: KeyActiveTime, key: number) =>
                                <Box className='between margin-y' key={key}>
                                    <Box className='d-flex' alignItems={"center"}>
                                        <UseSwitchesBasic
                                            handleChange={(e: any) => handleToggle(e, item)}
                                            checked={item.active}
                                        /> <p>{item.label}</p>
                                    </Box>
                                    <Box className={"sm-max-100"} style={{ position: 'relative' }}>
                                        <CustomDatePicker
                                            disbled={!item.active}
                                            handleChange={(val) => {
                                                handleTime(moment(val).format('HH:mm'), item)
                                            }}
                                            timeDefault={item[state.schedule.meta]}
                                        />
                                        {
                                            (item[state.schedule.meta] === undefined || item[state.schedule.meta]?.length === 0) && (
                                                <span style={{ position: 'absolute', top: '-1px', left: '0px', bottom: '0px', right: '0px', width: '100%', height: '100%', display: 'flex', alignItems: 'center', pointerEvents: 'none', cursor: 'pointer', background: '#fff', border: '1px solid #d0d0d0', borderRadius: '3px', color: '#9e9e9e' }}>
                                                    <span style={{ marginLeft: '0.5rem' }}>
                                                        Set time
                                                    </span>
                                                </span>
                                            )
                                        }
                                    </Box>

                                </Box>
                            )}
                            <Box className='option-box-title'>
                                {
                                    state.schedule.meta === "pickUp" ? (
                                        <>
                                            I’m flexible and willing to leave up to
                                        </>
                                    ) : (
                                        <>
                                            I’m flexible and willing to arrive up to
                                        </>
                                    )
                                }
                            </Box>
                            <Box>
                                <DropDown
                                    pairs={state.schedule.meta === "pickUp" ? pairsPickUp : pairsDropOff}
                                    handleChange={(e: any) => setHelperText(e.value)}
                                    label={''}
                                    val={helperText}
                                />

                            </Box>
                            <Box className='option-box-helper-text'>
                                This will help us match you with more members in your community with similar travel schedules
                            </Box>
                            <Button className='save-btn custom-btn mb-2'
                                fullWidth
                                onClick={() => handleSave()}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </CustomDrawer>
        </div>
    )
}

export default Pickup;

function getKeyActiveTime() {
    let res: Array<KeyActiveTime> = [
        {
            key: "MONDAY",
            label: "Monday",
            active: false,
            pickUp: "",
            dropOff: ""
        },
        {
            key: "TUESDAY",
            label: "Tuesday",
            active: false,
            pickUp: "",
            dropOff: ""
        },
        {
            key: "WEDNESDAY",
            label: "Wednesday",
            active: false,
            pickUp: "",
            dropOff: ""
        },
        {
            key: "THURSDAY",
            label: "Thursday",
            active: false,
            pickUp: "",
            dropOff: ""
        },
        {
            key: "FRIDAY",
            label: "Friday",
            active: false,
            pickUp: "",
            dropOff: ""
        }
    ]
    return res;
}

function getIndexFromDay(day: string) {
    switch (day) {
        case "MONDAY":
            return 0;
        case "TUESDAY":
            return 1;
        case "WEDNESDAY":
            return 2;
        case "THURSDAY":
            return 3;
        case "FRIDAY":
            return 4;
        default:
            return 0;
    }
}
