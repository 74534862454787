
import { Box } from '@mui/material';
import './style.scss';

interface ICardProps {
  logo: string;
  heading: string;
  description: string;
};

const NoTripsCard = ({ logo, heading, description }: ICardProps) => {
  return (
    <Box className="shadow-extra-light p-1 my-3rem w-80 rounded-1">
      <div className="no-trips-container">
        <div className="icon">
          <img src={logo} alt="car" />
        </div>
        <div className="no-card-description">
          <h3 className="h3">{heading}</h3>
          <p className="p">{description}</p>
        </div>
      </div>
    </Box>
  );
}

export default NoTripsCard;