import "./style.scss"
import { Box, TextField, FormControl, RadioGroup, FormControlLabel, Radio, Button, InputAdornment } from "@mui/material"
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import Header from "../../../components/header-1";
// import DefaultProfile from './../../../assets/images/default-profile.svg';
import Person from './../../../assets/images/person.svg';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateUser } from "../../../services/update/user-profile";
import { enqueueSnackbar } from "notistack";
import { initUserState } from "../../../slice/login";
import { validationSchema } from "./validation";
import { useNavigate } from "react-router-dom";
import { basicDetailFileUpload } from "../../../services/basic-detail-file-upload";
import VerifiedTickIcon from '../../../assets/images/verified-tick.svg'
import CustomFixedButton from "../../../components/custom-fixed-button";


const ProfileUpdate = () => {
    const dispatch = useDispatch();

    const { userDetailsReducer: { basicDetails,
        // vehicleDetails
    } } = useSelector((state: RootState) => state);
    const navigate = useNavigate();

    const mapDataFromStateToFormik = () => {
        return {
            firstName: basicDetails.firstName[0].value,
            lastName: basicDetails.lastName[0].value,
            email: basicDetails.email,
            gender: basicDetails.gender,
            photo: basicDetails.photo,
            phone: basicDetails.phone,
            carPoolPreference: basicDetails.userPreference,
            occupation: basicDetails?.occupation,
            profilePicture: null as any

        }
    }

    const mapDataFromFormikToState = (value: any) => {
        const { firstName, lastName, email, gender, phone, photo, carPoolPreference, occupation } = value;
        
        return {
            firstName: [{ lang: "EN", value: firstName }],
            lastName: [{ lang: "EN", value: lastName }],
            email: email,
            gender: gender,
            phone: phone,
            userPreference: carPoolPreference,
            photo,
            occupation
        }
    }

    const formik = useFormik({
        initialValues: mapDataFromStateToFormik(),
        validationSchema: basicDetails.userPreference === 'MARKET_PLACE' ? validationSchema.omit(["email"]) : validationSchema,
        onSubmit: async (values) => {
            try {
                let image_url = "";
                if (values.profilePicture != null) {
                    image_url = await basicDetailFileUpload(values.profilePicture as File);
                } else {
                    image_url = values.photo
                }
                const res = await updateUser(mapDataFromFormikToState({ ...values, photo: image_url }));
                dispatch(initUserState(res as any))
                enqueueSnackbar("User updated successfully !", { variant: 'success' });
                // console.log('111111111111111111111 ', values)
                // if ((values.carPoolPreference === "BOTH" || values.carPoolPreference === "OFFER") && vehicleDetails.registrationNo === "") {
                //     navigate('/update/vehicle')
                // }
                // else {
                //     navigate("/dashboard/profile")
                // }
                navigate("/dashboard/profile")

            } catch (error) {
                console.log(error);
                enqueueSnackbar("Something went wrong !", { variant: 'error' });
            }
        }
    });

    const fileUploadRef = useRef<HTMLInputElement>(null);
    const [profileString, setProfileString] = useState<string>("");

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = () => {
            setProfileString(reader.result as string);
        }
        if (formik.values.profilePicture instanceof File)
            reader.readAsDataURL(formik.values.profilePicture as File);
        //eslint-disable-next-line
    }, [formik.values.profilePicture]);

    useEffect(() => {
        mapDataFromStateToFormik();
        //eslint-disable-next-line
    }, []);

    return (
        <div id={"profile"}>
            <Header rightIcon={basicDetails.photo} />

            <Box className='padding-x rounded-top-green'>
                <div className="sub-header">
                    <div className="sub-header-icon-container">
                        <img alt='person' src={Person} />
                    </div>
                    <p className='sub-text sub-text-new'>Please update your personal profile.</p>
                </div>
            </Box>

            <p className="section-head section-head-bg-light-green">Your details</p>

            <form onSubmit={formik.handleSubmit}>
                <Box className='margin-x'>
                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="first_name">First Name</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='first_name'
                            name='firstName'
                            disabled={basicDetails.isVerified === true ? true : false}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                            placeholder='Enter your first name'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        (basicDetails.isVerified === true
                                        ) &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="last_name">Last Name</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='last_name'
                            name='lastName'
                            disabled={basicDetails.isVerified === true ? true : false}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            placeholder='Enter your last name'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        (basicDetails.isVerified === true
                                        ) &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="email">Email</label>
                        
                        <TextField
                            className='custom-input margin-y-label'
                            id='email'
                            name='email'
                            disabled
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            placeholder='Enter your email address'
                            InputProps={{
                                style: {
                                    height: '48px'
                                }
                            }}
                        />
                    </Box>

                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="phone">Phone Number</label>

                        <TextField
                            className='custom-input margin-y-label'
                            disabled
                            id='phone'
                            name='phone'
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                            placeholder='Enter your mobile contact number'
                            InputProps={{
                                style: {
                                    height: '48px'
                                }
                            }}
                        />
                    </Box>

                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="occupation">Occupation</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='occupation'
                            name='occupation'
                            disabled={basicDetails.isVerified === true ? true : false}
                            value={formik.values.occupation || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.occupation && Boolean(formik.errors.occupation)}
                            helperText={formik.touched.occupation && formik.errors.occupation}
                            placeholder='Enter your occupation'
                            InputProps={{
                                style: {
                                    height: '48px'
                                },
                                endAdornment: <InputAdornment position="start">
                                    {
                                        (basicDetails.isVerified === true
                                        ) &&
                                        <img src={VerifiedTickIcon} alt="verified-tick" />

                                    }
                                </InputAdornment>,
                            }}
                        />
                    </Box>

                    <Box className='margin-y'>
                        <FormControl>
                            <label className='custom-label dark-label' id="gender">Gender</label>

                            <RadioGroup
                                aria-labelledby="gender"
                                name="gender"
                                onChange={formik.handleChange}
                                value={formik.values.gender}
                            >
                                <FormControlLabel className='sub-text radio-btn' value="MALE" control={<Radio />} label="Male" />
                                <FormControlLabel className='sub-text radio-btn' value="FEMALE" control={<Radio />} label="Female" />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    <Box className='margin-y'>
                        <div className='custom-label dark-label' >Profile Picture</div>

                        <Box className="d-flex items-center gap-2 margin-y">
                            <div>
                                <img
                                    className='circle-img shadow-light'
                                    alt='profile'
                                    src={profileString === "" ?
                                        // signUpDetails.basicDetails.photoPath??DefaultProfile50x
                                        formik.values.photo
                                        : profileString}
                                />
                            </div>
                            <Button onClick={() => fileUploadRef?.current?.click()} variant='outlined' className='section-head upload-btn' size='small' style={{ textTransform: 'none' }}>Upload photo</Button>
                            <input
                                ref={fileUploadRef}
                                name={"profilePicture"}
                                type="file"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    // 1000000 bytes is 1MB
                                    if (event.currentTarget.files !== null && event.currentTarget.files[0].size > 2500000) {
                                        enqueueSnackbar("Max file size allowed is 2.5MB", { variant: 'error' });
                                        return;
                                    }
                                    formik.setFieldValue("profilePicture", event.currentTarget.files?.[0])
                                }}
                                style={{ display: 'none' }}
                            />
                        </Box>
                    </Box>
                </Box>

                { basicDetails.userPreference !== 'MARKET_PLACE' &&  (<><p className="section-head">Carpool preferences</p>

                <Box className='margin-x'>
                    <p className='custom-label dark-label margin-y'>Select how you plan to use EcoPooling</p>

                    <Box marginBottom="40px">
                        <FormControl>
                            <RadioGroup
                                name={"carPoolPreference"}
                                onChange={formik.handleChange}
                                value={formik.values.carPoolPreference}
                            >
                                <FormControlLabel className='sub-text radio-btn' value="OFFER" control={<Radio />} label="I will be looking to offer carpools" />
                                <FormControlLabel className='sub-text radio-btn' value="FINDER" control={<Radio />} label="I’m looking to book passenger seats on carpools " />
                                <FormControlLabel className='sub-text radio-btn' value="BOTH" control={<Radio />} label="Both driver and passenger" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {/* <Box className="d-flex justify-center items-center">
                        <Button className='next-btn custom-btn mb-40 btn-width btn-text' type={"submit"} fullWidth>
                            {formik.isSubmitting ? "LOADING..." : "UPDATE"}
                        </Button>
                    </Box> */}
                    
                </Box></>)
                }
                <CustomFixedButton text={formik.isSubmitting ? "LOADING..." : "UPDATE"} disable={formik.isSubmitting}/>
            </form>
        </div>
    )
}
export default ProfileUpdate;
