import './style.scss';
import { Box } from '@mui/material';
import Header from '../../../components/header-1';
import Car from '../../../assets/images/car.svg';
// import Download from '../../../assets/images/download.svg';
// import Notes from '../../../assets/images/notes.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect} from 'react';
import { getCarDetails } from '../../../services/car-detail';
import { enqueueSnackbar } from 'notistack';
import { updateVehicleDetails } from '../../../slice/login';
import { useNavigate } from 'react-router-dom';
//import { downloadDeclration } from '../../../services/declaration';

const VehicleDetail = () => {
    const userReducer = useSelector((state: RootState)=>state.userDetailsReducer);
    const { vehicleDetails, basicDetails } = userReducer;
    const navigate = useNavigate();
    const dispatch = useDispatch();
   // const [loading,setLoading] = useState(false);

    useEffect(()=>{
        vehicleDetails.registrationNo === "" && getCarDetails().then((res:any)=>{
            dispatch(updateVehicleDetails(res));
        }).catch((error: any)=>{
            const msg = error.response.data?.message?? "Could not fetch details";
            enqueueSnackbar(msg,{variant: 'error'});
        })
    //eslint-disable-next-line
    },[])

    // const downloadDeclrationFile = async ()=>{
    //     if(loading) return;
    //     try {
    //         setLoading(true);
    //         await downloadDeclration();
    //         enqueueSnackbar("File Downloaded",{variant: "success"});
    //     } catch (error) {
    //         enqueueSnackbar("Could not download file !",{variant: "error"});
    //     }finally{
    //         setLoading(false);
    //     }
    // }

    return (
        <div id='vehicle-detail'>
            <Header rightIcon={basicDetails.photo}/>
            <Box className='padding-x rounded-top-green'>
                <div className="sub-header">
                    <div className="sub-header-icon-container">
                    <img alt='signature' src={Car} />
                    </div>
                    <p className='heading sub-text-new'>My vehicle</p>
                </div>
            </Box>

            <Box className='section-head section-head-bg-light-green center' justifyContent='space-between'>
                <p>
                    Vehicle details
                </p>
                <p className="link edit-new edit-link-color-new " onClick={()=>navigate("/update/vehicle")}>
                    Edit
                </p>
            </Box>

            <Box marginX='16px' marginTop='16px' className='car-detail'>
                <Box marginBottom='16px' className='center' justifyContent='space-between'>
                    <Box width='168px'>
                        <p className='custom-label' style={{color:"#616161"}}>Car registration</p>
                        <p className='sub-text-14' style={{fontWeight:'400',fontSize:'14px',lineHeight:'21px'}}>{vehicleDetails.registrationNo}</p>
                    </Box>
                    <Box width='168px'>
                        <p className='custom-label' style={{color:"#616161"}}>Number of passenger seats</p>
                        <p className='sub-text-14' style={{fontWeight:'400',fontSize:'14px',lineHeight:'21px'}}>{vehicleDetails.noOfSeatAvailable}</p>
                    </Box>
                </Box>

                {/* <Box width='168px' marginBottom='18px'>
                    <p className='custom-label' style={{color:"#616161"}}>Number of child seats</p>
                    <p className='sub-text-14'>{vehicleDetails.noOfChildSeatAvailable}</p>
                </Box> */}

                {/* <Box className='declarations-box' marginBottom='24px'>
                    <p className='custom-label'>You made the following declarations</p>

                    <Box marginTop='8px' className='center sub-text-14 declarations-points' justifyContent='start'>
                        <img alt='checked-circle' src={CheckedCircle} />
                        <p className={'sub-text-14'}>I have a valid driving license</p>
                    </Box>

                    <Box className='center sub-text-14 declarations-points' justifyContent='start'>
                        <img alt='checked-circle' src={CheckedCircle} />
                        <p className={'sub-text-14'}>My vehicle has a valid MOT</p>
                    </Box>

                    <Box className='center sub-text-14 declarations-points' justifyContent='start'>
                        <img alt='checked-circle' src={CheckedCircle} />
                        <p className={'sub-text-14'}>I have an active car insurance policy</p>
                    </Box>
                </Box> */}

                {/* <Box className='location-box'>
                    <div className="inline cursor-pointer"onClick={downloadDeclrationFile}>
                        <Box display='flex' alignItems='center'>
                            <img alt='doc' src={Notes} />
                            <p className='custom-label' style={{color:"#616161"}}>Download declaration details</p>
                        </Box>
                        <img alt='doc' src={Download} />
                    </div>
                </Box> */}
            </Box>

        </div>
    )
}

export default VehicleDetail;