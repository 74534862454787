import "./style.scss"
import { Box, TextField, FormControl, MenuItem, Select } from "@mui/material"

import Header from "../../../components/header-1";
// import DefaultProfile from './../../../assets/images/default-profile.svg';
import Person from './../../../assets/images/person.svg';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { enqueueSnackbar } from "notistack";
import { useFormik } from "formik";
import { updateVehicle } from "../../../services/update/vehicle";
import { updateVehicleDetails } from "../../../slice/login";
import { useNavigate } from "react-router-dom";
import CustomFixedButton from "../../../components/custom-fixed-button";

const VehicleUpdate = () => {
    // const dispatch = useDispatch();
    const { vehicleDetails, basicDetails } = useSelector((state: RootState) => state.userDetailsReducer);
    const { noOfChildSeatAvailable, noOfSeatAvailable, registrationNo } = vehicleDetails;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            noOfChildSeatAvailable,
            noOfSeatAvailable,
            registrationNo
        },
        onSubmit: async (values) => {
            try {
                await updateVehicle(values);
                dispatch(updateVehicleDetails(values))
                enqueueSnackbar("Updated Successfully !", { variant: 'success' })
                navigate("/dashboard/vehicle-detail")
            } catch (error) {
                console.log(error);
                enqueueSnackbar("Couldn't update !", { variant: 'error' })
            }
        }
    });

    return (
        <div id={"profile"}>
            <Header rightIcon={basicDetails.photo} />
            <Box className='padding-x rounded-top-green'>
                <div className="sub-header">
                    <div className="sub-header-icon-container">
                    <img alt='person' src={Person} />
                    </div>
                    <p className='sub-text sub-text-new'>Please update your vehicle profile.</p>
                </div>
            </Box>

            <form onSubmit={formik.handleSubmit} className="d-flex flex-column flex-1">
                <Box className='margin-x d-flex flex-column flex-1 btn-padding-responsive'>
                    <Box className='margin-y'>
                        <label className='custom-label' htmlFor="first_name">Car registration</label>

                        <TextField
                            className='custom-input margin-y-label'
                            id='first_name'
                            name="registrationNo"
                            value={formik.values.registrationNo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.registrationNo && Boolean(formik.errors.registrationNo)}
                            helperText={formik.touched.registrationNo && formik.errors.registrationNo}
                            placeholder='For example CU57ABC or ABC1234'
                            InputProps={{
                                style: {
                                    height: '48px'
                                }
                            }}
                        />
                    </Box>

                    <Box className='mb-3'>
                        <label className='custom-label' htmlFor="first_name">Number of seats available for carpool passengers</label>

                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className='margin-y-label'
                                name="noOfSeatAvailable"
                                value={formik.values.noOfSeatAvailable}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                renderValue={(selected) => {
                                    if (selected === 0) {
                                        return <span style={{ fontSize: "14px", color: "#9E9E9E" }}>Please select an option</span>;
                                    }

                                    return selected;
                                }}
                            >
                                <MenuItem disabled>
                                    <span style={{ fontSize: "14px" }}>Please select an option</span>
                                </MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {/* <Box className='margin-y'>
                        <label className='custom-label' htmlFor="first_name">Number of child car seats</label>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className='margin-y-label'
                                name="noOfChildSeatAvailable"
                                value={formik.values.noOfChildSeatAvailable}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem disabled>
                                    <span style={{ fontSize: "14px" }}>Please select an option</span>
                                </MenuItem>
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </Select>
                        </FormControl>
                    </Box> */}

                    {/* <p className='custom-label dark-label'>Please confirm the following statements are correct</p>
                        <Box marginBottom="19px">
                            <div className="checkbox-container">
                                <Checkbox color='primary'
                                    name={"isDrivingLicence"}
                                    checked={formik.values.isDrivingLicence}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <p className='sub-text'>I have a valid driving license</p>
                            </div>
                            <div className="checkbox-container">
                                <Checkbox color='primary'
                                    name={"isMOT"}
                                    checked={formik.values.isMOT}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <p className='sub-text'>My vehicle has a valid MOT</p>
                            </div>
                            <div className="checkbox-container">
                                <Checkbox color='primary' 
                                    name={"isInsurance"}
                                    checked={formik.values.isInsurance}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <p className='sub-text'>I have an active car insurance policy</p>
                            </div>
                        </Box> */}
                        
                     {/* <Box className="d-flex justify-center items-end update-vehicle-button-wrapper">
                        <Button className='next-btn custom-btn btn-width btn-text' fullWidth type={"submit"}>{formik.isSubmitting ? "LOADING..." : "UPDATE"}</Button>
                    </Box> */}

                    <CustomFixedButton disable={formik.isSubmitting} text={formik.isSubmitting ? "LOADING..." : "UPDATE"}></CustomFixedButton>
                </Box>
            </form>
        </div>
    )
}
export default VehicleUpdate;
