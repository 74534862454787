import React from "react";
import { Box } from "@mui/material";
import { ImageComponentOverlapping } from "../../../../components/img-overlapping";
import DriverCar from "../../../../assets/images/driver-car.svg";
import CarStearingIcon from "../../../../assets/images/stearing.svg";
import RightIconLight from "../../../../assets/images/righ-arrow-light.svg";
import loadClockGreen from "../../../../assets/images/loadClockGreen.svg";
import cancelIcon from "../../../../assets/images/cancel.svg";

interface props {
  currTab: string;
  image: string;
  handleClick: () => void;
  multipleImage: any;
  bookedSeat: string;
  totalSeats: string;
  text: string;
  firstName: string;
  lastName: string;
  tripColorClass: string;
  tripStatusIcon: string;
  tripStatus: string;
  categoryName: string;
  time: string;
}
const Trip = ({
  currTab,
  image,
  multipleImage,
  bookedSeat,
  text,
  totalSeats,
  handleClick,
  firstName,
  lastName,
  tripColorClass,
  tripStatus,
  tripStatusIcon,
  categoryName,
  time,
}: props) => {
  return (
    <div>
      <Box display="flex" className="click margin-y" onClick={handleClick}>
        {currTab !== "DRIVER" ? (
          <img
            alt="profile"
            className="image-thumbnail rounded-full bordered-2 shadow-light"
            src={image}
          />
        ) : (
          <>
            {
              (bookedSeat > '0' && tripStatus !== 'Cancelled') ? (
                <div style={{ minWidth: "50px", paddingTop: "25px" }}>
                  <ImageComponentOverlapping image={multipleImage} />
                </div>
              ) : (
                <div
                  className="image-thumbnail rounded-full bordered-2 shadow-light d-flex justify-center items-center bg-gray"
                >
                  <img src={((tripStatus === 'Cancelled') || (tripStatus === 'Expired')) ? cancelIcon : loadClockGreen} alt="no user booked icon" />
                </div>
              )
            }
          </>
        )}

        <Box marginLeft="18px" className="info">
          <div className="sub-text-14 driver-name fw-700 d-flex items-center">
            {currTab === "PASSENGER" &&
              `${firstName} ${lastName}`}
          </div>

          <Box display="flex" marginY={"4px"}>
            {
              // if the current tab is passenger show car icon else show stearing
              currTab === "PASSENGER" ? (
                <div className="d-flex">
                  <div className="d-flex">
                    <img alt="car-driver" src={DriverCar} />
                  </div>

                  <div className={`${tripColorClass} d-flex item-center p-02 px-05 rounded-16`}>
                    {/* {
                      tripStatusIcon && (
                        <div className="mx-1 d-flex">
                          <img alt="car-driver " src={tripStatusIcon} />
                        </div>
                      )
                    } */}

                    <div className={`${tripStatus === 'Confirmed' && 'text-700'} sub-text`}>{tripStatus}</div>
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-05">
                  <div className="d-flex">
                    <img alt="car-stearing" src={CarStearingIcon} />
                  </div>

                  <div className={`${tripColorClass} d-flex item-center p-02 px-05 rounded-16`}>
                    {/* {
                      tripStatusIcon && (
                        <div className="mx-1 d-flex">
                          <img alt="car-driver " src={tripStatusIcon} />
                        </div>
                      )
                    } */}

                    <div className={`text-500 sub-text ${(bookedSeat !== '0' && tripStatus !== 'Cancelled') ? 'text-700' : (tripStatus !== 'Cancelled' && 'trip-text-light-gray')}`}>{tripStatus}</div>
                  </div>
                </div>
              )
            }
          </Box>
          <Box>
            <p className="sub-text text-500 text-gray mb-1 trip-text-light-gray">
              {categoryName}
              {time}
            </p>
          </Box>
        </Box>
        <Box marginLeft={"auto"}>
          <img src={RightIconLight} alt={"right arrow"} />
        </Box>
      </Box>
    </div>
  );
};

export default Trip;
