import checkMark from '../../../assets/images/check_mark.svg'
import { Button,Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './style.scss'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getPurchaseHistory } from '../../../services/payment';
import { setAllPurchasedCard } from '../../../slice/marketplace';
const PaymentDone = () => {
  const navigate = useNavigate();
  const {user:{_id}} = useSelector((state:RootState)=> state.userDetailsReducer)
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const result = await getPurchaseHistory({ userId: _id });
      dispatch(setAllPurchasedCard(result));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(()=>{
    fetchData();
    // eslint-disable-next-line 
  },[])

  return (
    <div className="payment-success-wrapper-main">
      <div className="sub-payment-success-wrapper">
        <div className="success-mark-container">
          <img src={checkMark} alt="all-done" />
        </div>
        <div className="success-text mt-3">All done!</div>
        <div className="sub-success-text mt-3 text-md">
          We'll notify you via WhatsApp once your gift card is ready.
        </div>
        <div className="sub-success-text mt-3 text-md">
          It can take up to 12 hours (typically within 1 hour) for us to process
          your purchase.
        </div>
      </div>
      <Box className="d-flex justify-center items-center">
      <Button
        className="next-btn custom-btn btn-width mt-3"
        fullWidth
        onClick={() => navigate("/dashboard/market-place")}
      >
        Done
      </Button>
      </Box>
    </div>
  );
}

export default PaymentDone